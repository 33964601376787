import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import {ReactSVG} from 'react-svg';
import svgLimits from '../../../../sources/images/interface/limits.svg';
import svgDelete from '../../../../sources/images/interface/basket.svg';
import InputText from '../../../../components/InputText';

import {useTranslation} from 'react-i18next';
import CheckBox from '../../../../components/CheckBox';
import ColorPicker from '../../../../components/ColorPicker';
import AlertError from '../../../../components/AlertError';
import Select from '../../../../components/Select';
import SetValueFromPassport from '../../../../components/SetValueFromPassport';
import {localeService} from 'services/locale.service';

function SetTableLimits({limits, onChange, dataSources}) {
  const {t} = useTranslation();
  const [isOpened, setOpened] = React.useState(false);
  const [chartLimits, setChartLimits] = React.useState(limits);
  const [error, setError] = React.useState('');
  const [isFetching, setFetching] = React.useState(false);

  React.useEffect(() => {
    setChartLimits(limits);
  },[limits]);

  const deleteLimit = (idx) => {
    let limitList = chartLimits.values;
    limitList.splice(idx, 1);
    if (idx >= 0) {
      setChartLimits({...chartLimits, values: limitList});
    }
  };

  const createLimit = () => {
    let limitList = [...chartLimits.values];
    limitList.push({value: 0, name: '', color: '#B80000', dataSource: null, type: ''});
    setChartLimits({isVisible: true, values: limitList});
  };

  return (<>
    <button className={'btn svg-btn mr1' + (chartLimits && chartLimits.isVisible ? ' active' : '')}
            title={localeService.isRussian() ? 'Установить пороги' : 'Set Limits'}
            onClick={() => setOpened(true)}>
      <ReactSVG src={svgLimits}/>
    </button>
    <Modal onCancel={() => setOpened(false)} footer={false} isClosable={false} width={'700px'} overflow={'auto'}
           isOpen={isOpened}
           title={localeService.isRussian() ? 'Установить пороги' : 'Set Limits'}>
      <form>
        {chartLimits && chartLimits.values && chartLimits.values.map((limit, i) => {
          let selectedDataSource = null;
          if (limit.dataSource) {
            selectedDataSource = dataSources.find(el => el.id === limit.dataSource.id);
          }
          return <div className={'group'} key={'limit' + i}>
            <label className={'group-header'}>{localeService.isRussian() ? 'Порог ' : 'Limit '}{(i + 1)}</label>

            {/**
             Выбор источника данных
             */}
            <Select label={localeService.isRussian() ? 'Источник данных' : 'Data Source'}
                    valueList={dataSources.map(ds => {
                      return {id: ds.id, name: ds.parameters.dataSourceName};
                    })}
                    onSelect={(ds) => {
                      let limitList = chartLimits.values;
                      limitList[i].dataSource = ds;
                      setChartLimits({...chartLimits, values: limitList});
                    }}
                    value={selectedDataSource ? {...selectedDataSource, name: selectedDataSource.parameters.dataSourceName} : null}/>

            {/**
             Выбор типа порога (фиксированное значение или из паспорта)
             */}
            {limit.dataSource &&
            <div className={'mb-4'}>
              <i>{localeService.isRussian() ? 'Задать значение' : 'Set Value'}</i>
              <div className={'d-flex mt-2'}>
                <button className={'btn btn-outline-primary ' + (limit.type !== 'FIXED_VALUE' ? '' : 'active')}
                        onClick={(e) => {
                          e.preventDefault();
                          let limitList = chartLimits.values;
                          limitList[i].type = 'FIXED_VALUE';
                          setChartLimits({...chartLimits, values: limitList});
                        }}>{localeService.isRussian() ? 'Вручную' : 'Manually'}</button>
                <button className={'ms-3 btn btn-outline-primary ' + (limit.type !== 'PASSPORT_VALUE' ? '' : 'active')}
                        onClick={(e) => {
                          e.preventDefault();
                          let limitList = chartLimits.values;
                          limitList[i].type = 'PASSPORT_VALUE';
                          setChartLimits({...chartLimits, values: limitList});
                        }}>{localeService.isRussian() ? 'Выбрать из паспорта' : 'Select from Passport'}</button>
              </div>
            </div>
            }


            {/**
             Задание фиксированного значения
             */}
            {limit.dataSource && limit.type === 'FIXED_VALUE' &&
            <div className={'d-flex justify-content-between'} style={{padding: 0, display: 'flex', width: '100%'}}>
              <InputText isDecimal={true} value={limit.value}
                         label={t('value')}
                         onChange={value => {
                           let limitList = chartLimits.values;
                           limitList[i].value = value;
                           limitList[i].type = 'FIXED_VALUE';
                           setChartLimits({...chartLimits, values: limitList});
                         }}/>
            </div>}

            {/**
             Задание значения из паспорта
             */}
            {limit.dataSource && limit.type === 'PASSPORT_VALUE' &&
            <SetValueFromPassport limit={limit} onChange={(passportValue) => {
              let {value, ...props} = passportValue;
              let limitList = chartLimits.values;
              limitList[i].value = value;
              limitList[i].type = 'PASSPORT_VALUE';
              limitList[i].props = props;
              setChartLimits({...chartLimits, values: limitList});
            }}/>
            }


            <div className={'d-flex justify-content-between align-items-start'} key={'limit' + i}
                 style={{padding: 0, display: 'flex', width: '100%'}}>
              <InputText value={limit.name}
                         label={(localeService.isRussian() ? 'Название порога ' : 'Limit Name')}
                         onChange={text => {
                           let limitList = chartLimits.values;
                           limitList[i].name = text;
                           setChartLimits({...chartLimits, values: limitList});
                         }}/>
              <ColorPicker style={{marginLeft: '0rem'}} color={limit.color} setColor={(color) => {
                let limitList = chartLimits.values;
                limitList[i].color = color;
                setChartLimits({...chartLimits, limits: limitList});
              }}/>
              <button className={'btn ems-btn dark'}
                      onClick={(e) => {
                        e.preventDefault();
                        deleteLimit(i);
                      }}>
                <ReactSVG src={svgDelete}/>
              </button>
            </div>
          </div>;
        })}

        {chartLimits && chartLimits.values && chartLimits.values.length > 0 &&
        <CheckBox checked={chartLimits.isVisible}
                  label={localeService.isRussian() ? 'Показывать пороги' : 'Show Limits'}
                  onChange={() => {
                    setChartLimits({...chartLimits, isVisible: !chartLimits.isVisible});
                  }}
        />
        }

        <AlertError onCancel={() => setError('')} isClosable={false} message={error} isOpen={error !== ''}/>

        {isFetching && <label>{localeService.isRussian() ? 'Подождите, идет расчет порогов...' : 'Applying Limits'}</label>}

        {!isFetching && <div className={'d-flex justify-content-center mt-5'}>
          <button type={'button'} className={'btn btn-outline-primary'}
                  onClick={() => createLimit()}>
            {localeService.isRussian() ? 'Создать порог' : 'Add Limit'}
          </button>
          <button type={'button'} className={'btn btn-outline-success'}
                  onClick={(e) => {
                    e.preventDefault();
                    setError('');
                    setFetching(true);
                    let isValid = true;
                    chartLimits.values.map(el => {
                      if (el.dataSource === null) {
                        isValid = false;
                        return;
                      }
                    });
                    if (isValid) {
                      onChange(chartLimits);
                      setFetching(false);
                      setOpened(false);
                    } else {
                      setFetching(false);
                      setError(localeService.isRussian() ? 'Пожалуйста, выберите источник данных!' : 'Please, select Data Source!');
                    }
                  }}>
            {t('apply')}
          </button>
          <button type={'button'} className={'btn btn-outline-primary'}
                  onClick={() => setOpened(false)}>
            {t('close')}
          </button>
        </div>}
      </form>
    </Modal>
  </>);
}

SetTableLimits.propTypes = {
  limits: PropTypes.object,
  onChange: PropTypes.func,
  dataSources: PropTypes.array
};


export default SetTableLimits;