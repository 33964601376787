import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CheckBox from './CheckBox';
import InputText from './InputText';
import {useTranslation} from 'react-i18next';
import SelectDate from './SelectDate';
import {ReactSVG} from 'react-svg';
import svgDelete from '../sources/images/interface/basket.svg';
import {localeService} from '../services/locale.service';
import InputTime from './InputTime';
import ArrayOfHours from './ArrayOfHours';
import SelectLayOut from './SelectLayOut';
import InputTextLayOut from './InputTextLayOut';
import {nanoid} from 'nanoid';

function AddAnalyticParametersToChart({demandFunction, onChangeParam, datetimeFilter}) {
    const {t} = useTranslation();
    const [func, setFunc] = useState(demandFunction);
    useEffect(() => {
        setFunc(demandFunction);
    }, [demandFunction]);

    const onChange = (func) => {
        setFunc(func);
        onChangeParam(func);
    };

    return <>
        {func.args.map((param, i) => {
            if (param.count > 0) {
                return [...Array(param.values.length).keys()].map((_, j) => {
                    if (param.type === 'DATE') {
                        // let selectedDate = new Date((param.args && param.args[i] && param.args[i].values[j]) ? moment(param.args[i].values[j]) : moment(datetimeFilter.startDate));
                        return (
                            <SelectDate key={'select-datetime-' + i + j}
                                        selectedDate={new Date(func.args[i].values[j])}
                                        label={t('analytic_parameter.' + param.name)}
                                        onChange={date => {
                                            let paramFunction = func;
                                            delete paramFunction.startDate;
                                            delete paramFunction.finishDate;
                                            if (date.isBefore(datetimeFilter.startDate)) {
                                                paramFunction.startDate = date.set('hours', 0).set('minute', 0).set('second', 0);
                                            }
                                            if (date.isAfter(datetimeFilter.finishDate)) {
                                                paramFunction.finishDate = date.set('hours', 23).set('minute', 59).set('second', 59);
                                            }
                                            paramFunction.args[i].values[j] = date.format('YYYY-MM-DD');
                                            onChange(paramFunction);
                                        }}
                            />
                        );
                    }
                    if (param.type === 'BOOLEAN') {
                        return <CheckBox key={'analyticFunct' + j + i} label={t('analytic_parameter.' + param.name)}
                                         checked={param.values[j] === 'true'}
                                         onChange={(checked) => {
                                             let paramFunction = func;
                                             paramFunction.args[i].values[j] = !checked ? 'false' : 'true';
                                             onChange(paramFunction);
                                         }}/>;
                    }

                    if (param.type === 'SELECT') {
                        return <SelectLayOut key={nanoid()} label={t('analytic_parameter.' + param.name)}
                                             valueList={param.selectValues} translateName={true}
                                             value={{name: param.values[j]}}
                                             onSelect={(val) => {
                                                 let paramFunction = func;
                                                 paramFunction.args[i].values[j] = val.name;
                                                 onChange(paramFunction);
                                             }}/>;
                    }

                    if (param.type === 'INTEGER') {
                        return <InputTextLayOut key={'integer-' + j + i} label={t('analytic_parameter.' + param.name)}
                                                value={param.values[j]}
                                                isInteger={true} onChange={(text) => {
                            let paramFunction = func;
                            paramFunction.args[i].values[j] = text;
                            onChange(paramFunction);
                        }}/>;
                    }

                    if (param.type === 'FLOAT') {
                        return <InputTextLayOut key={'float-' + j + i} label={t('analytic_parameter.' + param.name)}
                                                value={param.values[j]}
                                                isDecimal={true} onChange={(text) => {
                            let paramFunction = func;
                            paramFunction.args[i].values[j] = text;
                            onChange(paramFunction);
                        }}/>;
                    }

                    return <InputText key={'param-' + i + j}
                                      label={t('analytic_parameter.' + param.name) + (j + 1) + ' [' + param.type + ']'}
                                      value={param.values[j]}
                                      onChange={(text) => {
                                          let paramFunction = func;
                                          paramFunction.args[i].values[j] = text;
                                          onChange(paramFunction);
                                      }}/>;
                });
            }

            if (param.count < 0 && param.type === 'ARRAY_OF_HOURS') {
                return (
                    <div className={'d-flex'} key={'aoh' + i}>
                        <ArrayOfHours onChange={hours => {
                            let paramFunction = func;
                            paramFunction.args[i].values = hours;
                            onChange(paramFunction);
                        }} label={t('analytic_parameter.' + param.name)}/>
                    </div>
                );
            }

            if (param.count < 0 && param.type !== 'ARRAY_OF_HOURS') {
                return (<>
                    <div key={'add-remove-param-' + i} className={'d-flex'}>
                        <button type="button" className="btn btn-outline-primary default mb-3"
                                onClick={() => {
                                    let paramFunction = func;
                                    if (param.type === 'DATE' || param.type === 'TIME') {
                                        paramFunction.args[i].values.push(moment(datetimeFilter.finishDate).format('YYYY-MM-DD'));
                                    } else if (param.type === 'FLOAT' || param.type === 'INTEGER') {
                                        paramFunction.args[i].values.push(0);
                                    } else if (param.type === 'ARRAY_OF_HOURS') {
                                        paramFunction.args[i].values.push(0);
                                    } else if (param.type === 'SELECT') {
                                        paramFunction.args[i].values.push('');
                                    } else {
                                        paramFunction.args[i].values.push(null);
                                    }
                                    onChange(paramFunction);
                                }}>
                            {' + ' + t('analytic_parameter.' + param.name)}

                        </button>
                    </div>
                    {param && param.values.map((val, j) => {
                        if (param.type === 'DATE') {
                            return (
                                <div className={'d-flex justify-content-between'} key={j}
                                     style={{padding: 0, display: 'flex', width: '100%', position: 'relative'}}>
                                    <div key={'removable-date-' + i + j} className={'input-group understroke'}
                                         style={{justifyContent: 'space-between'}}>
                                        <div className={'input-group-prepend'}>
                                            <label>{t('analytic_parameter.' + param.name) + (j + 1)}</label>
                                            <DatePicker
                                                // dateFormat="DD-MMM-YYYY"
                                                key={'removable-date-' + j + i}
                                                showPopperArrow={false}
                                                selected={new Date(func.args[i].values[j])}
                                                onChange={date => {
                                                    const paramFunction = func;
                                                    paramFunction.args[i].values[j] = moment(date).format('YYYY-MM-DD');
                                                    onChange(paramFunction);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <button className={'btn danger svg-btn'}
                                            style={{marginLeft: '1rem'}}
                                            title={localeService.isRussian() ? 'Удалить параметр' : 'Remove parameter'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                const paramFunction = func;
                                                paramFunction.args[i].values.splice(j, 1);
                                                onChange(paramFunction);
                                            }}>
                                        <ReactSVG src={svgDelete}/>
                                    </button>
                                </div>

                            );
                        }
                        if (param.type === 'TIME') {
                            return (
                                <div className={'d-flex justify-content-between'} key={j}>
                                    <div key={'removable-date-' + i + j} className={'input-group understroke'}
                                         style={{justifyContent: 'space-between'}}>
                                        <div className={'input-group-prepend'}>
                                            <label>{t('analytic_parameter.' + param.name) + (j + 1)}</label>
                                            <InputTime
                                                // dateFormat="DD-MMM-YYYY"
                                                value={param.values[j]}
                                                key={'removable-date-' + j + i}
                                                onChange={time => {
                                                    const paramFunction = func;
                                                    paramFunction.args[i].values[j] = time;
                                                    onChange(paramFunction);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <button className={'btn danger svg-btn'}
                                            style={{marginLeft: '1rem'}}
                                            title={localeService.isRussian() ? 'Удалить параметр' : 'Remove parameter'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                const paramFunction = func;
                                                paramFunction.args[i].values.splice(j, 1);
                                                onChange(paramFunction);
                                            }}>
                                        <ReactSVG src={svgDelete}/>
                                    </button>
                                </div>
                            );
                        }
                        if (param.type === 'FLOAT') {
                            return (
                                <div className={'d-flex justify-content-between'} key={j}>
                                    <div key={'removable-date-' + i + j} className={'input-group understroke'}
                                         style={{justifyContent: 'space-between'}}>
                                        <div className={'input-group-prepend'}>
                                            <InputTextLayOut key={'float-' + j + i}
                                                             label={t('analytic_parameter.' + param.name)}
                                                             value={param.values[j]}
                                                             isDecimal={true} onChange={(text) => {
                                                let paramFunction = func;
                                                paramFunction.args[i].values[j] = text;
                                                onChange(paramFunction);
                                            }}/>
                                        </div>
                                    </div>
                                    <button className={'btn danger svg-btn'}
                                            style={{marginLeft: '1rem'}}
                                            title={localeService.isRussian() ? 'Удалить параметр' : 'Remove parameter'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                const paramFunction = func;
                                                paramFunction.args[i].values.splice(j, 1);
                                                onChange(paramFunction);
                                            }}>
                                        <ReactSVG src={svgDelete}/>
                                    </button>
                                </div>
                            );
                        }
                        if (param.type === 'INTEGER') {
                            return <InputTextLayOut key={'integer-' + j + i}
                                                    label={t('analytic_parameter.' + param.name)}
                                                    value={param.values[j]}
                                                    isInteger={true} onChange={(text) => {
                                let paramFunction = func;
                                paramFunction.args[i].values[j] = text;
                                onChange(paramFunction);
                            }}/>;
                        }
                        if (param.type === 'SELECT') {
                            return <SelectLayOut key={nanoid()}
                                                 label={t('analytic_parameter.' + param.name)}
                                                 valueList={param.options.map(v => ({name: v}))}
                                                 translateName={true}
                                                 value={{name: param.values[j]}}
                                                 onSelect={(val) => {
                                                     let paramFunction = func;
                                                     paramFunction.args[i].values[j] = val.name;
                                                     onChange(paramFunction);
                                                 }}/>;
                        }
                    })

                    }

                </>);
            }
        })
        }
    </>;
}

AddAnalyticParametersToChart.propTypes = {
    demandFunction: PropTypes.object,
    datetimeFilter: PropTypes.object,
    onChangeParam: PropTypes.func
};

export default AddAnalyticParametersToChart;