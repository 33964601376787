import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {SketchPicker} from 'react-color';
import {ReactSVG} from 'react-svg';
import svgColor from '../sources/images/interface/color.svg';
import {localeService} from '../services/locale.service';
import ClickOutside from '../components/ClickOutside';
import {useTranslation} from 'react-i18next';

function ColorPicker({color, setColor, style, disabled}) {
    const [isOpen, setOpen] = useState(false);
    const {t} = useTranslation();
    const [newColor, setNewColor] = useState(color);

    return <>
        <div className={'popover-container'}>
            <button type={'button'}
                    title={disabled ? (color || 'Цвет не задан') : (localeService.isRussian() ? 'Изменить цвет' : 'Change color')}
                    className={'color-picker-btn'}
                    style={{cursor: (disabled ? 'default' : 'pointer'),backgroundColor: color, position: 'relative', ...style}}
                    onClick={() => {
                        if(!disabled) {
                            setOpen(!isOpen);
                        }
                    }}>
                <ReactSVG src={svgColor}/>
            </button>
            {isOpen && newColor &&
            <ClickOutside key={'popover'} onClick={() => setOpen(false)}>
                <div style={{
                    position: 'absolute',
                    backgroundColor: newColor,
                    border: '1px solid grey',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    // bottom: 0,
                    // right: '52px',
                    height: 'max-content',
                    zIndex: 2
                }}>

                    <SketchPicker color={newColor}
                                  onChange={(color) => {
                                      // setOpen(false);
                                      setNewColor(color.hex);
                                  }
                                  }/>
                    <div className={'d-flex justify-content-center'}>
                    <button className={'btn btn-outline-primary default mt1 center'} style={{backgroundColor: 'white'}}
                            onClick={() => {
                                setColor(newColor);
                                setOpen(false);
                            }}>{t('apply')}</button>
                    </div>

                </div>
            </ClickOutside>
            }
        </div>
    </>;
}

ColorPicker.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object,
    setColor: PropTypes.func,
    disabled: PropTypes.bool
};

export default ColorPicker;