import React from 'react';
import Portal from './Portal';
import PropTypes from 'prop-types';
import '../styles/modal.scss';
import ClickOutside from './ClickOutside';
import {useTranslation} from 'react-i18next';


function Modal({title, isOpen, onCancel, children, isClosable, footer, onSubmit, isConfirm, width, height, overflow}) {
    const {t} = useTranslation();
    return (
        <>
            {isOpen &&
            <Portal>
                <div /*className={'modal-layout open'}*/ className={'modal-dialog'} style={{minWidth: width}}>
                    <ClickOutside onClick={() => onCancel()} disabled={!isClosable}>
                        <div /*className="modal-container"*/ className={'modal-content'}>
                            {title && <div className={'modal-header'}>
                                <h5 className={'modal-title'}>{title}</h5>
                                {onCancel != undefined &&
                                <button type='button' onClick={() => onCancel()} className={'btn-close'}/>}
                            </div>}
                            <div className={'modal-body'}
                                 style={{maxWidth: width, maxHeight: height, overflow: overflow}}>
                                {children}
                            </div>
                            {footer &&
                            <div className={'modal-footer'}>
                                {onCancel != undefined &&
                                <button type={'button'} className={'btn btn-outline-primary default'}
                                        onClick={() => onCancel()}>{t(isConfirm ? 'no' : 'close')}</button>}
                                {onSubmit != undefined &&
                                <button type={'button'} className={'btn btn-outline-success ml1'}
                                        onClick={() => onSubmit()}>{t(isConfirm ? 'yes' : 'apply')}</button>
                                }
                            </div>}
                        </div>
                    </ClickOutside>
                </div>
            </Portal>
            }
        </>
    );
}

Modal.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.node,
    isClosable: PropTypes.bool,
    footer: PropTypes.bool,
    isConfirm: PropTypes.bool,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.string,
    overflow: PropTypes.string
};

Modal.defaultProps = {
    title: null,
    isOpen: false,
    // onCancel: () => {},
    // onSubmit: () => {},
    children: null,
    isClosable: true,
    footer: true,
    isConfirm: false,
    width: 'auto',
    height: 'auto',
    overflow: 'unset'
};


export default Modal;
