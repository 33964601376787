import React from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../../constants/index';
import {localeService} from '../../../../services/locale.service';
import Modal from '../../../../components/Modal';
import StrategyCreate from './StrategyCreate';
import StrategyView from './StrategyView';
import WidgetChartPreview from '../WidgetChartPreview';

function Strategy({widgetProps, mode, onCloseEditSingleMode, sizeParams}) {
    return (
        <>
            {mode === dashboardConstants.VIEW_MODE &&
            <StrategyView widgetProps={widgetProps}/>
            }
            {mode === dashboardConstants.EDITION_MODE &&
            <WidgetChartPreview widgetProps={widgetProps} sizeParams={sizeParams}/>
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} width={800}
                   isClosable={false} footer={false} onCancel={() => onCloseEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <StrategyCreate widgetProps={widgetProps} onClose = {() => onCloseEditSingleMode()}/>
            </Modal>
            }
        </>
    );
}

Strategy.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    sizeParams: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default Strategy;