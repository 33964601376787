/*eslint-disable no-unused-vars*/
import React, {useState} from 'react';
import ImageUploader from './components/ImageUploader';
import {imageService} from './services/image.service';
import {apiConstants} from './constants';

function ImageMarker() {
    const [image, setImage] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [newMarker, setNewMarker] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [imageSize, setImageSize] = useState({width: 0, height: 0});
    const [moveMarker, setMoveMarker] = useState(null);
    const [cursorCoordinates, setCursorCoordinates] = useState(null);
    const [isMouseOverImage, setOverImage] = useState(false);


    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const createMarker = () => {
        setNewMarker({
            name: 'marker' + markers.length
        });
    };

    const addMarker = (e) => {
        if (newMarker) {
            const x = e.nativeEvent.offsetX;
            const y = e.nativeEvent.offsetY;
            let marker = {...newMarker};
            marker.x = x * 100 / imageSize.width;
            marker.y = y * 100 / imageSize.height;
            setMarkers([...markers, marker]);
            setNewMarker(null);
        }
    };

    const setCoordinates = (e) => {
        const x = e.nativeEvent.offsetX;
        const y = e.nativeEvent.offsetY;
        setCursorCoordinates({x: x, y: y});

        if (moveMarker) {
            let nMarkers = [...markers];
            const idx = nMarkers.findIndex(el => el.name === moveMarker.name);
            nMarkers[idx].x = x * 100 / imageSize.width;
            nMarkers[idx].y = y * 100 / imageSize.height;
            setMarkers(nMarkers);
        }
    };

    const onImgLoad = ({target: img}) => {
        console.log('onload::', img.offsetWidth, img.offsetHeight);
        setImageSize({width: img.offsetWidth, height: img.offsetHeight});
    };

    const uploadImage = (img) => {
        imageService.uploadImage(img).then(
            result => {
                console.log(result);
                // setUploadedImage(result.name);
                imageService.getImage(result.name)
                    .then(image => {
                        console.log('image', image);
                        setUploadedImage(image);
                    });
            }
        );
    };

    return (
        <div>
            <ImageUploader withPreloader={true} returnBase64={false} onChange={img => uploadImage(img)}/>

            {uploadedImage && <img src={uploadedImage}/>}
            {/*{image && <button onClick={() => createMarker()}>Add marker</button>}*/}

            {/*{image &&*/}
            {/*<div className={'mnemoscheme'} style={{position: 'relative', width: 800 + 'px'}} onMouseOver={() => setOverImage(true)} onMouseOut={() => setOverImage(false)}>*/}
                {/*<div className={'place-here'} style={{display: isMouseOverImage ? 'none' : 'block'}}/>*/}
                {/*<img src={image} style={{maxWidth: '800px', height: 'auto'}}*/}
                     {/*onLoad={({target:img}) => setImageSize({width: img.offsetWidth, height: img.offsetHeight})}*/}
                     {/*onClick={(e) => addMarker(e)} onMouseMove={e => setCoordinates(e)} onMouseUp={() => setMoveMarker(null)}*/}
                     {/*width={800}*/}
                {/*/>*/}
                {/*{markers.map((marker, i) => {*/}
                    {/*return <span className={'img-marker'}*/}
                                 {/*onMouseDown={() => setMoveMarker(marker)}*/}
                                 {/*onMouseUp={() => setMoveMarker(null)}*/}
                                  {/*key={i} style={{*/}
                        {/*position: 'absolute',*/}
                        {/*top: marker.y + '%',*/}
                        {/*left: marker.x + '%',*/}
                        {/*cursor: 'pointer',*/}
                        {/*backgroundColor: 'white',*/}
                        {/*padding: '6px'*/}
                    {/*}}>{marker.name}</span>;*/}
                {/*})}*/}
            {/*</div>*/}
            {/*}*/}
        </div>);
}

ImageMarker.propTypes = {};

export default ImageMarker;