import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import {analyticService} from '../../../../services/analyticService';
// import LoaderDots from '../../../../components/LoaderDots';
import {ReactSVG} from 'react-svg';
import svgProps from '../../../../sources/images/interface/settings.svg';
import Modal from '../../../../components/Modal';
import ArrayOfHoursSimple from '../../../../components/ArrayOfHoursSimple';
import svgWarning from '../../../../sources/images/interface/warning.svg';
import '../../../../styles/rrmse.scss';
import Loader from '../../../../components/Loader';
import LoaderDots from '../../../../components/LoaderDots';

function RRMSECalculation({dataSourceId, targetDay, inTable, width, height}) {
    const [isPropsOpened, setPropsOpened] = React.useState(false);
    const [isFetching, setFetching] = React.useState(false);
    const [rrmse, setRRMSE] = React.useState([]);
    const [error, setError] = React.useState('');

    const [functionArgs, setFunctionArgs] = React.useState(
        [
            {
                name: 'target_day',
                count: 1,
                values: [moment(targetDay).format('YYYY-MM-DD')]
            },
            {
                name: 'exception_days',
                count: 0,
                values: []
            },
            {
                name: 'peak_hours',
                count: 2,
                values: [15, 16]
            },
            {
                name: 'adjustment_hours',
                count: 14,
                values: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
            },
            {
                name: 'method_declared_chart',
                count: 0,
                values: []
            },
            {
                name: 'len_declared_chart',
                count: 0,
                values: []
            }
            // ,{
            //     name: 'method',
            //     count: 1,
            //     values: ['all_applicability ']
            // }
        ]);


    React.useEffect(() => {
        recalculateRRMSE();
    }, [targetDay]);

    const recalculateRRMSE = () => {
        setFetching(true);
        setError('');
        const timeOffset = moment().utcOffset();
        const start = moment(targetDay).subtract(45, 'days').subtract(timeOffset, 'minutes');//.set('hours', 0).set('minute', 0).set('second', 0);
        const finish = moment(targetDay).subtract(timeOffset, 'minutes').set('hours', 23).set('minute', 59).set('second', 59);

        const requestBody = {
            filter: {
                dataSources: [dataSourceId],
                dateFrom: start.format('YYYY-MM-DD HH:mm:ss'),
                dateTo: finish.format('YYYY-MM-DD HH:mm:ss')
            },
            countResult: 1,
            type: 'demand-response-baseline-applicability',
            args: [...functionArgs,
                {
                    name: 'method',
                    count: 1,
                    values: ['all_applicability']
                }
            ]
        };

        return analyticService.runAnalytic(requestBody)
            .then(response => {
                    return analyticService.getAnalyticResult(response.results[0], start, finish)
                        .then(
                            result => {
                                if (result.list.length === 7) {
                                    setRRMSE([
                                        {name: 'RRMSE без подстройки', value: result.list[2].value.toFixed(3)},
                                        {name: 'RRMSE с подстройкой', value: result.list[4].value.toFixed(3)},
                                        {name: 'RRMSE с подстройкой без понедельников', value: result.list[6].value.toFixed(3)}
                                    ]);
                                }
                            }
                        );
                }, (error) => {
                    setRRMSE([]);
                    setError(error);
                }
            ).finally(() => setFetching(false));
    };

    return (
        <div className={'rrmse-widget ' + (inTable ? 'in-table' : 'on-widget')} style={{width: width || '100%', height: height || '2rem'}}>
            {!inTable && <label>
                RRMSE<br/>
                <small>(без подст. / с подст. / с подст. без пн.)</small>
            </label>}
            {!isFetching && <div className={'rrmse-values'}>
                {rrmse.length > 1 && rrmse.map((item,i) => <span key={i} title={item.name} className={item.value > 0.2 ? 'rrmse warning' : 'rrmse'}>{item.value}</span>)}
                {error !== '' && <span className={'rrmse warning'} title={error}><ReactSVG src={svgWarning}/></span>}
            </div>}
            {isFetching && !inTable && <Loader/>}
            {isFetching && inTable && <LoaderDots/>}
            <button className={'btn svg-btn'} onClick={() => setPropsOpened(true)}>
                <ReactSVG src={svgProps}/>
            </button>
            <Modal isOpen={isPropsOpened} onCancel={() => setPropsOpened(false)} width={800}
                   onSubmit={() => {
                       setPropsOpened(false);
                       recalculateRRMSE();
                   }}>
                <form>
                    <ArrayOfHoursSimple key={'propH1'} values={functionArgs[2].values}
                                        onChange={hours => {
                                            const fArgs = [...functionArgs];
                                            fArgs[2].values = hours;
                                            fArgs[2].count = hours.length;
                                            setFunctionArgs(fArgs);
                                        }} label={'Часы подстройки'}/>
                    <ArrayOfHoursSimple key={'propH2'} values={functionArgs[3].values}
                                        onChange={hours => {
                                            const fArgs = [...functionArgs];
                                            fArgs[3].values = hours;
                                            fArgs[3].count = hours.length;
                                            setFunctionArgs(fArgs);
                                        }} label={'Пиковые часы'}/>
                </form>
            </Modal>
        </div>
    );
}

RRMSECalculation.propTypes = {
    dataSourceId: PropTypes.number,
    targetDay: PropTypes.object,
    inTable: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number
};

RRMSECalculation.defaultProps = {
    inTable: true
};

export default RRMSECalculation;

