import React, {useState} from 'react';
import {localeService} from '../../services/locale.service';
import ImportForm from './ImportEnergoSphere';
import ImportFromFile from './ImportFromFile';
import ImportManual from './ImportManual';

function Import() {

    const [importType, setImportType] = useState(null);

    return (<>
        <nav className="page-header">
            <ul className="nav-left">
                <li><h5>{localeService.isRussian() ? 'Импорт данных' : 'Data Import'}</h5></li>
            </ul>
        </nav>
        <div>
            <div className={'container-700'}>
                <h5 className={'mt-5 mb-4'}>
                    {localeService.isRussian() ? 'Tип импорта' : 'Import type'}
                </h5>
                <div className={'d-flex'}>
                    <button type="button"
                            className={'btn btn-outline-primary default ' + (importType === 'importFromFile' ? 'active' : '')}
                            onClick={() => setImportType('importFromFile')}>{localeService.isRussian() ? 'Импорт из файла' : 'Import from file'}</button>
                    {/*<button type="button"*/}
                    {/*className={'btn btn-outline-primary default ' + (importType === 'importForm' ? 'active' : '')}*/}
                    {/*onClick={() => setImportType('importForm')}>{localeService.isRussian() ? 'Импорт из EnergoSphere' : 'Import from EnergoSphere'}</button>*/}
                    <button type="button"
                            className={'ms-5 btn btn-outline-primary default ' + (importType === 'manualImport' ? 'active' : '')}
                            onClick={() => setImportType('manualImport')}>{localeService.isRussian() ? 'Ручной ввод данных' : 'Manual Data Input'}</button>
                </div>
            </div>

            {importType === 'importFromFile' && <ImportFromFile/>}
            {importType === 'importForm' && <ImportForm/>}
            {importType === 'manualImport' && <ImportManual/>}


        </div>
    </>);
}

export default Import;