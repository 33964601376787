import React from 'react';
import PropTypes from 'prop-types';
import {deviceService} from '../../../services/device.service';
import {localeService} from '../../../services/locale.service';
import {Link} from 'react-router-dom';

function CapacityVariableList({capacity}) {
    const [variableList, setVariableList] = React.useState([]);
    const [isEmpty, setEmpty] = React.useState(false);

    React.useEffect(() => {
        setEmpty(false);
        setVariableList([]);
        deviceService.getVariablesByCapacity(capacity.id).then(
            result => {
                console.log('getVariablesByCapacity', result);
                if(result.length > 0){
                    Promise.all(result.map(v => {
                        return deviceService.getDevice(v.deviceId).then(
                            device => {
                              return {...v, device: device.result};
                            }
                        );
                    })).then(vList => {
                        console.log('vList', vList);
                        setVariableList(vList);
                    });

                }else{
                    setEmpty(true);
                }

            }
        );
    },[capacity]);

    return (
        <table className="table">
            <thead>
                <tr>
                    <td className={'help-text'}>ID</td>
                    <td>{localeService.isRussian() ? 'Имя переменной' : 'Variable Name'}</td>
                    <td>{localeService.isRussian() ? 'Режим' : 'Mode'}</td>
                    <td>{localeService.isRussian() ? 'Тип' : 'Type'}</td>
                    <td>{localeService.isRussian() ? 'Устройство' : 'Device'}</td>
                </tr>
            </thead>
            <tbody>
            {variableList.length > 0 && variableList.map((v, i) => {
                return <tr key={i}>
                    <td className={'help-text'}>{v.id}</td>
                    <td>{v.name}</td>
                    <td>{v.mode}</td>
                    <td>{v.device.deviceProtocol.name}</td>
                    <td><Link to={'/device/' + v.device.id}>{v.device.name}</Link></td>
                </tr>;
            })}
            {isEmpty && <tr><td colSpan={4}>{localeService.isRussian() ? 'Список переменных пуст' : 'There are no variables'}</td></tr>}
            </tbody>
        </table>
    );
}

CapacityVariableList.propTypes = {
    capacity: PropTypes.object
};

export default CapacityVariableList;