import {apiConstants} from '../constants';
import {apiService} from './api.service';

export function getTariffList(){
    return apiService.fetchGet(apiConstants.TARIFF_LIST);
}

export function getOrganizationBalance(){
    return apiService.fetchGet(apiConstants.ORGANIZATION_BALANCE);
}

export function getOrganizationTariff(){
    return apiService.fetchGet(apiConstants.ORGANIZATION_TARIFF);
}

export function changeTariff(tariffCode){
    return apiService.fetchPost(apiConstants.TARIFF_CHANGE,
        JSON.stringify({
        changeTariffCode: tariffCode
    })).then(()=>{
        window.location.reload();
    });
}

export function addOrgBalance(sum){
    return apiService.fetchPost(apiConstants.ORGANIZATION_ADD_BALANCE,
        JSON.stringify({
        value: sum
    })
    );
}

export const tariffService = {
    getTariffList,
    getOrganizationBalance,
    getOrganizationTariff,
    changeTariff,
    addOrgBalance
};