import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../components/Loader';
import {localeService} from '../../services/locale.service';
import {capacityService, dashboardService} from '../../services';
import DashboardFilterPanel from './DashboardFilterPanel';
import WidgetsGrid from './WidgetsGrid';
import {SizeMe} from 'react-sizeme';
import {analyticService} from '../../services/analyticService';
import {uploadFunctionList} from '../../redux/actions/analyticsActions';
import {connect} from 'react-redux';
import {clearDashboard, setEditedWidgets} from '../../redux/actions/dashboardActions';
import AlertError from '../../components/AlertError';
import RenameDashboardModal from './RenameDashboardModal';
import {setCapacityTree} from '../../redux/actions/capacityActions';

//функция для рендеринга интерфейса для просмотра дашборда
function Dashboard({dashboardId, uploadFunctionList, setEditedWidgets, setCapacityTree}) {
    //инициализация дашборда
    const [dashboard, setDashboard] = useState(null);
    //инициализация перменнной для загрузки дашбодра
    const [isFetchingDashboard, setFetchingDashboard] = useState(false);
    //инизиализация спсика виджетов
    const [widgetList, setWidgetList] = useState([]);
    //инициализация перменнной для загрузки спсика виджетов
    const [isFetchingWidgetList, setFetchingWidgetList] = useState(false);
    //инициализация перменнной для ошибки
    const [error, setError] = useState('');
    //функция-обработчик при запуске компонента
    useEffect(() => {
        analyticService.getFunctionList().then(
            functionList => {
                uploadFunctionList(functionList);
            }
        );

        capacityService.getCapacityTree().then(
            response => {
                setCapacityTree(response.list);
            }
        );
    }, []);

    //функция-обработчик события - получение нового ID ыбранного дашборда
    useEffect(() => {
        setError('');//обновление состояния ошибки
        setFetchingDashboard(true);//обновление состояния - "идет запрос" - да
        setDashboard(null);//обновление состояния переменной выбранного дашборда
        setWidgetList([]);//обновление состояния списка виджетов дашборда
        clearDashboard();//очищение компонентов страницы
        dashboardService.getDashboard(dashboardId).then(//запрос метода API на получение параметров дашборда
            response => {
                setDashboard(response);//обновление состояния переменной выбранного дашборда
                setFetchingDashboard(false);//обновление состояния - "идет запрос" - нет
                setFetchingWidgetList(true);//обновление состояния - "идет запрос виджетов" - да
                dashboardService.getDashboardWidgetList(dashboardId)//запрос метода API на получение списка виджетов дашборда
                    .then(
                        widgetList => {//ответ - список виджетов дашборда
                            setWidgetList(widgetList);//обновление состояния списка виджетов дашборда
                            setEditedWidgets(widgetList);//добавляем список виджетов в redux
                            setFetchingWidgetList(false);//обновление состояния - "идет запрос виджетов" - нет
                        }
                    );
            }, () => {//обработчик ошибки
                setFetchingDashboard(false);//обновление состояния - "идет запрос" - нет
                setError(localeService.isRussian() ? 'Не удалось загрузить дашборд. Данный дашборд либо не существует, либо у Вас нет прав на его просмотр.' : 'Cannot load Dashboard');//обновление состояния ошибки
            }
        );
    }, [dashboardId]);
//возврат сгенерированного HTML кода
    return (
        <SizeMe>{({size}) =>
            // вывод блока отображения дашборда
            <div className={'dashboard-container'} style={{margin: '-1rem', backgroundColor: '#f1f1f1', minHeight: 'calc(100vh - 5rem)'}}>
                {isFetchingDashboard && //если состояние переменно "идет запрос" - да, то выводить компонент состояния загрузки
                <Loader waitText={localeService.isRussian() ? 'Загрузка дашборда...' : 'Loading dashboard...'}/>}
                <div className={'row'}>
                    {/*вывод блока отображения названия дашборда*/}
                    {dashboard && //если переменная дашборда инициирована
                    <div style={{display: 'flex'}}>
                        {/*то выводить блок заголовка дашборда*/}
                        <h4 style={{margin: '0 1rem 0 0'}}>{dashboard.name}</h4>
                        {/*вывод компонента для переименования дашборда*/}
                        <RenameDashboardModal dashboard={dashboard} onRename={(newName) => setDashboard({...dashboard, name: newName})}/>
                    </div>
                    }
                </div>
                {dashboard &&  //если переменная дашборда инициирована
                <DashboardFilterPanel //то выводить компонент управления дашбордом - фильтр времени
                    dashboardId={dashboardId} //передача ID дашборда
                    dashboard={dashboard} //передача параметров дашборда
                />}
                {/*вывод ошибки*/}
                <AlertError isOpen={error != ''} isClosable={false} message={error}/>
                {isFetchingWidgetList && //если состояние переменной "идет запрос виджетов" - да, то выводить компонент состояния загрузки
                <Loader waitText={localeService.isRussian() ? 'Загрузка списка виджетов...' : 'Loading Widget List...'}/>}
                {/*компонент для рендеринга спсика виджетов */}
                <WidgetsGrid widgetList={widgetList} size={size}/>
            </div>
        }</SizeMe>
    );
}

Dashboard.propTypes = {
    dashboardId: PropTypes.number,
    uploadFunctionList: PropTypes.func,
    setEditedWidgets: PropTypes.func,
    setCapacityTree: PropTypes.func
};

const mapDispatchToProps = {
    uploadFunctionList: uploadFunctionList,
    setEditedWidgets: setEditedWidgets,
    setCapacityTree: setCapacityTree
};
export default connect(null, mapDispatchToProps)(Dashboard);