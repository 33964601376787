import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import InputText from '../../../../components/InputText';
import {localeService} from '../../../../services/locale.service';
import {dashboardConstants} from '../../../../constants/dashboard.constants';
import {updateWidget} from '../../../../redux/actions/dashboardActions';
import Select from '../../../../components/Select';
import {getDataSourceByType} from './strategy.service';

function StrategyCreate({widgetProps, updateWidget, dashboardDataSources}) {

    const [mainDS, setMainDS] = useState(null);
    const [sneDS, setSneDS] = useState(null);
    const [sneDS1, setSneDS1] = useState(null);
    const [sneDS2, setSneDS2] = useState(null);
    const [vieDS, setVieDS] = useState(null);
    const [ogoDS, setOgoDS] = useState(null);
    const [ogoDS1, setOgoDS1] = useState(null);
    const [ogoDS2, setOgoDS2] = useState(null);
    const [widget, setWidget] = useState(widgetProps);
    const {t} = useTranslation();


    React.useEffect(() => {
            setMainDS(getDataSourceByType(widgetProps.dataSources, 'MAIN_DATA_SOURCE'));
            setSneDS(getDataSourceByType(widgetProps.dataSources, 'SNE_DATA_SOURCE'));
            setSneDS1(getDataSourceByType(widgetProps.dataSources, 'SNE_DATA_SOURCE_1'));
            setSneDS2(getDataSourceByType(widgetProps.dataSources, 'SNE_DATA_SOURCE_2'));
            setVieDS(getDataSourceByType(widgetProps.dataSources, 'VIE_DATA_SOURCE'));
            setOgoDS(getDataSourceByType(widgetProps.dataSources, 'OGO_DATA_SOURCE'));
            setOgoDS1(getDataSourceByType(widgetProps.dataSources, 'OGO_DATA_SOURCE_1'));
            setOgoDS2(getDataSourceByType(widgetProps.dataSources, 'OGO_DATA_SOURCE_2'));
    }, []);


    const saveWidgetProps = () => {
        let dsList = [];

        if(mainDS === null){
            alert('Выберите основной источник данных!');
        }else{
            dsList.push({...mainDS, parameters: {...mainDS.parameters, type: 'MAIN_DATA_SOURCE'}});
            if(sneDS !== null){
                dsList.push({...sneDS, parameters: {...sneDS.parameters, type: 'SNE_DATA_SOURCE'}});
            }
            if(sneDS1 !== null){
                dsList.push({...sneDS1, parameters: {...sneDS1.parameters, type: 'SNE_DATA_SOURCE_1'}});
            }
            if(sneDS2 !== null){
                dsList.push({...sneDS2, parameters: {...sneDS2.parameters, type: 'SNE_DATA_SOURCE_2'}});
            }
            if(vieDS !== null){
                dsList.push({...vieDS, parameters: {...vieDS.parameters, type: 'VIE_DATA_SOURCE'}});
            }
            if(ogoDS !== null){
                dsList.push({...ogoDS, parameters: {...ogoDS.parameters, type: 'OGO_DATA_SOURCE'}});
            }
            if(ogoDS1 !== null){
                dsList.push({...ogoDS1, parameters: {...ogoDS1.parameters, type: 'OGO_DATA_SOURCE_1'}});
            }
            if(ogoDS2 !== null){
                dsList.push({...ogoDS2, parameters: {...ogoDS2.parameters, type: 'OGO_DATA_SOURCE_2'}});
            }
            updateWidget({
                ...widget,
                dataSources: dsList, mode: dashboardConstants.EDITION_MODE
            });
        }
    };

    return (
        <div>
            <form>
                <InputText label={t('widget_name')} value={widget.name}
                           onChange={(text) => setWidget({...widget, name: text})}/>

                <Select key={'mDS'} label={'Основной источник данных'} type={'datasource_with_info'} value={mainDS}
                        valueList={dashboardDataSources}
                        onSelect={(ds) => setMainDS({...ds, idx: new Date().getTime(), sourceID: ds.id})}/>

                <b>СНЭ</b>
                <Select key={'sDS'} label={'Источник данных для разгрузки'} type={'datasource_with_info'} value={sneDS}
                        valueList={dashboardDataSources}
                        onSelect={(ds) => setSneDS({...ds, idx: new Date().getTime(), sourceID: ds.id})}/>
                <Select key={'sDS1'} label={'Источник данных для восполнения 1'} type={'datasource_with_info'} value={sneDS1}
                        valueList={dashboardDataSources}
                        onSelect={(ds) => setSneDS1({...ds, idx: new Date().getTime(), sourceID: ds.id})}/>
                <Select key={'sDS2'} label={'Источник данных для восполнения 2'} type={'datasource_with_info'} value={sneDS2}
                        valueList={dashboardDataSources}
                        onSelect={(ds) => setSneDS2({...ds, idx: new Date().getTime(), sourceID: ds.id})}/>

                <b>ВИЭ</b>
                <Select key={'vDS'} label={'Источник данных ВИЭ'} type={'datasource_with_info'} value={vieDS}
                        valueList={dashboardDataSources}
                        onSelect={(ds) => setVieDS({...ds, idx: new Date().getTime(), sourceID: ds.id})}/>

                <b>УН (Управляемая нагрузка)</b>
                <Select key={'oDS'} label={'Источник данных для снижения'} type={'datasource_with_info'} value={ogoDS}
                        valueList={dashboardDataSources}
                        onSelect={(ds) => setOgoDS({...ds, idx: new Date().getTime(), sourceID: ds.id})}/>
                <Select key={'oDS1'} label={'Источник данных для восполнения 1'} type={'datasource_with_info'} value={ogoDS1}
                        valueList={dashboardDataSources}
                        onSelect={(ds) => setOgoDS1({...ds, idx: new Date().getTime(), sourceID: ds.id})}/>
                <Select key={'oD2'} label={'Источник данных для восполнения 2'} type={'datasource_with_info'} value={ogoDS2}
                        valueList={dashboardDataSources}
                        onSelect={(ds) => setOgoDS2({...ds, idx: new Date().getTime(), sourceID: ds.id})}/>

                <div className={'d-flex justify-content-center'}>
                    <button type={'button'} className={'btn btn-outline-success'}
                            onClick={() => {
                                saveWidgetProps();
                            }}>
                        {localeService.isRussian() ? 'Сохранить' : 'Save'}
                    </button>
                    <button type={'button'} className={'btn btn-outline-danger'}
                            onClick={() => {
                                updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                            }}>
                        {t('cancel')}
                    </button>
                </div>
            </form>
        </div>
    );
}

StrategyCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(StrategyCreate);