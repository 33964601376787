/*eslint-disable no-unused-vars*/
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {updateWidget} from '../../../redux/actions/dashboardActions';
// import {localeService} from 'services/locale.service';
import {dashboardService} from '../../../services';
import {imageService} from '../../../services/image.service';
import ClickOutside from '../../../components/ClickOutside';

function MnemoschemeWidgetView({widgetProps, size, datetimeFilter}) {
    const [dataSources, setDataSources] = useState(widgetProps.dataSources);
    const [image, setImage] = useState(null);
    const [imageSize, setImageSize] = useState({width: 0, height: 0});
    const [isFetching, setFetching] = useState(false);

    React.useEffect(() => {
        if (widgetProps.parameters && widgetProps.parameters.image) {
            setFetching(true);

            imageService.getImage(widgetProps.parameters.image)
                .then(image => {
                    setImage(image);

                    if (widgetProps.parameters.function) {
                        Promise.all(
                            widgetProps.dataSources.map((dataSource) => {
                                if (dataSource.parameters) {
                                    return dashboardService.getWidgetFunctionData(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate, widgetProps.parameters.function.functionCode, widgetProps.parameters.function.functionParams, null)
                                        .then(result => {
                                                return {
                                                    ...dataSource,
                                                    value: (result.list && result.list[0]) ? Number.parseFloat(result.list[0].value.toFixed(2)) : null
                                                };
                                            }
                                        );
                                } else {
                                    return null;
                                }
                            })
                        ).then(dsList => {
                            setDataSources(dsList);
                            setFetching(false);
                        });
                    }
                });
        }
    }, [datetimeFilter]);

    return (<>
            {image &&
            <div className={'mnemoscheme'} style={{
                position: 'relative',
                width: size.width
            }}>
                <img src={image} style={{
                    userSelect: 'none',
                    maxWidth: size.width,
                    height: 'auto'
                }}
                     onLoad={({target: img}) => setImageSize({
                         width: img.offsetWidth,
                         height: img.offsetHeight
                     })}
                />
                {dataSources && dataSources.map((dataSource, i) => {
                    return <MnemoPlacemark dataSource={dataSource} key={'mnemoPlaceMark'+i}/>;
                })}
            </div>
            }
        </>
    );
}

function MnemoPlacemark({dataSource}){
    const [isCollapsed, setCollapsed] = useState(true);
        return <ClickOutside onClick={() => setCollapsed(true)} key={'co'+dataSource.id}>
                        <span className={'img-marker'}
                              onClick={() => setCollapsed(false)} style={{
                            userSelect: 'none',
                            position: 'absolute',
                            top: dataSource.parameters.position.y + '%',
                            left: dataSource.parameters.position.x + '%',
                            cursor: 'pointer',
                            backgroundColor: 'white',
                            padding: '6px',
                            borderRadius: '8px',
                            border: '4px solid' + dataSource.parameters.color
                        }}>
                        <button className={'btn link-btn'} onClick={() => setCollapsed(false)}>{dataSource.parameters.dataSourceName}</button>
                            {!isCollapsed && dataSource.value && <p>{dataSource.value}</p>}
                            {!isCollapsed && dataSource.parameters.linkToDashboard &&
                            <p><a href={'/dashboard/' + dataSource.parameters.linkToDashboard.id}
                                  title={dataSource.parameters.linkToDashboard.name}
                            >{dataSource.parameters.linkToDashboard.name}
                            </a></p>}
                    </span>
        </ClickOutside>;
}

MnemoPlacemark.propTypes = {
    dataSource: PropTypes.object
};

MnemoschemeWidgetView.propTypes = {
    widgetProps: PropTypes.object,
    size: PropTypes.object,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};
const mapDispatchToProps = {
    updateWidget: updateWidget
};

export default connect(mapStateToProps, mapDispatchToProps)(MnemoschemeWidgetView);