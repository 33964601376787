import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants/index';
import {updateWidget, zoomIn} from '../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import {
    ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceArea, Area
} from 'recharts';
import {dashboardService} from '../../../services';
import moment from 'moment';
import numeral from 'numeral';
import {withSize} from 'react-sizeme';
import svgShowDayAndNight from '../../../sources/images/interface/day-night.svg';
import svgShow from '../../../sources/images/interface/show.svg';
import svgHide from '../../../sources/images/interface/hide.svg';
import {ReactSVG} from 'react-svg';
import AddFunctionToGroupChart from './components/AddFunctionToGroupChart';
import {widgetsService} from '../../../services/widget.service';
import CustomLegend from '../../../components/CustomLegend';
import CustomTooltip from '../../../components/CustomTooltip';
import {localeService} from '../../../services/locale.service';


function GroupChartView({widgetProps, datetimeFilter, size, zoomIn, updateWidget}) {

    const [functionDataSources, setFunctionDataSources] = useState([]);
    const [groupedDataSource, setGroupedDataSource] = useState({
        parameters: {
            dataSourceName: widgetProps.parameters.groupDataSourceName,
            color: widgetProps.parameters.groupColor
        },
        data:[]
    });
    const [selectedArea, setSelectedArea] = useState({left: '', right: ''});
    const [dayAndNightDataSource, setDayAndNightDataSource] = useState({isVisible: false});
    const [normalization, setNormalization] = useState(false);
    const [showUngroupedDS, setShowUngroupedDS] = useState(true);

    const [mainDataSources, setMainDataSources] = useState(() => {
            let dsList = [];
            widgetProps.dataSources.map(ds => {
                if (ds.parameters.type === 'MAIN_DATASOURCE') {
                    let mainDataSource = ds;
                    mainDataSource.additionalDataSources = [];
                    widgetProps.dataSources.map(addDS => {
                        if (addDS.parameters.parentDataSource && addDS.parameters.parentDataSource === parseInt(mainDataSource.sourceID)) {
                            mainDataSource.additionalDataSources.push(addDS);
                        }
                    });
                    dsList.push(mainDataSource);
                }
            });
            return dsList;
        }
    );

    useEffect(() => {
        setSelectedArea({left: '', right: ''});
        dashboardService.loadWidgetDataTimeGrouped(mainDataSources, datetimeFilter, size.width).then(
            dataSources => {
                setMainDataSources(dataSources);

                const resultArray = [];
                dataSources.map((ds) => {
                    ds.data.map((data) => {
                        const idx = resultArray.findIndex(el => el.time_upload === data.time_upload);
                        if (idx < 0) {
                            resultArray.push(data);
                        } else {
                            resultArray[idx] = {...resultArray[idx], value: resultArray[idx].value + data.value};
                        }
                    });
                });
                setGroupedDataSource({...groupedDataSource, data: resultArray});
            }
        );

        dashboardService.loadGroupedFunctionDataSources(functionDataSources, mainDataSources, datetimeFilter, size.width).then(
            functionDataSources => {
                setFunctionDataSources(functionDataSources);
            }
        );
        const dayAndNightDS = widgetsService.generateDayAndNightDataSource(datetimeFilter.startDate, datetimeFilter.finishDate);
        setDayAndNightDataSource({...dayAndNightDS, isVisible: dayAndNightDS.isVisible});
    }, [datetimeFilter]);


    // Зуммироние
    const zoom = () => {
        let {left, right} = selectedArea;

        if (left === right || right === '') {
            setSelectedArea({left: '', right: ''});
            return;
        }

        if (left > right) {
            [left, right] = [right, left];
        }

        zoomIn({
            ...datetimeFilter,
            startDate: moment.utc(moment.unix(left)),
            finishDate: moment.utc(moment.unix(right)),
            datetimeRange: dashboardConstants.DATETIME_FILTER_LIST[0]
        });

    };

    const setFunctionDataSourceList = (newFunctDSList) => {
        setFunctionDataSources(newFunctDSList);

        updateWidget({
            ...widgetProps, parameters: {
                ...widgetProps.parameters, presets: {
                    functionDataSources: newFunctDSList.map(el => {
                        const ds = {...el};
                        delete ds.data;
                        return ds;
                    })
                }
            }
        });
    };

    return (
        <div>
            <div className={'widget-management-console'}>
                <button type="button"
                        title={localeService.isRussian() ? 'Показать/скрыть оригинальные данные' : 'Show/Hide original data'}
                        className={'btn btn-outline-primary default round-btn' + (showUngroupedDS ? ' active' : '')}
                        onClick={() => setShowUngroupedDS(!showUngroupedDS)}>
                    {showUngroupedDS && <ReactSVG src={svgShow}/>}
                    {!showUngroupedDS && <ReactSVG src={svgHide}/>}
                </button>
                <button type="button"
                        title={localeService.isRussian() ? 'Показать/Скрыть подсветку дня и ночи' : 'Show/Hide Day and Night Backlight'}
                        className={'btn btn-outline-primary default round-btn' + (dayAndNightDataSource.isVisible ? ' active' : '')}
                        onClick={() => {
                            console.log(dayAndNightDataSource);
                            setDayAndNightDataSource({
                                ...dayAndNightDataSource,
                                isVisible: !dayAndNightDataSource.isVisible
                            });
                        }}>
                    <ReactSVG src={svgShowDayAndNight}/>
                </button>
                <button type="button"
                        title={localeService.isRussian() ? 'Нормализация' : 'Normalization'}
                        className={'btn btn-outline-primary default round-btn' + (normalization ? ' active' : '')}
                        onClick={() => setNormalization(!normalization)}>
                    <span style={{fontStyle: 'italic', fontSize: '0.8rem', fontWeight: 'bold'}}>N</span>
                </button>
                <AddFunctionToGroupChart
                    onApplyFunction={functionDS => {
                        setFunctionDataSources([...functionDataSources, functionDS]);
                    }}
                    dataSources={mainDataSources} widgetWidth={size.width}/>
            </div>
            <ComposedChart
                width={size.width}
                height={(100 * widgetProps.h) - 18}
                onMouseDown={(e) => e && setSelectedArea({...selectedArea, left: e.activeLabel})}
                onMouseMove={(e) => selectedArea.left && setSelectedArea({...selectedArea, right: e.activeLabel})}
                onMouseUp={() => zoom()}>
                <CartesianGrid strokeDasharray="3 3"/>
                {/*Отображение дня/ночи */}
                {dayAndNightDataSource.isVisible && dayAndNightDataSource.data && dayAndNightDataSource.data.map((point, i) => {
                    if (i < dayAndNightDataSource.data.length - 1) {
                        return (<ReferenceArea x1={dayAndNightDataSource.data[i].time_upload}
                                               x2={dayAndNightDataSource.data[i + 1].time_upload}
                                               key={'day-n-night-k' + i}
                                               fillOpacity={0.2}
                                               fill={dayAndNightDataSource.data[i].color}/>);
                    }
                })}
                <XAxis
                    dataKey="time_upload"
                    tickFormatter={(number) => {
                        return moment.unix(number).format(moment.duration(datetimeFilter.finishDate.diff(datetimeFilter.startDate)).asHours() > 24 ? 'DD/MM HH:mm' : 'HH:mm:ss');
                    }}
                    interval={'preserveStart'}
                    allowDataOverflow={true}
                    type="number"
                    allowDecimals={false}
                    allowDuplicatedCategory={false}
                    scale={'utc'}
                    domain={['dataMin', 'dataMax']}//{[moment(datetimeFilter.startDate).unix().valueOf(), moment(datetimeFilter.finishDate).unix().valueOf()]}
                />
                <YAxis dataKey="value"
                       type="number" domain={['auto', 'auto']}
                       tickFormatter={(value) => {
                           return numeral(value).format('0.0 a');
                       }}
                />
                <Tooltip content={(e) => e.active && selectedArea.left === '' &&
                        <CustomTooltip label={e.label} widgetData={{dataSources: [groupedDataSource, ...mainDataSources]}}
                                       functionDataSources={functionDataSources}/>}
                    cursor={{stroke: '#005ea3', strokeWidth: 2, opacity: '0.7'}}/>
                <Legend align={'center'} content={() =>
                    <CustomLegend widgetDataSources={[groupedDataSource, ...mainDataSources]} functionDataSources={functionDataSources}
                                  onDeleteFunction={(funcDS) => {
                                      // deleteFunctionFromPreset(funcDS.idx);
                                      setFunctionDataSourceList(functionDataSources.filter(el => el.idx !== funcDS.idx));
                                  }}/>}/>
                {dayAndNightDataSource.data &&
                <Line
                    type={'monotone'}
                    dataKey="value"
                    data={dayAndNightDataSource.data}
                    stroke={'#cccccc'}
                    key={dayAndNightDataSource.id}
                    name={'Day and Night'}
                    background={{fill: '#eee'}}
                    dot={false}
                    activeDot={false}
                />
                }
                {groupedDataSource && <Line
                    type={'stepAfter'}
                    dataKey="value"
                    data={widgetsService.modifyChartData(groupedDataSource.data, normalization)}
                    stroke={groupedDataSource.parameters.color}
                    strokeWidth={'2px'}
                    name={groupedDataSource.dataSourceName}
                    key={'groupChart'}
                    background={{fill: '#eee'}}
                    dot={false}
                    activeDot={false}
                    animationDuration={0}
                />}
                {showUngroupedDS && mainDataSources && mainDataSources.map((source) => {
                    return (<Line
                        type={'stepAfter'}
                        dataKey="value"
                        data={widgetsService.modifyChartData(source.data, normalization)}
                        stroke={source.parameters.color}
                        strokeWidth={'2px'}
                        name={source.parameters.dataSourceName}
                        key={'lineChart' + source.id}
                        background={{fill: '#eee'}}
                        dot={false}
                        activeDot={false}
                        animationDuration={0}
                    />);
                })}

                {functionDataSources && functionDataSources.map((source, i) => {
                    let functionSource = [];
                    functionSource.push(<Line
                        type={'stepAfter'}
                        dataKey="value"
                        data={widgetsService.modifyChartData(source.data, normalization)}
                        stroke={source.parameters.color}
                        strokeWidth={'2px'}
                        name={source.parameters.dataSourceName}
                        key={'func_ds_' + i}
                        background={{fill: '#eee'}}
                        dot={false}
                        activeDot={false}
                        // strokeDasharray={'5 5'}
                        animationDuration={0}
                    />);


                    /**
                     * Объединяем два ряда для отображения доверительного интервала
                     * */
                    if(source.functionCode === 'PREDICTION-HOLT-WINTERS' && source.data.length === 3){
                        const uppedLineData = widgetsService.modifyChartData(source.data[2], normalization);
                        const lowerLineData = widgetsService.modifyChartData(source.data[1], normalization);
                        functionSource.push(<Area type='stepAfter' dataKey='value' key={'porog_1' + i}
                                                  data={uppedLineData.map((upperLine,ind) => {
                                                      return {
                                                          time_upload: upperLine.time_upload,
                                                          value: [
                                                              upperLine.value,
                                                              lowerLineData[ind].value
                                                          ]
                                                      };
                                                  })}
                            // stackId={i}
                                                  stroke='none'
                                                  fillOpacity={0.3}
                                                  fill='grey'/>);
                    }
                    return functionSource;
                })}

                {/*выделение области зума*/}
                {(selectedArea.left && selectedArea.right) &&
                <ReferenceArea x1={selectedArea.left} x2={selectedArea.right}
                               strokeOpacity={0.3}/>}
            </ComposedChart>
        </div>
    );

}

GroupChartView.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    datetimeFilter: PropTypes.object,
    zoomIn: PropTypes.func,
    updateWidget: PropTypes.func
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};
const mapDispatchToProps = {
    zoomIn: zoomIn,
    updateWidget: updateWidget
};
export default withSize()(connect(mapStateToProps, mapDispatchToProps)(GroupChartView));
