import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import ChannelProps from './components/ChannelProps';

function Import80020Props({preparseredData, capacityTree, importColumns, onChangeImportProps}) {

    const {t} = useTranslation();

    return (
        <div>
            {preparseredData.content && preparseredData.content.map((content, i) => {
                return <div className={'device-info'} key={i} style={{width: '100%'}}>
                        <span className={'title'}>
                            {(i+1) + '. ' + t('code') + '  ' + content.code}
                        </span>
                    <h5>{content.name}</h5>

                    <form key={'channel-' + i}>
                        {content.channels.map((channel, j) => {
                            return <ChannelProps key={j}
                                                 channel={channel}
                                                 importColumns={importColumns}
                                                 capacityTree={capacityTree}
                                                 onChangeChannelProps={channelsProps => onChangeImportProps(channelsProps)}/>;
                        })
                        }
                    </form>
                </div>;
            })}
        </div>
    );
}

Import80020Props.propTypes = {
    preparseredData: PropTypes.array,
    capacityTree: PropTypes.array,
    importColumns: PropTypes.array,
    onChangeImportProps: PropTypes.func
};

export default Import80020Props;