import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {dashboardService} from '../../../services';
import {connect} from 'react-redux';
import {dashboardConstants} from '../../../constants';
import WidgetLoader from '../../../components/WidgetLoader';
import {Cell, Legend, Pie, PieChart, Tooltip} from 'recharts';
import * as numeral from 'numeral';
import EmptyData from '../../../components/EmptyData';

function PieChartView({widgetProps, datetimeFilter, size}) {
    const [widgetData, setWidgetData] = useState([]);
    const [isUpdating, setUpdating] = useState(false);
    const chartWidth = size.width;
    const chartHeight = (100 * widgetProps.h) - 40;
    const [isEmptyData, setEmptyData] = useState(false);

    /**
     *Обновление данных по изменению @datetimeFilter
     * */
    useEffect(() => {
        setUpdating(true);
        setEmptyData(false);
        Promise.all(
            widgetProps.dataSources.map((dataSource) => {
                if (dataSource.parameters) {
                    return dashboardService.getWidgetFunctionData(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate, dataSource.parameters.functionCode, dataSource.parameters.functionParams, size.width)
                        .then(result => {
                                return {
                                    name: dataSource.parameters.dataSourceName,
                                    color: dataSource.parameters.color,
                                    value: (result.list && result.list[0]) ? Number.parseFloat(result.list[0].value.toFixed(2)) : null
                                };
                            }
                        );
                } else {
                    return null;
                }
            })
        ).then(widgetData => {
            let nullCount = 0;
            widgetData.map(el => {
                if(el.value === null){
                    nullCount++;
                }
            });
            if(nullCount === widgetData.length){
                setEmptyData(true);
            }
            setWidgetData(widgetData);
        }).finally(
            () => setUpdating(false)
        );
    }, [datetimeFilter]);

    return <>
        {isUpdating && <WidgetLoader/>}
        {isEmptyData && <EmptyData/>}
        {widgetData.length > 0 && !isEmptyData &&
        <PieChart width={chartWidth}
                  height={chartHeight}>
            <Pie
                data={widgetData}
                dataKey={'value'}
                nameKey={'name'}
                innerRadius={widgetProps.widgetType === dashboardConstants.WIDGET_DOUGHNUT_CHART ? (Math.min(chartWidth, chartHeight) / 2 - 60) : 0}
                outerRadius={Math.min(chartWidth, chartHeight) / 2 - 40}
                label={(labelProps) => {
                    return numeral(labelProps.value).format('0.0 a');
                }}
                labelLine={true}
                isAnimationActive={false}
            >
                {
                    widgetData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color}/>
                    ))
                }
            </Pie>
            {widgetProps.parameters.showLegend && <Legend/>}
            <Tooltip/>
        </PieChart>}
    </>;
}


PieChartView.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

export default connect(mapStateToProps, null)(PieChartView);