import React from 'react';
import PropTypes from 'prop-types';
import {RadialBar, RadialBarChart} from 'recharts';

function FunctionWidgetPreview({widgetProps, sizeParams}) {

    const [previewData, setPreviewData] = React.useState([]);

    React.useEffect(()=> {
        const initialValue = [{
            name: 'bgd',
            uv: 1000,
            pv: 1000,
            fill: 'white'
        },{
            name: widgetProps.dataSources[0] && widgetProps.dataSources[0].parameters.dataSourceName || widgetProps.parameters && widgetProps.parameters.groupDataSourceName || '',
            uv: Math.floor(Math.random() * Math.floor(1000)),
            pv: 1000,
            fill: widgetProps.dataSources[0] && widgetProps.dataSources[0].parameters.color || widgetProps.parameters && widgetProps.parameters.groupColor || '#216ba5'
        }];
        setPreviewData(initialValue);
    }, [widgetProps]);

    return <div>
        <RadialBarChart  width={sizeParams.width}
                         height={sizeParams.height} innerRadius="60%"
                        outerRadius="100%" barSize={30} data={previewData} startAngle={220}
                        endAngle={-40}>
            <RadialBar minAngle={0} background clockWise={true} dataKey="uv" label={{ position: 'insideStart', fill: '#fff' }} />
        </RadialBarChart>
        <div className={'widget-header'} style={{position: 'absolute', bottom: '4px',textAlign: 'center'}}>
            <label>{widgetProps.parameters ? widgetProps.parameters.dataSourceName || widgetProps.parameters.groupDataSourceName || '' : ''}</label>
        </div>
    </div>;
}


FunctionWidgetPreview.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    sizeParams: PropTypes.object
};

export default FunctionWidgetPreview;