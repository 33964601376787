import React from 'react';
import {ReactSVG} from 'react-svg';
import PropTypes from 'prop-types';

const ListView = ({src, img, renderActions, statusRender, onClick, onViewAll, footerAction, renderName, viewAllText}) => {

    return <React.Fragment>
        <div className="list-view">
            {src && src.length > 0 && src.map((source, idx) =>
                <div key={idx} className="list-view__item" onClick={() => onClick && onClick(source)}>
                    <ReactSVG src={img} className="list-view__item__image"/>
                    <div className="list-view__item__content">
                        <div className="list-view__item__content__main">
                            <span className="list-view__item__content__name">
                                {renderName(source)}

                            </span>
                            <span className="list-view__item__content__number">№ {idx + 1}</span>
                        </div>
                        <div className="list-view__item__content__sub">
                            <span className="list-view__item__content__status">
                                {statusRender && statusRender(source)}
                            </span>
                        </div>


                    </div>
                    <div className="list-view__item__actions">
                        {renderActions && renderActions(source)}

                    </div>
                </div>
            )}
            <div className="list-view__footer mt-5">
                <span className="list-view__footer__view_all" onClick={() => onViewAll()}>
                    {viewAllText}
                </span>
                {footerAction && footerAction()}

            </div>

        </div>
    </React.Fragment>;
};

ListView.propTypes = {
    src: PropTypes.array,
    renderActions: PropTypes.func,
    img: PropTypes.string,
    onClick: PropTypes.func,
    footerAction: PropTypes.func,
    onViewAll: PropTypes.func,
    renderName: PropTypes.func,
    statusRender: PropTypes.func,
    viewAllText: PropTypes.string

};
ListView.defaultProps = {
    viewAllText: 'Смотреть все'
};

export default ListView;
