import {apiConstants} from '../constants';
import i18next from 'i18next';
import {apiService} from './api.service';


function makeToken(length) {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function authorizeUser(username, password) {
    const userAgentToken = makeToken(10);
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'user-agent-token': userAgentToken,
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({email: username, password: password})
    };

    // const url = apiConstants.USER_AUTHORIZATION_URL + '?grant_type=password' + '&password=' + creds.password + '&username=' + creds.username;
    const url = apiConstants.USER_AUTH_URL;
    return fetch(url, config)
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    return Promise.reject(i18next.language === 'ru' ? 'Неверный email и/или пароль' : 'Bad credentials');
                } else if(response.status === 502){
                    return Promise.reject(i18next.language === 'ru' ? 'Не могу связаться с сервером!' : 'Server Lost');
                } else {
                    return response.json().then(err => Promise.reject(err.errorCode + ' ' + err.errorMsg));
                }
            } 
            return response.json();
        }, error => {
            return Promise.reject(error);
        })
        .then(response => {
            if (response && response.authenticationToken) {
                const emsUser = {
                    sessionResp: response,
                    organizationID: response.companyPost[0].organizationId,
                    userAgentToken: userAgentToken,

                };
                sessionStorage.setItem('ems_user', JSON.stringify(emsUser));
                return response;
            } else {
                return response;
            }
        }, error => {
            return Promise.reject(error);
        });
}

function confirmEmail(password, token) {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            password: password,
            token: token
        })
    };

    return apiService.fetchURL(apiConstants.CONFIRM_EMAIL, config);
}

export function getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('ems_user'));
}


export function logout() {
    clearInterval(apiConstants.SESSION_UPDATER_INTERVAL);
    sessionStorage.removeItem('ems_user');
}


export const loginService = {
    getCurrentUser,
    authorizeUser,
    confirmEmail,
    logout
};

