import React from 'react';
import PropTypes from 'prop-types';


function InputTime({label, value, onChange, isRequired, min, max, step, placeHolder, notInline, disable}) {

    const onChangeHandle = (el) => {

        onChange(el);
    };

    return <div
        className={'input-group understroke' + (isRequired ? ' required' : '') + (notInline ? ' not-inline' : '')}>
        <div className={'input-group-prepend'}>
            <label>{label}</label>
            <input className={(isRequired ? 'required' : '')}
                   disabled={disable}
                   type={'time'}
                   min={min}
                   max={max}
                   step={step}
                   value={value}
                   placeholder={placeHolder}
                   onChange={el => {
                       el.preventDefault();
                       onChangeHandle(el.target.value);
                   }}/>
        </div>


    </div>;
}

InputTime.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    disable: PropTypes.bool,
    placeHolder: PropTypes.string,
    notInline: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
};

InputTime.defaultProps = {
    label: '',
    value: '',
    onChange: () => {
    },
    isRequired: false,
    disable: false,
    notInline: false,
    min: 0,
    max: 999,
    step: 1,
};

export default InputTime;