import React from 'react';
import {ReactSVG} from 'react-svg';
import svgLogo from '../sources/images/interface/logo2.svg';
import {apiConstants} from '../constants/api.constants';

function Logo() {
    const [isDr, setDr] = React.useState(apiConstants.APP_NAME !== 'EMS Insyte');
    return (
        <>
            <div className={'ems-logo'} onClick={(e) => {
                e.stopPropagation();
                if (e.ctrlKey) {
                    setDr(!isDr);
                }
            }}>
                {isDr && <h2 className={'logo-text'}>DR PLATFORM</h2>}
                {!isDr && <ReactSVG src={svgLogo}/>}
            </div>
        </>
    );
}

Logo.propTypes = {};

export default Logo;