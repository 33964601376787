import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../services/locale.service';
import {useTranslation} from 'react-i18next';
import Modal from '../../components/Modal';
import SelectDeviceVariableFromCapacityTree from '../../components/SelectDeviceVariableFromCapacityTree';
import Select from '../../components/Select';

function CreateCapacityBalance({capacityTree, importColumns, onChange}) {
    const {t} = useTranslation();


    const [balance, setBalance] = React.useState({
        device: null,
        uuidChannelSub: [],
        uuidChannelSum: []
    });
    const [isBalanceOpened, setBalanceOpened] = React.useState(false);

    const getSelected = (channelUUID) => {
        let selectedValue = {value: 0, name: t('no')};
        if (balance) {
            if (balance.uuidChannelSub.findIndex(sub => sub === channelUUID) >= 0) {
                selectedValue = {value: -1, name: t('subtract')};
            } else if (balance.uuidChannelSum.findIndex(sub => sub === channelUUID) >= 0) {
                selectedValue = {value: 1, name: t('addition')};
            }
        }
        return selectedValue;
    };

    const addChannelToBalance = (selectedValue, channelUUID) => {
        if (selectedValue.value === 0) {
            setBalance({
                ...balance,
                uuidChannelSum: balance.uuidChannelSum.filter(ch => ch !== channelUUID),
                uuidChannelSub: balance.uuidChannelSub.filter(ch => ch !== channelUUID)
            });
        }
        if (selectedValue.value === -1) {
            setBalance({
                ...balance,
                uuidChannelSum: balance.uuidChannelSum.filter(ch => ch !== channelUUID),
                uuidChannelSub: [...balance.uuidChannelSub, channelUUID]
            });
        }
        if (selectedValue.value === 1) {
            setBalance({
                ...balance,
                uuidChannelSub: balance.uuidChannelSub.filter(ch => ch !== channelUUID),
                uuidChannelSum: [...balance.uuidChannelSum, channelUUID]
            });
        }
    };

    const onSetBalance = () => {
        setBalanceOpened(false);
        onChange({
            dataSourceID: balance.device.id,
            deviceUUID: balance.device.deviceGUID,
            uuidChannelSub: balance.uuidChannelSub,
            uuidChannelSum: balance.uuidChannelSum
        });
    };

    return (
        <>
            <button onClick={() => setBalanceOpened(true)}
                    className={'btn btn-outline-primary default mr2'}>{localeService.isRussian() ? 'Создать баланс нагрузок' : 'Create Capacity Balance'}</button>

            <Modal isOpen={isBalanceOpened} isClosable={false} footer={false}
                   title={localeService.isRussian() ? 'Создать баланс нагрузок' : 'Create Capacity Balance'}>
                <form>
                    <div className={'input-group'}>
                        <div className={'input-group-prepend'}>
                            <label>{localeService.isRussian() ? 'Переменная для группировки' : 'Device variable for group'}</label>
                        </div>
                        {balance &&
                        <SelectDeviceVariableFromCapacityTree capacityTree={capacityTree}
                                                              device={balance.device}
                                                              onSelect={(device) =>
                                                                  setBalance({
                                                                      ...balance,
                                                                      device: device
                                                                  })}/>
                        }
                    </div>
                    {balance.device !== null && <table className={'table'} cellSpacing={0} cellPadding={0}>
                        <thead>
                        <tr>
                            <td>{t('channel')}</td>
                            <td>{t('variable')}</td>
                            <td>{localeService.isRussian() ? 'Группировка' : 'is Grouped'}</td>
                        </tr>
                        </thead>
                        <tbody>
                        {importColumns.map((column, i) => {
                            return <tr key={i}>
                                <td>{column.uuidChannel}</td>
                                <td>{column.device.name}</td>
                                <td>
                                    <Select value={getSelected(column.uuidChannel)}
                                            style={{margin: '0.5rem 0'}}
                                            isUnderstroke={false}
                                            onSelect={(value) => addChannelToBalance(value, column.uuidChannel)}
                                            valueList={[
                                                {value: 0, name: t('no')},
                                                {value: 1, name: t('addition')},
                                                {value: -1, name: t('subtract')}
                                            ]}/>
                                </td>
                            </tr>;
                        })}
                        </tbody>
                    </table>}
                </form>
                <div className={'d-flex justify-content-center'}>
                    {balance.device !== null && (balance.uuidChannelSum.length > 0 || balance.uuidChannelSub.length > 0) &&
                    <button className={'btn btn-outline-danger mr2'} onClick={() => onSetBalance()}>{t('yes')}</button>}
                    <button className={'btn btn-outline-primary default'}
                            onClick={() => setBalanceOpened(false)}>{t('cancel')}</button>
                </div>
            </Modal>
        </>
    );
}

CreateCapacityBalance.propTypes = {
    capacityTree: PropTypes.array,
    importColumns: PropTypes.array,
    onChange: PropTypes.func
};

export default CreateCapacityBalance;