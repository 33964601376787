import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {localeService} from '../services/locale.service';
import ClickOutside from './ClickOutside';

function SelectDateMultiple({onSetDateList, label, datetimeFilter, disabled}) {

    const [dateList, setDateList] = useState([]);
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <div className={'selected-multiple-dates'}
                //      style={{
                //     width: '100%',
                //     border: '1px solid #ccc',
                //     borderRadius: '0.5rem',
                //     display: 'inline-block',
                //     padding: '1rem',
                //     boxSizing: 'border-box'
                // }}
            >
                <button className={'btn btn-outline-success'} disabled={disabled}
                        onClick={() => setOpen(!isOpen)}>{label}</button>
                <ClickOutside onClick={() => setOpen(false)}>
                    <div style={{
                        display: isOpen ? 'block' : 'none',
                        position: 'absolute',
                        top: '0',
                        // width: '230px',
                        backgroundColor: 'white',
                        padding: '2rem',
                        border: '1px solid grey',
                        borderRadius: '0.5rem',
                        textAlign: 'center',
                        zIndex: 1
                    }}>
                        <DatePicker
                            selected={new Date(datetimeFilter.startDate)}
                            inline
                            onChange={(el) => {
                                const date = moment(el);
                                let newDateList = [...dateList];
                                const idx = dateList.findIndex(el => el.isSame(date, 'day'));
                                if (idx < 0) {
                                    newDateList = [...dateList, date];
                                }else{
                                    newDateList.splice(idx,1);
                                }

                                setDateList(newDateList);
                            }}
                            dateFormat="YYYY-MM-DD"
                            highlightDates={dateList.map(el => new Date(el))}
                            minDate={datetimeFilter.startDate}
                            maxDate={datetimeFilter.finishDate}
                        />
                        <div className={'d-flex justify-content-between'}>
                            <button className={'btn btn-outline-success'} onClick={() => {
                                onSetDateList(dateList);
                                setOpen(false);
                            }}>
                                {localeService.isRussian() ? 'Применить' : 'Apply'}
                            </button>
                            <button className={'btn btn-outline-danger'}
                                    onClick={() => setOpen(false)}>{localeService.isRussian() ? 'Отмена' : 'Cancel'}</button>
                        </div>
                    </div>
                </ClickOutside>
            </div>
        </>

    );
}

SelectDateMultiple.propTypes = {
    disabled: PropTypes.bool,
    onSetDateList: PropTypes.func,
    label: PropTypes.string,
    datetimeFilter: PropTypes.object
};

export default SelectDateMultiple;
