import {apiService} from './api.service';
import {apiConstants} from '../constants';


export function getScenarioList() {
    return apiService.fetchGet(apiConstants.SCENARIO_GET_LIST);
}

export function getScenario(id) {
    return apiService.fetchGet(apiConstants.SCENARIO_GET, {scriptId: id});
}

export function deleteScenario(id) {

    return apiService.fetchPost(apiConstants.SCENARIO_DELETE, JSON.stringify({scriptId: id}));
}

export function saveScenario(scenario) {
    return apiService.fetchPost(apiConstants.SCENARIO_ADD, JSON.stringify(scenario));
}

export function getScenarioListByCapacity(capacityId) {
    return apiService.fetchGet(apiConstants.SCENARIO_GET_BY_CAPACITY, {capacityId});
}

export function getScenarioListByVariable(variableId) {
    return apiService.fetchGet(apiConstants.SCENARIO_GET_BY_VARIABLE, {variableId});
}

export const scenarioService = {
    getScenarioList,
    deleteScenario,
    getScenario,
    saveScenario,
    getScenarioListByCapacity,
    getScenarioListByVariable
};

