import React, {useState, useEffect} from 'react';
import {localeService} from '../../services/locale.service';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import InputText from '../../components/InputText';
import SelectCapacityTree from '../../components/SelectCapacityTree';
import CreateVirtualVariableGenerator from './CreateVirtualVariableGenerator';
import {capacityService} from '../../services/capacity.service';

function CreateVirtualVariable({onAddVariable, onCancel, capacityTree}) {
    const [virtualVariable, setVirtualVariable] = useState({name: '', id: new Date().getTime()});
    const [errorMsg, setErrorMsg] = useState('');
    const {t} = useTranslation();
    const [tree, setTree] = useState(capacityTree);


    useEffect(() => {
        if (!capacityTree) {
            capacityService.getCapacityTree()
                .then((response) => {
                    setTree(response.list);
                });
        }
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setErrorMsg('');
        if (virtualVariable.name === '') {
            setErrorMsg(localeService.isRussian() ? 'Введите имя пременной'
                : 'Enter Device Variable Name!');
        } else if (!virtualVariable.capacity) {
            setErrorMsg(localeService.isRussian() ? 'Выберите нагрузку для перменной!'
                : 'Select variable capacity!');
        } else {
            if(!virtualVariable.generator || virtualVariable.generator.type === 'NONE'){
                    onAddVariable(virtualVariable);
            }else{
                let isErr = false;
                switch (virtualVariable.generator.type) {
                    case 'ANALOG_RANDOM':
                        if (parseInt(virtualVariable.generator.config.minValue) > parseInt(virtualVariable.generator.config.maxValue)) {
                            setErrorMsg(localeService.isRussian() ? 'Минимальное значение больше максимального!'
                                : 'The minimum value is greater than the maximum!');
                            isErr = true;
                        }
                        break;
                    case 'ANALOG_FUNCTION':
                        var mExpress = virtualVariable.generator.config.mathExpression;
                        var runMExpress = mExpress.replace(new RegExp('time', 'g'), '1');
                        try {
                            eval(runMExpress);
                        } catch (err) {
                            setErrorMsg(localeService.isRussian() ? 'Неверно задана функция!'
                                : 'Invalid function!');
                            isErr = true;
                        }
                        break;
                    case 'DIGITAL_RANDOM_PERIOD':
                        if (virtualVariable.generator.config.from >= virtualVariable.generator.config.to) {
                            setErrorMsg(localeService.isRussian() ? 'Значение ОТ должно быть меньше ДО!'
                                : 'The value of FROM must be less than TO!');
                            isErr = true;
                        }
                        if (!virtualVariable.generator.config.from) {
                            setErrorMsg(localeService.isRussian() ? 'Заполните поле ОТ!'
                                : 'Fill in the FROM field!');
                            isErr = true;
                        }
                        if (!virtualVariable.generator.config.to) {
                            setErrorMsg(localeService.isRussian() ? 'Заполните поле ДО!'
                                : 'Fill in the TO field!');
                            isErr = true;
                        }
                        break;
                    case 'DIGITAL_FIXED_COUNT':
                        if (virtualVariable.generator.config.from >= virtualVariable.generator.config.to) {
                            setErrorMsg(localeService.isRussian() ? 'Значение ОТ должно быть меньше ДО!'
                                : 'The value of FROM must be less than TO!');
                            isErr = true;
                        }
                        if (!virtualVariable.generator.config.from) {
                            setErrorMsg(localeService.isRussian() ? 'Заполните поле ОТ!'
                                : 'Fill in the FROM field!');
                            isErr = true;
                        }
                        if (!virtualVariable.generator.config.to) {
                            setErrorMsg(localeService.isRussian() ? 'Заполните поле ДО!'
                                : 'Fill in the TO field!');
                            isErr = true;
                        }
                        if (!virtualVariable.generator.config.count) {
                            setErrorMsg(localeService.isRussian() ? 'Введите количество!'
                                : 'Enter count!');
                            isErr = true;
                        }
                        if (virtualVariable.generator.config.count <= 0) {
                            setErrorMsg(localeService.isRussian() ? 'Количество не может быть меньше или равно нулю!'
                                : 'Count cannot be less than or equal to zero!');
                            isErr = true;
                        }
                        break;
                }
                if (!virtualVariable.generator.config.cron) {
                    setErrorMsg(localeService.isRussian() ? 'Введите частоту!' : 'Enter the cron!');
                    isErr = true;
                }
                if (!isErr) {
                    onAddVariable(virtualVariable);
                }
            }
        }
    };

    return <div className={'container-70'} onSubmit={() => onSubmit()}>
        <form>
            <InputText label={localeService.isRussian() ? 'Имя переменной' : 'Variable Name'} isRequired={true}
                       placeHolder={(localeService.isRussian() ? 'Например: ' : 'For example: ')+'variable1'}
                       onChange={text => setVirtualVariable({...virtualVariable, name: text})}
                       value={virtualVariable.name}/>
            <div className={'input-group understroke required'}>
                <div className={'input-group-prepend'}>
                    <label>{t('capacity')}</label>
                </div>
                {tree &&
                <SelectCapacityTree capacityTree={tree}
                                    onSelect={(capacity) => setVirtualVariable({
                                        ...virtualVariable,
                                        capacity: capacity
                                    })}
                                    capacity={virtualVariable.capacity}/>
                }
            </div>
            <CreateVirtualVariableGenerator
                onChange={(generator) => setVirtualVariable({...virtualVariable, generator: generator})}/>
            {errorMsg &&
            <div className="alert alert-danger">{errorMsg}</div>
            }
            <div className={'d-flex justify-content-between'}>
                <button className={'btn btn-outline-success'} onClick={(e) => onSubmit(e)}>{t('create')}</button>
                <button className={'btn btn-outline-danger'} onClick={() => onCancel()}>{t('cancel')}</button>
            </div>
        </form>
    </div>;
}

CreateVirtualVariable.propTypes = {
    onAddVariable: PropTypes.func,
    onCancel: PropTypes.func,
    capacityTree: PropTypes.array
};

export default CreateVirtualVariable;