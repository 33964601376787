import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import InputText from '../../../components/InputText';
import {useTranslation} from 'react-i18next';
import CheckBox from '../../../components/CheckBox';
import {ReactSVG} from 'react-svg';
import svgAddColEnd from '../../../sources/images/interface/add_col_end.svg';
import svgDelColEnd from '../../../sources/images/interface/del_col_end.svg';
import svgAddRowEnd from '../../../sources/images/interface/add_row_bottom.svg';
import svgDelRowEnd from '../../../sources/images/interface/del_row_bottom.svg';
import EditWidgetDataSource from '../EditWidgetDataSource';
import AddFunctionToDatasource from '../AddFunctionToDatasource';

function TableWidgetCreate({widgetProps, updateWidget, dashboardDataSources}) {
    const [widget, setWidget] = useState(widgetProps);
    const {t} = useTranslation();
    const tableTypes = ['MANUAL', 'TIME_SERIES', 'FUNCTION'];

    React.useEffect(() => {
        if (!widget.parameters) {
            setWidget({
                ...widget,
                dataSources: [],
                parameters: {
                    showHeader: true,
                    type: tableTypes[0],
                    tableContent: [['']]
                }
            });
        }
    }, []);

    const addCol = (e) => {
        e.preventDefault();
        let table = widget.parameters.tableContent;
        table.map((row) => {
            row.push('');
        });
        setWidget({...widget, parameters: {...widget.parameters, tableContent: table}});
    };

    const addRow = (e) => {
        e.preventDefault();
        let table = widget.parameters.tableContent;
        let row = [];
        for (let i = 0; i < table[0].length; i++) {
            row.push('');
        }
        table.push(row);
        setWidget({...widget, parameters: {...widget.parameters, tableContent: table}});
    };

    const deleteRow = (e) => {
        e.preventDefault();
        let table = widget.parameters.tableContent;
        if (table.length > 1) {
            table.pop();
            setWidget({...widget, parameters: {...widget.parameters, tableContent: table}});
        }
    };

    const deleteCol = (e) => {
        e.preventDefault();
        let table = widget.parameters.tableContent;
        if (table[0].length > 2) {
            table.map(row => {
                row.pop();
            });
            setWidget({...widget, parameters: {...widget.parameters, tableContent: table}});
        }
    };

    const changeTableType = (typeId) => {
        setWidget({...widget, parameters: {...widget.parameters, type: tableTypes[typeId]}});
    };

    const createDataSource = () => {
        const dataSources = [...widget.dataSources, {idx: 'new', parameters: {}}];
        setWidget({...widget, dataSources: dataSources});
    };

    return <>
        <form>
            <InputText label={t('name')} value={widget.name}
                       onChange={(text) => setWidget({...widget, name: text})}/>
            {widget.parameters &&
            <div className={'input-group understroke mb4'}>
                <div className={'input-group-prepend'}>
                    <label>{localeService.isRussian() ? 'Тип таблицы' : 'Table type:'}</label>
                </div>
                <label>
                    <input type="radio" value="0" checked={widget.parameters.type === tableTypes[0]}
                           onClick={() => changeTableType(0)}/>
                    {localeService.isRussian() ? 'Ручной ввод данных' : 'Manual input'}
                </label>
                <label>
                    <input type="radio" value="1" checked={widget.parameters.type === tableTypes[1]}
                           onClick={() => changeTableType(1)}/>
                    {localeService.isRussian() ? 'Временной ряд' : 'Time series'}
                </label>
                <label>
                    <input type="radio" value="2" checked={widget.parameters.type === tableTypes[2]}
                           onClick={() => changeTableType(2)}/>
                    {localeService.isRussian() ? 'Функция' : 'Function'}
                </label>
            </div>
            }
            {widget.parameters && widget.parameters.type === tableTypes[0] &&
            <>
                <CheckBox onChange={(checked) => {
                    setWidget({...widget, parameters: {...widget.parameters, showHeader: checked}});
                }}
                          label={localeService.isRussian() ? 'Отображать первую строку, как заголовок' : 'Show header row'}
                          checked={widget.parameters.showHeader}/>
                <div className={'row'}>
                    <button className={'svg-btn big'} onClick={(e) => addCol(e)}
                            title={localeService.isRussian() ? 'Добавить столбец в конец таблицы' : 'Add column at the end of table'}>
                        <ReactSVG src={svgAddColEnd}/>
                    </button>
                    <button className={'svg-btn big'} onClick={(e) => deleteCol(e)}
                            title={localeService.isRussian() ? 'Удалить последний столбец' : 'Delete last column'}>
                        <ReactSVG src={svgDelColEnd}/>
                    </button>
                    <button className={'svg-btn big'} onClick={(e) => addRow(e)}
                            title={localeService.isRussian() ? 'Добавить строку в конец таблицы' : 'Add row at the end of table'}>
                        <ReactSVG src={svgAddRowEnd}/>
                    </button>
                    <button className={'svg-btn big'} onClick={(e) => deleteRow(e)}
                            title={localeService.isRussian() ? 'Удалить последнюю строку' : 'Delete last row'}>
                        <ReactSVG src={svgDelRowEnd}/>
                    </button>
                </div>
                <div className={'scrollable-zone'}>
                    <table className="table" style={{margin: 0 + 'px', border: '1px border grey'}}>
                        {widget.parameters.showHeader &&
                        <thead>
                        <tr>
                            {
                                widget.parameters.tableContent[0].map((col, j) => {
                                    return (<th key={j}>
                                        <input type={'text'}
                                               placeholder={localeService.isRussian() ? 'Введите значение' : 'Enter value'}
                                               value={col}
                                               onChange={(e) => {
                                                   let tableContent = widget.parameters.tableContent;
                                                   tableContent[0][j] = e.target.value;
                                                   setWidget({
                                                       ...widget,
                                                       parameters: {...widget.parameters, tableContent: tableContent}
                                                   });
                                               }}/>
                                    </th>);
                                })
                            }
                        </tr>
                        </thead>
                        }
                        <tbody>
                        {widget.parameters.tableContent.map((row, i) => {
                            if (widget.parameters.showHeader && i === 0) {
                                return;
                            } else {
                                return (
                                    <tr key={i}>
                                        {
                                            row.map((col, j) => {
                                                return (<td key={j}>
                                                    <input type={'text'}
                                                           placeholder={localeService.isRussian() ? 'Введите значение' : 'Enter value'}
                                                           value={col}
                                                           onChange={(e) => {
                                                               let tableContent = widget.parameters.tableContent;
                                                               tableContent[i][j] = e.target.value;
                                                               setWidget({
                                                                   ...widget,
                                                                   parameters: {
                                                                       ...widget.parameters,
                                                                       tableContent: tableContent
                                                                   }
                                                               });
                                                           }}/>
                                                </td>);
                                            })
                                        }
                                    </tr>
                                );
                            }
                        })}
                        </tbody>
                    </table>
                </div>
            </>}

            {widget.parameters && (widget.parameters.type === tableTypes[1] || widget.parameters.type === tableTypes[2]) &&
            <>
                {dashboardDataSources.length > 0 &&
                <button type={'button'} className={'btn btn-outline-primary default'}
                        onClick={() => createDataSource()}>
                    {localeService.isRussian() ? 'Добавить источник данных' : 'Add Data Source'}
                </button>
                }

                {widget.dataSources && widget.dataSources.length > 0 && widget.dataSources.map((dataSource, key) => {
                    return <EditWidgetDataSource key={key} dataSourceNumber={key + 1} widgetDataSource={dataSource}
                                                 onChangeDataSource={(ds) => {
                                                     widget.dataSources[key] = ds;
                                                     setWidget(widget);
                                                 }}
                                                 onDeleteDataSource={(dsIndex) => {
                                                     setWidget({
                                                         ...widget,
                                                         dataSources: widget.dataSources.filter(el => el.idx !== dsIndex)
                                                     });
                                                 }}
                    />;
                })}
            </>
            }

            {widget.parameters && (widget.parameters.type === tableTypes[2]) && widget.dataSources && widget.dataSources.length > 0 &&
                <AddFunctionToDatasource withDemand={false} appliedFunction={widget.parameters.function ? {...widget.parameters.function, name: widget.parameters.function.functionName} : null}
                onApplyFunction={(appliedFunction) => {
                    setWidget({
                        ...widget,
                        parameters: {
                            ...widget.parameters,
                            function: appliedFunction
                        }
                    });
            }}/>
            }


            <div className={'d-flex justify-content-center'}>
                <button type={'button'} className={'btn btn-outline-success'}
                        onClick={() => {
                            updateWidget({...widget, mode: dashboardConstants.EDITION_MODE});
                        }}>
                    {localeService.isRussian() ? 'Сохранить' : 'Save'}
                </button>
                <button type={'button'} className={'btn btn-outline-danger'}
                        onClick={() => {
                            updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                        }}>
                    {t('cancel')}
                </button>
            </div>
        </form>

    </>;
}

TableWidgetCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(TableWidgetCreate);