/* eslint-disable */
import React, {useState} from 'react';
// import Select from 'components/Select';
import PropTypes from 'prop-types';
import InputText from 'components/InputText';

import ClickOutside from 'components/ClickOutside';
import {capacityService} from 'services/capacity.service';
import {localeService} from '../../../services/locale.service';

const CapacitySearch = ({capacityTree, onSelect}) => {
    // const values=capacityTree.

    const recurrentLoader = (parentCapacity, func) => {
        const {capacity}=parentCapacity;
        if (capacity.isGroup && capacity.child) {
            capacity.child.map(item => recurrentLoader({parent:parentCapacity,capacity:item}, func));
            func(parentCapacity);
        } else {
            func(parentCapacity);
        }
    };

    const [capacityList, setList] = React.useState([]);
    const [value, setValue] = useState('');
    React.useEffect(() => {
        const arr = [];
       
        capacityTree.forEach(item => recurrentLoader({parent:null,capacity:item}, (data) => arr.push(data)));
       
        setList(arr);
    }, [capacityTree]);
    return (
        <React.Fragment>
            <form className="ml2">

                <InputText label={localeService.isRussian() ? 'Поиск нагрузки' : 'Capacity search'} value={value} onChange={e => setValue(e)}/>
                <ClickOutside disabled={value.length === 0} i onClick={e => console.log(e)}>
                    <div className={'ml2 dropdown' + (value.length > 0 ? ' open' : '')}
                         style={{width: 400, top: 120, minWidth: 400}}>
                        <div className={'dropdown-content'}>
                            <ul>
                                {capacityList.length > 0 && capacityList.filter(({capacity}) => {
                                    
                                    if (capacity.name.indexOf(value) > -1) {
                                        return true;
                                    }
                                }).map((item) => <li key={item.capacity.id} onClick={() => {
                                    onSelect(item);
                                    setValue('');
                                }}>
                                    {item.capacity.name}
                                </li>)}
                            </ul>
                        </div>
                    </div>
                </ClickOutside>
            </form>


        </React.Fragment>
    );
};

CapacitySearch.propTypes = {
    capacityTree: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default CapacitySearch;
