import React from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../../constants/index';
import {localeService} from '../../../../services/locale.service';
import Modal from '../../../../components/Modal';
import PivotTableView from './PivotTableView';
import PivotTableProps from './PivotTableCreate';

function PivotTableWidget({widgetProps, mode, closeEditSingleMode}) {

    return<>
        {mode === dashboardConstants.VIEW_MODE &&
        <PivotTableView widgetProps={widgetProps}/>
        }
        {mode === dashboardConstants.EDITION_MODE &&
            <div className={'scrollable-zone'}>
            <table className="table" style={{margin: 0 + 'px', border: '1px border grey'}}>
                <thead>
                <tr>
                    {
                        [...Array(30).keys()].map((_, j) => {
                            return (<th key={j}>{j + 1}</th>);
                        })
                    }
                </tr>
                </thead>
                <tbody>
                {[...Array(12).keys()].map((_, i) => {
                    return (
                            <tr key={i}>
                                {
                                    [...Array(30).keys()].map((_, j) => {
                                        return <td key={j}>{}</td>;
                                    })
                                }
                            </tr>
                        );
                })}
                </tbody>
            </table>
        </div>}
        {mode === dashboardConstants.EDIT_SINGLE_MODE &&
        <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => closeEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
            <PivotTableProps widgetProps={widgetProps}/>
        </Modal>
        }
    </>;
}


PivotTableWidget.propTypes = {
    widgetProps: PropTypes.object,
    closeEditSingleMode: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default PivotTableWidget;