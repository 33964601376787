import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../styles/dict-of-time.scss';
import {localeService} from '../services/locale.service';
// import ClickOutside from './ClickOutside';
// import {useTranslation} from 'react-i18next';
import Modal from './Modal';

function DictOfTime({onChange, label, value}) {
    // const {t} = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [selectMode, setSelectMode] = React.useState(false);
    const [dictOfTime, setDictOfTime] = React.useState(initDictOfTime(value));

    function initDictOfTime(value){
        let dot = [];
        if (value !== null && value !== [] && value !== undefined) {
            const hours = value.map(d => d.split(','));
            dot = [...Array(24).keys()].map((_, i) => {
                let isSelected = false;
                hours.map(hh => {
                    if (i >= Number.parseInt(hh[0].substring(0, 2)) && i <= Number.parseInt(hh[1].substring(0, 2))) {
                        isSelected = true;
                    }
                });
                if (isSelected) {
                    return 1;
                } else {
                    return 0;
                }
            });
        } else {
            dot = [...Array(24).keys()].map(() => {
                return 0;
            });
        }
        return dot;
    }

    const selectTimeElement = (el, i) => {
        let dot = [...dictOfTime];
        dot[i] = el === 0 ? 1 : 0;
        setDictOfTime(dot);
    };

    const recalculateDictOfTime = (dictOfTime) => {
        let values = [];
        dictOfTime.map((el, i) => {
            if (el === 1 && dictOfTime[i - 1] !== undefined && dictOfTime[i - 1] === 0) {
                values.push(moment().hours(i).minutes(0).seconds(0).format('HH:mm:ss') + ',');
            }
            if (el === 1 && dictOfTime[i - 1] === undefined) {
                values.push(moment().hours(i).minutes(0).seconds(0).format('HH:mm:ss') + ',');
            }
            if (el === 0 && dictOfTime[i - 1] !== undefined && dictOfTime[i - 1] === 1) {
                values[values.length - 1] = values[values.length - 1] + moment().hours(i - 1).minutes(59).seconds(0).format('HH:mm:ss');
            }
            if (el === 1 && dictOfTime[i + 1] === undefined) {
                values[values.length - 1] = values[values.length - 1] + moment().hours(i).minutes(59).seconds(0).format('HH:mm:ss');
            }
        });
        return values;
    };

    const setHours = () => {
        onChange(recalculateDictOfTime(dictOfTime));
    };

    return <div className={'mb-3 layout-input-group'}>
            <label>{label}</label>
        <p onClick={() => setOpen(!open)} className = {'form-control'} style={{cursor: 'pointer'}}>
            {dictOfTime.reduce((partialSum, a) => partialSum + a, 0) > 0 && recalculateDictOfTime(dictOfTime).map((el, i) => {
                return <span key={i}>{el + '   '}</span>;
            })}
            {dictOfTime.reduce((partialSum, a) => partialSum + a, 0) === 0 && 'Выбрать часы'}
        </p>
        {/*<ClickOutside onClick={() => setOpen(false)}>*/}
        <Modal isOpen={open} onCancel={() => setOpen(false)} isClosable={false}
               title={localeService.isRussian() ? 'Выберите часы' : 'Select hours'} width={800}
               onSubmit={() => {
                   setOpen(false);
                   setHours();
               }}>
            {/*<div className={'group'} style={{width: '100%', display: open ? 'block' : 'none'}}>*/}
            {/*<label*/}
            {/*className={'group-header'}>{localeService.isRussian() ? 'Выберите часы' : 'Choose hours'}</label>*/}
            <div className={'dict-of-time'}>
                <div className={'hours-line'}
                     onMouseLeave={() => setSelectMode(false)}
                >
                    {dictOfTime.map((el, i) => {
                        return <button key={'hours' + i}
                                       type={'button'}
                                       className={'hour ' + (el === 1 ? ' active' : '')}
                                       onMouseDown={() => {
                                           setSelectMode(true);
                                           selectTimeElement(el, i);
                                       }}
                                       onMouseUp={(e) => {
                                           e.preventDefault();
                                           setSelectMode(false);
                                       }}
                                       onMouseOver={() => {
                                           if (selectMode) {
                                               selectTimeElement(el, i);
                                           }
                                       }}
                        >
                            <span className={'label'}>{moment().hours(i + 1).minutes(0).format('HH')}</span>
                        </button>;
                    })}
                    {/*</div>*/}
                    {/*<div className={'d-flex justify-content-end mt-5'}>*/}
                    {/*<button className={'btn btn-outline-success'} onClick={(e) => {*/}
                    {/*e.preventDefault();*/}
                    {/*setOpen(false);*/}
                    {/*setHours();*/}
                    {/*}}>{t('apply')}</button>*/}
                    {/*<button className={'btn btn-outline-primary default'}*/}
                    {/*onClick={(e) => {*/}
                    {/*e.preventDefault();*/}
                    {/*setOpen(false);*/}
                    {/*}}>{t('cancel')}</button>*/}
                    {/*</div>*/}
                </div>
            </div>
        </Modal>
        {/*</ClickOutside>*/}
    </div>;
}

DictOfTime.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.array
};

export default DictOfTime;