import React,{useState, useEffect} from 'react';
import '../../styles/tariff.scss';
import {localeService} from '../../services/locale.service';
import AccessTariffsItem from './AccessTariffsItem';
import Modal from '../../components/Modal';
import {tariffService} from '../../services/tariff.service';
import InputText from '../../components/InputText';

function AccessTariffs(){

    const [isAddOpened, setAddOpened] = useState(false);

    let activeTariffPrice = 0;
    let [tariffs, setTariffs] = useState([]);
    let [orgBalance, setOrgBalance] = useState(0);
    let [orgTariff, setOrgTariff] = useState(null);
    let [sum,setSum] = useState(0);

    useEffect(() => {
        tariffService.getTariffList()
            .then((response) => {
                setTariffs(response.tariff);
            });
        tariffService.getOrganizationBalance()
            .then((response) => {
                setOrgBalance(response.balance);
            });
        tariffService.getOrganizationTariff()
            .then((response) => {
                setOrgTariff(response.tariff);
            });
    }, []);

    tariffs.forEach((item)=>{
        if (JSON.parse(orgTariff)===item.code){
            activeTariffPrice=item.tariffPrice;
        }
    });

    return(
        <div className="pricing-div">
            <nav className="page-header">
                <ul className="nav-left">
                    <li>
                        <div className={'pricing-header-box'}>
                            <h5 className='pricing-header'>{localeService.isRussian() ? 'Выбор тарифного плана.' : 'Subscription plans.'}</h5>
                            <h5 className='pricing-balance'>{(localeService.isRussian() ? 'Ваш Баланс: ' : 'Balance: ') + orgBalance}</h5>
                            <button className={'add-balance-btn'} onClick={()=>{setAddOpened(true); setSum(0);}}>
                                <div className={'add-balance-btn-circle'}>
                                    <span className={'add-balance-btn-plus'}>+</span>
                                </div>
                                <p className={'add-balance-btn-text'}>{localeService.isRussian() ? 'Пополнить баланс' : 'Top up balance'}</p>
                            </button>
                        </div>
                    </li>
                </ul>
            </nav>
            {
                tariffs.map(item => {
                    return (<AccessTariffsItem key={item.id}
                                               orgBalance={orgBalance}
                                               tariffCode={item.code}
                                               tariffName={localeService.isRussian() ? item.nameRu : item.nameEn}
                                               tariffDescript={localeService.isRussian() ? item.descriptRu : item.descriptEn}
                                               tariffPrice={item.tariffPrice}
                                               activeTariffPrice={activeTariffPrice}
                                               isShowPricePM={!!item.tariffPrice}
                                               tariffUser={item.deviceLimit === -1 ? (localeService.isRussian() ? 'Число пользователей' : 'The number of')
                                                   : (localeService.isRussian() ? 'пользователей: ' : 'users: ')+ item.deviceLimit}
                                               tariffCapacity={item.capacityLimit === -1 ? (localeService.isRussian() ? 'нагрузок, устройств,' : 'users, capacities,')
                                                   : (localeService.isRussian() ? 'нагрузок: ' : 'capacities: ')+ item.deviceLimit}
                                               tariffDevice={item.capacityLimit === -1 ? (localeService.isRussian() ? 'переменных' : 'devices, variables')
                                                   : (localeService.isRussian() ? 'устройств: ' : ' devices: ') + item.deviceLimit}
                                               tariffVariable={item.capacityLimit === -1 ? (localeService.isRussian() ? 'не ограничено' : 'is not limited')
                                                   : (localeService.isRussian() ? 'переменных: ' : 'variables: ')+ item.variableLimit}
                                               isShowDescript={!(item.id === 1 || item.id === 2)}
                                               isPricingSquare={JSON.parse(orgTariff)===item.code}
                    />);
                })
            }

            <Modal isOpen={isAddOpened}
                   footer={false}
                   title={localeService.isRussian() ? 'Для пополения баланса вам прийдёт письмо с реквизитами. Введите сумму пополнения.'
                       : 'To top up the balance you will receive a letter with the details. Enter the deposit amount.'}
                   isClosable={false}>
                <div className={'modal-body'}>
                    <form>
                        <InputText label={localeService.isRussian() ? 'Сумма:' : 'Sum:'}
                                   value={sum}
                                   isInteger={true}
                                   onChange={text => setSum(text)}/>
                    </form>
                    <div className={'d-flex justify-content-center'}>
                        <button className={'btn btn-outline-success mr2'} onClick={()=>{tariffService.addOrgBalance(sum); setAddOpened(false);}}>
                            {localeService.isRussian() ? 'Получить реквизиты' : 'Get details'}
                        </button>
                        <button className={'btn btn-outline-danger'} onClick={() => setAddOpened(false)}>
                            {localeService.isRussian() ? 'Отмена пополнения' : 'Cancel top upping'}
                        </button>
                    </div>
                </div>
            </Modal>

        </div>
    );
}

export default AccessTariffs;