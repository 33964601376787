const platform = 'DR';
// const platform = 'EMS';

const DOMAIN_URL = platform === 'DR' ? 'https://flex4energy.com' : 'https://ems.insyte.ru';
// eslint-disable-next-line no-undef
const PUBLIC_URL = process.env.NODE_ENV === 'development ' ? '' : DOMAIN_URL;
const API_URL =DOMAIN_URL + '/api';

export const apiConstants = {
    APP_NAME:  platform === 'DR' ? 'FLEX 4 ENERGY' : 'EMS Insyte',
    CURRENT_TIME: DOMAIN_URL + '/system/current/time',
    PUBLIC_URL: PUBLIC_URL,
    API_URL: API_URL,
    // USER_AUTHORIZATION_URL: DOMAIN_URL + '/oauth/token',
    USER_AUTH_URL: API_URL + '/auth/login',

    GET_USER: API_URL + '/user',

    UPLOAD_RELATION_DATA: API_URL + '/data/upload/and/relation',
    UPLOAD_RELATION_DATA_80020: API_URL + '/data/upload/and/relation/80020',
    UPLOAD_CSV_FILE_URL: API_URL + '/data/parse',
    UPLOAD_XML_FILE_URL: API_URL + '/data/xml/parse',
    UPLOAD_80020_FILE_URL: API_URL + '/data/80020/parse',
    UPLOAD_EXCEL_FILE_URL: API_URL + '/data/excel/parse',
    UPLOAD_DATA_MANUAL: API_URL + '/data/upload/and/relation/manual',
    CHECK_CSV_FILE: API_URL + '/file/csv/check',
    SAVE_CSV_FILE: API_URL + '/file/csv',
    COMPLETE_UPLOAD_CSV_FILE: API_URL + '/file/csv',

    UPLOAD_FROM_ENERGO_SPHERE_TEST: API_URL + '/data/upload/energosphere/test',
    UPLOAD_FROM_ENERGO_SPHERE: API_URL + '/data/upload/energosphere',

    GET_CAPACITY_LIST: API_URL + '/capacity/list',
    GET_CAPACITY_TREE: API_URL + '/capacity/tree',
    GET_CAPACITY_SUB_LIST: API_URL + '/capacity/get/sub',
    CREATE_LOAD: API_URL + '/capacity/save',
    DELETE_LOAD: API_URL + '/capacity/delete',
    GET_CAPACITY: API_URL + '/capacity/get',
    GET_CAPACITY_BY_VARIABLE_ID: API_URL + '/capacity/device_variable/get',

    FIND_DEVICE: API_URL + '/device/mqtt/check',
    GET_DEVICE_LIST: API_URL + '/device/list',
    GET_VARIABLE_LIST_BY_CAPACITY: API_URL + '/device/variables/by/capacity',
    ADD_REAL_DEVICE: API_URL + '/device/mqtt/add',
    EDIT_DEVICE: API_URL + '/device/mqtt/edit',
    ADD_VIRTUAL_DEVICE: API_URL + '/device/virtual/add',
    ADD_MODBUS_DEVICE: API_URL + '/device/modbus/add',
    ADD_VIRTUAL_VARIABLE: API_URL + '/device/virtual/add/variable',
    DELETE_VIRTUAL_VARIABLE: API_URL + '/device/virtual/delete/variable',
    EDIT_VIRTUAL_VARIABLE: API_URL + '/device/virtual/edit/variable',
    DEVICE_STOP: API_URL + '/device/stop',
    DEVICE_START: API_URL + '/device/start',
    DEVICE_DROP: API_URL + '/device/drop',
    DEVICE_VARIABLE_STOP: API_URL + '/device/variable/stop',
    DEVICE_VARIABLE_START: API_URL + '/device/variable/start',
    GET_DEVICE_VARIABLE_LIST: API_URL + '/device/mqtt/variables',
    GET_VIRTUAL_DEVICE_VARIABLE_LIST: API_URL + '/device/variables',
    DEVICE_RENAME: API_URL + '/device/rename',
    DEVICE_DELETE: API_URL + '/device/delete',
    VARIABLE_RENAME: API_URL + '/device/variable/rename',
    GET_DEVICE: API_URL + '/device/get',
    DEVICE_LOCATION: API_URL + '/device/location',
    SET_DEVICE_TIMEZONE: API_URL + '/device/timezone',
    GET_DEVICE_BY_SOURCEID: API_URL + '/device/by/sourceId',


    CREATE_DASHBOARD: API_URL + '/dashboard/create',
    EDIT_DASHBOARD: API_URL + '/dashboard/modify',
    CREATE_WIDGET: API_URL + '/widget/create',
    EDIT_WIDGET: API_URL + '/widget/edit',
    GET_DASHBOARD_LIST: API_URL + '/dashboard/list',
    GET_DASHBOARD: API_URL + '/dashboard/get',
    GET_WIDGET_TYPE_LIST: API_URL + '/widget/type/list',
    GET_WIDGET_LIST: API_URL + '/widget/list',
    GET_TRANSFORMATIONS_FUNCTION_LIST: API_URL + '/widget/transformations/function/list',
    GET_AGGREGATION_FUNCTION_LIST: API_URL + '/widget/aggregation/function/list',
    GET_ANALITICS_FUNCTION_LIST: API_URL + '/widget/analytics/function/list',
    GET_WIDGET_DATASOURCE_LIST: API_URL + '/widget/data/list',
    GET_WIDGET_CHART_FUNCTION_DATA: API_URL + '/widget/data/function',
    GET_WIDGET_DATASOURCE_COUNT: API_URL + '/widget/data/count',
    DELETE_WIDGET: API_URL + '/widget/delete',
    DELETE_DASHBOARD: API_URL + '/dashboard/delete',
    ANALYTIC_RUN: API_URL + '/widget/analytics/run',
    ANALYTIC_GET_RESULT: API_URL + '/widget/data/result',
    WIDGET_SET_DATA: API_URL + '/widget/data/set',
    SAVE_ANALYTICS: API_URL + '/widget/analytics/save',

    GET_SCRIPT_LIST: API_URL + '/rscript/list',

    ORGANIZATION_LIST: API_URL + '/organization/list',
    ORGANIZATION_INFO: API_URL + '/organization/info',
    ORGANIZATION_ROLE_LIST: API_URL + '/organization/role/list',
    ORGANIZATION_ROLE_CREATE: API_URL + '/organization/role/create',
    ORGANIZATION_ROLE_DELETE: API_URL + '/organization/role/delete',
    ORGANIZATION_ADD_USER: API_URL + '/organization/user/add',
    ORGANIZATION_EDIT_USER: API_URL + '/organization/user/edit',
    ORGANIZATION_DELETE_USER: API_URL + '/organization/user/delete',
    ORGANIZATION_INFO_EDIT: API_URL + '/organization/info/edit',
    ORGANIZATION_USER_LIST: API_URL + '/organization/user/list',
    ORGANIZATION_ACCESSIBLE_ROLE_LIST: API_URL + '/organization/accessible/role',
    ORGANIZATION_BALANCE: API_URL + '/organization/balance',
    ORGANIZATION_TARIFF: API_URL + '/organization/tariff',
    ORGANIZATION_ADD_BALANCE: API_URL + '/organization/add/balance',

    USER_ACTIVATE: API_URL + '/auth/set/password',
    USER_EDIT: API_URL + '/user/edit',
    CONFIRM_EMAIL: API_URL + '/auth/confirm',

    REGISTER_NEW_ORGANIZATION_ADMINISTRATOR: API_URL + '/auth/registration/company',


    PASSPORT_ADD_CONNECT: API_URL + '/capacity/passport/connect',
    PASSPORT_ADD_DELIVERY: API_URL + '/capacity/passport/delivery',
    PASSPORT_ADD_STORAGE: API_URL + '/capacity/passport/storage',
    PASSPORT_ADD_DYNAMIC: API_URL + '/capacity/passport/dynamic',
    PASSPORT_DOWNLOAD: API_URL + '/file/download',
    PASSPORT_CREATE: API_URL + '/passport/create',
    PASSPORT_DELETE: API_URL + '/passport/delete',
    GET_PASSPORT_LIST: API_URL + '/passport/list',
    PASSPORT_UPLOAD_FILE: API_URL + '/capacity/passport/dynamic/block_file',

    CAPACITY_PASSPORT_CREATE: API_URL + '/capacity/passport/dynamic',
    CAPACITY_PASSPORT_DELETE: API_URL + '/capacity/passport/',

    TARIFF_LIST: API_URL + '/tariff/list',
    TARIFF_CHANGE: API_URL + '/tariff/change',
    SCENARIO_GET_LIST: API_URL + '/script/auto/list',
    SCENARIO_GET: API_URL + '/script/auto/',
    SCENARIO_DELETE: API_URL + '/script/auto/delete',
    SCENARIO_ADD: API_URL + '/script/auto/save',
    SCENARIO_GET_BY_CAPACITY: API_URL + '/script/auto/list/by/capacityId',
    SCENARIO_GET_BY_VARIABLE: API_URL + '/script/auto/list/by/variable',

    SESSION_UPDATER_INTERVAL: null,

    GET_TAG_LIST: API_URL + '/dashboard/tegs',
    ADD_TAG: API_URL + '/dashboard/tegs/add',
    DELETE_TAG: API_URL + '/dashboard/tegs/delete',
    GET_DASHBOARD_LIST_BY_TAG: API_URL + '/dashboard/list/by/tegs',

    IMAGE_UPLOAD: API_URL + '/data/upload/image',
    IMAGE_GET: API_URL + '/file/download/image',


};
