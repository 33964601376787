// /* eslint-disable no-unused-vars */
import React, {useState} from 'react';

import AddScenario from './AddScenario';
import EditScenario from './EditScenario';
import Modal from './../../components/Modal';
import ScenarioTable from './ScenarioTable';
import SmartLoader from '../../components/SmartLoader';
import {localeService} from './../../services/locale.service';
import {scenarioService} from './../../services/scenario.service';
import {useTranslation} from 'react-i18next';
import scenarioTransform from '../../utils/scenarioTransform';

function ScenarioPage() {
    const {t} = useTranslation();
    const [isFetch, setIsFetch] = useState(false);
    const [scenarioList, setScenarioList] = useState([]);
    const [activeModal, setActiveModal] = React.useState(false);
    const [activeEditModal, setActiveEditModal] = React.useState(false);
    const [selectedScenary, setSelectedScenary] = React.useState(undefined);
    const [createScenarioStatus, setCreateScenarioStatus] = React.useState(
        undefined
    );
    const [editScenarioStatus, setScenarioStatus] = React.useState(undefined);
    React.useEffect(() => {
        loadScenarios();
    }, []);
    const loadScenarios = () => {
        setIsFetch(true);
        scenarioService.getScenarioList().then((data) => {
            setScenarioList(data.list.map(item => scenarioTransform(item)));



            setIsFetch(false);
            setCreateScenarioStatus(undefined);
            setScenarioStatus(undefined);
        });
    };
    const handleCreateScenario = (status, setter, err) => {
        if (err) {
            setter(true);
            setCreateScenarioStatus(true);
            setScenarioStatus(true);
        }
        if (status) {
            setter(false);
            setCreateScenarioStatus(undefined);

            loadScenarios();
        }
    };
    const startScript = (scenario, actionType) => {
        switch (actionType) {
            case 'ACTIVATE':
                scenario.active = !scenario.active;
                scenario.scriptId = scenario.id;
                scenarioService
                    .saveScenario(scenario)
                    .then(() => {
                        scenarioService
                            .getScenarioList()
                            .then((data) => setScenarioList(data.list));
                    })
                    .catch((err) => console.log(err));
                return;
            case 'REMOVE':
                scenarioService.deleteScenario(scenario.id).then(() => {
                    scenarioService
                        .getScenarioList()
                        .then((data) => setScenarioList(data.list));
                });
                return;
            case 'EDIT':
                setActiveEditModal(true);
                setSelectedScenary(scenario);
                return;
        }
    };
    return (
        <React.Fragment>
            <div>
                <nav className="page-header">
                    <ul className="nav-left">
                        <li>
                            <h5>
                                {localeService.isRussian()
                                    ? 'Список сценариев'
                                    : 'Scenario List'}
                            </h5>
                        </li>
                    </ul>
                    <ul className="nav-right">
                        <li>
                            <button
                                className="btn btn-outline-primary default   "
                                onClick={() => setActiveModal(true)}
                                title={t('create') + ' ' + t('scenario.name').toLowerCase()}
                            >
                                {t('create') + ' ' + t('scenario.name').toLowerCase()}
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="mr1 ml1">
                    <SmartLoader
                        status={isFetch}
                        waitText={
                            localeService.isRussian()
                                ? 'Загрузка сценариев'
                                : 'Load Scenario List'
                        }
                    >
                        <ScenarioTable
                            action={startScript}
                            onSelect={(scenario) => {
                                setActiveEditModal(true);
                                setSelectedScenary(scenario);
                            }}
                            source={scenarioList}
                        />
                    </SmartLoader>
                </div>
            </div>
            <Modal
                title={
                    localeService.isRussian() ? 'Создание сценария' : 'Create scenario'
                }
                isOpen={activeModal}
                footer={false}
                isClosable={false}
                onCancel={() => {
                    setActiveModal(false);
                }}
                width={900}
            >
                <SmartLoader
                    waitText={
                        localeService.isRussian()
                            ? 'Загрузка сценария'
                            : 'Loading scenario...'
                    }
                    failText={
                        localeService.isRussian()
                            ? 'Не все поля заполнены'
                            : 'Not all fields are filled in'
                    }
                    failStatus={createScenarioStatus}
                    actionButton={() => (
                        <div className={'modal-footer'}>
                            <div>
                                <button
                                    className="btn btn-outline-primary default   "
                                    onClick={() => setActiveModal(false)}
                                >
                                    {t('close')}
                                </button>
                            </div>
                            <div>
                                <button
                                    className="btn btn-outline-primary default   "
                                    onClick={() => {
                                        setCreateScenarioStatus(false);
                                    }}
                                >
                                    {t('edit')}
                                </button>
                            </div>
                        </div>
                    )}
                >
                    {!createScenarioStatus && (
                        <AddScenario
                            onCancel={() => setActiveModal(false)}
                            onCreate={(status, err) =>
                                handleCreateScenario(status, setActiveModal, err)
                            }
                        />
                    )}
                </SmartLoader>
            </Modal>

            <Modal
                title={
                    localeService.isRussian()
                        ? 'Редактирование сценария'
                        : 'Edit scenario'
                }
                isOpen={activeEditModal}
                footer={false}
                isClosable={false}
                onCancel={() => {
                    setActiveEditModal(false);
                }}
                width={900}
            >
                {selectedScenary && (
                    <SmartLoader
                        waitText={
                            localeService.isRussian()
                                ? 'Загрузка сценария'
                                : 'Loading scenario...'
                        }
                        failText={
                            localeService.isRussian()
                                ? 'Не все поля заполнены'
                                : 'Not all fields are filled in'
                        }
                        failStatus={editScenarioStatus}
                        actionButton={() => (
                            <div className={'modal-footer'}>
                                <div>
                                    <button
                                        className="btn btn-outline-primary default   "
                                        onClick={() => setActiveEditModal(false)}
                                    >
                                        {t('close')}
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="btn btn-outline-primary default   "
                                        onClick={() => {
                                            setScenarioStatus(false);
                                        }}
                                    >
                                        {t('edit')}
                                    </button>
                                </div>
                            </div>
                        )}
                    >
                        {!editScenarioStatus && (
                            <EditScenario
                                onCancel={() => setActiveEditModal(false)}
                                onCreate={(status, err) =>
                                    handleCreateScenario(status, setActiveEditModal, err)
                                }
                                scenario={selectedScenary}
                            />
                        )}
                    </SmartLoader>
                )}
            </Modal>
        </React.Fragment>
    );
}

export default ScenarioPage;
