import React, {useState} from 'react';
import {localeService} from '../../../../services/locale.service';
import InputText from '../../../../components/InputText';
import '../../../../styles/form.scss';
import '../../../../styles/passport-view.scss';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import svgDelete from '../../../../sources/images/interface/basket.svg';
import Select from '../../../../components/Select';
import {createCapacityPassport} from '../../../../services/passport.service';
import AlertError from '../../../../components/AlertError';
import PropTypes from 'prop-types';
import SelectDate from '../../../../components/SelectDate';

function CreateCapacityPassport({capacityId, onCancel, onSuccess}) {
    const myPassport = {
        name: '',
        blocks: []
    };

    const [passport, setPassport] = useState(myPassport);
    const [error, setError] = useState('');
    const {t} = useTranslation();

    const createAll = (n, key, blockKey) => {
        switch (n) {
            case 'block':
                passport.blocks = [...passport.blocks, {header: '', fields: [], filesBtns: []}];
                break;
            case 'field':
                passport.blocks[key].fields = [...passport.blocks[key].fields, {
                    type: '',
                    name: '',
                    unit: '',
                    value: ''
                }];
                break;
            case 'fileBtn':
                passport.blocks[key].filesBtns = [...passport.blocks[key].filesBtns, {btnDesc: ''}];
                break;
            default:
                passport.blocks[blockKey].fields[key].value = [...passport.blocks[blockKey].fields[key].value, {value: ''}];
                break;
        }
        setPassport({...passport});
    };

    const deleteBlock = (key) => {
        let blocks = passport.blocks;
        blocks.splice(key, 1);
        if (key >= 0) {
            passport.blocks = blocks;
            setPassport({...passport});
        }
    };
    const deleteField = (key, blockKey) => {
        let fields = passport.blocks[blockKey].fields;
        fields.splice(key, 1);
        if (key >= 0) {
            passport.blocks[blockKey].fields = fields;
            setPassport({...passport});
        }
    };
    const deleteFileBtn = (key, blockKey) => {
        let filesBtns = passport.blocks[blockKey].filesBtns;
        filesBtns.splice(key, 1);
        if (key >= 0) {
            passport.blocks[blockKey].filesBtns = filesBtns;
            setPassport({...passport});
        }
    };

    const updateField = (key, text, fn, blockKey) => {
        switch (fn) {
            case 'header':
                passport.blocks[key].header = text;
                break;
            case 'type':
                passport.blocks[blockKey].fields[key].type = text;
                clearField(blockKey, key);
                break;
            case 'name':
                passport.blocks[blockKey].fields[key].name = text;
                break;
            case 'unit':
                passport.blocks[blockKey].fields[key].unit = text;
                break;
            case 'value':
                passport.blocks[blockKey].fields[key].value = text;
                break;
            default:
                passport.blocks[blockKey].filesBtns[key].btnDesc = text;
                break;
        }
        setPassport({...passport});
    };

    const clearField = (blockKey, key) => {
        passport.blocks[blockKey].fields[key].name = '';
        passport.blocks[blockKey].fields[key].unit = '';
        passport.blocks[blockKey].fields[key].value = '';
        setPassport({...passport});
    };

    const onCreatePassport = () => {
        if (passport.name === '') {
            setError(localeService.isRussian() ? 'Введите тип паспорта' : 'Enter Passport type');
        } else if (passport.blocks.some(block => block.header === '')) {
            setError(localeService.isRussian() ? 'Введите название блока' : 'Enter block name');
        } else if (passport.blocks.some(block => block.fields.some(field => field.type === ''))) {
            setError(localeService.isRussian() ? 'Выберите тип поля' : 'Choose field type');
        } else if (passport.blocks.some(block => block.fields.some(field => field.name === ''))) {
            setError(localeService.isRussian() ? 'Введите название поля' : 'Enter field name');
        } else if (passport.blocks.some(block => block.fields.some(field => field.unit === '' && field.type !== 'DATE' && field.type !== 'STRING'))) {
            setError(localeService.isRussian() ? 'Введите единицу измерения' : 'Enter unit');
        } else if (passport.blocks.some(block => block.fields.some(field => field.value === ''))) {
            setError(localeService.isRussian() ? 'Введите значение' : 'Enter value');
        } else if (passport.blocks.some(block => block.filesBtns.some(fileBtn => fileBtn.btnDesc === ''))) {
            setError(localeService.isRussian() ? 'Введите описание кнопки' : 'Enter button description');
        } else {
            createCapacityPassport(passport.blocks, passport.name, capacityId).then(
                () => {
                    alert(localeService.isRussian() ? 'Новый тип паспорта успешно создан!' : 'Passport ia successfully created');
                    setPassport(myPassport);
                    onSuccess();
                }, error => {
                    alert((localeService.isRussian() ? 'е удалось создать паспорт!  ' : 'Error!  ') + error);
                }
            );
        }
    };

    return (
        <>
            <div className={'passport-constructor-box'}>
                <div className={'passport-constructor'}>
                    <form>
                        <div className={'mixed-input'}>
                            <InputText
                                label={localeService.isRussian() ? 'Новый тип паспорта: ' : 'New passport type: '}
                                className={'head-input'}
                                value={passport.name} onChange={text => setPassport({...passport, name: text})}/>
                            <button type={'button'} className={'btn btn-outline-secondary btn-sm default'}
                                    onClick={() => createAll('block')}>
                                {localeService.isRussian() ? 'Добавить блок' : 'Add block'}
                            </button>
                        </div>
                        {passport.blocks.length > 0 && passport.blocks.map((block, blockKey) => {
                            return (
                                <div key={blockKey}>
                                    <div className={'mixed-input'}>
                                        <InputText
                                            label={localeService.isRussian() ? 'Заголовок блока: ' : 'Block header: '}
                                            className={'passport-block-header'}
                                            value={block.header}
                                            onChange={text => updateField(blockKey, text, 'header')}/>
                                        <button className={'danger svg-btn ml1'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.confirm(localeService.isRussian() ? 'Вы уверены?' : 'Are you sure?') ? deleteBlock(blockKey) : e.preventDefault();
                                                }}
                                                title={localeService.isRussian() ? 'Удалить блок' : 'Delete block'}>
                                            <ReactSVG src={svgDelete}/>
                                        </button>
                                        <button type={'button'} className={'btn btn-outline-secondary default'}
                                                onClick={() => createAll('field', blockKey)}>
                                            {localeService.isRussian() ? 'Добавить поле' : 'Add Field'}
                                        </button>
                                    </div>
                                    {passport.blocks[blockKey].fields.length > 0 && passport.blocks[blockKey].fields.map((field, fieldKey) => {
                                        return (
                                            <div key={fieldKey}>
                                                <div className={'mixed-input'}>
                                                    <Select
                                                        style={{marginBottom: '0'}}
                                                        valueList={[
                                                            {
                                                                value: 'BIG_DECIMAL',
                                                                name: localeService.isRussian() ? 'Десятичное число' : 'Decimal Number'
                                                            },
                                                            {
                                                                value: 'INTEGER',
                                                                name: localeService.isRussian() ? 'Целое число' : 'Integer Number'
                                                            },
                                                            {
                                                                value: 'STRING',
                                                                name: localeService.isRussian() ? 'Строка' : 'String'
                                                            },
                                                            {
                                                                value: 'DATE',
                                                                name: localeService.isRussian() ? 'Дата' : 'Date'
                                                            },
                                                        ]}
                                                        label={t('type')}
                                                        onSelect={el => {
                                                            updateField(fieldKey, el.value, 'type', blockKey);
                                                        }}/>
                                                    <InputText
                                                        className={'passport-constructor-field'}
                                                        placeHolder={t('passport.field_name')}
                                                        value={field.name}
                                                        onChange={text => updateField(fieldKey, text, 'name', blockKey)}/>

                                                    {field.type !== 'DATE' && field.type !== 'STRING' &&
                                                    <InputText
                                                        className={'passport-constructor-field'}
                                                        placeHolder={t('unit')}
                                                        value={field.unit}
                                                        onChange={text => updateField(fieldKey, text, 'unit', blockKey)}/>}

                                                    {field.type === 'DATE' &&
                                                    <SelectDate
                                                        selectedDate={field.value === '' ? new Date() : new Date(field.value)}
                                                        onChange={date => updateField(fieldKey, date.format('YYYY/MM/DD'), 'value', blockKey)}/>}

                                                    {field.type !== 'DATE' &&
                                                    <InputText
                                                        className={'passport-constructor-field'}
                                                        placeHolder={t('value')}
                                                        value={field.value + ''}
                                                        isDecimal={field.type === 'BIG_DECIMAL'}
                                                        isInteger={field.type === 'INTEGER'}
                                                        onChange={text => {
                                                            updateField(fieldKey, text, 'value', blockKey);
                                                        }}/>}

                                                    <button className={'danger svg-btn ml1'}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                window.confirm(localeService.isRussian() ? 'Вы уверены?' : 'Are you sure?') ? deleteField(fieldKey, blockKey) : e.preventDefault();
                                                            }}
                                                            title={localeService.isRussian() ? 'Удалить поле' : 'Delete field'}>
                                                        <ReactSVG src={svgDelete}/>
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {passport.blocks[blockKey].filesBtns.length > 0 && passport.blocks[blockKey].filesBtns.map((field, key) => {
                                        return (
                                            <div key={key} className={'mixed-input'}>
                                                <div>
                                                    <button
                                                        className={'btn btn-outline-secondary non-working passport-file-btn'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                        }}>
                                                        {localeService.isRussian() ? 'Кнопка загрузки файла' : 'File upload button'}
                                                    </button>
                                                </div>
                                                <InputText
                                                    className={'passport-constructor-field'}
                                                    placeHolder={localeService.isRussian() ? 'Описание кнопки' : 'Button description'}
                                                    value={field.btnDesc}
                                                    onChange={text => updateField(key, text, 'btnDesc', blockKey)}/>
                                                <button className={'danger svg-btn ml1'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            deleteFileBtn(key, blockKey);
                                                        }}
                                                        title={localeService.isRussian() ? 'Удалить кнопку' : 'Delete button'}>
                                                    <ReactSVG src={svgDelete}/>
                                                </button>
                                            </div>
                                        );
                                    })
                                    }
                                </div>);
                        })}
                    </form>
                </div>
            </div>
            <AlertError message={error} isOpen={error !== ''} onCancel={() => setError('')}/>
            {passport.blocks.length > 0 && !error &&
                <div className={'d-flex justify-content-end mt-5 mb-4'}>
                    <button type={'button'} className={'btn me-4 btn-outline-danger default'}
                            onClick={() => onCancel()}>
                        {t('close')}
                    </button>
                    <button type={'button'} className={'btn btn-outline-success'}
                            onClick={() => onCreatePassport()}>
                        {t('save')}
                    </button>
                </div>
            }
        </>
    );
}

CreateCapacityPassport.propTypes = {
    capacityId: PropTypes.number,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func
};

export default CreateCapacityPassport;