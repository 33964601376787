import PropTypes from 'prop-types';
import React, {useState} from 'react';
import '../styles/dropdown.scss';
import ClickOutside from './ClickOutside';
import {useTranslation} from 'react-i18next';

function Select({valueList, onSelect, label, children, translateName, value, isDisabled, placeholder, style, type}) {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState({});

    React.useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    return <div className={'mb-3 layout-input-group'}
                style={style}>
            <label>{label}</label>
        {isDisabled &&
        <span className={'form-control'} onClick={() => setIsOpen(!isOpen)}>
            {selectedValue ? selectedValue.name : (placeholder ? <i>{placeholder}</i> : '')}
            </span>
        }
        {!isDisabled &&
        <div className={'dropdown-container' + (isOpen ? ' open' : '')}>
            <span className={'selected-item layout'} onClick={() => {
                setIsOpen(!isOpen);
            }}>
                {selectedValue ? (translateName ? t(selectedValue.name) : selectedValue.name) : (placeholder ? <i>{placeholder}</i> : '')}
                </span>
            <ClickOutside onClick={() => setIsOpen(false)}>
                <div className={'dropdown' + (isOpen ? ' open' : '')}
                     style={{maxHeight: (valueList.length * (type === 'datasource_with_info' ? 3 : 2.2)) + 'rem'}}>
                    <ul>
                        {valueList.map((el, i) => {
                            return (
                                <li key={'val-' + i}
                                    className={selectedValue && selectedValue.id && selectedValue.id === el.id ? 'selected' : ''}
                                    style={{maxHeight: type === 'datasource_with_info' ? 'none' : '1.8rem'}}
                                    onClick={() => {
                                        if(type === 'datasource_with_info'){
                                            setSelectedValue({name: el.parameters.dataSourceName});
                                        }else{
                                            setSelectedValue({name: translateName ? t(el.name) : el.name});
                                        }


                                        onSelect(el);
                                        setIsOpen(false);
                                    }}>
                                    <span>{translateName ? t(el.name) : el.name}</span>
                                    {type === 'datasource_with_info' &&
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        {el.parameters.dataSourceName &&
                                        <span
                                            className={'help-text'}><label>{t('data_source_name')}</label> {el.parameters.dataSourceName}</span>}
                                        {el.capacity &&
                                        <span
                                            className={'help-text'}><label>{t('capacity')}: </label> {el.capacity.name}
                                        </span>}
                                    </div>}
                                </li>);
                        })}
                    </ul>
                </div>
            </ClickOutside>
        </div>}
        {children}
    </div>;
}

Select.propTypes = {
    valueList: PropTypes.array,
    onSelect: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.any,
    isRequired: PropTypes.bool,
    children: PropTypes.node,
    translateName: PropTypes.bool,
    isUnderstroke: PropTypes.bool,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string
};

Select.defaultProps = {
    translateName: false,
    isUnderstroke: true,
    isDisabled: false
};

export default Select;