import React from 'react';
import PropTypes from 'prop-types';
import FileInput from '../../../../../components/FileInput';
import {localeService} from '../../../../../services/locale.service';
import Modal from '../../../../../components/Modal';
import InputText from '../../../../../components/InputText';
import {uploadPassportFile} from '../../../../../services/passport.service';
import {useTranslation} from 'react-i18next';

function UploadFileToBlock({blockId, onUpload}) {
    const initFile = {file: null, name: '', blockId: blockId};
    const {t} = useTranslation();
    const [isOpened, setOpened] = React.useState(false);
    const [attachedFile, setAttachedFile] = React.useState(initFile);

    const uploadFile = () => {
        if(attachedFile.name === ''){
            alert(localeService.isRussian() ? 'Введите название файла!' : 'Enter field name!');
        } else if(attachedFile.file === null){
            alert(localeService.isRussian() ? 'Загрузите файл!' : 'Select a File!');
        }else {
            uploadPassportFile(attachedFile.file, attachedFile.blockId, attachedFile.name).then(
                () => {
                    onClose();
                    onUpload();
                },
                error => {
                    alert((localeService.isRussian() ? 'Не удалось загрузить файл!' : 'Cannot uploadFile!  ') + error);
                    onClose();
                }
            );
        }
    };

    const onClose = () => {
        setAttachedFile(initFile);
        setOpened(false);
    };

    return (
        <>
            <button className={'btn btn-outline-primary default'} onClick={(e) => {
                e.preventDefault();
                setOpened(true);
            }}>{localeService.isRussian() ? 'Прикрепить файл' : 'Upload File'}</button>
            <Modal isOpen={isOpened} isClosable={false} footer={false} width={700}
                   title={localeService.isRussian() ? 'Прикрепить файл' : 'Attach file'}>
                    <form>
                        <FileInput
                            accept=""
                            onChange={file => setAttachedFile({...attachedFile, file: file})}
                            fileName={attachedFile.file && attachedFile.file.name}
                            buttonText="Нажмите для загрузки файла"
                        />
                        <InputText className={'mt2'}
                                   label={localeService.isRussian() ? 'Название файла' : 'Fields Name'}
                                   value={attachedFile.name}
                                   onChange={text => setAttachedFile({...attachedFile, name: text})}/>
                    </form>
                    <div className={'d-flex justify-content-end'}>
                        <button className={'btn btn-outline-primary default'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    uploadFile();
                                }}>{localeService.isRussian() ? 'Загрузить файл' : 'Upload File'}</button>
                        <button className={'btn btn-outline-danger'} onClick={() => onClose(false)}>{t('close')}</button>
                    </div>
            </Modal>
        </>
    );
}

UploadFileToBlock.propTypes = {
    blockId: PropTypes.number,
    onUpload: PropTypes.func
};

export default UploadFileToBlock;