import React from 'react';
import PropTypes from 'prop-types';

function CustomInputTime({
                           label,
                           value,
                           onChange,
                           min,
                           max,
                           step,
                           placeHolder,
                           disable
                         }) {
  const onChangeHandle = (el) => {
    onChange(el);
  };

  return (
    <div className="scenario-custom">
      {label && <label>{label}</label>}
      <input
        className="scenario-custom__input"
        disabled={disable}
        type={'time'}
        min={min}
        max={max}
        step={step}
        value={value}
        placeholder={placeHolder}
        onChange={(el) => {
          el.preventDefault();
          onChangeHandle(el.target.value);
        }}
      />
    </div>
  );
}

CustomInputTime.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  disable: PropTypes.bool,
  placeHolder: PropTypes.string,
  notInline: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number
};

CustomInputTime.defaultProps = {
  label: '',
  value: '',
  onChange: () => {
  },
  isRequired: false,
  disable: false,
  notInline: false,
  min: 0,
  max: 999,
  step: 1
};

export default CustomInputTime;
