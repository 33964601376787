import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../services/locale.service';
import Modal from '../../components/Modal';
import {tariffService} from '../../services/tariff.service';
import InputText from '../../components/InputText';

function AccessTariffsItem({
                               orgBalance, tariffCode, tariffName, tariffDescript, tariffPrice, activeTariffPrice, isShowPricePM, tariffUser,
                               tariffCapacity, tariffDevice, tariffVariable, isShowDescript, isPricingSquare}){

    const [isSucOpened, setSucOpened] = useState(false);
    const [isErrOpened, setErrOpened] = useState(false);
    const [isAddOpened, setAddOpened] = useState(false);
    const [isErrNotLowOpened, setErrNotLowOpened] = useState(false);

    let [sum,setSum] = useState(0);

    function connectNewTariff() {
        if(tariffPrice < activeTariffPrice) {
            setErrNotLowOpened(true);
        } else{
            orgBalance >= tariffPrice ? isPricingSquare ? setSucOpened(false) : setSucOpened(true)
                : isPricingSquare ? setErrOpened(false) : setErrOpened(true);
        }
    }

    return(
        <div className={isPricingSquare ? 'pricing-square pricing-square-active' : 'pricing-square'}>
            <span className={isPricingSquare ? 'tariff-title tariff-title-active'
                : 'tariff-title'}>{tariffName}</span>
            <div className="pricing-square-description">
                {tariffDescript}
            </div>
            <h4 className={isPricingSquare ? 'pricing-square-price pricing-square-price-active'
                : 'pricing-square-price'}>
                {tariffPrice ? tariffPrice : localeService.isRussian() ? 'БЕСПЛАТНО' : 'FREE'}
                <span className={isShowPricePM ? 'active' : 'hide'}>{localeService.isRussian() ? ' руб/мес'
                    : ' rub/month'}</span>
            </h4>
            <div className="pricing-square-item">{tariffUser}</div>
            <div className="pricing-square-item">{tariffCapacity}</div>
            <div className="pricing-square-item">{tariffDevice}</div>
            <div className="pricing-square-item">{tariffVariable}</div>
            <button className={isPricingSquare ? 'btn btn-outline-primary tariff-btn tariff-btn-active'
                        : 'btn btn-outline-primary default tariff-btn'}
                    onClick={() => connectNewTariff()}>
                {isPricingSquare ? localeService.isRussian() ? 'Сейчас' : 'Now'
                    : localeService.isRussian() ? 'Подключить' : 'Connect'}
            </button>
            <div className={isShowDescript ? 'active' : 'hide'}>
                <div className="pricing-square-description pricing-square-description-bottom">
                    {localeService.isRussian() ? 'Подключение сторонних сервисов '
                        : 'Connection of side services'}
                </div>
            </div>

            <Modal isOpen={isSucOpened}
                    footer={false}
                    title={localeService.isRussian() ? 'Вы уверены?' : 'Are you sure?'}
                    isClosable={false}>
                <div className={'d-flex justify-content-center'}>
                    <button className={'btn btn-outline-success mr2'} onClick={() => {tariffService.changeTariff(tariffCode);}}>
                        {localeService.isRussian() ? 'Да' : 'Yes'}
                    </button>
                    <button className={'btn btn-outline-danger'} onClick={() => setSucOpened(false)}>
                        {localeService.isRussian() ? 'Нет' : 'No'}
                    </button>
                </div>
            </Modal>

            <Modal isOpen={isErrOpened}
                   footer={false}
                   title={localeService.isRussian() ? 'ОШИБКА! НЕДОСТАТОЧНО СРЕДСТВ!' : 'ERROR! INSUFFICIENT FUNDS!'}
                   isClosable={false}>
                <div className={'d-flex justify-content-center'}>
                    <button className={'btn btn-outline-success'} onClick={()=>{setErrOpened(false); setAddOpened(true);}}>
                        {localeService.isRussian() ? 'Пополнить баланс' : 'Top up balance'}
                    </button>
                    <button className={'btn btn-outline-danger'} onClick={() => setErrOpened(false)}>
                        {localeService.isRussian() ? 'Закрыть' : 'Close'}
                    </button>
                </div>
            </Modal>

            <Modal isOpen={isErrNotLowOpened}
                   footer={false}
                   title={localeService.isRussian() ? 'При переходе на более низкий тариф, пожалуйста свяжитесь с администрацией.'
                       : 'When switching to a lower tariff, please contact the administration.'}
                   isClosable={false}>
                <div className={'d-flex justify-content-center'}>
                    <button className={'btn btn-outline-success'} onClick={()=>setErrNotLowOpened(false)}>
                        {localeService.isRussian() ? 'Ясно' : 'Clear'}
                    </button>
                </div>
            </Modal>

            <Modal isOpen={isAddOpened}
                   footer={false}
                   title={localeService.isRussian() ? 'Для пополения баланса вам прийдёт письмо с реквизитами. Введите сумму пополнения.'
                       : 'To top up the balance you will receive a letter with the details. Enter the deposit amount.'}
                   isClosable={false}>
                <div className={'modal-body'}>
                    <form>
                        <InputText label={localeService.isRussian() ? 'Сумма:' : 'Sum:'}
                                   value={sum}
                                   isInteger={true}
                                   onChange={text => setSum(text)}/>
                    </form>
                    <div className={'d-flex justify-content-center'}>
                        <button className={'btn btn-outline-success mr2'} onClick={()=>{tariffService.addOrgBalance(sum); setAddOpened(false);}}>
                            {localeService.isRussian() ? 'Получить реквизиты' : 'Get details'}
                        </button>
                        <button className={'btn btn-outline-danger'} onClick={() => setAddOpened(false)}>
                            {localeService.isRussian() ? 'Отмена пополнения' : 'Cancel top upping'}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

AccessTariffsItem.propTypes = {
    orgBalance: PropTypes.number,
    tariffCode: PropTypes.string,
    tariffName: PropTypes.string,
    tariffDescript: PropTypes.string,
    tariffPrice: PropTypes.string,
    activeTariffPrice: PropTypes.number,
    isShowPricePM: PropTypes.bool,
    tariffUser: PropTypes.string,
    tariffCapacity: PropTypes.string,
    tariffDevice: PropTypes.string,
    tariffVariable: PropTypes.string,
    isShowDescript: PropTypes.bool,
    isPricingSquare: PropTypes.bool,
};

export default AccessTariffsItem;