import React, {useState} from 'react';
import Select from '../../components/Select';
import {useTranslation} from 'react-i18next';
import SelectDateRange from '../../components/SelectDateRange';
import moment from 'moment';
import {localeService} from '../../services/locale.service';
import {Line, LineChart, XAxis, YAxis, CartesianGrid} from 'recharts';
import numeral from 'numeral';
import Modal from '../../components/Modal';
import SelectDeviceVariableFromCapacityTree from '../../components/SelectDeviceVariableFromCapacityTree';
import {capacityService, importService} from '../../services';
import Loader from '../../components/Loader';

function ImportManual() {

    const [inputProps, setInputProps] = useState({
        startDate: new Date(),
        endDate: new Date(),
        tableData: []
    });
    const [textAreaValue, setTextAreaValue] = React.useState('');
    const [textRows, setTextRows] = React.useState([]);
    const [totalValue, setTotalValue] = React.useState(0);
    const [isOpenImportLoader, setOpenImportLoader] = useState(false);
    const [importResult, setImportResult] = useState({isFetching:false, status: '', message:''});
    const [capacityTree, setCapacityTree] = useState([]);
    const [device, setDevice] = useState(null);


    React.useEffect(() => {
        capacityService.getCapacityTree().then(
            results => {
                setCapacityTree(results.list);
            }
        );
    }, []);
    const {t} = useTranslation();

    const cancelInputData = () => {
        setInputProps({
            startDate: new Date(),
            endDate: new Date(),
            tableData: []
        });
        setTextAreaValue('');
        setTextRows([]);
        setTotalValue(0);
        setOpenImportLoader(false);
        setImportResult({isFetching:false, status: '', message:''});
    };

    const fillInputTable = (inProps) => {
        if (inProps.startDate && inProps.endDate && inProps.cron && inProps.cron.value > 0) {
            let tableData = [];
            let begin = moment(inProps.startDate).startOf('day');
            let end = moment(inProps.endDate).endOf('day');
            const dataCount = Math.round(end.diff(begin, 'minutes') / inProps.cron.value);

            [...Array(dataCount).keys()].map(() => {
                begin.add(inProps.cron.value, 'minutes');
                tableData.push({
                    datetime: moment(begin).valueOf(),
                    value: 0
                });
            });
            setInputProps({...inProps, tableData: tableData});
        }
    };

    const onChangeTextArea = (textArea) => {
        const text = textArea.replace(/,/g, '.').replace(/[^0-9.\n\n\r]/g, '');
        setTextAreaValue(text);
        const newLineRegex = /(\n|\n|\r)/gm;
        const textRows = [];
        let total = 0;
        let tableData = inputProps.tableData;
        text.toString()
            .split(newLineRegex)
            .map((part) => {
                if(!part.match(newLineRegex)){
                    if(part === ''){
                        total += 0;
                    }else{
                        total += Number.parseFloat(part);

                    }
                    textRows.push(part);
                }
            });
        textRows.map((rowData,i) => {
            if(i < tableData.length){
                tableData[i].value = Number.parseFloat(rowData);
            }
        });
        setInputProps({...inputProps, tableData: tableData});
        setTotalValue(total);
        setTextRows(textRows);
    };

    const startImport = () => {
        if(device === null){
            alert(localeService.isRussian() ? 'Выберите устройство для записи!' : 'Select Device');
        }else{
            setOpenImportLoader(true);
            setImportResult({...importResult, isFetching: true});
            importService.uploadDataManual(inputProps.tableData, device.id, device.deviceGUID).then(
                results => {
                    setImportResult({status: 'SUCCESS', message:'', isFetching: false});
                    console.log(results);
                },error => {
                    console.log(error);
                    setImportResult({status: 'FAIL', message:'Cannot load data', isFetching: false});
                }
            );
        }
    };

    return (<div>
        <div className={'container-700'}>
            <form>
                <div className={'input-group understroke'}>
                    <div className={'input-group-prepend'}>
                        <label>{localeService.isRussian() ? 'Устройство для записи' : 'Select Device'}</label>
                    </div>
                    <SelectDeviceVariableFromCapacityTree capacityTree={capacityTree}
                                                          onSelect={(device) => {
                                                              setDevice(device);
                                                          }}/>

                </div>

                <SelectDateRange startDate={inputProps.startDate} endDate={inputProps.endDate}
                                 onSetDateRange={dates => {
                                     setInputProps({...inputProps, startDate: dates[0], endDate: dates[1]});
                                     fillInputTable({...inputProps, startDate: dates[0], endDate: dates[1]});
                                 }}/>
                <Select label={t('response_period')}
                        value={{value: '', name: t('check_response_period')}}
                        onSelect={val => {
                            setInputProps({...inputProps, cron: val});
                            fillInputTable({...inputProps, cron: val});
                        }}
                        valueList={[
                            {value: 0, name: t('check_response_period')},
                            {value: 1, name: t('every_minute')},
                            {value: 15, name: t('every_15minute')},
                            {value: 30, name: t('every_30minute')},
                            {value: 60, name: t('every_hour')},
                            {value: 1440, name: t('every_day')}
                        ]}/>

                {inputProps.tableData.length > 0 &&
                <table className={'table manual-input-table scrollable'} cellPadding={0} cellSpacing={0}>
                    {textAreaValue === '' && <label className="textarea-placeholder"
                                                    style={{}}>{localeService.isRussian() ? 'Вставьте столбец со значениями' : 'Paste text here'}</label>}
                    <thead>
                    <tr>
                        <th width="200px" align="right">{t('datetime')}</th>
                        <th width="500px">{t('value')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{}}>{moment(inputProps.tableData[0].datetime).format('DD.MM.YYYY HH:mm')}</td>
                        <td rowSpan={inputProps.tableData.length}>
                                    <textarea rows={inputProps.tableData.length}
                                              value={textAreaValue}
                                              onChange={(text) => onChangeTextArea(text.target.value)}>
                                    </textarea>
                        </td>
                    </tr>
                    {inputProps.tableData.length > 1 && inputProps.tableData.map((dataRow, i) => {
                        if (i > 0) {
                            return <tr key={i}>
                                <td>{moment(dataRow.datetime).format('DD.MM.YYYY HH:mm')}</td>
                            </tr>;
                        }
                    })}
                    </tbody>

                </table>
                }
                {inputProps.tableData.length > 0 &&
                    <>
                    <div className={'row'}>
                        <p><b>{localeService.isRussian() ? 'Количество строк "Дата/Время"' : 'Data/Time Rows Count'}</b>   {inputProps.tableData.length}</p>
                        <p><b>{localeService.isRussian() ? 'Количество введенных строк' : 'Entered Rows Count'}</b>   {textRows.length}</p>
                        <p><b>{localeService.isRussian() ? 'Сумма введенных значений' : 'Rows Total Summ'}</b>   {totalValue.toFixed(3)}</p>
                    </div>
                        <LineChart
                            width={700}
                            height={300}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                tickFormatter={(number) => {
                                return moment(number).format('DD/MM HH:mm');
                            }}
                                allowDataOverflow={true}
                                type="category"
                                dataKey="datetime" />
                            <YAxis dataKey="value"
                                   type="number" domain={['auto', 'auto']}
                                   tickFormatter={(value) => {
                                       return numeral(value).format('0.0 a');
                                   }}/>
                            <Line type="monotone" dataKey="value" stroke="#82ca9d" strokeWidth={2} data={inputProps.tableData} dot={false}
                                  activeDot={false} />
                        </LineChart>

                        <div className={'d-flex justify-content-center'}>
                            <button className={'btn btn-outline-success'} onClick={(e) => {
                                e.preventDefault();
                                startImport();
                            }}>{localeService.isRussian() ? 'Начать импорт' : 'Start Import'}</button>
                        </div>
                        </>
                }

                {/*<AlertError isOpen={textRows.length > inputProps.tableData.length} isClosable={false}*/}
                            {/*message={localeService.isRussian() ? 'Количество введенных строк превышает количество строк Дата/Время' : 'The number of lines entered exceeds the number of date/time lines'}/>*/}

            </form>

        </div>

        {/*Лоадер для импорта*/}
        <Modal isOpen={isOpenImportLoader} onCancel={() => setOpenImportLoader(false)} footer={false}>
            {importResult.isFetching && <Loader waitText={localeService.isRussian() ? 'Идет импорт данных...' : 'Import in process...'}/>}
            {importResult.status === 'SUCCESS' && <h5>{localeService.isRussian() ? 'Данные были успешно загружены' : 'Data has been successfully imported.'}</h5>}
            {importResult.status === 'FAIL' &&
            <div className="alert alert-danger">{importResult.message}</div>}
            <div className={'d-flex'}>
                <button className={'btn btn-outline-primary default'} onClick={() => {
                    setOpenImportLoader(false);
                    cancelInputData();
                }}>Ok</button>
            </div>
        </Modal>
    </div>);
}

export default ImportManual;