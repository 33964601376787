import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import InputText from '../../../components/InputText';
import {useTranslation} from 'react-i18next';

function TextWidget({widgetProps, mode, updateWidget}) {
    const {t} = useTranslation();
    const [widget, setWidget] = useState(widgetProps);
    const closeEditSingleMode = () => {
        updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
    };
    return (
        <>
            {mode === dashboardConstants.VIEW_MODE &&
            <div style={{whiteSpace: 'pre-wrap'}}>{widget.parameters.text}</div>
            }
            {mode === dashboardConstants.EDITION_MODE &&
            <div style={{width: '90%', height: 'calc(100% - 2rem)', textAlign: 'center'}}>
                <div style={{whiteSpace: 'pre-wrap'}}>{(widget.parameters && widget.parameters.text) || (localeService.isRussian() ? 'Ввведите текст' : 'Type a Text')}</div>
            </div>
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false}
                   onCancel={() => closeEditSingleMode()}
                   title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <form>
                    <InputText label={t('name')} value={widget.name}
                               onChange={(text) => setWidget({...widget, name: text})}/>
                    <InputText label={localeService.isRussian() ? 'Текст виджета' : 'Text'}
                               value={widget.parameters && widget.parameters.text}
                               isTextarea = {true}
                    onChange={text => setWidget({...widget, parameters: {...widget.parameters, text: text}})}/>
                </form>
                <div className="d-flex justify-content-center">
                    <button className={'btn btn-outline-danger'} onClick={() => closeEditSingleMode()}>Отмена</button>
                    <button className={'btn btn-outline-success'} onClick={() =>
                        updateWidget({...widget, mode: dashboardConstants.EDITION_MODE})
                    }>Сохранить</button>
                </div>
            </Modal>
            }
        </>
    );
}

TextWidget.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};

export default connect(null, mapDispatchToProps)(TextWidget);