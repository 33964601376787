import React from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants';
import FunctionWidgetView from './FunctionWidgetView';
import FunctionWidgetConfiguration from './FunctionWidgetCreate';
import Modal from '../../../components/Modal';
import {localeService} from '../../../services/locale.service';
import FunctionWidgetPreview from './FunctionWidgetPreview';

function FunctionWidget({widgetProps, mode, onCloseEditSingleMode, sizeParams}) {
    return (
        <>
            {mode === dashboardConstants.VIEW_MODE &&
            <FunctionWidgetView widgetProps={widgetProps}/>
            }
            {mode === dashboardConstants.EDITION_MODE &&
            <FunctionWidgetPreview widgetProps={widgetProps} sizeParams={sizeParams} />
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => onCloseEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <FunctionWidgetConfiguration widgetProps={widgetProps}/>
            </Modal>
            }
        </>
    );
}


FunctionWidget.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    sizeParams: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default FunctionWidget;