import React from 'react';
import PropTypes from 'prop-types';
import SelectDate from './SelectDate';
import CheckBox from './CheckBox';
import Select from './Select';
import InputText from './InputText';
import {useTranslation} from 'react-i18next';

function EditFunctionArg({arg, onChange, disable}) {
    const {t} = useTranslation();
    React.useEffect(() => {
    }, [arg]);
    if (arg.count === 1) {
        switch (arg.type) {
            case 'DATE':
                return <SelectDate selectedDate={new Date(arg.values[0])}
                                   label={t('analytic_parameter.' + arg.name)}
                                   onChange={date => onChange({...arg, values: [date.format('YYYY-MM-DD')]})}/>;
            case 'BOOLEAN':
                return <CheckBox label={t('analytic_parameter.' + arg.name)}
                                 checked={arg.values[0] === 'true'}
                                 onChange={(checked) => onChange({...arg, values: [checked ? 'true' : 'false']})}/>;
            case 'SELECT':
                return <Select label={t('analytic_parameter.' + arg.name)} valueList={arg.selectValues}
                               onSelect={(val) => onChange({...arg, values: [val.name]})}/>;
            case 'INTEGER':
                return <InputText label={t('analytic_parameter.' + arg.name)} value={arg.values[0]} disable={disable}
                                  isInteger={true} onChange={(text) => onChange({...arg, values: [text]})}/>;
            case 'FLOAT':
                return <InputText label={t('analytic_parameter.' + arg.name)} value={arg.values[0]} disable={disable}
                                  isDecimal={true} onChange={(text) => onChange({...arg, values: [text]})}/>;
            default:
                return <InputText label={t('analytic_parameter.' + arg.name) + ' [' + arg.type + ']'} disable={disable}
                                  value={arg.values[0]}
                                  onChange={(text) => onChange({...arg, values: [text]})}/>;
        }
    }else{
        return null;
    }
}

EditFunctionArg.propTypes = {
    onChange: PropTypes.func,
    arg: PropTypes.object,
    disable: PropTypes.bool
};

EditFunctionArg.defaultProps = {
    disable: false
};

export default EditFunctionArg;