import React from 'react';
import '../styles/device-list.scss';
import {Redirect} from 'react-router-dom';

function Sso() {
    const paramsString = document.location.search;
    const searchParams = new URLSearchParams(paramsString);
    const newAuthToken = searchParams.get('authToken');
    const newSessionExpiresIn = searchParams.get('sessionExpiresIn');
    const newRoles = searchParams.get('roles');
    const newOrganizationId = searchParams.get('organizationId');
    const newOrganizationName = searchParams.get('organizationName');
    const newOrganizationPostId = searchParams.get('organizationPostId');
    const newOrganizationPostName = searchParams.get('organizationPostName');
    const newAccessibleRole = searchParams.get('accessibleRole');
    const newStatus = searchParams.get('status');
    const newOrganizationID = searchParams.get('organizationID');
    const newUserAgentToken = searchParams.get('userAgentToken');

    const emsUser = {
        sessionResp: {'authenticationToken':newAuthToken,'sessionExpiresIn':newSessionExpiresIn,'roles':[newRoles],'companyPost':[{'organizationId':newOrganizationId,'organizationName':newOrganizationName,'organizationPostId':newOrganizationPostId,'OrganizationPostName':newOrganizationPostName,'accessibleRole':[newAccessibleRole]}],'status':newStatus},
        organizationID: newOrganizationID,
        userAgentToken: newUserAgentToken
    };
    sessionStorage.setItem('ems_user', JSON.stringify(emsUser));

    return(
        <Redirect
            to='/office'
        />
    );
}

export default Sso;