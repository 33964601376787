import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants';
import {connect} from 'react-redux';
import {withSize} from 'react-sizeme';
import {dashboardService} from '../../../services';
import Loader from '../../../components/Loader';
import AddDemandParametersToFunctionWidget from './components/AddDemandParametersToFunctionWidget';
import * as numeral from 'numeral';
import RRMSECalculation from './components/RRMSECalculation';
import {localeService} from '../../../services/locale.service';

function FunctionWidgetView({widgetProps, datetimeFilter, size, analyticFunctionList}) {

    const widgetSize = {width: size.width, height: (100 * widgetProps.h) - 40};

    const widgetParams = (widgetProps.dataSources && widgetProps.dataSources[0] && widgetProps.dataSources[0].parameters) || null;
    const [functionResult, setFunctionResult] = useState({value: null, error: ''});
    const [isFetching, setFetching] = useState(false);

    useEffect(() => {
        const widgetParams = (widgetProps.dataSources && widgetProps.dataSources[0] && widgetProps.dataSources[0].parameters) || null;
        if(widgetParams && widgetParams.functionCode && !widgetParams.functionCode.startsWith('DEMAND') && widgetParams.functionCode !== 'RRMSE_MULTIPLE') {
            setFunctionResult({...functionResult, error: ''});
            reloadFunctionData();
        }
    },[datetimeFilter]);

    const reloadFunctionData = () => {
        setFetching(true);
        dashboardService.loadFunctionWidgetData(widgetProps.dataSources[0].id, widgetParams, datetimeFilter).then(
            result => {
                applyResult(result);
            }, error => {
                setFunctionResult({
                    value: null,
                    error: error.errorCode + ' ' + error.errorMsg
                });
            }
        ).finally(
            () => setFetching(false)
        );
    };

    const applyResult = (result) => {
        const value = result ? Math.pow(10, result.toFixed().toString().length) : null;
        setFunctionResult({
            value: value,
            error: ''
        });
    };

    return <div style={{height: widgetSize.height}}>
        {isFetching && <Loader/>}
        {widgetParams && <>
        {widgetParams.functionCode !== 'RRMSE_MULTIPLE' &&
        <div className={'d-flex justify-content-center align-items-center'} style={{height: '100%'}}>
        {!isFetching && functionResult.value !== null && <b style={{fontSize: 64, color: widgetParams && widgetParams.color || '#216ba5'}}>{numeral(functionResult.value).format('0.00 a')}</b>}
            {!isFetching && functionResult.value === null && <b style={{fontSize: 64, color: widgetParams && widgetParams.color || '#216ba5'}}>---</b>}
        </div>}

        {widgetParams.functionCode === 'RRMSE_MULTIPLE' &&
            <RRMSECalculation targetDay={datetimeFilter.finishDate} dataSourceId={widgetProps.dataSources[0].id} inTable={false} width={widgetSize.width}
                              height={!widgetProps.name ? (widgetSize.height - 28) : widgetSize.height}/>
        }

        {widgetParams && widgetParams.functionCode && widgetParams.functionCode.startsWith('DEMAND') && analyticFunctionList && widgetProps.dataSources[0] &&
        <AddDemandParametersToFunctionWidget
            onApplyFunction={(result) => applyResult(result)}
            datetimeFilter={datetimeFilter}
            analyticFunctionList={analyticFunctionList}
            dataSource={widgetProps.dataSources[0]} />}

        {!widgetProps.name && <div className={'widget-header'} style={{position: 'absolute', bottom: '-4px',textAlign: 'center', textOverflow: 'ellipsis'}}
        title={(localeService.isRussian() ? 'Источник данных: ' : 'Data Source: ') + (widgetParams && (widgetParams.dataSourceName + (widgetParams.unit ? (', ' + widgetParams.unit) : '')))}>
            <label><small className={'help-text'}>{localeService.isRussian() ? 'Источник данных: ' : 'Data Source: '}</small>{widgetParams && (widgetParams.dataSourceName + (widgetParams.unit ? (', ' + widgetParams.unit) : ''))}</label>
        </div>}
        </>}
    </div>;
}


FunctionWidgetView.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    datetimeFilter: PropTypes.object,
    analyticFunctionList: PropTypes.array
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    return {datetimeFilter, analyticFunctionList};
};

export default withSize()(connect(mapStateToProps, null)(FunctionWidgetView));