import React from 'react';
import Loader from './Loader';
import PropTypes from 'prop-types';
import 'styles/smart-loader.scss';
function SmartLoader({
  waitText,
  status,
  failStatus,
  children,
  failText,
  actionButton,
}) {
  return (
    <div className="smart-loader">
      {failStatus && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h5>{failText}</h5>
          <div className="smart-loader__actions">
            {actionButton && actionButton()}
          </div>
        </div>
      )}
      {status && <Loader waitText={waitText} />}

      {!status && children}
    </div>
  );
}
SmartLoader.propTypes = {
  waitText: PropTypes.string,
  status: PropTypes.bool,
  children: PropTypes.object,
  failText: PropTypes.string,
  actionButton: PropTypes.func,
  failStatus: PropTypes.bool,
};
SmartLoader.defaultProps = {
  failStatus: false,
  status: false,
  waitText: 'Загрузка..',
};
export default SmartLoader;
