import React from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import svgEdit from '../../../sources/images/interface/edit.svg';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import InputText from '../../../components/InputText';
import {useTranslation} from 'react-i18next';
import ColorPicker from '../../ColorPicker';

function EditDashboardDataSource({dataSource, onChange}) {
    const {t} = useTranslation();
    const [ds, setDS] = React.useState(dataSource);
    const [isOpen, setOpen] = React.useState(false);

    const onCancel = () => {
        setDS(dataSource);
        setOpen(false);
    };

    return (
        <>
            <button className={'btn btn-link'} onClick={() => setOpen(!isOpen)}>
                <ReactSVG src={svgEdit}/>
                <span>{t('edit')}</span>
            </button>
            <Modal isOpen={isOpen}
                   title={localeService.isRussian() ? 'Редактирование источника данных' : 'Edit Data Source'}
                   onCancel={() => onCancel()} footer={false}>
                <form>
                    <InputText label={t('name')} value={ds.parameters.dataSourceName}
                               onChange={t => setDS({...ds, parameters: {...ds.parameters, dataSourceName: t}})}/>
                    <div className={'d-flex justify-content-between'}>
                        <InputText label={t('unit')} value={ds.parameters.unit}
                                   onChange={t => setDS({...ds, parameters: {...ds.parameters, unit: t}})}/>
                        <ColorPicker color={ds.parameters.color || '#ededed'} style={{marginLeft: '2rem'}} disabled={false}
                                     setColor={c => setDS({...ds, parameters: {...ds.parameters, color: c}})}/>
                    </div>
                </form>
                <div className={'modal-footer'}>
                    <button className={'btn btn-outline-secondary'} onClick={() => onCancel()}>{t('cancel')}</button>
                    <button className={'btn btn-outline-success'} onClick={() => {
                        onChange(ds);
                        setOpen(false);
                    }}>{t('save')}</button>
                </div>
            </Modal>
        </>
    );
}

EditDashboardDataSource.propTypes = {
    dataSource: PropTypes.object,
    onChange: PropTypes.func
};

export default EditDashboardDataSource;