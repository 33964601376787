import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {updateWidget} from '../../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import {localeService} from '../../../../services/locale.service';
import {dashboardConstants} from '../../../../constants';
import {useTranslation} from 'react-i18next';
import InputText from '../../../../components/InputTextLayOut';
import CheckBox from '../../../../components/CheckBox';
import {ReactSVG} from 'react-svg';
import svgDelete from '../../../../sources/images/interface/basket.svg';
import AddDataSourcesGroup from './components/AddDataSourcesGroup';

function SurplusAndDeficitWidgetCreate({widgetProps, updateWidget, dashboardDataSources}) {
    const {t} = useTranslation();
    const [widget, setWidget] = useState(widgetProps);
    const [isGroupWidget, setGroupWidget] = useState(widgetProps.dataSources && widgetProps.dataSources.length > 4);

    return <>
        <form>
            <InputText label={t('widget_name')} value={widget.name}
                       onChange={(text) => setWidget({...widget, name: text})}/>
            <CheckBox checked={isGroupWidget}
                      label={localeService.isRussian() ? 'Виджет для группы' : 'Widget for group'}
                      onChange={() => setGroupWidget(!isGroupWidget)}/>

            {widget.dataSources && widget.dataSources.filter(el => el.parameters.type === 'FACT_DS').map((fds, fdsIdx) => (
                <div className={'group'} key={`group${fdsIdx}`}>
                    <label className={'group-header'}>
                        {localeService.isRussian() ? 'Группа источников данных ' : 'Data Source '}
                    </label>
                    <p><b className={'me-4'}>{fds.parameters.type}</b>{fds.parameters.dataSourceName}</p>
                    {widget.dataSources.filter(el => (el.sourceID ? (el.parameters.parentId === Number.parseInt(fds.sourceID)) : (el.parameters.parentId === fds.id)))
                        .map((addDS, aIdx) => (<p key={`addDs${fdsIdx}${aIdx}`}>
                                <b className={'me-4'}>{addDS.parameters.type}</b>
                                {addDS.parameters.dataSourceName}
                            </p>)
                        )}
                    <div className={'d-flex justify-content-end'}>
                        <button className={'danger svg-btn ml1'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    let widgetDSList = [...widget.dataSources];
                                    widgetDSList = widgetDSList.filter(el => el.parameters.parentId !== fds.id);
                                    widgetDSList = widgetDSList.filter(el => el.id !== fds.id);
                                    setWidget({...widget, dataSources: widgetDSList});
                                }}>
                            <ReactSVG src={svgDelete}/>
                        </button>
                    </div>
                </div>
            ))}


            {(widget.dataSources.length === 0 || isGroupWidget) &&
            <AddDataSourcesGroup dashboardDataSources={dashboardDataSources.filter(dds => !widget.dataSources.some(wds => wds.id === dds.id))}
                                 onChange={(dsGroup) => {
                                     setWidget({...widget, dataSources: [...widget.dataSources,...dsGroup]});
                                 }}/>}

        </form>
        <div className={'modal-footer mt-5'}>
            <div className={'d-flex justify-content-center'}>
                <button type={'button'} className={'btn btn-outline-success'}
                        onClick={() => {
                            updateWidget({...widget, mode: dashboardConstants.EDITION_MODE});
                        }}>
                    {localeService.isRussian() ? 'Сохранить' : 'Save'}
                </button>
                <button type={'button'} className={'btn btn-outline-danger'}
                        onClick={() => {
                            updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                        }}>
                    {t('cancel')}
                </button>
            </div>
        </div>
    </>;
}

SurplusAndDeficitWidgetCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(SurplusAndDeficitWidgetCreate);