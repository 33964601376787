import {apiConstants} from '../constants';
import {apiService} from './api.service';
import i18next from 'i18next';
import {loginService} from './login.service';


export function getVirtualDeviceVariables(deviceId) {

    const user = loginService.getCurrentUser();
    const config = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
            'Content-Type': 'application/json',
            'user-agent-token': user.userAgentToken
        }
    };
    const url = apiService.addProps(apiConstants.GET_VIRTUAL_DEVICE_VARIABLE_LIST) + '&deviceId=' + deviceId;
    return fetch(url, config)
        .then(response => {
            const jResponse = response.json();
            if (response.status !== 200) {
                return jResponse.then(() => Promise.reject('Cannot get virtual device variables!'));
            }
            return jResponse;
        });
}

export function getRealDeviceVariables(deviceId) {
    const user = loginService.getCurrentUser();
    const config = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
            'Content-Type': 'application/json',
            'user-agent-token': user.userAgentToken
        }
    };
    const url = apiService.addProps(apiConstants.GET_DEVICE_VARIABLE_LIST) + '&deviceId=' + deviceId;
    return fetch(url, config)
        .then(response => {
            const jResponse = response.json();
            if (response.status !== 200) {
                return jResponse.then(() => Promise.reject('Cannot get device variables!'));
            }
            return jResponse;
        });
}

export function findDevice(deviceId) {
    const body = {
        'guid': deviceId
    };
    return apiService.fetchPost(apiConstants.FIND_DEVICE, JSON.stringify(body));
}

export function getDeviceList(pageNum, countPerPage, searchCriteria) {
    const user = loginService.getCurrentUser();
    const config = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
            'Content-Type': 'application/json',
            'user-agent-token': user.userAgentToken
        }
    };
    let page = pageNum;
    if (page === undefined) {
        page = 0;
    }
    let count = countPerPage;
    if (count === undefined) {
        count = 1000;
    }
    const url = apiService.addProps(apiConstants.GET_DEVICE_LIST);
    return fetch(url + '&page=' + page + '&size=' + count + '&searchCriteria=' + searchCriteria, config)
        .then(response => {
            const jResponse = response.json();
            if (!response.ok) {
                return jResponse.then(err => Promise.reject(err.message));
            }
            return jResponse;
        })
        .then(jResponse => {
            if (jResponse.status !== 'SUCCESS') {
                return Promise.reject('Cannot get device list!');
            }
            return jResponse;
        });
}


export function getAllDeviceList() {
    const config = {
        method: 'GET',
        headers: {
            'Authorization': apiService.getAuthorizationHeader(),
            'user-agent-token': sessionStorage.getItem('ua_token')
        }
    };
    const url = apiService.addProps(apiConstants.GET_DEVICE_LIST);
    return fetch(url + '&page=0&size=10000', config)
        .then(response => {
            const jResponse = response.json();
            if (!response.ok) {
                return jResponse.then(() => Promise.reject('Cannot get device list!'));
            }
            return jResponse;
        });
}

export function getVariablesByCapacity(capacityId) {
    return apiService.fetchGet(apiConstants.GET_VARIABLE_LIST_BY_CAPACITY, {capacityId: capacityId}).then(
        response => {
            return response.result;
        }
    );
}

export function addRealDevice(newDevice) {
    return apiService.fetchPost(apiConstants.ADD_REAL_DEVICE, JSON.stringify(newDevice));
}

export function editDevice(newDevice) {
    return apiService.fetchPost(apiConstants.EDIT_DEVICE, JSON.stringify(newDevice));
}

export function addVirtualVariable(variable, deviceId) {
    return apiService.fetchPost(apiConstants.ADD_VIRTUAL_VARIABLE, JSON.stringify(variable), {deviceId: deviceId});

}

export function deleteVirtualVariable(deviceId, virtualVariableId){
    return apiService.fetchPost(apiConstants.DELETE_VIRTUAL_VARIABLE, null, {deviceId: deviceId, id: virtualVariableId});
}

export function addVirtualDevice(device) {
    const body = {
        'name': device.name,
        'relations': device.variableList.map(el => {
            let virtualVariable = {
                capacityID: el.capacity.id,
                name: el.name
            };
            if(el.generator){
                virtualVariable = {...virtualVariable, type: el.generator.type, config: JSON.stringify(el.generator.config)};
            }
            return virtualVariable;
        })
    };

    return apiService.fetchPost(apiConstants.ADD_VIRTUAL_DEVICE, JSON.stringify(body));
}

export function addModbusDevice(device) {
    const body = {
        'name': device.name,
        'networkConfig': {
            host: device.host,
            port: device.port
        },
        'relations': device.variableList.map((el,i) => {
            let virtualVariable = {
                capacityID: el.capacity.id,
                name: el.name,
                active: true,
                timeout: 0,
                variableId: i
            };
            return virtualVariable;
        })
    };

    return apiService.fetchPost(apiConstants.ADD_MODBUS_DEVICE, body);
}

export function getGenerators() {
    const generators = [
        {
            name: i18next.language === 'ru' ? 'Без генератора' : 'Without generator',
            value: ''
        },
        {
            name: 'Analog Random',
            value: 'ANALOG_RANDOM'
        },
        {
            name: 'Analog Function',
            value: 'ANALOG_FUNCTION'
        },
        {
            name: 'Digital Random Period',
            value: 'DIGITAL_RANDOM_PERIOD'
        },
        {
            name: 'Digital Fixed Count',
            value: 'DIGITAL_FIXED_COUNT'
        }
    ];
    return Promise.resolve(generators);
}

export function editVirtualVariable(variable){
    const body = {
        deviceId: variable.deviceId,
        id: variable.id,
        name: variable.name,
        organizationId:JSON.parse(sessionStorage.getItem('ems_user')).organizationID
    };
    return apiService.fetchPost(apiConstants.EDIT_VIRTUAL_VARIABLE, JSON.stringify(body));
}

export function getDevice(deviceId){
    return apiService.fetchGet(apiConstants.GET_DEVICE, {id: deviceId});
}

export function renameDevice(deviceId, name){
    return apiService.fetchPost(apiConstants.DEVICE_RENAME, JSON.stringify({name: name}), {id: deviceId});
}
export function deleteDevice(deviceId){
    return apiService.fetchPost(apiConstants.DEVICE_DELETE, null, {deviceId: deviceId});
}

export function deviceLocation(deviceId,lat,lng){
    return apiService.fetchPost(apiConstants.DEVICE_LOCATION, JSON.stringify({lat: lat, lng: lng}),{deviceId: deviceId});
}

export function setDeviceTimeZone(deviceId, timeZone){
    return apiService.fetchPost(apiConstants.SET_DEVICE_TIMEZONE, JSON.stringify({timeZone: timeZone}) ,{deviceId: deviceId});
}

export function getDeviceBySourceId(sourceId){
    return apiService.fetchGet(apiConstants.GET_DEVICE_BY_SOURCEID, {sourceId: sourceId}).then(
        response => {
            const device = response.result;
            return device;
        }
    );
}

export const deviceService = {
    getDevice,
    deleteDevice,
    editVirtualVariable,
    findDevice,
    renameDevice,
    getGenerators,
    addRealDevice,
    addVirtualDevice,
    getDeviceList,
    getVariablesByCapacity,
    addVirtualVariable,
    editDevice,
    getRealDeviceVariables,
    getVirtualDeviceVariables,
    getAllDeviceList,
    deleteVirtualVariable,
    addModbusDevice,
    deviceLocation,
    setDeviceTimeZone,
    getDeviceBySourceId
};

