/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../../services/locale.service';
import {updateWidget} from '../../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import InputText from '../../../../components/InputText';
import {dashboardConstants} from '../../../../constants/index';
import AddDataSourceWithAdditionalDataSources from '../components/AddDataSourceWithAdditionalDataSources';
import {ReactSVG} from 'react-svg';
import svgDelete from '../../../../sources/images/interface/basket.svg';

function GroupUnloadingCreate({widgetProps, updateWidget, dashboardDataSources}) {
    const [widget, setWidget] = useState(widgetProps);
    const {t} = useTranslation();

    return (
        <>
            <form>
                <InputText label={t('widget_name')} value={widget.name}
                           onChange={(text) => setWidget({...widget, name: text})}/>


                <div className={'row'}>
                    <p className={'help-text'}>
                        <b>{localeService.isRussian() ? 'Настройка источников данных' : 'Data Sources Configuration'}</b>
                    </p>
                </div>
                {/*<SetWidgetDataSources widgetDataSources={widgetProps.dataSources}*/}
                {/*dashboardDataSources={dashboardDataSources}*/}
                {/*onChange={(wDSList) => {*/}
                {/*setWidget({...widget, dataSources: wDSList});*/}
                {/*}}/>*/}
                {widget.dataSources.map((dataSource, i) => {
                    if (dataSource.parameters.type === 'MAIN_DATA_SOURCE') {
                        return <div className={'group'} key={`group${i+1}`}>
                            <label className={'group-header'}>
                                {localeService.isRussian() ? 'Группа источников данных ' : 'Data Source '}
                            </label>
                            <p>{dataSource.parameters.dataSourceName || (localeService.isRussian() ? 'Источник данных не выбран' : 'Data Source is not selected')}</p>
                            <p className={'mt-4'}><i>Дополнительные источники данных:</i></p>
                            <ul>
                                {widget.dataSources.filter(el => (el.sourceID ? (el.parameters.parentId === Number.parseInt(dataSource.sourceID)) : (el.parameters.parentId === dataSource.id)))
                                    .map((addDS, aIdx) => (<li key={`addDs${i}${aIdx}`}>
                                        {addDS.parameters.dataSourceName + (addDS.capacity ? ` (${addDS.capacity.name})` : '')}
                                    </li>)
                                    )}
                            </ul>
                            <div className={'d-flex justify-content-end'}>
                                <button className={'danger svg-btn ml1'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            let widgetDSList = [...widget.dataSources];
                                            widgetDSList = widgetDSList.filter(el => el.parameters.parentId !== dataSource.id);
                                            widgetDSList = widgetDSList.filter(el => el.id !== dataSource.id);
                                            setWidget({...widget, dataSources: widgetDSList});
                                        }}>
                                    <ReactSVG src={svgDelete}/>
                                </button>
                            </div>
                        </div>;
                    }
                })}
                <div className={'d-flex justify-content-center'}>
                    <AddDataSourceWithAdditionalDataSources dashboardDataSources={dashboardDataSources}
                                                            onChange={(dsGroup) => {
                                                                setWidget({
                                                                    ...widget,
                                                                    dataSources: [...widget.dataSources, ...dsGroup]
                                                                });

                                                            }}/>
                </div>
                <div className={'modal-footer mt-5'}>
                    <div className={'d-flex justify-content-center'}>
                        <button type={'button'} className={'btn btn-outline-success'}
                                onClick={() => {
                                    updateWidget({...widget, mode: dashboardConstants.EDITION_MODE});
                                }}>
                            {localeService.isRussian() ? 'Сохранить' : 'Save'}
                        </button>
                        <button type={'button'} className={'btn btn-outline-danger'}
                                onClick={() => {
                                    updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                                }}>
                            {t('cancel')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

GroupUnloadingCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupUnloadingCreate);