import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import {connect} from 'react-redux';

function AddLinkToDashboard({dashboardTagList, onChange, selectedDashboard}) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className={'d-flex justify-content-between align-items-start'}>
                <div className=" input-group understroke">
                    <div className="input-group-prepend"><label>Ссылка на дашборд</label></div>
                    {selectedDashboard && <input type={'text'} title={selectedDashboard.name} disabled={true} value={selectedDashboard.name}/>}
                </div>
                <button type={'button'} className={'btn btn-outline-secondary'}
                        onClick={() => setIsOpen(!isOpen)}>
                    {!selectedDashboard && <span>{localeService.isRussian() ? 'Привязать ссылку на дашборд' : 'Add link to Dashboard'}</span>}
                    {selectedDashboard && '...'}
                </button>
            </div>
            <Modal isOpen={isOpen} isClosable={false} width={800}
                   onCancel={() => setIsOpen(false)}
                   title={localeService.isRussian() ? 'Выберите дашборд' : 'Select dashboard'}>
                <div className={'dropdown-container open is-always-open'}>
                    <div className={'dropdown open'}>
                        <div className={'dropdown-content'}>
                            <ul className="tree-parent open">
                                {dashboardTagList && dashboardTagList.map((db, dbIdx) => {
                                    if (db.tag) {
                                        return <DashboardGroup key={'tag' + dbIdx} dashboardGroup={db} onChange={(el) => {
                                            onChange(el);
                                            setIsOpen(false);
                                        }} selectedDashboard={selectedDashboard}/>;
                                    } else {
                                        return <li key={'db' + db.id} onClick={() => {
                                            onChange(db);
                                            setIsOpen(false);
                                        }}>
                                            <div className={'tree-item ' + (selectedDashboard && selectedDashboard.id === db.id ? 'active' :'')}>
                                                <span>{db.name}</span>
                                            </div>
                                        </li>;
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

function DashboardGroup({dashboardGroup, onChange, selectedDashboard}) {
    const [isOpen, setIsOpen] = useState(selectedDashboard && selectedDashboard.id ? (dashboardGroup.dashboardList.findIndex(el => el.id === selectedDashboard.id) >= 0 ? true : false) : false);
    return (<>
            <li>
                <div className={'tree-item '}>
                    <button type={'button'} className={'toggle-tree-item'}
                            onClick={() => setIsOpen(!isOpen)}>{isOpen ? '-' : '+'}</button>
                    <span>{dashboardGroup.tag}</span>
                </div>
            </li>
            <ul className={'tree-parent' + (isOpen ? ' open' : '')}>
                {dashboardGroup.dashboardList.map(db => {
                    return <li key={'db' + db.id} onClick={() => onChange(db)}>
                        <div className={'tree-item ' + (selectedDashboard && selectedDashboard.id === db.id ? 'active' :'')}>
                            <span>{db.name}</span>
                        </div>
                    </li>;
                })}
            </ul>
        </>
    );
}

DashboardGroup.propTypes = {
    dashboardGroup: PropTypes.object,
    onChange: PropTypes.func,
    selectedDashboard: PropTypes.object
};

AddLinkToDashboard.propTypes = {
    dashboardTagList: PropTypes.array,
    onChange: PropTypes.func,
    selectedDashboard: PropTypes.object
};

const mapStateToProps = state => {
    const dashboardTagList = state.dashboardReducer.dashboardTagList;
    return {dashboardTagList};
};

export default connect(mapStateToProps, null)(AddLinkToDashboard);