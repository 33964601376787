import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../../../../../components/CheckBox';

function CustomLegend({visibleCharts, chartsData, onChange}) {

    return (
        <>
            {chartsData.length > 0 && <div className={'d-flex justify-content-center'}>
                <ul className={'legend'}>
                    {chartsData.map((dataSource, i) => {
                        return <li key={'ds-legend-' + i} style={{color: dataSource.parameters.color}}>
                            <CheckBox checked={visibleCharts.findIndex(el => el === dataSource.parameters.type) >= 0}
                                      onChange={(checked) => {
                                          if(checked){
                                              onChange([...visibleCharts, dataSource.parameters.type]);
                                          }else{
                                              onChange(visibleCharts.filter(el => el != dataSource.parameters.type));
                                          }
                                      }}/>
                    <span className="li-text ms-1">
                        {dataSource.parameters.dataSourceName}
                        {dataSource.parameters.unit ? (', ' + dataSource.parameters.unit) : ''}
                        </span>
                        </li>;
                    })}
                </ul>

            </div>}
        </>
    );
}

CustomLegend.propTypes = {
    visibleCharts: PropTypes.array,
    chartsData: PropTypes.array,
    onChange: PropTypes.func
};


export default CustomLegend;