import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../components/Modal';
import {localeService} from '../../../../services/locale.service';
import InputText from '../../../../components/InputTextLayOut';
import AlertError from '../../../../components/AlertError';
import CheckBox from '../../../../components/CheckBox';
import SelectLayOut from 'components/SelectLayOut';

function AddDataSourceWithAdditionalDataSources({onChange, dashboardDataSources}) {
    const {t} = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const [error, setError] = useState(null);
    const [additionalDataSources, setAdditionalDataSources] = useState([]);
    const [mainDS, setMainDataSource] = useState(null);

    return (<div className={'d-flex justify-content-center'}>
        <button className={'btn btn-outline-primary'}
                title={localeService.isRussian() ? 'Добавить группу источников данных' : 'Add Data Source Group'}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                    setMainDataSource(null);
                    setAdditionalDataSources([]);
                }}>
            {localeService.isRussian() ? 'Добавить группу источников данных' : 'Add Data Source Group'}
        </button>
        <Modal onCancel={() => setOpen(false)} footer={false} isClosable={false} isOpen={isOpen}
               title={localeService.isRussian() ? 'Добавление группы источников данных' : 'Data Source Group Addition'}>
            <form>
                <SelectLayOut label={localeService.isRussian() ? 'Основной источник данных' : 'Main Data Source'}
                        valueList={dashboardDataSources.map(dbDS => {
                            return {
                                id: dbDS.id,
                                name: dbDS.name + (dbDS.capacity ? `(${dbDS.capacity.name})` : '')
                            };
                        })}
                        onSelect={(ds) => {
                            const mainDS = dashboardDataSources.find(el => el.id === ds.id);
                            mainDS.parameters = {
                                dataSourceName: ds.name,
                                type: 'MAIN_DATA_SOURCE'
                            };
                            mainDS.idx = 'new';
                            setMainDataSource(mainDS);
                        }}
                        defaultValue={mainDS ?
                            {
                                id: mainDS.id,
                                name: mainDS.parameters.dataSourceName + (mainDS.parameters.unit ? (', ' + mainDS.parameters.unit) : '') + (mainDS.capacity ? `(${mainDS.capacity.name})` : '')
                            } : 'Выберите основной источник данных'}/>
                <div className={''}>
                    <InputText label={t('name')}
                               value={(mainDS && mainDS.parameters && mainDS.parameters.dataSourceName) || ''}
                               onChange={text => setMainDataSource({
                                   ...mainDS,
                                   parameters: {...mainDS.parameters, dataSourceName: text}
                               })}/>
                </div>
                {mainDS &&
                <ul style={{listStyle: 'none', backgroundColor: '#efefef'}}>
                    <li key={'empty_index'} style={{margin: '1rem 0 1rem -1rem'}}><i>{localeService.isRussian() ? 'Выберите дополнительные источники данных' : 'Select additional data sources'}</i></li>
                    {dashboardDataSources.length > 0 && dashboardDataSources.sort((a, b) => {
                        return a.capacity.name.localeCompare(b.capacity.name);
                    }).map((dbDS, i) => {
                        return [(dashboardDataSources[i - 1] === undefined || (dashboardDataSources[i - 1] && dashboardDataSources[i - 1].capacity.id !== dbDS.capacity.id)) &&
                        <li><b>{dbDS.capacity.name}</b></li>,
                            <li key={i}>
                                <CheckBox
                                    label={(dbDS.id === mainDS.id ? '* ': '') + dbDS.parameters.dataSourceName + (dbDS.parameters.unit ? (', ' + dbDS.parameters.unit) : '')}
                                    checked={additionalDataSources.findIndex(el => el.id === dbDS.id) >= 0}
                                    isDisabled={dbDS.id === mainDS.id}
                                    title={dbDS.id === mainDS.id ? 'Источнык данных уже выбран, как основной!' : ''}
                                    onChange={() => {
                                        if(additionalDataSources.findIndex(el => el.id === dbDS.id) < 0){
                                            setAdditionalDataSources([...additionalDataSources, dbDS]);
                                        }else{
                                            setAdditionalDataSources(additionalDataSources.filter(el => el.id !== dbDS.id));
                                        }
                                    }}
                                />
                            </li>];
                    })}
                </ul>
                }

                <div className={'modal-footer'}>
                    <div className={'d-flex justify-content-center'}>
                        <button type="button" className={'btn btn-outline-success'} onClick={(e) => {
                            e.preventDefault();
                            if (mainDS === null) {
                                setError(localeService.isRussian() ? 'Выберите источник данных, к которому необходимо применить функцию' : 'Select Data Source');
                            } else {
                                setOpen(false);
                                let widgetDataSources = [mainDS];
                                additionalDataSources.map(ds => {
                                    widgetDataSources.push({...ds, idx: 'new', parameters: {dataSourceName: ds.name, type: 'ADDITIONAL_DATA_SOURCE', parentId: mainDS.id}});
                                });
                                setAdditionalDataSources([]);
                                setMainDataSource(null);
                                onChange(widgetDataSources);
                            }
                        }}>{t('add')}</button>
                        <button className={'btn btn-outline-danger'} onClick={() => {
                            setOpen(false);
                            setMainDataSource(null);
                            setAdditionalDataSources([]);
                        }}>{t('cancel')}</button>
                    </div>
                </div>
            </form>
            <AlertError isOpen={error !== null} onCancel={() => setError(null)} message={error}/>
        </Modal>

    </div>);
}

AddDataSourceWithAdditionalDataSources.propTypes = {
    dashboardDataSources: PropTypes.array,
    onChange: PropTypes.func
};


const mapStateToProps = state => {
    const transformationFunctionList = state.analyticReducer.transformationFunctionList;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {transformationFunctionList, analyticFunctionList, datetimeFilter};
};


export default connect(mapStateToProps, null)(AddDataSourceWithAdditionalDataSources);