import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {localeService} from '../../services/locale.service';
import Select from '../../components/Select';
import InputText from '../../components/InputText';

function AddFunctionToDatasource({aggregationFunctionList, analyticFunctionList, onApplyFunction, appliedFunction, withDemand}) {


    const [selectedFunction, setSelectedFunction] = useState(appliedFunction);
    const [availableFunctionList, setFunctionList] = useState(aggregationFunctionList);

    React.useEffect(() => {
        const specialRRMSEFunction = {
            code: 'RRMSE_MULTIPLE',
            name: 'RRMSE Multiple',
            countParam: 0
        };

        const analyticFuncList = withDemand ? analyticFunctionList.filter(el =>
            el.code === 'DEMAND_RESPONSE_CHECK' || el.code === 'DEMAND_RESPONSE_BOOLEAN' || el.code === 'DEMAND_RESPONSE_RRMSE' || el.code === 'DEMAND_RESPONSE_BASELINE_APPLICABILITY') : [];
        setFunctionList([...availableFunctionList, ...analyticFuncList, specialRRMSEFunction]);
    }, []);

    // React.useEffect(() => {
    //     setSelectedFunction(appliedFunction);
    //     if(appliedFunction !== undefined){
    //         const idx = availableFunctionList.findIndex(el => el.code === appliedFunction.functionCode);
    //         if(idx >= 0){
    //             console.log('Selected Func', availableFunctionList[idx]);
    //         }
    //
    //     }
    // }, [appliedFunction]);

    const onChange = (func) => {
        setSelectedFunction(func);
        onApplyFunction(func);
    };


    return <>
        <div className={'group'}>
            <label
                className={'group-header'}>{localeService.isRussian() ? 'Применить функцию ' : 'Apply Function'}</label>
            <Select label={localeService.isRussian() ? 'Выберите функцию' : 'Select Function'}
                    valueList={availableFunctionList || []}
                    value={selectedFunction && {name: selectedFunction.functionName}}
                    onSelect={f => {
                        console.log('Selected Func', f);
                        let selectedFunc = {
                            functionCode: f.code,
                            functionParams: Array.from(Array(f.countParam), () => 0),
                            functionName: f.name
                        };
                        onChange(selectedFunc);
                    }}/>
            {selectedFunction && selectedFunction.functionParams && !selectedFunction.functionCode.startsWith('DEMAND') && selectedFunction.functionParams.length > 0 &&
            Array.from(Array(selectedFunction.functionParams.length).keys()).map(i => {
                return (<InputText key={'funcParam' + i}
                                       label={(localeService.isRussian() ? 'Необходимый для функции параметр ' : 'Required Function Parameter ') + (i + 1) }
                                       isInteger={true}
                                   value={selectedFunction.functionParams[i]}
                                       onChange={(text) => {
                                           let funcParams = selectedFunction.functionParams;
                                           funcParams[i] = text;
                                           onChange({...selectedFunction, functionParams: funcParams});
                                       }}
                />);
            })
            }
        </div>

    </>;
}

AddFunctionToDatasource.propTypes = {
    aggregationFunctionList: PropTypes.array,
    analyticFunctionList: PropTypes.array,
    onApplyFunction: PropTypes.func,
    selectedFunction: PropTypes.object,
    appliedFunction: PropTypes.object,
    withDemand: PropTypes.bool
};

AddFunctionToDatasource.defaultProps = {
    withDemand: true
};

const mapStateToProps = state => {
    const aggregationFunctionList = state.analyticReducer.aggregationFunctionList;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    return {aggregationFunctionList, analyticFunctionList};
};



export default connect(mapStateToProps, null)(AddFunctionToDatasource);