import React from 'react';
import '../styles/preloader.scss';

function Loader() {

    return (<div className={'dots-loader'}>
        <div className={'dots dot-1'}/>
        <div className={'dots dot-2'}/>
        <div className={'dots dot-3'}/>
    </div>);
}

export default Loader;