import {apiConstants} from '../constants';
import {apiService} from './api.service';

function getOrganizationList() {
    return apiService.fetchGet(apiConstants.ORGANIZATION_LIST);
}

function getOrganizationRoleList() {
    return apiService.fetchGet(apiConstants.ORGANIZATION_ROLE_LIST).then(
        resp => {
            return resp.list;
        }
    );
}

function createOrganizationRole(newRole) {
    return apiService.fetchPost(apiConstants.ORGANIZATION_ROLE_CREATE, JSON.stringify(newRole));
}

function deleteOrganizationRole(roleId){
    return apiService.fetchGet(apiConstants.ORGANIZATION_ROLE_DELETE, {organizationPostId: roleId});
}

function addUserToOrganization(newUser) {
    return apiService.fetchPost(apiConstants.ORGANIZATION_ADD_USER, JSON.stringify(newUser));
}

function editUser(user) {
    return apiService.fetchPost(apiConstants.ORGANIZATION_EDIT_USER, JSON.stringify(user));
}

function getOrganizationUserList() {
    return apiService.fetchGet(apiConstants.ORGANIZATION_USER_LIST);
}

function getOrganizationInfo() {
    return apiService.fetchGet(apiConstants.ORGANIZATION_INFO);
}

function getAccessibleRoleList() {
    return apiService.fetchGet(apiConstants.ORGANIZATION_ACCESSIBLE_ROLE_LIST);
}

function deleteUser(userId, postId) {
    return apiService.fetchGet(apiConstants.ORGANIZATION_DELETE_USER, {userId: userId, organizationPostId : postId});
}

function editOrganizationInfo(organization){
    return apiService.fetchPost(apiConstants.ORGANIZATION_INFO_EDIT, JSON.stringify({
        'address': organization.address,
        'inn': organization.inn,
        'kpp': organization.kpp,
        'name': organization.name,
        'phones': organization.phones
    }));
}


export const organizationService = {
    getOrganizationList,
    getOrganizationInfo,
    getOrganizationRoleList,
    getOrganizationUserList,
    addUserToOrganization,
    editUser,
    editOrganizationInfo,
    createOrganizationRole,
    deleteOrganizationRole,
    getAccessibleRoleList,
    deleteUser
};

