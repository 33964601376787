import 'styles/accordion.scss';

import PropTypes from 'prop-types';
import React from 'react';
import {ReactSVG} from 'react-svg';
import svgArrow from '../../../sources/images/interface/arrow.svg';

export default function Accordion({ children, label }) {
  const [active, setActive] = React.useState(false);
  return (
    <div className="accordion">
      <header className="accordion-header">
        <button
          className="default svg-btn "
          onClick={() => setActive(!active)}
        >
          {active ? <ReactSVG src={svgArrow}/> : <ReactSVG style={{transform: 'rotate(-90deg)'}} src={svgArrow}/>}
            <p>{label}</p>
        </button>
      </header>
      <hr />
      {active && <div className="accordion-content">{children}</div>}
    </div>
  );
}

Accordion.propTypes = {
  children: PropTypes.object,
  label: PropTypes.string,
};
