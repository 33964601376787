import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import {localeService} from '../../../../services/locale.service';
import {ReactSVG} from 'react-svg';
import svgLimits from '../../../../sources/images/interface/limits.svg';
import svgDelete from '../../../../sources/images/interface/basket.svg';
import InputText from '../../../../components/InputText';

import {useTranslation} from 'react-i18next';
import CheckBox from '../../../../components/CheckBox';
import ColorPicker from '../../../ColorPicker';
import Select from '../../../../components/Select';
import AlertError from '../../../../components/AlertError';

function SetChartLimits({limits, onChange, dataSources}) {
    const {t} = useTranslation();
    const [isOpened, setOpened] = React.useState(false);
    const [chartLimits, setChartLimits] = React.useState(limits);
    const [error, setError] = React.useState('');

    const deleteLimit = (idx) => {
        let limitList = chartLimits.values;
        limitList.splice(idx, 1);
        if (idx >= 0) {
            setChartLimits({...chartLimits, values: limitList});
        }
    };

    const createLimit = () => {
        let limitList = [...chartLimits.values];
        limitList.push({value: 0, name: '', color: 'red', dataSource: null});
        setChartLimits({isVisible: true, values: limitList});
    };

    return (<>
        <button className={'btn btn-outline-primary default round-btn' + (chartLimits && chartLimits.isVisible ? ' active':'')}
                title={localeService.isRussian() ? 'Установить пороги' : 'Set Limits'}
                onClick={() => setOpened(true)}>
            <ReactSVG src={svgLimits}/>
        </button>
        <Modal onCancel={() => setOpened(false)} footer={false} isClosable={false} width={'600px'} overflow={'auto'}
               isOpen={isOpened}
               title={localeService.isRussian() ? 'Установить пороги' : 'Set Limits'}>
            <form>
                {chartLimits.values.map((limit, i) => {
                    return <div className={'group'} key={'limit' + i}>
                        <label
                            className={'group-header'}>{localeService.isRussian() ? 'Порог ' : 'Limit '}{(i + 1)}</label>

                        <div className={'row'}  style={{padding: 0, display: 'flex', width: '100%'}}>
                            <InputText isInteger={true} value={limit.value}
                                       label={t('value')}
                                       onChange={value => {
                                           let limitList = chartLimits.values;
                                           limitList[i].value = value;
                                           setChartLimits({...chartLimits, values: limitList});
                                       }}/>
                            <ColorPicker style={{margin: '0 1rem'}} color={limit.color} setColor={(color) => {
                                let limitList = chartLimits.values;
                                limitList[i].color = color;
                                setChartLimits({...chartLimits, limits: limitList});
                            }}/>
                        </div>
                        <Select label={localeService.isRussian() ? 'Источник данных' : 'Data Source'}
                                valueList={dataSources.map(ds => {
                                    return {id: ds.id, name: ds.parameters.dataSourceName};
                                })}
                                onSelect={(ds) => {
                                    let limitList = chartLimits.values;
                                    limitList[i].dataSource = ds;
                                    setChartLimits({...chartLimits, values: limitList});
                                }}
                                value={limit.dataSource}/>
                        <div className={'row'} key={'limit' + i} style={{padding: 0, display: 'flex', width: '100%'}}>
                            <InputText value={limit.name}
                                       label={(localeService.isRussian() ? 'Название порога ' : 'Limit Name')}
                                       onChange={text => {
                                           let limitList = chartLimits.values;
                                           limitList[i].name = text;
                                           setChartLimits({...chartLimits, values: limitList});
                                       }}/>
                            <button className={'danger svg-btn ml1'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteLimit(i);
                                    }}>
                                <ReactSVG src={svgDelete}/>
                            </button>
                        </div>
                    </div>;
                })}

                {chartLimits.values.length > 0 &&
                <CheckBox checked={chartLimits.isVisible}
                          label={localeService.isRussian() ? 'Показывать пороги' : 'Show Limits'}
                          onChange={() => {
                              setChartLimits({...chartLimits, isVisible: !chartLimits.isVisible});
                          }}
                />
                }

                <AlertError onCancel={() => setError('')} isClosable={false} message={error} isOpen={error !== ''}/>

                <div className={'d-flex justify-content-center'}>
                    <button type={'button'} className={'btn btn-outline-primary default'}
                            onClick={() => createLimit()}>
                        {localeService.isRussian() ? 'Создать порог' : 'Add Limit'}
                    </button>
                    <button type={'button'} className={'btn btn-outline-success'}
                            onClick={(e) => {
                                e.preventDefault();
                                setError('');
                                let isValid = true;
                                chartLimits.values.map(el => {
                                   if(el.dataSource === null){
                                       isValid = false;
                                       return;
                                   }
                                });
                                if(isValid){
                                    onChange(chartLimits);
                                    setOpened(false);
                                }else{
                                    setError(localeService.isRussian() ? 'Пожалуйста, выберите источник данных!' : 'Please, select Data Source!');
                                }
                            }}>
                        {t('apply')}
                    </button>
                    <button type={'button'} className={'btn btn-outline-primary default'}
                            onClick={() => setOpened(false)}>
                        {t('close')}
                    </button>
                </div>
            </form>
        </Modal>
    </>);
}

SetChartLimits.propTypes = {
    limits: PropTypes.object,
    onChange: PropTypes.func,
    dataSources: PropTypes.array
};


export default SetChartLimits;