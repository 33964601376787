/*eslint-disable no-unused-vars*/
import {localeService} from '../../../services/locale.service';
import EditWidgetDataSource from '../EditWidgetDataSource';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {dashboardConstants} from '../../../constants';
import InputText from '../../../components/InputText';
import ImageUploader from '../../../components/ImageUploader';
import {SizeMe} from 'react-sizeme';
import {dashboardService} from '../../../services';
import AddFunctionToDatasource from '../AddFunctionToDatasource';
import {imageService} from '../../../services/image.service';

function MapWidgetCreate({widgetProps, updateWidget}) {
    const [widget, setWidget] = useState(widgetProps);
    const {t} = useTranslation();
    const [image, setImage] = useState(null);
    const [imageSize, setImageSize] = useState({width: 0, height: 0});
    const [openedTab, setOpenedTab] = useState('image');
    const [editedMarker, setEditedMarker] = useState(null);

    React.useEffect(() => {
        if (!widget.parameters) {
            setWidget({
                ...widget,
                dataSources: [],
                parameters: {
                    function: null,
                    image: null
                }
            });
        }
        if(widget.parameters && widget.parameters.image){
            imageService.getImage(widget.parameters.image)
                .then(image => {
                    setImage(image);
                });
        }
    }, []);

    const createDataSource = (e) => {
        const x = e.nativeEvent.offsetX * 100 / imageSize.width;
        const y = e.nativeEvent.offsetY * 100 / imageSize.height;
        const dataSources = [...widget.dataSources,
            {
                idx: new Date().getTime(),
                parameters: {
                    dataSourceName: '',
                    color: dashboardService.getRandomColor(),
                    position: {
                        x: x,
                        y: y
                    }
                }
            }];
        setWidget({...widget, dataSources: dataSources});
    };

    const deleteDataSource = (idx) => {
        let dsList = widget.dataSources;
        dsList.splice(idx, 1);
        if (idx >= 0) {
            setWidget({...widget, dataSources: dsList});
        }

    };

    const setMouseCoordinates = (e) => {
        const x = e.nativeEvent.offsetX;
        const y = e.nativeEvent.offsetY;

        if (editedMarker) {
            let nMarkers = [...widget.dataSources];
            const idx = nMarkers.findIndex(el => el.id === editedMarker.id);
            nMarkers[idx].parameters.position.x = x * 100 / imageSize.width;
            nMarkers[idx].parameters.position.y = y * 100 / imageSize.height;
            setWidget({...widget, dataSources: nMarkers});
        }
    };

    return (
        <div title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
            <SizeMe>{({size}) =>
                <form>
                    <div className={'row'}>
                        <InputText label={t('name')} value={widget.name}
                                   onChange={(text) => setWidget({...widget, name: text})}/>
                    </div>
                    <div className={'row'}>
                        <ImageUploader withPreloader={true} returnBase64={false} onChange={img =>
                            imageService.uploadImage(img).then(
                                result => {
                                    const imgName = result.name;
                                    console.log('imgName', imgName);
                                    setWidget({
                                        ...widget,
                                        parameters: {
                                            ...widget.parameters,
                                            image: imgName
                                        }
                                    });
                                    imageService.getImage(result.name)
                                        .then(image => {
                                            setImage(image);
                                        });
                                }
                            )}/>
                    </div>


                    {image &&
                    <div className={'d-flex justify-content-center'}>
                        <button className={'btn btn-outline-primary default ' + (openedTab === 'image' ? 'active' : '')}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenedTab('image');
                                }}>
                            {localeService.isRussian() ? 'Метки на изображении' : 'Markers on Image'}
                        </button>
                        <button className={'btn btn-outline-primary  default ' + (openedTab === 'datasources' ? 'active' : '')}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenedTab('datasources');
                                }}>
                            {localeService.isRussian() ? 'Настройки источников данных' : 'Datasource Props'}
                        </button>
                    </div>
                    }
                    {image && <div className={'row'}>
                        <div className={openedTab === 'image' ? 'col-70' : 'col-30'}
                             onClick={() => setOpenedTab('image')}>
                            {openedTab === 'image' && <p className={'help-text'}>
                                {localeService.isRussian() ? 'Кликните на изображение, где вы хотите поставить метку' : 'Click on image where you want to set Marker'}
                            </p>}
                            <div className={'mnemoscheme'} style={{
                                position: 'relative',
                                width: openedTab === 'image' ? (size.width * 0.7) : (size.width * 0.3)
                            }}>
                                <img src={image} style={{
                                    userSelect: 'none',
                                    maxWidth: openedTab === 'image' ? (size.width * 0.7) : (size.width * 0.3),
                                    height: 'auto'
                                }}
                                     onLoad={({target: img}) => setImageSize({
                                         width: img.offsetWidth,
                                         height: img.offsetHeight
                                     })}
                                     onClick={(e) => {
                                         if (openedTab === 'image') {
                                             createDataSource(e);
                                         }
                                     }}
                                     onMouseMove={e => setMouseCoordinates(e)}
                                     onMouseUp={() => setEditedMarker(null)}
                                />
                                {widget.dataSources.map((dataSource, i) => {
                                    return <span className={'img-marker'}
                                                 onMouseDown={() => setEditedMarker(dataSource)}
                                                 onMouseUp={() => setEditedMarker(null)}
                                                 key={i} style={{
                                        userSelect: 'none',
                                        position: 'absolute',
                                        top: dataSource.parameters.position.y + '%',
                                        left: dataSource.parameters.position.x + '%',
                                        cursor: 'pointer',
                                        backgroundColor: 'white',
                                        padding: '6px',
                                        borderRadius: '8px',
                                        border: '4px solid' + dataSource.parameters.color
                                    }}>{dataSource.parameters.dataSourceName}</span>;
                                })}
                            </div>
                        </div>
                        <div className={openedTab === 'datasources' ? 'col-70' : 'col-30'}
                             onClick={() => setOpenedTab('datasources')}
                             style={{paddingLeft: '2rem'}}>

                            {widget.dataSources.length > 0 && widget.dataSources.map((dataSource, key) => {
                                return <EditWidgetDataSource key={key}
                                                             isCollapsed={openedTab !== 'datasources'}
                                                             dataSourceNumber={key + 1}
                                                             widgetDataSource={dataSource}
                                                             onChangeDataSource={(ds) => {
                                                                 widget.dataSources[key] = ds;
                                                                 setWidget(widget);
                                                             }}
                                                             withLinkToDashboard={true}
                                                             onDeleteDataSource={() => {
                                                                 deleteDataSource(key);
                                                                 // setWidget({...widget, dataSources: widget.dataSources.filter(el => el.idx !== ds.idx)});
                                                             }}
                                />;
                            })}

                            {widget.dataSources && widget.dataSources.length > 0 &&
                            <AddFunctionToDatasource withDemand={false} appliedFunction={widget.parameters.function ? {
                                ...widget.parameters.function,
                                name: widget.parameters.function.functionName
                            } : null}
                                                     onApplyFunction={(appliedFunction) => {
                                                         setWidget({
                                                             ...widget,
                                                             parameters: {
                                                                 ...widget.parameters,
                                                                 function: appliedFunction
                                                             }
                                                         });
                                                     }}/>
                            }
                        </div>
                    </div>
                    }


                    <div className={'d-flex justify-content-center'}>
                        {/*{dashboardDataSources.length > 0 &&*/}
                        {/*<button type={'button'} className={'btn btn-outline-primary default'}*/}
                        {/*onClick={() => createDataSource()}>*/}
                        {/*{localeService.isRussian() ? 'Добавить источник данных' : 'Add Data Source'}*/}
                        {/*</button>*/}
                        {/*}*/}
                        <button type={'button'} className={'btn btn-outline-success'}
                                onClick={() => {
                                    updateWidget({...widget, mode: dashboardConstants.EDITION_MODE});
                                }}>
                            {localeService.isRussian() ? 'Сохранить' : 'Save'}
                        </button>
                        <button type={'button'} className={'btn btn-outline-danger'}
                                onClick={() => {
                                    updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                                }}>
                            {t('cancel')}
                        </button>
                    </div>
                </form>
            }</SizeMe>

        </div>);
}

MapWidgetCreate.propTypes = {
    widgetProps: PropTypes.object,
    dashboardDataSources: PropTypes.array,
    updateWidget: PropTypes.func,
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};
const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(MapWidgetCreate);