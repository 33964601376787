export const analyticsConstants = {
    GET_SCRIPT_LIST: 'GET_SCRIPT_LIST',
    GET_TRANSFORMATION_FUNCTION_LIST: 'GET_TRANSFORMATION_FUNCTION_LIST',
    GET_ANALYTIC_FUNCTION_LIST: 'GET_ANALYTIC_FUNCTION_LIST',
    UPLOAD_FUNCTION_LIST: 'UPLOAD_FUNCTION_LIST'
};


export const DRFunctionList = [
    'DEMAND_RESPONSE_BASELINE_APPLICABILITY'
    // 'ELECTRICITYCOSTCALCULATION',
    // 'DEMAND_RESPONSE_RRMSE',
    // 'ANALYSISDEMANDRESPONSERMSE',
    // 'ANALYSISDEMANDRESPONSERMSESCALAR',
    // 'DEMAND_RESPONSE_CHECK',
    // 'DEMAND_RESPONSE_BOOLEAN',
    // 'ANALYSISPREDICTIONCLD',
    // 'ANALYSISPREDICTIONHW',
    // 'ANALYSISPREDICTIONPAR',
    // 'ANALYSISPREDICTIONNDAYS',
    // 'ANALYSISPREDICTIONSARIMA',
    // 'PEAK_PREDICTION_ML',
    // 'PEAK_PREDICTION_STATISTICAL',
    // 'PREDICTION-HOLT-WINTERS',
    // 'TEST'
];

export const PredictionFunctionList = [
    'PEAK_PREDICTION_ML',
    'PEAK_PREDICTION_STATISTICAL',
    // 'ANALYSISPREDICTIONCLD',
    'ANALYSISPREDICTIONHW',
    // 'ANALYSISPREDICTIONPAR',
    'ANALYSISPREDICTIONAAR',
    // 'FLEXIBILITY',
    // 'ANALYSISPREDICTIONNDAYS',
    'ANALYSISPREDICTIONSARIMA',
    // 'PREDICTION-HOLT-WINTERS',
    // 'ANALYSISDEMANDRESPONSERMSE'
];
