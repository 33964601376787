import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SelectCapacityTree from '../../../components/SelectCapacityTree';
import Loader from '../../../components/Loader';
import {localeService} from '../../../services/locale.service';
import {useTranslation} from 'react-i18next';
import {deviceService} from '../../../services/device.service';
import Modal from '../../../components/Modal';
import {capacityService} from '../../../services/capacity.service';
import {setDataSourceList, updateWidget} from '../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import {dashboardService} from '../../../services';
import ColorPicker from '../../ColorPicker';
import CheckBox from '../../../components/CheckBox';
import EditDashboardDataSource from './EditDashboardDataSource';

function SelectDBDataSources({dataSourceList, setDataSourceList, widgetList, updateWidget}) {
    const [capacity, setCapacity] = useState(null);
    const [isFetchingDeviceVariables, setFetchingDeviceVariables] = useState(false);
    const [capacityVariables, setCapacityVariables] = useState([]);
    const [usedVariableList, setUsedVariableList] = useState([]);
    const [isAllSelected, setAllSelected] = useState(false);
    const {t} = useTranslation();
    const [isOpenSelectDS, setOpenSelectDS] = useState(false);
    const [capacityTree, setCapacityTree] = useState([]);

    React.useEffect(() => {
        capacityService.getCapacityTree()
            .then((response) => {
                setCapacityTree(response.list);
            });
        setUsedVariableList(dataSourceList);
    }, [dataSourceList]);

    const onSelectCapacity = (capacity) => {
        setCapacity(capacity);
        setCapacityVariables([]);
        setFetchingDeviceVariables(true);
        deviceService.getVariablesByCapacity(capacity.id).then(
            variableList => {
                setCapacityVariables(variableList);
                setFetchingDeviceVariables(false);
            }
        );
        setAllSelected(false);
    };

    const initNewDataSource = (variable) => {
        const newDS = {
            ...variable,
            capacity: {name: capacity.name, id: capacity.id},
            parameters: {
                dataSourceName: variable.name,
                color: dashboardService.getRandomColor()
            }
        };
        return newDS;
    };

    const addVariable = (variable) => {
        let varList = [];
        if (isChecked(variable.id)) {
            varList = usedVariableList.filter(el => el.id !== variable.id);
        } else {
            varList = [...usedVariableList, initNewDataSource(variable)];
        }
        setDataSourceList(varList);
    };

    const isChecked = (varId) => {
        let isExist = false;
        if (usedVariableList.length > 0) {
            const idx = usedVariableList.findIndex(el => el.id === varId);
            if (idx >= 0) {
                isExist = true;
            }
        }
        return isExist;
    };


    const selectAll = () => {
        let varList = usedVariableList;
        if (!isAllSelected) {
            capacityVariables.filter(f => !usedVariableList.some(item => item.id === f.id)).map(variable => {
                const newVar = initNewDataSource(variable);
                varList = [...varList, newVar];
            });

            setAllSelected(true);
        } else {
            capacityVariables.map(variable => {
                varList = varList.filter(el => el.id !== variable.id);
            });
            setAllSelected(false);
        }
        setDataSourceList(varList);
    };


    const updateDataSource = (dataSource) => {
        let dsList = [...usedVariableList];
        let dsIdx = dsList.findIndex(el => el.id === dataSource.id);
        dsList[dsIdx] = dataSource;
        setDataSourceList(dsList);
        widgetList.map(w => {
            let wDsIdx = w.dataSources.findIndex(el => el.sourceID === (dataSource.sourceID !== undefined ? dataSource.sourceID : dataSource.id));
            if (wDsIdx >= 0) {
                w.dataSources[wDsIdx].parameters = {
                    ...w.dataSources[wDsIdx].parameters,
                    dataSourceName: dataSource.parameters.dataSourceName,
                    unit: dataSource.parameters.unit,
                    color: dataSource.parameters.color
                };
                updateWidget(w);
            }
        });
    };

    return (
        <div className={'container-fluid mb-5'}>
            <table className={'table'}>
                <thead>
                <tr>
                    <td>№</td>
                    <td>{t('variable_name')}</td>
                    <td>{t('capacity')}</td>
                    <td>{t('device_guid')}</td>
                    <td>{t('data_source_name')}</td>
                    <td>{t('unit')}</td>
                    <td>{t('color')}</td>
                    <td></td>
                </tr>
                </thead>
                <tbody>
                {usedVariableList.length === 0 && <tr>
                    <td colSpan={8}><span
                        className={'help-text'}>{localeService.isRussian() ? 'Список источников данных пуст' : 'Data Source List is empty!'}</span>
                    </td>
                </tr>}
                {usedVariableList.map((ds, i) => {
                    console.log(`usedVariableList(${i})`,ds);
                    return <tr key={i + '' + ds.id}
                               style={{backgroundColor: ((!ds.capacity || !ds.deviceGUID) ? '#ffdede' : 'white')}}
                               title={(!ds.capacity || !ds.deviceGUID) ? 'Ошибки в источнике данных!' : ''}>
                        <td className={'ds-idx'}>{i + 1}</td>
                        <td>{ds.name}</td>
                        <td className={'help-text'}>{ds.capacity ? ds.capacity.name : ''}</td>
                        <td className={'help-text'}>{ds.deviceGUID}</td>
                        <td>{ds.parameters.dataSourceName}</td>
                        <td>{ds.parameters.unit}</td>
                        <td>
                            <ColorPicker style={{margin: '0 1rem'}} color={ds.parameters.color} disabled={true}/>
                        </td>
                        <td><EditDashboardDataSource dataSource={ds} onChange={el => updateDataSource(el)}/></td>
                    </tr>;
                })}
                </tbody>
            </table>

            <button className={'mt-4 btn btn-outline-success'}
                    onClick={() => {
                        setCapacity(null);
                        setCapacityVariables([]);
                        setOpenSelectDS(true);
                    }}>{localeService.isRussian() ? 'Добавить источник данных' : 'Add Data Source'}</button>

            <Modal isOpen={isOpenSelectDS} isClosable={false} width={800}
                   onCancel={() => setOpenSelectDS(false)}
                   title={localeService.isRussian() ? 'Выберите переменные устройств из дерева нагрузок' : 'Select variables from capacity tree'}>
                <div className={'select-db-datasources'} style={{backgroundColor: 'transparent'}}>
                    <div className={'col'}>
                        <h2 className={'step'}>{localeService.isRussian() ? 'Дерево нагрузок' : 'Capacity Tree'}</h2>
                        <SelectCapacityTree capacityTree={capacityTree}
                                            onSelect={capacity => onSelectCapacity(capacity)}
                                            isAlwaysOpen={true}/>
                    </div>
                    <div className={'col'} style={{borderLeft: '1px dashed #d8d8d8'}}>
                        <h2 className={'step'}>{localeService.isRussian() ? 'Переменные нагрузки' : 'Capacity Variables'}</h2>
                        {!capacity &&
                        <p className={'help-text'}>{localeService.isRussian() ? 'Выберите нагрузуку' : 'Select capacity'}</p>}
                        {capacity &&
                        <p className={'help-text'}>{(localeService.isRussian() ? 'Переменные нагрузки ' : 'Variables of capacity ') + '"' + capacity.name + '"'}</p>}
                        {isFetchingDeviceVariables && <Loader
                            waitText={localeService.isRussian() ? 'Загрузка переменных ...' : 'Fetching device variables...'}/>}
                        {!isFetchingDeviceVariables && capacityVariables.length > 0 &&
                        <div
                            className={'dropdown-container open is-always-open'}>
                            <div className={'dropdown open'} style={{minWidth: 'calc(100% - 1rem)'}}>
                                <div className="dropdown-content">
                                    <ul className={'select-variable-list'}>
                                        {capacityVariables.length > 1 &&
                                        <li key={'var-all'}>
                                            <CheckBox checked={isAllSelected}
                                                      label={localeService.isRussian() ? 'Выбрать все' : 'Select All'}
                                                      onChange={() => selectAll()}/>
                                        </li>}
                                        {capacityVariables.map((variable) => {
                                            return (
                                                <li key={'var' + variable.id}>
                                                    <CheckBox checked={isChecked(variable.id)} label={variable.name}
                                                              onChange={() => addVariable(variable)}/>
                                                </li>
                                            );
                                        })}

                                    </ul>
                                </div>
                            </div>
                        </div>}
                        {!isFetchingDeviceVariables && capacity && capacityVariables.length === 0 &&
                        <p>{localeService.isRussian() ? 'В выбранной нагрузке нет источников данных' : 'Variable List for this capacity is empty'}</p>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    );
}

SelectDBDataSources.propTypes = {
    dataSourceList: PropTypes.array,
    widgetList: PropTypes.array,
    setDataSourceList: PropTypes.func,
    updateWidget: PropTypes.func
};

const mapStateToProps = state => {
    const dataSourceList = state.dashboardReducer.dataSourceList;
    const widgetList = state.dashboardReducer.widgetList;
    return {dataSourceList, widgetList};
};

const mapDispatchToProps = {
    setDataSourceList: setDataSourceList,
    updateWidget: updateWidget
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectDBDataSources);