import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {CirclePicker} from 'react-color';
import {ReactSVG} from 'react-svg';
import svgColor from '../sources/images/interface/color.svg';
import {localeService} from '../services/locale.service';
import ClickOutside from './ClickOutside';

function ColorPicker({color, setColor, style}) {
    const [isOpen, setOpen] = useState(false);

    return <>
        <div className={'popover-container'}>
            <button type={'button'}
                    title={localeService.isRussian() ? 'Изменить цвет' : 'Change color'}
                    className={'btn eco-btn color-picker'}
                    style={{backgroundColor: color, position: 'relative', ...style}}
                    onClick={() => setOpen(!isOpen)}>
                <ReactSVG src={svgColor}/>
            </button>
            {isOpen &&
            <ClickOutside key={'popover'} onClick={() => setOpen(false)}>
                <div style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    border: '1px solid grey',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    bottom: 0,
                    right: '52px',
                    height: 'fit-content',
                    zIndex: 2,
                    display: 'flex'
                }}>

                    <CirclePicker color={color}
                                  onChangeComplete={(color) => {
                                      setOpen(false);
                                      setColor(color.hex);
                                  }
                                  }/>

                </div>
            </ClickOutside>
            }
        </div>
    </>;
}

ColorPicker.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object,
    setColor: PropTypes.func
};

export default ColorPicker;