export function calculateTotalData(dsInGroup, totalData, widgetDataSources) {
    return totalData.map((tData) => {
        let data = [];
        if (dsInGroup.length > 0) {
            dsInGroup.map((groupId, gIdx) => {
                widgetDataSources.filter(el => el.parameters.type === tData.parameters.type && (el.parameters.parentId ? el.parameters.parentId === groupId : parseInt(el.sourceID) === groupId)).map((wds) => {
                    if (gIdx > 0) {
                        if (wds.data.length === data.length) {
                            data = wds.data.map((d, j) => {
                                return {...d, value: d.value + data[j].value};
                            });
                        }
                    } else {
                        data = wds.data;
                    }
                });
            });
        }
        return {...tData, data: data};
    });
}

export function initTotalData() {
    return ['FACT_DS', 'FACT_DR_DS', 'GBN_DS', 'GBN_DR_DS'].map((dsType, i) => {
        return {
            id: i,
            isVisible: true,
            parameters: {dataSourceName: getDSNameByType(dsType), color: getColorByType(dsType), type: dsType}
        };
    });

}

export function calculateGbnMinusFactDr(dsList) {
    dsList.filter(el => el.parameters.type === 'FACT_DS').map((fDs) => {
        const gbnDs = dsList.find(el => el.parameters.type === 'GBN_DS' && el.parameters.parentId === parseInt(fDs.sourceID));
        const factDrDs = dsList.find(el => el.parameters.type === 'FACT_DR_DS' && el.parameters.parentId === parseInt(fDs.sourceID));
        let gbnMinusFactDrData = [];
        if (gbnDs.data && factDrDs.data && gbnDs.data.length === factDrDs.data.length) {
            gbnMinusFactDrData = gbnDs.data.map((d1, i) => {
                return {...d1, value: d1.value - factDrDs.data[i].value};
            });
        }
        fDs.gbnMinusFactDrData = gbnMinusFactDrData;
    });
    return dsList;
}

export function setUnloadValue(dsList) {
    dsList.filter(el => el.parameters.type === 'FACT_DS').map((fDs) => {
        fDs.unloadValue = 1;
    });
    return dsList;
}

const getColorByType = (type) => {
    if (type === 'FACT_DS') {
        return 'blue';
    } else if (type === 'FACT_DR_DS') {
        return 'orange';
    } else if (type === 'GBN_DS') {
        return 'red';
    } else if (type === 'GBN_DR_DS') {
        return 'green';
    } else {
        return 'grey';
    }
};

export function getDSNameByType(type) {
    if (type === 'FACT_DS') {
        return 'Факт';
    } else if (type === 'FACT_DR_DS') {
        return 'Факт + DR';
    } else if (type === 'GBN_DS') {
        return 'ГБН';
    } else if (type === 'GBN_DR_DS') {
        return 'ГБН + DR';
    } else {
        return type;
    }
}

export function generateHighLigthDifferenceData(dsInGroup, widgetDataSources, totalDataSources, window) {
    const unloadSumm = widgetDataSources.filter(el => el.parameters.type === 'FACT_DS').reduce((total,item) => {
         return dsInGroup.includes(+item.sourceID) ? (total + parseFloat(item.unloadValue)) : total;
         }, 0);

    let gbnDataList = [];
    totalDataSources.find(el => el.parameters.type === 'GBN_DS').data.map(data => {
        if (data.time_upload >= window.start && data.time_upload <= window.finish) {
            gbnDataList.push({time_upload: data.time_upload , value: data.value - unloadSumm});
        }
    });


    const gbnMinusUnloadSumm = {
        parameters: {
            dataSourceName: 'ГБН - Разгрузка',
            color: 'purple'
        },
        data: gbnDataList
    };

    let backlightsAreas = [];
    const factDrDs = totalDataSources.find(el => el.parameters.type === 'FACT_DR_DS');
    if(factDrDs && factDrDs.data){
        factDrDs.data.map((factDrData, timeIdx) => {
            if (factDrData.time_upload >= window.start && factDrData.time_upload < window.finish) {
                const gbnData = gbnDataList.find(el => el.time_upload === factDrData.time_upload);

                if(gbnData){
                    let color = 'grey';
                    if (gbnData.value < factDrData.value) {
                        color = 'red';
                    } else {
                        color = 'green';
                    }
                    backlightsAreas = [...backlightsAreas, {
                        color: color,
                        x1: factDrDs.data[timeIdx].time_upload,
                        x2: factDrDs.data[timeIdx + 1].time_upload,
                        y1: Math.min(factDrDs.data[timeIdx].value, gbnData.value),
                        y2: Math.max(factDrDs.data[timeIdx].value, gbnData.value)
                    }];
                    backlightsAreas = [...backlightsAreas, {
                        color: 'url(#' + color + ')',
                        x1: factDrDs.data[timeIdx].time_upload,
                        x2: factDrDs.data[timeIdx + 1].time_upload
                    }];
                }
            }
        });
    }

    let highLigthDifferenceData = {
        gbnMinusUnloadSumm: gbnMinusUnloadSumm,
        totalUnloadValue: unloadSumm,
        backlightsAreas: backlightsAreas
    };

    return highLigthDifferenceData;
}