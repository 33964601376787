import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import AlertError from '../../components/AlertError';
import InputText from '../../components/InputText';
import Select from '../../components/Select';
import {organizationService} from '../../services/organization.service';
import {localeService} from '../../services/locale.service';
import Loader from '../../components/Loader';

function AddNewUserToOrganization({roleList, onSubmit}) {
    const {t} = useTranslation();
    const [user, setUser] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        organizationPost: null,
        email: ''
    });
    const [isFetching, setFetching] = useState(false);
    const [error, setError] = useState('');
    const [validationError, setValidationError] = useState('');

    const addUser = () => {
        if (user.email === '') {
            setValidationError(localeService.isRussian() ? 'Введите email!' : 'Enter email!');
        } else if (user.organizationPost === null) {
            setValidationError(localeService.isRussian() ? 'Выберите роль!!' : 'Select Role!');
        } else {
            setFetching(true);
            let newUser = user;
            newUser.organizationPostId = user.organizationPost.id;
            delete newUser.organizationPost;
            organizationService.addUserToOrganization(newUser)
                .then(
                    result => {
                        if (result.status === 'SUCCESS') {
                            onSubmit(true);
                        }
                    }, error => {
                        setError((localeService.isRussian() ? 'Невозможно добавить пользователя! ' : 'Cannot add user! ' ) + t('error_code.' + error));
                    }
                ).finally(() => setFetching(false));
        }
    };

    return <>
        {isFetching && <Loader/>}
        {!isFetching && !error &&
        <form>
            <InputText isRequired={true} label={t('email')} value={user.email}
                       onChange={text => setUser({...user, email: text})}/>
            <InputText label={t('first_name')} value={user.firstName}
                       onChange={text => setUser({...user, firstName: text})}/>
            <InputText label={t('middle_name')} value={user.middleName}
                       onChange={text => setUser({...user, middleName: text})}/>
            <InputText label={t('last_name')} value={user.lastName}
                       onChange={text => setUser({...user, lastName: text})}/>
            <Select label={t('role')} valueList={roleList} value={user.organizationPost}
                    onSelect={post => setUser({...user, organizationPost: post})}/>
            <hr/>
            {!validationError && <div className={'d-flex justify-content-center'}>
                <button type={'button'} className={'btn btn-outline-success mr1'}
                        onClick={(e) => {
                            e.preventDefault();
                            addUser();
                        }}>{t('apply')}</button>
                <button type={'button'} className={'btn btn-outline-primary default'}
                        onClick={() => onSubmit(false)}>{t('cancel')}</button>
            </div>}
            <AlertError isOpen={validationError !== ''} message={validationError}
                        onCancel={() => setValidationError('')}/>
        </form>}
        <AlertError isOpen={error !== ''} message={error} onCancel={() => onSubmit(false)}/>
    </>;
}

AddNewUserToOrganization.propTypes = {
    roleList: PropTypes.array,
    onSubmit: PropTypes.func
};

export default AddNewUserToOrganization;