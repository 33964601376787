import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withSize} from 'react-sizeme';
import {connect} from 'react-redux';
import {widgetsService} from '../../../../services/widget.service';
import numeral from 'numeral';
import moment from 'moment';
import {Area, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis} from 'recharts';
import {buildArgs, calculateTimeSeries, initOGO, initSNE, initVIE, initWidgetDataSources} from './strategy.service';
import StrategyTooltip from './StrategyTooltip';
import {analyticService} from '../../../../services/analyticService';
import SetStrategyArgs from './SetStrategyArgs';
import {dashboardService} from 'services/dashboard.service';

function StrategyView({widgetProps, datetimeFilter, size}) {

    const [widgetDateTime, setWidgetDateTime] = useState(datetimeFilter);
    const [widgetDataSources, setWidgetDataSources] = useState([]);
    const [isFetching, setFetching] = useState(true);
    const [flexibilityResult, setFlexibility] = useState([]);

    const [sne, setSne] = useState(initSNE());
    const [vie, setVie] = useState(initVIE());
    const [ogo, setOgo] = useState(initOGO());

    useEffect(() => {
        setFetching(true);

        let dt = datetimeFilter;
        setWidgetDateTime(dt);

        initWidgetDataSources(widgetProps.dataSources, dt).then(
            dsList => {
                setWidgetDataSources(dsList);
                setFetching(false);
            }
        );
    }, [datetimeFilter]);



    const calculateFlexibility = () => {
        // let timeOffset = moment().utcOffset();
        let timeOffset = dashboardService.getDataSourceTimeOffset(widgetDataSources[0].device);

        let args = buildArgs(sne, vie, ogo, widgetDateTime, timeOffset);

        let funcProps = {
            name: 'flexibility',
            code: 'FLEXIBILITY',
            args: args,
            outputsCount: 1,
            functionCode: 'FLEXIBILITY',
            sourceType: 'ANALYTIC_FUNCTION',
            parameters: {
                dataSourceName: 'F(x): flexibility',
                color: '#b73159',
                dataSource: {
                    id: widgetDataSources[0].props.id,
                    name: widgetDataSources[0].props.name
                }
            }
        };

        setFetching(true);
        analyticService.getAnalyticWithResultId(widgetDataSources.find(el => el.type === 'MAIN_DATA_SOURCE').props.id, funcProps, datetimeFilter).then(
            result => {
                console.log(result);
                if (result && result.data) {
                    setFlexibility(result.data);
                }else{
                    alert('Не удалось рассчитать гибкость! Попробуйте другие параметры');
                }
            }
        ).catch(() => alert('Не удалось рассчитать гибкость! Попробуйте другие параметры'))
            .finally(() => setFetching(false));

    };

    return (
        <div className={'widget-content' + (isFetching ? ' data-loading' : '')}>
            <ComposedChart
                width={size.width}
                height={((100 * widgetProps.h) - 60)}
            >
                <CartesianGrid strokeDasharray='3 3'/>
                <XAxis
                    dataKey="time_upload"
                    tickFormatter={(number) => {
                        return moment.unix(number).format(moment.duration(widgetDateTime.finishDate.diff(widgetDateTime.startDate)).asHours() > 24 ? 'DD/MM HH:mm' : 'HH:mm:ss');
                    }}
                    allowDataOverflow={true}
                    type="number"
                    allowDecimals={false}
                    // allowDuplicatedCategory={false}
                    scale={'utc'}
                    domain={[moment(moment(widgetDateTime.startDate)).unix().valueOf(), moment(moment(widgetDateTime.finishDate)).unix().valueOf()]}
                />
                <YAxis dataKey="value"
                       type="number" domain={['auto', 'auto']}
                       tickFormatter={(value) => {
                           return numeral(value).format('0.0 a');
                       }}
                />
                <Tooltip content={(e) => e.active &&
                    <StrategyTooltip label={e.label} widgetData={widgetDataSources} functionDataSources={flexibilityResult}/>}
                         cursor={{stroke: 'black', strokeWidth: 2, opacity: '0.7'}}/>

                <Legend/>

                {widgetDataSources.map((source) => {
                    return (<Line
                        type={'stepAfter'}
                        dataKey="value"
                        data={widgetsService.modifyChartData(source.props.data, false)}
                        stroke={source.props.parameters.color}
                        strokeWidth={'2px'}
                        strokeOpacity={0.8}
                        name={source.props.parameters.dataSourceName}
                        key={'lineChart' + source.props.id}
                        background={{fill: '#eee'}}
                        dot={false}
                        activeDot={false}
                        animationDuration={0}
                    />);
                })}
                {flexibilityResult.length > 0 &&
                <Line type={'stepAfter'}
                      dataKey="value"
                      data={widgetsService.modifyChartData(flexibilityResult, false)}
                      stroke={'blue'}
                      strokeWidth={'2px'}
                      strokeOpacity={0.8}
                      name={'Flexibility'}
                      key={'flex'}
                      background={{fill: '#eee'}}
                      dot={false}
                      activeDot={false}
                      animationDuration={0}/>}

                {/*Отображаем время разгрузки СНЭ*/}
                {sne[0].value && sne[1].value > 0 && sne[2].value.length > 0 &&
                <Area type="stepAfter" legendType={'square'} dataKey="value"
                      stroke={'#41d8ca'}
                      fill={'#41d8ca'}
                      name={'Разгрузка СНЭ'} animationDuration={0}
                      data={calculateTimeSeries(datetimeFilter, sne[2].value, sne[1].value)}/>
                }

                {/*Отображаем время восполнения 1 СНЭ*/}
                {sne[0].value && sne[3].value > 0 && sne[4].value.length > 0 &&
                <Area type="stepAfter" legendType={'square'} dataKey="value"
                      stroke={'#53d833'}
                      fill={'#53d833'}
                      name={'Восполнение 1 СНЭ'} animationDuration={0}
                      data={calculateTimeSeries(datetimeFilter, sne[4].value, sne[3].value)}/>
                }

                {/*Отображаем время восполнения 2 СНЭ*/}
                {sne[0].value && sne[3].value > 0 && sne[5].value.length > 0 &&
                    <Area type="stepAfter" legendType={'square'} dataKey="value"
                          stroke={'#43aa2a'}
                          fill={'#43aa2a'}
                          name={'Восполнение 2 СНЭ'} animationDuration={0}
                          data={calculateTimeSeries(datetimeFilter, sne[5].value, sne[3].value)}/>
                }

                {/*Отображаем время снижения ОГО*/}
                {ogo[0].value && ogo[1].value > 0 && ogo[2].value.length > 0 &&
                <Area type="stepAfter" legendType={'square'} dataKey="value"
                      stroke={'#d86e18'}
                      fill={'#d86e18'}
                      name={'Снижение УН'} animationDuration={0}
                      data={calculateTimeSeries(datetimeFilter, ogo[2].value, ogo[1].value)}/>
                }

                {/*Отображаем время восполнения ОГО 1*/}
                {ogo[0].value && ogo[3].value > 0 && ogo[4].value.length > 0 &&
                <Area type="stepAfter" legendType={'square'} dataKey="value"
                      stroke={'#d8d322'}
                      fill={'#d8d322'}
                      name={'Восполнение УН 1'} animationDuration={0}
                      data={calculateTimeSeries(datetimeFilter, ogo[4].value, ogo[3].value)}/>
                }

                {/*Отображаем время восполнения ОГО 2*/}
                {ogo[0].value && ogo[5].value > 0 && ogo[6].value.length > 0 &&
                <Area type="stepAfter" legendType={'square'} dataKey="value"
                      stroke={'#d83f5b'}
                      fill={'#d83f5b'}
                      name={'Восполнение УН 2'} animationDuration={0}
                      data={calculateTimeSeries(datetimeFilter, ogo[6].value, ogo[5].value)}/>
                }

                {/*Отображаем время разгрузки ВИЭ*/}
                {vie[0].value && vie[1].value > 0 && vie[2].value.length > 0 &&
                <Area type="stepAfter" legendType={'square'} dataKey="value"
                      stroke={'#4748d8'}
                      fill={'#4748d8'}
                      name={'Разгрузка ВИЭ'} animationDuration={0}
                      data={calculateTimeSeries(datetimeFilter, vie[2].value, vie[1].value)}/>
                }

            </ComposedChart>
            <div className={'d-flex justify-content-end'} style={{width: size.width + 'px'}}>
                <SetStrategyArgs dateTimeFilter={datetimeFilter} formName={'СНЭ'} funcArgs={sne}
                                 onChange={sneArgs => {
                                     setSne(sneArgs);
                                     setFlexibility([]);
                                 }}
                />
                <SetStrategyArgs onChange={sneArgs => {
                    setOgo(sneArgs);
                    setFlexibility([]);
                }} dateTimeFilter={datetimeFilter} formName={'УН (Управляемая нагрузка)'}
                                 funcArgs={ogo}/>
                <SetStrategyArgs onChange={sneArgs => {
                    setVie(sneArgs);
                    setFlexibility([]);
                }} dateTimeFilter={datetimeFilter} formName={'ВИЭ'}
                                 funcArgs={vie}/>
                <button className={'btn btn-primary'} onClick={() => calculateFlexibility()}>{!isFetching ? 'Рассчитать гибкость' : 'Идет расчет...'}
                </button>
                {flexibilityResult.length > 0 && <button className={'btn btn-outline-success'} onClick={() => calculateFlexibility()}>Сохранить стратегию</button>}
            </div>

        </div>
    );
}

StrategyView.propTypes = {
    widgetProps: PropTypes.object,
    size: PropTypes.object,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

export default withSize()(connect(mapStateToProps, null)(StrategyView));