import React, {useState} from 'react';
import InputText from '../../components/InputText';
import {localeService} from '../../services/locale.service';
import Loader from '../../components/Loader';
import {deviceService} from '../../services';
import PropTypes from 'prop-types';

function SearchDeviceByGUID({onFindDevice}){
    const [guid, setGuid] = useState('');
    const [isFetching, setFetching] = useState(false);
    const [error, setError] = useState('');
    const findDevice = (e) => {
        e.preventDefault();
      setFetching(true);
      setError('');
      deviceService.findDevice(guid).then(
          result => {
              console.log(result);
              onFindDevice(result.result);
              setFetching(false);
          },error => {
              console.log(error);
              setFetching(false);
              if (error.errorMsg || error.errorCode) {
                setError(error.errorMsg || error.errorCode);
              } else {
                  setError(localeService.isRussian() ? 'Устройство не найдено' : 'Cannot find device');
              }
          }
      );
    };

    return <form>
        <p className={'help-text'}>{localeService.isRussian() ? 'Введите GUID устройства' : 'Enter device GUID'}</p>
        <InputText onChange={text => setGuid(text)} label={'GUID'} value={guid} disable={isFetching} warningText={error}/>
        {isFetching && <Loader waitText={localeService.isRussian() ? 'Идет поиск устройства...' : 'Searching device...'}/>}
        {!isFetching && <div className={'d-flex mt-4'}>
            <button className={'btn btn-outline-primary default'} onClick={(e) => findDevice(e)}>{localeService.isRussian() ? 'Поиск устройства' : 'Find Device'}</button>
        </div>}
    </form>;
}

SearchDeviceByGUID.propTypes = {
    onFindDevice: PropTypes.func
};

export default SearchDeviceByGUID;