import React from 'react';
import {ReactSVG} from 'react-svg';
import svgArrowTop from 'sources/images/interface/arrow-top.svg';
import {localeService} from 'services/locale.service';
import svgDuplicate from 'sources/images/duplicate.svg';
import svgHide from 'sources/images/interface/hide.svg';
import svgShow from 'sources/images/interface/show.svg';
import svgTrash from 'sources/images/trash.svg';
import PropTypes from 'prop-types';

/**
 *
 * @param scenarioModel
 * @returns {*}
 * @constructor {JSX.Element}
 */
const LocalAccordion = ({
                            scenarioStatement,
                            onCreateDuplicate,
                            onMoveUp,
                            onMoveDown,
                            onRemove,
                            onDisable,
                            children,
                            hasActions,
                            actionChildrenRender,
                        }) => {
    const [showStatus, setShowStatus] = React.useState(true);
    return (
        <>
            <div className="accordion">
                <div className="accordion__header">
                    <div className="accordion__header__left">
                        <ul className="accordion__action-list">
                            <li>
                                <button
                                    className="btn btn-outline-primary round-btn default edit-variable-btn "
                                    style={{transform: showStatus ? 'rotate(180deg)' : 'none'}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowStatus(!showStatus);
                                    }}
                                >
                                    <ReactSVG src={svgArrowTop}/>
                                </button>
                            </li>
                            <li className="accordion__action-list__item">
                <span className="accordion__name">
                  {scenarioStatement.code}
                </span>
                            </li>
                            {scenarioStatement.disabled && (
                                <li>
                  <span className="accordion__disable__span">
                    {localeService.isRussian() ? 'Не активно' : 'Disabled'}
                  </span>
                                </li>
                            )}
                        </ul>
                    </div>
                    {hasActions ? (
                        <div className="accordion__header__right">
                            <ul className="accordion__action-list">
                                <li>
                                    <button
                                        className="btn btn-outline-primary round-btn default edit-variable-btn "
                                        style={{transform: 'rotate(180deg)'}}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onMoveDown(scenarioStatement);
                                        }}
                                    >
                                        <ReactSVG src={svgArrowTop}/>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="btn btn-outline-primary round-btn default edit-variable-btn "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onMoveUp(scenarioStatement);
                                        }}
                                    >
                                        <ReactSVG src={svgArrowTop}/>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="btn btn-outline-primary round-btn default edit-variable-btn "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onCreateDuplicate(scenarioStatement);
                                        }}
                                    >
                                        <ReactSVG src={svgDuplicate}/>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="btn btn-outline-primary round-btn default edit-variable-btn "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onDisable(scenarioStatement);
                                        }}
                                    >
                                        <ReactSVG
                                            src={scenarioStatement.disabled ? svgHide : svgShow}
                                        />
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="btn btn-outline-primary round-btn default edit-variable-btn "
                                        onClick={() => onRemove(scenarioStatement)}
                                    >
                                        <ReactSVG src={svgTrash}/>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    ) : (
                        actionChildrenRender && actionChildrenRender()
                    )}
                </div>
                {showStatus && (
                    <div
                        className={`accordion__content ${
                            scenarioStatement.disabled ? 'accordion__content--disabled' : ''
                        }`}
                    >
                        {children}
                    </div>
                )}
            </div>
        </>
    );
};

LocalAccordion.propTypes = {
    scenarioStatement: PropTypes.object,
    onCreateDuplicate: PropTypes.func,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    onRemove: PropTypes.func,
    onDisable: PropTypes.func,
    children: PropTypes.element,
    hasActions: PropTypes.bool,
    actionChildrenRender: PropTypes.func,
};
export default LocalAccordion;
