import {apiConstants, dashboardConstants} from '../constants';
import {apiService} from './api.service';
import moment from 'moment';
import {randomColor} from 'randomcolor';
import WidgetChartProperties from '../containers/dashboard/widgets/UnworkedWidget';

function createDashboard(dashboard) {
    return apiService.fetchPost(apiConstants.CREATE_DASHBOARD, JSON.stringify({name: dashboard.name}))
        .then(response => {
            const dashboardID = Number.parseInt(response.id);
            return Promise.all(
                dashboard.widgetList.map((widget) => {
                    return createWidget({...widget, dashboard: {id: dashboardID}});
                })
            ).then(widgetResults => {
                return {dashboardID: dashboardID, widgetResults: widgetResults};
            });


        });
}

async function editDashboard(dashboard) {
    if (dashboard.name !== dashboard.newName) {
        await apiService.fetchPost(apiConstants.EDIT_DASHBOARD, JSON.stringify({
            id: dashboard.id,
            name: dashboard.newName
        })).then(
            res => {
                console.log('DB rename, response', res);
            }
        );
    }
    return Promise.all(
        dashboard.widgetList.map((widget) => {
            if (widget.id.toString().startsWith('new_')) {
                return createWidget({...widget, dashboard: {id: dashboard.id}});
            } else {
                return editWidget({...widget, dashboard: {id: dashboard.id}}).then(
                    () => {
                        return {status: 'SUCCESS'};
                    }, error => {
                        return {status: 'FAIL', error: error};
                    }
                );
            }

        })
    ).then(widgetResults => {
        return {dashboardID: dashboard.id, widgetResults: widgetResults};
    });
}

function createWidget(el) {
    let widget = {
        dashboard: el.dashboard,
        parameters: JSON.stringify({
            ...el.parameters,
            w: el.w,
            h: el.h,
            x: el.x,
            y: el.y
        }),
        name: el.name,
        description: '',
        type: {
            id: el.widgetTypeId
        },
        dataSources: !el.dataSources ? [] : el.dataSources.map(ds => {
            const dataSource = {
                sourceID: ds.id,
                parameters: ds.parameters ? JSON.stringify(ds.parameters) : JSON.stringify({
                    dataSourceName: ds.dataSourceName,
                    color: ds.color,
                    unit: ds.unit,
                    functionCode: ds.functionCode,
                    functionParams: ds.functionParams
                })
            };
            dataSource.sourceType = 'DEVICE_VARIABLE_DATA';
            return dataSource;
        })
    };

    return apiService.fetchPost(apiConstants.CREATE_WIDGET, JSON.stringify(widget))
        .then(response => {
            return response;
        });
}

function editWidget(el) {
    let widget = {
        id: el.id,
        dashboard: el.dashboard,
        parameters: JSON.stringify({
            ...el.parameters,
            w: el.w,
            h: el.h,
            x: el.x,
            y: el.y
        }),
        name: el.name,
        description: '',
        type: {
            id: el.type.id
        },
        dataSources: !el.dataSources ? [] : el.dataSources.map(ds => {
            const dataSource = {
                id: ds.id,
                sourceID: ds.sourceID !== undefined ? ds.sourceID.toString() : ds.id,
                parameters: ds.parameters ? JSON.stringify(ds.parameters) : JSON.stringify({
                    dataSourceName: ds.dataSourceName,
                    color: ds.color,
                    unit: ds.unit,
                    functionCode: ds.functionCode,
                    functionParams: ds.functionParams
                })
            };
            if (ds.idx) {
                delete dataSource.id;
            }
            if(ds.sourceID === undefined){
                delete dataSource.id;
            }
            // if (!ds.idx) {
            //     dataSource.id = ds.id;
            // }
            dataSource.sourceType = 'DEVICE_VARIABLE_DATA';
            return dataSource;
        })
    };

    return apiService.fetchPost(apiConstants.EDIT_WIDGET, JSON.stringify(widget))
        .then(response => {
            return response;
        });
}

function getDashboardList() {
    return apiService.fetchGet(apiConstants.GET_DASHBOARD_LIST).then(
        response => {
            if (response.status === 'SUCCESS') {
                return response.list;
            } else {
                return [];
            }
        }
    );
}

function getDashboard(id) {
    return apiService.fetchGet(apiConstants.GET_DASHBOARD, {id: id}).then(
        response => {
            let dashboard = response.result;
            if (dashboard.parameters) {
                dashboard.parameters = JSON.parse(dashboard.parameters);
            }
            return dashboard;
        }
    );
}

function getDashboardWidgetList(id) {
    return apiService.fetchGet(apiConstants.GET_WIDGET_LIST, {id: id}).then(
        result => {
            let widgetList = [];
            result.list.map((widget) => {
                const wParams = JSON.parse(widget.parameters);

                // инициализация спецтипов виджетов старой версии
                let widgetType = widget.type.type;
                if (wParams.type === 'PIVOT_TABLE') {
                    widgetType = dashboardConstants.WIDGET_PIVOT_TABLE;
                }
                if (wParams.type === dashboardConstants.WIDGET_GROUP_CHART) {
                    widgetType = dashboardConstants.WIDGET_GROUP_CHART;
                }
                if (wParams.type === dashboardConstants.WIDGET_GROUP_FUNCTION) {
                    widgetType = dashboardConstants.WIDGET_GROUP_FUNCTION;
                }
                const w = {
                    ...widget,
                    parameters: wParams,
                    h: wParams.h,
                    w: wParams.w,
                    x: wParams.x,
                    y: wParams.y,
                    widgetType: widgetType,
                    dataSources: widget.dataSources.map(ds => {
                        let dsParams = JSON.parse(ds.parameters);
                        return {...ds, parameters: dsParams};
                    })
                };

                widgetList.push(w);
            });
            return widgetList;
        }, () => {
            return [];
        }
    );
}

function getWidgetDataSourceCount(startDate, finishDate, dataSourceId) {
    const body = {
        dataSourceId: dataSourceId,
        dateFrom: moment(new Date(startDate)).format('YYYY-MM-DD HH:mm:ss'),
        dateTo: moment(new Date(finishDate)).format('YYYY-MM-DD HH:mm:ss'),
        timeGroup: '1s'
    };
    return apiService.fetchPost(apiConstants.GET_WIDGET_DATASOURCE_COUNT, JSON.stringify(body));
}

function widgetDataSet(dataSourceId, dateSet, value) {
    const body = {
        dataSourceId: dataSourceId,
        value: value,
        dateSet: dateSet
    };
    return apiService.fetchPost(apiConstants.WIDGET_SET_DATA, JSON.stringify(body));
}

function getTimeGroup(startDate, finishDate, widgetWidth, count, widgetTimeGroup) {
    if (widgetTimeGroup === undefined || widgetTimeGroup === 'auto') {
        let timeGroup = '1d';
        if (count > 700) {
            if (widgetWidth) {
                const secDifference = moment.duration(finishDate.diff(startDate)).asSeconds();
                const tg = Math.ceil(secDifference / widgetWidth);
                timeGroup = (Math.ceil(tg / 60) * 60) + 's';
            }
            if (!widgetWidth) {
                const difference = moment.duration(finishDate.diff(startDate)).asHours();
                if (difference > 2 && difference <= 100) {
                    timeGroup = '20m';
                }
                if (difference > 2400) {
                    timeGroup = '1d';
                }
                if (difference <= 2) {
                    timeGroup = '5s';
                }
            }
        } else {
            timeGroup = '1s';
        }

        return timeGroup;
    } else {
        return widgetTimeGroup;
    }
}

function getWidgetFunctionData(dataSourceId, startDate, finishDate, functionType, functionParams, widgetWidth, widgetTimeGroup) {
    return getWidgetDataSourceCount(startDate, finishDate, dataSourceId)
        .then(
            countResponse => {
                let timeGroup = '';
                if (widgetTimeGroup === undefined || widgetTimeGroup === 'auto') {
                    timeGroup = getTimeGroup(startDate, finishDate, widgetWidth, countResponse.count);
                } else {
                    timeGroup = widgetTimeGroup;
                }
                let timeOffset = getDataSourceTimeOffset(dataSourceId);
                const body = {
                    dataSourceId: dataSourceId,
                    dateFrom: moment(startDate).subtract(timeOffset, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                    dateTo: moment(finishDate).subtract(timeOffset, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                    timeGroup: timeGroup,
                    type: functionType
                };
                if (functionParams && functionParams.length > 0) {
                    body.params = functionParams;
                }
                return apiService.fetchPost(apiConstants.GET_WIDGET_CHART_FUNCTION_DATA, JSON.stringify(body));
            }
        );
}

function getAggregationFunctionData(dataSourceId, startDate, finishDate, functionType, functionParams) {
    const body = {
        dataSourceId: dataSourceId,
        dateFrom: moment(new Date(startDate)).format('YYYY-MM-DD HH:mm:ss'),
        dateTo: moment(new Date(finishDate)).format('YYYY-MM-DD HH:mm:ss'),
        timeGroup: '1s',
        type: functionType
    };
    if (functionParams && functionParams.length > 0) {
        body.params = functionParams;
    }
    return apiService.fetchPost(apiConstants.GET_WIDGET_CHART_FUNCTION_DATA, JSON.stringify(body));
}

function getDashboardWidgetDataSource(id, startDate, finishDate, widgetWidth, widgetTimeGroup) {
    return getWidgetDataSourceCount(startDate, finishDate, id)
        .then(
            countResponse => {
                const timeGroup = getTimeGroup(startDate, finishDate, widgetWidth, countResponse.count, widgetTimeGroup);
                const start = moment(new Date(startDate)).format('YYYY-MM-DD HH:mm:ss');
                const finish = moment(new Date(finishDate)).format('YYYY-MM-DD HH:mm:ss');
                const filter = {
                    dataSourceId: id,
                    dateFrom: start,
                    dateTo: finish,
                    timeGroup: timeGroup
                };
                return apiService.fetchPost(apiConstants.GET_WIDGET_DATASOURCE_LIST, JSON.stringify(filter));
            }
        )
        .then((results) => {
            return results;
        });
}

function getSourceDatas(id, startDate, finishDate,timeGroup) {
    const start = moment(new Date(startDate)).format('YYYY-MM-DD HH:mm:ss');
    const finish = moment(new Date(finishDate)).format('YYYY-MM-DD HH:mm:ss');
    const filter = {
        dataSourceId: id,
        dateFrom: start,
        dateTo: finish,
        timeGroup: timeGroup
    };
    return apiService.fetchPost(apiConstants.GET_WIDGET_DATASOURCE_LIST, JSON.stringify(filter));
}

function getDashboardWidgetDataSourceCapacityProfile(id, startDate, finishDate) {
    const start = moment(new Date(startDate)).format('YYYY-MM-DD HH:mm:ss');
    const finish = moment(new Date(finishDate)).format('YYYY-MM-DD HH:mm:ss');
    const filter = {
        dataSourceId: id,
        dateFrom: start,
        dateTo: finish,
        timeGroup: '1h'
    };
    const url = apiService.addProps(apiConstants.GET_WIDGET_DATASOURCE_LIST);
    return apiService.fetchPost(url, JSON.stringify(filter));
}

function getDashboardWidgetDataSourceTimeGroup1s(id, startDate, finishDate) {
    const start = moment(new Date(startDate)).format('YYYY-MM-DD HH:mm:ss');
    const finish = moment(new Date(finishDate)).format('YYYY-MM-DD HH:mm:ss');
    const filter = {
        dataSourceId: id,
        dateFrom: start,
        dateTo: finish,
        timeGroup: '1s'
    };
    const url = apiService.addProps(apiConstants.GET_WIDGET_DATASOURCE_LIST);
    return apiService.fetchPost(url, JSON.stringify(filter));
}

function getDataSourceDataWithTimeGrouped(id, startDate, finishDate, timeGroup) {
    const start = moment(new Date(startDate)).format('YYYY-MM-DD HH:mm:ss');
    const finish = moment(new Date(finishDate)).format('YYYY-MM-DD HH:mm:ss');
    const filter = {
        dataSourceId: id,
        dateFrom: start,
        dateTo: finish,
        timeGroup: timeGroup
    };
    return apiService.fetchPost(apiConstants.GET_WIDGET_DATASOURCE_LIST, JSON.stringify(filter));
}

function getWidgetTypeList() {
    let widgetList = [];
    return apiService.fetchGet(apiConstants.GET_WIDGET_TYPE_LIST).then(
        result => {
            widgetList = result.list;
            // widgetList = widgetList.filter(el => (el.type !== 'WIDGET_ELECTRICITY_STORAGE' && el.type !== 'WIDGET_ELECTRICITY_COST' && el.type !== 'WIDGET_CAPACITY_PROFILE'));
            const informationWidgets = [];
            let infoIdx = 0;
            informationWidgets.push([]);

            const controlWidgets = [];
            controlWidgets.push([]);
            let controlIdx = 0;

            // widgetList.push({name:'Demand Management Strategy',
            //     description:'Demand Management Strategy',
            //     additional: JSON.stringify({type: 'information_panel', minWidth: 4, minHeight: 3}),
            //     img:{'id':48,'fileName':'step_chart.png','relativePath':'00/00/1c.png','module':'COMMON','fileType':'COMMON'},
            //     type:'WIDGET_DEMAND_MANAGEMENT_STRATEGY'});

            widgetList.map(w => {
                const widget = w;
                if (w.additional) {
                    widget.additional = JSON.parse(w.additional);
                } else {
                    widget.additional = {
                        type: 'information_panel'
                    };
                }

                if (widget.additional.type === 'information_panel') {
                    if (informationWidgets[infoIdx].length === 16) {
                        infoIdx++;
                        informationWidgets.push([]);
                    }
                    informationWidgets[infoIdx].push(widget);
                }
                if (widget.additional.type === 'control_panel') {
                    if (controlWidgets[controlIdx].length === 16) {
                        controlIdx++;
                        controlWidgets.push([]);
                    }
                    controlWidgets[controlIdx].push(widget);
                }
            });
            return {
                informationWidgets: informationWidgets,
                controlWidgets: controlWidgets
            };
        },
        () => {
            return {
                informationWidgets: null,
                controlWidgets: null
            };
        }
    );


}

export function deleteWidget(widgetId) {
    return apiService.fetchGet(apiConstants.DELETE_WIDGET, {id: widgetId});
}

export function deleteDashboard(dashboardId) {
    return apiService.fetchGet(apiConstants.DELETE_DASHBOARD, {id: dashboardId});
}

function getWidgetByType(type) {
    switch (type) {
        case dashboardConstants.WIDGET_LINE_CHART: {
            return WidgetChartProperties();
        }
        default:
            return WidgetChartProperties();
    }
}


function loadFunctionWidgetData(dataSourceId, widgetParams, datetimeFilter) {
    return dashboardService.getWidgetFunctionData(dataSourceId, datetimeFilter.startDate, datetimeFilter.finishDate, widgetParams.functionCode, widgetParams.functionParams, 0)
        .then(
            result => {
                const functionResult = result.list[0] ? result.list[0].value : undefined;
                return functionResult;
            }, error => {
                return Promise.reject(error);
            }
        );

}

function loadFunctionDataSources(functionDataSources, datetimeFilter, width, timeGroup) {
    return Promise.all(
        functionDataSources.map((functionDS) => {
            if (functionDS.sourceType === 'TRANSFORMATION_FUNCTION' && functionDS.functionCode !== 'HOLT_WINTERS') {
                let timeOffset = getDataSourceTimeOffset(functionDS.parameters.dataSource.device);
                let start = moment(datetimeFilter.startDate).subtract(timeOffset, 'minutes');
                let finish = moment(datetimeFilter.finishDate).subtract(timeOffset, 'minutes');
                return getWidgetFunctionData(functionDS.parameters.dataSource.id, start, finish,
                    functionDS.functionCode, functionDS.functionParams, width, timeGroup)
                    .then(
                        result => {
                            const dataSource = functionDS;
                            dataSource.data = result.list;
                            return dataSource;
                        },
                        () => {
                            const dataSource = functionDS;
                            dataSource.data = [];
                            return dataSource;
                        }
                    );
            } else {
                return functionDS;
            }
        })
    );
}

function loadGroupedFunctionDataSources(functionDataSources, dataSources, datetimeFilter, width, timeGroup) {
    return Promise.all(
        functionDataSources.map((functionDS) => {
            if (functionDS.sourceType === 'TRANSFORMATION_FUNCTION') {
                return Promise.all(dataSources.map(ds => {
                    return getWidgetFunctionData(ds.id, datetimeFilter.startDate, datetimeFilter.finishDate,
                        functionDS.functionCode, functionDS.functionParams, width, timeGroup)
                        .then(
                            result => {
                                return result.list;
                            },
                            () => {
                                return [];
                            }
                        );
                })).then(
                    functionResults => {
                        return {...functionDS, data: groupData(functionResults)};
                    });
            } else {
                return functionDS;
            }
        })
    );
}

function getDataSourceTimeOffset(dsDevice) {
    if (dsDevice && dsDevice.timeZone) {
        let timeString = dsDevice.timeZone.replace(/[^:\d]/g, '');
        let time = timeString.split(':');
        let hr = parseInt(time[0]);
        let min = parseInt(time[1]);
        let timeOffset = hr * 60 + min;
        if (dsDevice.timeZone.startsWith('UTC+')) {
            return timeOffset;
        } else {
            return timeOffset * (-1);
        }
    } else {
        return moment().utcOffset();
    }
}

function loadWidgetDataTimeGrouped(dataSources, datetimeFilter, width, timeGroup) {
    return Promise.all(
        dataSources.map((ds) => {
            if (ds) {
                let timeOffset = getDataSourceTimeOffset(ds.device);
                return getDashboardWidgetDataSource(ds.id, moment(datetimeFilter.startDate).subtract(timeOffset, 'minutes'), moment(datetimeFilter.finishDate).subtract(timeOffset, 'minutes'), width, timeGroup)
                    .then(
                        result => {
                            const dataSource = ds;
                            dataSource.data = result.list;
                            return dataSource;
                        },
                        () => {
                            const dataSource = ds;
                            dataSource.data = [];
                            return dataSource;
                        }
                    );
            }
        })
    );
}

function loadWidgetData(dataSources, datetimeFilter) {
    let widgetData = Array.from(Array(dataSources.length), () => null);
    return new Promise(resolve => {
            dataSources.map((ds, dsIdx) => {
                if (ds) {
                    let timeOffset = getDataSourceTimeOffset(ds.device);
                    getSourceDatas(ds.id, moment(datetimeFilter.startDate).subtract(timeOffset, 'minutes'), moment(datetimeFilter.finishDate).subtract(timeOffset, 'minutes'), datetimeFilter.timeGroup)
                        .then(
                            result => {
                                let dataSource = {...ds};
                                dataSource.data = result.list;
                                widgetData[dsIdx] = dataSource;
                                if(widgetData.every(el => el !== null)){
                                    resolve(widgetData);
                                }
                            },
                            () => {
                                let dataSource = {...ds};
                                dataSource.data = [];
                                widgetData[dsIdx] = dataSource;
                                if(widgetData.every(el => el !== null)){
                                    resolve(widgetData);
                                }
                            }
                        );
                }
            });
    });

    // return Promise.all(
    //     dataSources.map((ds) => {
    //         if (ds) {
    //             let timeOffset = getDataSourceTimeOffset(ds.device);
    //             return getSourceDatas(ds.id, moment(datetimeFilter.startDate).subtract(timeOffset, 'minutes'), moment(datetimeFilter.finishDate).subtract(timeOffset, 'minutes'), datetimeFilter.timeGroup)
    //                 .then(
    //                     result => {
    //                         const dataSource = ds;
    //                         dataSource.data = result.list;
    //                         return dataSource;
    //                     },
    //                     () => {
    //                         const dataSource = ds;
    //                         dataSource.data = [];
    //                         return dataSource;
    //                     }
    //                 );
    //         }
    //     })
    // );
}

function loadWidgetDataGroupedBy(dataSources, datetimeFilter, timeGroup) {
    return Promise.all(
        dataSources.map((ds) => {
            if (ds) {
                let timeOffset = getDataSourceTimeOffset(ds.device);
                return getSourceDatas(ds.id, moment(datetimeFilter.startDate).subtract(timeOffset, 'minutes'), moment(datetimeFilter.finishDate).subtract(timeOffset, 'minutes'), timeGroup)
                    .then(
                        result => {
                            const dataSource = ds;
                            dataSource.data = result.list;
                            return dataSource;
                        },
                        () => {
                            const dataSource = ds;
                            dataSource.data = [];
                            return dataSource;
                        }
                    );
            }
        })
    );
}

function getRandomColor() {
    // const colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b'];
    // return colors[number ? number : Math.floor(Math.random() * Math.floor(colors.length - 1))];

    let color = randomColor({
        luminosity: 'bright',
        format: 'hex' // e.g. 'rgb(225,200,20)'
    });
    return color;
}

function groupData(resultsArray) {
    const groupedArray = [];
    resultsArray.map((resultArray) => {
        resultArray.map((data) => {
            const idx = groupedArray.findIndex(el => el.time_upload === data.time_upload);
            if (idx < 0) {
                groupedArray.push(data);
            } else {
                groupedArray[idx] = {...groupedArray[idx], value: groupedArray[idx].value + data.value};
            }
        });
    });
    return groupedArray;
}

function updateDashboard(dashboard) {
    return apiService.fetchPost(apiConstants.EDIT_DASHBOARD, JSON.stringify(dashboard));
}

// eslint-disable-next-line no-unused-vars
function saveDashboardPresets(dashboardId, datetimeFilter, widgetList) {
    return Promise.all([
        widgetList.map(widget => {
            editWidget({...widget, dashboard: {id: dashboardId}}).then(
                () => {
                    return {status: 'SUCCESS', widgetId: widget.id};
                }, error => {
                    return {status: 'FAIL', error: error, widgetId: widget.id};
                }
            );
            // saveWidgetPreset(widget);
        }),
        dashboardService.getDashboard(dashboardId).then(
            dashboard => {
                return updateDashboard({
                    id: dashboard.id, name: dashboard.name,
                    parameters: JSON.stringify({...dashboard.parameters, presets: {datetimeFilter: datetimeFilter}})
                });
            }
        )]
    ).then(
        results => {
            return results;

        }
    );

}

function getTagList() {
    return apiService.fetchGet(apiConstants.GET_TAG_LIST, null);
}

function addTagToDashboard(dashboardId, tag) {
    const body = {
        dashboardId: dashboardId,
        tegCode: tag
    };
    return apiService.fetchPost(apiConstants.ADD_TAG, JSON.stringify(body), null);
}

function deleteTagFromDashboard(dashboardId, tag) {
    const body = {
        dashboardId: dashboardId,
        tegCode: tag
    };
    return apiService.fetchPost(apiConstants.DELETE_TAG, JSON.stringify(body), null);
}

function getDashboardListByTag(tag) {
    return apiService.fetchGet(apiConstants.GET_DASHBOARD_LIST_BY_TAG, {tegCode: tag});
}

// function saveWidgetPreset(widget) {
//     if(widget.parameters.presets){
//         localStorage.setItem('widget_presets_' + widget.id, JSON.stringify(widget.parameters.presets));
//     }else{
//         localStorage.removeItem('widget_presets_' + widget.id);
//     }
// }

function calculateTimeGroup(datetimeFilter) {
    let timeGroup = '1d';
    const difference = moment.duration(datetimeFilter.finishDate.diff(datetimeFilter.startDate)).asHours();
    if (difference <= 1) {
        timeGroup = '15s';
    } else if (difference > 1 && difference <= 3) {
        timeGroup = '60s';
    } else if (difference > 3 && difference <= 6) {
        timeGroup = '120s';
    } else if (difference > 6 && difference <= 12) {
        timeGroup = '180s';
    } else if (difference > 12 && difference <= 24) {
        timeGroup = '240s';
    } else if (difference > 24 && difference <= 168) {
        timeGroup = '30m';
    } else if (difference > 168 && difference <= 960) {
        timeGroup = '1h';
    } else if (difference > 960 && difference <= 2184) {
        timeGroup = '6h';
    } else {
        timeGroup = '1d';
    }
    return timeGroup;
}

export const dashboardService = {
    saveDashboardPresets,
    loadGroupedFunctionDataSources,
    groupData,
    loadFunctionWidgetData,
    loadFunctionDataSources,
    getRandomColor,
    loadWidgetDataTimeGrouped,
    getWidgetByType,
    createDashboard,
    editDashboard,
    createWidget,
    getDashboardList,
    getDashboard,
    getDashboardWidgetList,
    getDashboardWidgetDataSource,
    getDashboardWidgetDataSourceCapacityProfile,
    getWidgetTypeList,
    deleteDashboard,
    deleteWidget,
    getWidgetFunctionData,
    getTimeGroup,
    widgetDataSet,
    getDashboardWidgetDataSourceTimeGroup1s,
    getAggregationFunctionData,
    getWidgetDataSourceCount,
    getDataSourceDataWithTimeGrouped,
    loadWidgetDataGroupedBy,
    getTagList,
    addTagToDashboard,
    deleteTagFromDashboard,
    getDashboardListByTag,
    calculateTimeGroup,
    loadWidgetData,
    getDataSourceTimeOffset
};

