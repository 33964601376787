import React, {useEffect, useState} from 'react';
import {localeService} from '../../services/locale.service';
import '../../styles/dashboard.scss';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {clearDashboard, updateDashboardList, updateWidgetsPosition} from '../../redux/actions/dashboardActions';
import Modal from '../../components/Modal';
import InputText from '../../components/InputText';
import {useTranslation} from 'react-i18next';
import {dashboardService} from '../../services';
import Loader from '../../components/Loader';
import {Link} from 'react-router-dom';
import AlertError from '../../components/AlertError';
import {uploadFunctionList} from '../../redux/actions/analyticsActions';
import {analyticService} from '../../services/analyticService';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import SelectDBDataSources from './components/SelectDBDataSources';
import CreateWidgetsGrid from './components/CreateWidgetsGrid';

function CreateDashboard({widgetList, clearDashboard, uploadFunctionList, updateDashboardList}) {
    const {t} = useTranslation();
    const [dashboardName, setDashboardName] = useState('');

    const [createDashboardForm, setCreateDashboardForm] = useState({
        isOpen: false,
        isFetching: false,
        newDashboardID: null,
        error: ''
    });

    useEffect(() => {
        clearDashboard();
        analyticService.getFunctionList().then(
            functionList => {
                uploadFunctionList(functionList);
            }
        );
    }, []);

    // инициализация функции для создания дашборда
    const saveDashboard = (e) => {
        e.preventDefault();
        setCreateDashboardForm({...createDashboardForm, isFetching: true});//обновление состояния для мониторинга статуса системы
        //вызов метода API для создания дашборда
        dashboardService.createDashboard({
            name: dashboardName, //название дашборда
            widgetList: widgetList //список виджетов дашборда
        }).then(resp => { //ответ на запрос
            if (resp.dashboardID) {//если ответ содержит ID созданного дашборда
                dashboardService.getDashboardList()//получить список дашбордов системы
                    .then((dbList) => {
                        updateDashboardList(dbList);//обновить список дашбордов
                    });
                setCreateDashboardForm({//обновление состояния параметров
                    ...createDashboardForm,
                    newDashboardID: resp.dashboardID,//новый ID дашборда
                    isFetching: false //состояние запроса
                });
            } else {
                setCreateDashboardForm({//обновление состояния параметров
                    ...createDashboardForm,
                    isFetching: false,//состояние запроса
                    error: localeService.isRussian() ? 'Не удалось сохранить дашборд!' : 'Cannot save dashboard'//ошибка при выполнении запроса
                });
            }
        }, error => { //обновление состояния ошибки при создании дашборда
            setCreateDashboardForm({...createDashboardForm, isFetching: false, error: JSON.stringify(error)});
        });
    };


    const closeSavingForm = () => {
        setCreateDashboardForm({
            isOpen: false,
            isFetching: false,
            newDashboardID: null,
            error: ''
        });
    };

    return (
        <div className={'container-fluid'}>
            <div className={'d-flex justify-content-between flex-nowrap m-4'}>
                <h3>{localeService.isRussian() ? 'Создание дашборда' : 'Create Dashboard'}</h3>
                {widgetList.length > 0 &&
                <button className={'btn btn-outline-success'} //вывод кнопки для создания дашборда
                        onClick={() => setCreateDashboardForm(//обновить состояние параметров компонента
                            {...createDashboardForm,
                                isOpen: true//открыть окно для отображения формы для создания дашборда
                            }
                            )}>{localeService.isRussian() ? 'Создать дашборд' : 'Create Dashboard'}</button>
                }
            </div>

            {/*Компонент вкладок*/}
            <Tabs className="ems-tabs">
                {/*кнопки управления - заголовки вкладок*/}
                <TabList className="ems-tab-list">
                    {/*переключение на вкладку с выбором источников данных для дашборда*/}
                    <Tab className="ms-4 btn-tab-default">{t('data_sources')}</Tab>
                    {/*переключение на вкладку для выбора виджетов для дашборда*/}
                    <Tab className="btn-tab-default">{localeService.isRussian() ? 'Виджеты' : 'Widgets'}</Tab>
                </TabList>
                {/*панель отображения контента выбранной вкладки*/}
                <TabPanel style={{display: 'flex', justifyContent: 'center'}}>
                    {/*вывод компонента для выбора источников данных*/}
                    <SelectDBDataSources/>
                </TabPanel>
                {/*панель отображения контента выбранной вкладки*/}
                <TabPanel>
                    {/*вывод компонента для выбора виджетов*/}
                    <CreateWidgetsGrid/>
                </TabPanel>
            </Tabs>

            {/*диалоговое окно для создания дашборда*/}
            <Modal isOpen={createDashboardForm.isOpen} //флаг открытого окна
                   isClosable={false}//флаг автозакрытия
                   footer={false}//не использовать стандартный "подвал"
                   title={localeService.isRussian() ? 'Создать дашборд' : 'Create Dashboard'}//заголовок окна
            >
                <form>
                    {/*элемент для ввода названия дашборда*/}
                    <InputText label={localeService.isRussian() ? 'Название дашборда' : 'Dashboard Name'}
                               value={dashboardName} //отображение названия дашборда
                               onChange={(text) => setDashboardName(text)} //обработчик изменения названия дашборда
                    />
                    {!createDashboardForm.isFetching && //если не выполняется запрос на создание дашборда
                    !createDashboardForm.newDashboardID && //если ID ового дашборда еще не получено
                    !createDashboardForm.error && //если нет ошибок
                        // то отображать кнопку создания дашборда
                    <div className={'d-flex justify-content-end'}>
                        {/*вывод кнопки для отмены*/}
                        <button className={'btn btn-outline-danger'}
                                onClick={() => closeSavingForm()}>{t('cancel')}</button>
                        {/*если имя дашборда не пустое, то выводить кнопку для создания дашборда*/}
                        {dashboardName !== '' && <button className={'btn btn-outline-success'}
                                                         onClick={(e) => saveDashboard(e)} //обработчик нажатия - вызов метода для создания
                        >{t('save')}</button>}
                    </div>}
                    {createDashboardForm.error && //если есть ошибки, то выводить компонент для просмотра ошибки
                    <AlertError isClosable={false} message={createDashboardForm.error}/>}
                    {createDashboardForm.isFetching && //если выполняется запрос на создание дашборда, то выводить компонент для ожидания
                    <Loader waitText={localeService.isRussian() ? 'Сохранение дашборда...' : 'Saving dashboard...'}/>}
                    {!createDashboardForm.isFetching && //если не выполняется запрос на создание дашборда
                    createDashboardForm.newDashboardID && //если получен ID нового дашборда
                    //то выводить блок с успешным созданием дашборда
                    <div className={'row'}>
                        <div className={'d-flex justify-content-end'}>
                            {localeService.isRussian() ? 'Дашборд успешно создан!' : 'Dshboard is successfully created!'}
                        </div>
                        <div className={'d-flex justify-content-end'}>
                            {/*отображать ссылку для перехода на страницу просмотра нового дашборда*/}
                            <Link to={'/dashboard/' + createDashboardForm.newDashboardID}>
                                <button type="button" className={'btn btn-outline-success'}>Ok</button>
                            </Link>
                        </div>
                    </div>
                    }
                </form>
            </Modal>
        </div>
    );
}

CreateDashboard.propTypes = {
    widgetList: PropTypes.array,
    clearDashboard: PropTypes.func,
    updateWidgetsPosition: PropTypes.func,
    uploadFunctionList: PropTypes.func,
    updateDashboardList: PropTypes.func
};

const mapStateToProps = state => {
    const widgetList = state.dashboardReducer.widgetList;
    return {widgetList};
};
const mapDispatchToProps = {
    clearDashboard: clearDashboard,
    uploadFunctionList: uploadFunctionList,
    updateWidgetsPosition: updateWidgetsPosition,
    updateDashboardList: updateDashboardList
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateDashboard);
