import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import {useTranslation} from 'react-i18next';
import EditWidgetDataSource from '../EditWidgetDataSource';
import AddFunctionToDatasource from '../AddFunctionToDatasource';
import InputText from '../../../components/InputText';
import ColorPicker from '../../ColorPicker';
import {dashboardService} from '../../../services';

function GroupFunctionWidgetCreate({widgetProps, updateWidget, dashboardDataSources}) {
    const [widget, setWidget] = useState({...widgetProps,
        parameters: {...widgetProps.parameters,
            groupDataSourceName: widgetProps.parameters && widgetProps.parameters.groupDataSourceName || 'Group Data',
            groupColor: (widgetProps.parameters && widgetProps.parameters.groupColor) || dashboardService.getRandomColor()
        }});
    const [groupFunction ,setGroupFunction] = useState(widgetProps.parameters && widgetProps.parameters.groupFunction || null);
    const {t} = useTranslation();

    const createDataSource = () => {
        const dataSources = [...widget.dataSources, {
            idx: new Date().getTime(), parameters: {dataSourceName: '', type: 'MAIN_DATASOURCE'},
            additionalDataSources: [
                {idx: 'startHourDS', parameters: {dataSourceName: '', type: 'START_HOUR_DATASOURCE'}},
                {idx: 'durationDS', parameters: {dataSourceName: '', type: 'DURATION_DATASOURCE'}},
                {idx: 'valueDS', parameters: {dataSourceName: '', type: 'VALUE_DATASOURCE'}}
            ]
        }];
        setWidget({...widget, dataSources: dataSources});
    };

    return (
        <>
            <form>
                <InputText label={t('name')} value={widget.name}
                           onChange={(text) => setWidget({...widget, name: text})}/>
                <div className={'row'} style={{padding: 0, display: 'flex', position: 'relative', width: '100%'}}>

                    <InputText label={localeService.isRussian() ? 'Название группы источников данных' : 'Group Data Source Name'}
                               value={widget.parameters.groupDataSourceName || ''}
                               onChange={text => setWidget({
                                   ...widget,
                                   parameters: {...widget.parameters, groupDataSourceName: text}
                               })}/>
                    <ColorPicker style={{margin: '0 1rem'}} color={widget.parameters.groupColor} setColor={(color) =>
                        setWidget({...widget, parameters: {...widget.parameters, groupColor: color}})}/>
                </div>
                <h4>1. {localeService.isRussian() ? 'Выберите функцию' : 'Select a Function'}</h4>
                <AddFunctionToDatasource appliedFunction={widget.parameters && widget.parameters.groupFunction ? {...widget.parameters.groupFunction, name: widget.parameters.groupFunction.functionName} : undefined}
                                         onApplyFunction={(appliedFunction) => {
                                             setGroupFunction(appliedFunction);
                                             if(!groupFunction.functionCode.startsWith('DEMAND') && appliedFunction.functionCode.startsWith('DEMAND')){
                                                 setWidget({...widget, dataSources: []});
                                             }
                                           }}/>
                <h4>2. {localeService.isRussian() ? 'Добавьте источники данных' : 'Add Data Sources'}</h4>
                {widget.dataSources.map((ds, key) => {
                    if (ds.parameters.type === 'MAIN_DATASOURCE') {
                        return <EditWidgetDataSource key={key}
                                                     withAdditionalDataSources={groupFunction && groupFunction.functionCode.startsWith('DEMAND')}
                                                     dataSourceNumber={key + 1}
                                                     widgetDataSource={ds}
                                                     onChangeDataSource={(ds) => {
                                                         widget.dataSources[key] = ds;
                                                         setWidget(widget);
                                                     }}
                                                     onDeleteDataSource={() => {
                                                         let dsList = widget.dataSources;
                                                         dsList.splice(key, 1);
                                                         if(key >= 0){
                                                             setWidget({...widget, dataSources: dsList});
                                                         }
                                                     }}
                                                     withColor={true} withUnit={false}
                        />;
                    }
                })
                }

                <div className={'d-flex justify-content-center'}>
                    {dashboardDataSources.length > 0 &&
                    <button type={'button'} className={'btn btn-outline-primary default'}
                            onClick={() => createDataSource()}>
                        {localeService.isRussian() ? 'Добавить источник данных' : 'Add Data Source'}
                    </button>
                    }
                    <button type={'button'} className={'btn btn-outline-success'}
                            onClick={() => {
                                let allDataSources = [];
                                widget.dataSources.map((ds) => {
                                    if(groupFunction.functionCode.startsWith('DEMAND')){
                                        if (ds.parameters.type === 'MAIN_DATASOURCE') {
                                            allDataSources = [...allDataSources, ds, ...ds.additionalDataSources];
                                        }
                                    }else{
                                        let mainDataSource = ds;
                                        delete ds.additionalDataSources;
                                        allDataSources = [...allDataSources, mainDataSource];
                                    }
                                });
                                updateWidget({
                                    ...widget,
                                    dataSources: allDataSources,
                                    mode: dashboardConstants.EDITION_MODE,
                                    parameters: {...widget.parameters, groupFunction: groupFunction}
                                });
                            }}>
                        {localeService.isRussian() ? 'Сохранить' : 'Save'}
                    </button>
                    <button type={'button'} className={'btn btn-outline-danger'}
                            onClick={() => {
                                updateWidget({...widget,
                                    mode: dashboardConstants.EDITION_MODE,
                                    parameters: {...widget.parameters, groupFunction : groupFunction}});
                            }}>
                        {t('cancel')}
                    </button>
                </div>
            </form>
        </>
    );
}

GroupFunctionWidgetCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupFunctionWidgetCreate);