
function setLanguage(language) {
    localStorage.setItem('ems-language', language);
    window.goBack();
}

function isRussian() {
    if(localStorage.getItem('ems-language') === 'ru') {
        return true;
    } else {
        return false;
    }
}

export const localeService = {
    setLanguage,
    isRussian
};

