import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import Modal from '../../../components/Modal';

function UnworkedWidget({widgetProps,  mode, onCloseEditSingleMode}) {
    return <div>
        <h5>{localeService.isRussian() ? 'Виджет на реконструкции': 'Widget on reconstruction'}</h5>
        <pre>{widgetProps.widgetType}</pre>
        {mode === dashboardConstants.EDIT_SINGLE_MODE &&
        <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} onCancel={() => onCloseEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
            <h5>{localeService.isRussian() ? 'Виджет на реконструкции': 'Widget on reconstruction'}</h5>
            <pre>{widgetProps.widgetType}</pre>
        </Modal>
        }
    </div>;
}

UnworkedWidget.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default UnworkedWidget;
