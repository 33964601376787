import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ClickOutside from '../../../../components/ClickOutside';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../components/Modal';
import {localeService} from '../../../../services/locale.service';
import {dashboardService} from '../../../../services/index';
import InputText from '../../../../components/InputText';
import ColorPicker from '../../../ColorPicker';
import Select from '../../../../components/Select';
import AlertError from '../../../../components/AlertError';
import Loader from '../../../../components/Loader';
import AddAnalyticParametersToChart from '../../../../components/AddAnalyticParametersToChart';
import {analyticService} from '../../../../services/analyticService';
import {DRFunctionList} from '../../../../constants/analytics.constants';

//компонент для добавления графика расчета аналитической функции
function AddDemandFunctionToChart({onApplyFunction, analyticFunctionList, dataSources, datetimeFilter}) {
    //инициализация библиотеки для перевода
    const {t} = useTranslation();
    //итнициализация флага раскрытого списка функций
    const [isDropDownOpened, setDropDownOpened] = useState(false);
    //инициализация выбранной функции
    const [selectedFunction, setSelectedFunction] = useState(null);
    //инициализация флага открытого конфигуратора функции
    const [isOpenConfigurator, setOpenConfigurator] = useState(false);
    //инициализация переменной ошибки
    const [error, setError] = useState(null);
    //инициализация состояния загрузки
    const [isFetching, setFetching] = useState(false);
    //функция-обработчик события выбора функции
    const onSelectFunction = (func) => {
        //инициализация состояния выбранной функции
        setSelectedFunction({
            ...func,
            idx: new Date().getTime(),
            functionParams: Array.from(Array(func.countParam), () => 0),
            parameters: {
                dataSourceName: 'F(x): ' + func.name,
                color: dashboardService.getRandomColor(),
                dataSource: dataSources.length === 1 ? {
                    id: dataSources[0].id,
                    name: dataSources[0].parameters.dataSourceName
                } : null
            },
            args: analyticService.initAnalyticFunctionParameters(func, datetimeFilter)
        });
        //закрытие выпадающего списка
        setDropDownOpened(false);
        //открытие конфигуратора функции
        setOpenConfigurator(true);
    };
    //функция-обработчик события применения параметров конфигурации функции
    const onSubmitFunction = () => {
        //обновление состояния загрузки
        setFetching(true);
        const dataSourceId = selectedFunction.parameters.dataSource.id;
        //запрос resultId для расчета функции
        analyticService.getAnalyticWithResultId(dataSourceId, selectedFunction, datetimeFilter).then(
            response => {
                    //определение успешности выполнения расчета
                    if (!response || !response.data) {
                        setError(localeService.isRussian() ? 'Не удалось применить функцию. Попробуйте использовать другие параметры.' : 'Cannot apply function. Try to change function parameters.');
                    }else if(response.data.length === 0){
                        setError(localeService.isRussian() ? 'Запрос не вернул результатов. Возможно заданы некорректные параметры.' : 'The data is not enough.');
                    } else {
                        //преобразование результатов
                        const functionDataSource = {
                            ...selectedFunction,
                            resultId: response.resultId,
                            data: response.data
                        };
                        //обновление состояний пременных формы
                        onApplyFunction(functionDataSource);
                        setOpenConfigurator(false);
                        setDropDownOpened(false);
                        setSelectedFunction(null);
                        onApplyFunction(functionDataSource);
                    }
            }
        ).finally(
            () => {
                setFetching(false);
            }
        );
    };
    //возврат сгенерированного HTML кода
    return (<div className={'dropdown-container function-list' + (isDropDownOpened ? ' open' : '')}>
        {/*кнопка открытия выпадающего списка для выбора функций    */}
        <button className={'btn btn-outline-primary default round-btn'}
                    title={'Demand Response Functions'}
                    onClick={() => {
                setDropDownOpened(!isDropDownOpened);
                setSelectedFunction(false);
            }}>
                <span style={{fontStyle: 'italic', fontSize: '0.8rem', fontWeight: 'bold'}}>DR</span>
            </button>
            <ClickOutside onClick={() => setDropDownOpened(false)}>
                <div className={'dropdown' + (isDropDownOpened ? ' open' : '')}>
                    <ul>
                        {analyticFunctionList.map((func, i) => {
                            // выводим все функции кроме агрегаторов и функций прогнозирования
                            if (DRFunctionList.includes(func.code)) {
                                return <li key={'anal-' + i} onClick={() => onSelectFunction({
                                    ...func,
                                    functionCode: func.code,
                                    sourceType: 'ANALYTIC_FUNCTION'
                                })}>
                                    <span>{t('functionName.' + func.name)}</span>
                                </li>;
                            }
                        })}
                    </ul>
                </div>
            </ClickOutside>
            {/*диалоговое окно конфигуратора выбранной функции*/}
            <Modal onCancel={() => {setOpenConfigurator(false); setSelectedFunction(null); }}
                   footer={false} isClosable={false} width={800} isOpen={isOpenConfigurator}
                   title={localeService.isRussian() ? 'Параметры применения функции' : 'Function Configuration'}>
                {selectedFunction && !error && !isFetching &&
                <form>
                    {/*выбор источника данных по которому необходимо произвести расчет*/}
                    <Select label={localeService.isRussian() ? 'Источник данных' : 'Data Source'}
                            valueList={dataSources.map(ds => {
                                return {id: ds.id, name: ds.parameters.dataSourceName};
                            })}
                            onSelect={(ds) => setSelectedFunction({
                                ...selectedFunction,
                                parameters: {...selectedFunction.parameters, dataSource: ds}
                            })}
                            value={dataSources.length === 1 ? {
                                id: dataSources[0].id,
                                name: dataSources[0].parameters.dataSourceName
                            } : null}/>
                    {/*настройка параметров отображения графика расчета - название и цвет*/}
                    <div className={'d-flex justify-content-between'} style={{padding: 0, display: 'flex', width: '100%', position: 'relative'}}>
                        <InputText label={t('name')}
                                   value={selectedFunction.parameters.dataSourceName}
                                   onChange={text => setSelectedFunction({
                                       ...selectedFunction,
                                       parameters: {...selectedFunction.parameters, dataSourceName: text}
                                   })}/>
                        <ColorPicker style={{marginLeft: '1rem'}} color={selectedFunction.parameters.color}
                                     setColor={(color) =>
                                         setSelectedFunction({
                                             ...selectedFunction,
                                             parameters: {...selectedFunction.parameters, color: color}
                                         })}/>
                    </div>
                    {/*добавление настройки параметров для расчета  */}
                    {selectedFunction && selectedFunction.args && selectedFunction.args.length > 0 &&
                    <AddAnalyticParametersToChart datetimeFilter={datetimeFilter} demandFunction={selectedFunction}
                                                   onChangeParam={func => {
                                                       setSelectedFunction({...selectedFunction, ...func});
                                                   }}/>
                    }
                    <hr/>
                    <div className={'d-flex justify-content-between'}>
                        {/*кнопка применения параметров расчета*/}
                        <button type="button" className={'btn btn-outline-success'} onClick={(e) => {
                            e.preventDefault();
                            if (selectedFunction.parameters.dataSource === null) {
                                setError(localeService.isRussian() ? 'Выберите источник данных, к которому необходимо применить функцию' : 'Select Data Source');
                            } else {
                                onSubmitFunction();
                            }
                        }}>{t('apply')}</button>
                        {/*кнопка для отмены*/}
                        <button className={'btn btn-outline-danger'} onClick={() => {
                            setOpenConfigurator(false);
                            setSelectedFunction(null);
                        }}>{t('cancel')}</button>
                    </div>
                </form>
                }
                {/*отображение загрузчика*/}
                {isFetching &&
                <Loader waitText={localeService.isRussian() ? 'Загрузка функции...' : 'Loading function...'}/>}
                {/*отображение ошибки*/}
                <AlertError isOpen={error !== null} onCancel={() => setError(null)} message={error}/>
            </Modal>
        </div>);
}

AddDemandFunctionToChart.propTypes = {
    analyticFunctionList: PropTypes.array,
    onApplyFunction: PropTypes.func,
    dataSources: PropTypes.array,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {analyticFunctionList, datetimeFilter};
};

export default connect(mapStateToProps, null)(AddDemandFunctionToChart);