/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {scenarioService, getScenario} from '../../services/scenario.service';
import {useTranslation} from 'react-i18next';
import ScenarioEditor from './components/ScenarioEditor';

//функция редактирования сценария
const EditScenario = ({scenario, onCreate, onCancel}) => {
    //инициализация библиотеки для перевода языка
    const {t} = useTranslation();
    //инициализация выбранного для редактирования сценария
    const [selectedScenary, setSelectedScenary] = useState(undefined);
    //функция-обработчик для инициализации выбранного сценария при его изменении от родителя
    useEffect(() => {
        setSelectedScenary(scenario);
    }, [scenario]);
    //инициализация функции сохранения изменений редактирования
    const saveData = (scenario) => {
        scenarioService
            .saveScenario({scriptId: selectedScenary.id, ...scenario})
            .then(() => {
                onCreate(true, undefined);
            })
            .catch((err) => onCreate(false, err));
    };
    //возврат сгенерированного кода
    return (
        <div>
            {selectedScenary &&
            //если сценари для редактирования выбран, то вызвать компонент для создания сценария с параметрами сценария для редактирования
            (
                <ScenarioEditor
                    onCancel={onCancel}
                    scenarioModel={selectedScenary}
                    onCreate={saveData}
                />
            )}
        </div>
    );
};
EditScenario.propTypes = {
    scenario: PropTypes.object,
    onCancel: PropTypes.func,
    onCreate: PropTypes.func,
};
export default EditScenario;
