import React from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants';
import WidgetChartView from './WidgetChartView';
// import WidgetChartProps from './WidgetCreate';
import WidgetChartProps from './WidgetChartCreate';
import Modal from '../../../components/Modal';
import {localeService} from '../../../services/locale.service';
import WidgetChartPreview from './WidgetChartPreview';

function WidgetChart({widgetProps, mode, onCloseEditSingleMode, sizeParams}) {
    return (
        <div>
            {mode === dashboardConstants.VIEW_MODE &&
                <WidgetChartView widgetProps={widgetProps}/>
            }
            {mode === dashboardConstants.EDITION_MODE &&
            <WidgetChartPreview widgetProps={widgetProps} sizeParams={sizeParams}/>
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} width={800}
                   onCancel={() => onCloseEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <WidgetChartProps widgetProps={widgetProps}/>
            </Modal>
            }
            </div>
    );
}

WidgetChart.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    size: PropTypes.object,
    sizeParams: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default WidgetChart;