import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import DatePicker, {registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru'; // the locale you want
import en from 'date-fns/locale/en-US';
import {localeService} from '../services/locale.service'; // the locale you want
registerLocale('ru', ru);
registerLocale('en', en);

function SelectDate({ selectedDate, format, onChange, label }) {
  return (
    <>
      <div className={'input-group understroke'}>
        <div className={'input-group-prepend'}>
          <label>{label}</label>
        </div>
        <DatePicker
          dateFormat={format}
          locale={localeService.isRussian() ? 'ru' : 'en'}
          showPopperArrow={false}
          selected={selectedDate}
          onChange={(date, event) => {
            event.preventDefault();
            event.stopPropagation();
            onChange(moment(date));
          }}
        />
      </div>
    </>
  );
}

SelectDate.propTypes = {
  selectedDate: PropTypes.object,
  onChange: PropTypes.func,
  format: PropTypes.string,
  label: PropTypes.string,
};
SelectDate.defaultProps = {
  format: 'dd MMMM, yyyy',
};

export default SelectDate;
