import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import ImageUploader from '../../../components/ImageUploader';
import InputText from '../../../components/InputText';
import {useTranslation} from 'react-i18next';
import SelectCapacityTree from '../../../components/SelectCapacityTree';
import {capacityService} from '../../../services/capacity.service';
import AlertError from '../../../components/AlertError';

//функция рендеринга интерфейса для создания нагрузки
function CreateCapacity({capacityTree, onSuccess}) {

    //инициализация библиотеки для перевода языка интерфейса
    const {t} = useTranslation();
    //константа для инициализации пустой нагрузки
    const initCapacity = {
        name: '',
        parentId: 0
    };
    //инициализация флага открытия диалогового окна
    const [isOpen, setOpen] = React.useState(false);
    //инициализация переменной создаваемой нагрузки
    const [capacity, setCapacity] = React.useState(initCapacity);
    //инициализация переменной ошибки
    const [error, setError] = React.useState('');
    //инициализация функции для создания нагрузки
    const createCapacity = () => {
        //проверка на указание названия создаваемой нагрузки
        if (capacity.name !== '') {
            //преобразование переменной для запроса на создание нагрузки
            let newCapacity = {//переменная для запроса
                name: capacity.name, //название нагрузки
                ownerCapacityId: capacity.parentId, //ID родительской нагрузки
                group: capacity.parentId === 0, //флаг для определения родительской нагрузки
                imgView: capacity.image //изображение нагрузки
            };
            //вызов метода API для передачи запроса на создание нагрузки
            capacityService.createCapacity(newCapacity).then(() => {
                setCapacity(initCapacity); //обновить значение создаваемой нагрузки
                onSuccess();//вызов обработчика для успешного создания нагрузки
                setOpen(false);//закрыть диалоговое окно
            }).catch((er) => {
                setError(`Ошибка: ${er}`);//вывод ошибки, при неудачном создании нагрузки
            });
        } else {
            //вывод ошибки, при создании пустой нагрузки (без названия)
            setError(localeService.isRussian() ? 'Введите название нагрузки!' : 'Please, enter capacity name!');
        }
    };

    //возврат сгенерированного HTML кода
    return (
        <>
            {/*вывод кнопки для открытия диалогового окна с формой для создания нагрузки*/}
            <button className={'btn btn-outline-success'}
                    onClick={() => setOpen(!isOpen)}//обработчик функции нажатия кнопки - поменять значение флага открытия диалогового окна
            >{localeService.isRussian() ? 'Добавить нагрузку' : 'Create Capacity'}</button>
            {/*компонент диалогового окна*/}
            <Modal title={localeService.isRussian() ? 'Добавить нагрузку' : 'Create Capacity'} //заголдовок окна
                   isOpen={isOpen}//флаг открытия окна
                   footer={false} //флаг для использования подвала окна
                   onCancel={() => setOpen(false)}//обработчик кнопки для отмены
            >
                {/*вывод формы ввода параметров добавляемой нагрузки*/}
                <form>
                    {/*если нагрузка дочерняя, то вывести элемент для добавления изображения*/}
                    {capacity.ownerCapacityId !== 0 && (
                        <ImageUploader //компонент для добавления изображения
                            onChange={(file) => setCapacity({...capacity, image: file})}
                        />
                    )}
                    {/*ввод названия нагрузки*/}
                    <InputText label={t('name')} value={capacity.name}
                               onChange={text => setCapacity({...capacity, name: text})}/>
                    {/*выбор родительской нагрузки*/}
                    <div className="input-group understroke">
                        <div className="input-group-prepend">
                            <label>{localeService.isRussian() ? 'Родительская нагрузка' : 'Parent Capacity'}</label>
                        </div>
                        {/*вызов компонента для выбора нагрузки из дерева нагрузок */}
                        <SelectCapacityTree capacityTree={capacityTree} selectedCapacityId={capacity.parentId}
                                            onSelect={cap => setCapacity({...capacity, parentId: cap.id})}/>
                    </div>

                </form>
                {/*компонент для вывода тескта ошибки*/}
                <AlertError isOpen={error !== ''} onCancel={() => setError('')} message={error}/>
                {/*вывод блока для управления диалоговым окном */}
                <div className={'modal-footer'}>
                    {/*кнопка для создания нагрузки*/}
                    <button className={'btn btn-outline-success'}
                            onClick={() => createCapacity()}//обработчик нажатия на кнопку - вызов метода createCapacity
                    >{t('create')}</button>
                    {/*кнопка для отмены и закрытия диалогового окна*/}
                    <button className={'btn btn-outline-danger'} onClick={() => setOpen(false)}>{t('cancel')}</button>
                </div>
            </Modal>
        </>
    );
}

CreateCapacity.propTypes = {
    capacityTree: PropTypes.array,
    onSuccess: PropTypes.func
};

export default CreateCapacity;