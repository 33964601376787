import React from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import LinesWidgetCreate from './LinesWidgetCreate';
import BarChartView from './BarChartView';
import BarChartPreview from './BarChartPreview';

function BarChart({widgetProps, mode, closeEditSingleMode, sizeParams}) {
    return (
        <>
            {mode === dashboardConstants.VIEW_MODE &&
            <BarChartView widgetProps={widgetProps}/>
            }
            {mode === dashboardConstants.EDITION_MODE &&
            <BarChartPreview widgetProps={widgetProps} sizeParams={sizeParams}/>
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => closeEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <LinesWidgetCreate widgetProps={widgetProps} onClose = {() => closeEditSingleMode()}/>
            </Modal>
            }
        </>
    );
}

BarChart.propTypes = {
    widgetProps: PropTypes.object,
    closeEditSingleMode: PropTypes.func,
    sizeParams: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default BarChart;