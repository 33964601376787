import React from 'react';
import {localeService} from '../services/locale.service';

function ErrorPage() {

    return (
        <>
            <div className="container-700">
            <h1>404</h1>
            <h3>{localeService.isRussian() ? 'Страница не найдена!' : 'Page not found!'}</h3>
            </div>
        </>
    );
}

export default ErrorPage;