import {localeService} from '../services/locale.service';

export const deviceConstants = {
    CLEAR_ALL_DEVICE_ACTIONS: 'CLEAR_ALL_DEVICE_ACTIONS',

    FIND_DEVICE_REQUEST: 'FIND_DEVICE_REQUEST',
    FIND_DEVICE_SUCCESS: 'FIND_DEVICE_SUCCESS',
    FIND_DEVICE_FAILURE: 'FIND_DEVICE_FAILURE',

    DATA_SOURCE_LIST_REQUEST: 'DATA_SOURCE_LIST_REQUEST',
    DATA_SOURCE_LIST_SUCCESS: 'DATA_SOURCE_LIST_SUCCESS',
    DATA_SOURCE_LIST_FAILURE: 'DATA_SOURCE_LIST_FAILURE',

    GET_VIRTUAL_DEVICES_FAILURE: 'GET_VIRTUAL_DEVICES_FAILURE',
    GET_VIRTUAL_DEVICES_REQUEST: 'GET_VIRTUAL_DEVICES_REQUEST',
    GET_VIRTUAL_DEVICES_SUCCESS: 'GET_VIRTUAL_DEVICES_SUCCESS',

    GET_DEVICE_LIST_REQUEST: 'GET_DEVICE_LIST_REQUEST',
    GET_DEVICE_LIST_SUCCESS: 'GET_DEVICE_LIST_SUCCESS',
    GET_DEVICE_LIST_FAILURE: 'GET_DEVICE_LIST_FAILURE',

    ADD_DEVICE_REQUEST: 'ADD_DEVICE_REQUEST',
    ADD_DEVICE_SUCCESS: 'ADD_DEVICE_SUCCESS',
    ADD_DEVICE_FAILURE: 'ADD_DEVICE_FAILURE',

    GET_GENERATORS: 'GET_GENERATORS',

    generatorList: [
        {
            name: 'None',
            value: 'NONE'
        },
        {
            name: localeService.isRussian() ? 'Непрерывный случайный сигнал' : 'Continuous random signal',
            value: 'ANALOG_RANDOM'
        },
        {
            name: localeService.isRussian() ? 'Непрерывный сигнал, заданный функцией'
                                            : 'Continuous signal, set by function',
            value: 'ANALOG_FUNCTION'
        },
        {
            name: localeService.isRussian() ? 'Дискретный сигнал со случайным периодом'
                : 'Discrete signal with a random period',
            value: 'DIGITAL_RANDOM_PERIOD'
        },
        {
            name: localeService.isRussian() ? 'Дискретный фиксированный сигнал'
                : 'Discrete fixed signal',
            value: 'DIGITAL_FIXED_COUNT'
        }
    ]
};
