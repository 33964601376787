import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../services/locale.service';
import {deviceService} from '../../services/device.service';
import CreateVirtualVariable from './CreateVirtualVariable';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import AlertError from '../../components/AlertError';
import svgAdd from '../../sources/images/interface/add.svg';
import {ReactSVG} from 'react-svg';

function AddVirtualVariableToExistsDevice({deviceId, onSuccess}) {
    const [isOpen, setOpen] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [error, setError] = useState('');

    return <>
        <button className={'success svg-btn'} style={{display: 'block', margin: '4px'}}
                onClick={() => setOpen(true)}
                title={localeService.isRussian() ? 'Добавить переменную' : 'Add Variable'}>
            <ReactSVG src={svgAdd}/>
        </button>
        {/*Окно для добавления новой виртуальной переменной*/}
        <Modal isOpen={isOpen} isClosable={true} footer={false} width={600}
               title={localeService.isRussian() ? 'Добавить новую переменную устройства' : 'Add Device Variable'}>
            {!isFetching && !error &&
            <CreateVirtualVariable onCancel={() => setOpen(false)}
                                   onAddVariable={(variable) => {
                                       setFetching(true);
                                       const newVar = {
                                           active: true,
                                           capacityID: variable.capacity.id,
                                           config: variable.generator && JSON.stringify(variable.generator.config) || null,
                                           name: variable.name,
                                           type: variable.generator && variable.generator.type || null
                                       };
                                       if(newVar.config === '{}' || newVar.config === null){
                                           delete newVar.config;
                                       }
                                       if(newVar.type === 'NONE' || newVar.config === null){
                                           delete newVar.type;
                                       }
                                       deviceService.addVirtualVariable(newVar, deviceId).then(
                                           response => {
                                               if (response.status === 'SUCCESS') {
                                                   setOpen(false);
                                                   onSuccess(true);
                                               }
                                           }, error => {
                                               setError('Cannot ADD Variable: ' + JSON.stringify(error));
                                           }
                                       ).finally(() => {
                                           setFetching(false);
                                       });
                                   }}/>
            }
            {isFetching && <Loader/>}
            <AlertError isOpen={error !== ''} message={error} onCancel={() => {
                setError('');
            }}/>
        </Modal>
    </>;
}

AddVirtualVariableToExistsDevice.propTypes = {
    deviceId: PropTypes.number,
    onSuccess: PropTypes.func
};

export default AddVirtualVariableToExistsDevice;