import React from 'react';
import PropTypes from 'prop-types';

function AlertSuccess({message, isOpen, onCancel, isClosable, style}) {

    return <>
        {(isOpen || isClosable) &&
        <div className={'row'}>
            <div className="alert alert-success" style={style}>{message}
                {onCancel && !isClosable && <div className={'to-right'}><button className={'btn btn-outline-success'} onClick={() => onCancel()}>Ok</button></div> }
            </div>
        </div>}
    </>;
}

AlertSuccess.propTypes = {
    message: PropTypes.string,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    isClosable: PropTypes.bool,
    style: PropTypes.object
};

AlertSuccess.defaultProps = {
    isClosable: false
};

export default AlertSuccess;