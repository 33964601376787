import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ClickOutside from '../../../../components/ClickOutside';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../components/Modal';
import {localeService} from '../../../../services/locale.service';
import {dashboardService} from '../../../../services/index';
import InputText from '../../../../components/InputText';
import ColorPicker from '../../../ColorPicker';
import AlertError from '../../../../components/AlertError';
import Loader from '../../../../components/Loader';
import {analyticService} from '../../../../services/analyticService';
import AddAnalyticParametersToGroupChart from '../../../../components/AddAnalyticParametersToGroupChart';

function AddFunctionToGroupChart({onApplyFunction, analyticFunctionList, transformationFunctionList, dataSources, datetimeFilter, widgetWidth}) {
    const {t} = useTranslation();
    const [isInfluxFunctionOpened, setInfluxFunctionOpened] = useState(false);
    const [isDemandFunctionOpened, setDemandFunctionOpened] = useState(false);
    const [isPredictionFunctionOpened, setPredictionFunctionOpened] = useState(false);
    const [selectedFunction, setSelectedFunction] = useState(null);
    const [isOpenConfigurator, setOpenConfigurator] = useState(false);
    const [error, setError] = useState(null);
    const [isFetching, setFetching] = useState(false);

    const onSelectFunction = (func) => {
        let selectedFunction = {
            ...func,
            idx: new Date().getTime(),
            functionParams: analyticService.initTransformationFunctionParameters(func),
            parameters: {
                dataSourceName: 'F(x): ' + func.name,
                color: dashboardService.getRandomColor()
            }
        };

        if (func.sourceType === 'ANALYTIC_FUNCTION') {
            const args = analyticService.initAnalyticFunctionParameters(func, datetimeFilter);
            selectedFunction = {
                ...selectedFunction,
                args: args
            };

            const dataSourcesArgs = dataSources.map(ds => {
                return {
                    dsId: ds.id,
                    dsName: ds.parameters.dataSourceName,
                    args: selectedFunction.args.filter(arg => (arg.count === -1 || (['discharge_start_hour', 'discharge_duration', 'discharge_value'].findIndex(el => el === arg.name) >= 0))),
                    additionalDataSources: ds.additionalDataSources
                };
            });
            selectedFunction.args = args.filter(el => ['discharge_start_hour', 'discharge_duration', 'discharge_value'].findIndex(name => name === el.name) < 0);

            selectedFunction.dataSourcesArgs = dataSourcesArgs;

        }

        setSelectedFunction(selectedFunction);

        setInfluxFunctionOpened(false);
        setDemandFunctionOpened(false);
        setPredictionFunctionOpened(false);
        setOpenConfigurator(true);
    };

    const onSubmitFunction = () => {
        if (selectedFunction.sourceType === 'ANALYTIC_FUNCTION') {
            submitAnalyticFunction();
        } else {
            setFetching(true);
            Promise.all(
                dataSources.map(ds => {
                    return dashboardService.getWidgetFunctionData(ds.id, datetimeFilter.startDate, datetimeFilter.finishDate,
                        selectedFunction.functionCode, selectedFunction.functionParams, widgetWidth)
                        .then(
                            result => {
                                return result.list;
                            }, () => {
                                return [];
                            }
                        );
                })
            ).then(
                functionResults => {
                    setOpenConfigurator(false);
                    setInfluxFunctionOpened(false);
                    setDemandFunctionOpened(false);
                    setPredictionFunctionOpened(false);
                    setSelectedFunction(null);
                    onApplyFunction({...selectedFunction, data: dashboardService.groupData(functionResults)});
                }
            ).finally(
                () => {
                    setFetching(false);
                }
            );
        }
    };

    const submitAnalyticFunction = () => {
        setFetching(true);
        Promise.all(
            dataSources.map(ds => {
                let analyticFunction = selectedFunction;
                const dsArgs = selectedFunction.dataSourcesArgs.filter(el => el.dsId === ds.id);
                if (dsArgs.length === 1) {
                    analyticFunction.args = [...analyticFunction.args, ...dsArgs[0].args];
                }
                return analyticService.calculateAnalyticFunction(ds.id, analyticFunction, datetimeFilter)
                    .then(
                        result => {
                            return selectedFunction.outputsCount > 1 ? result[0] : result;
                        }, () => {
                            return [];
                        }
                    );
            })
        ).then(
            functionResults => {
                setOpenConfigurator(false);
                setInfluxFunctionOpened(false);
                setDemandFunctionOpened(false);
                setPredictionFunctionOpened(false);
                setSelectedFunction(null);
                onApplyFunction({...selectedFunction, data: dashboardService.groupData(functionResults)});
            }
        ).finally(
            () => {
                setFetching(false);
            }
        );
    };

    return (
        <>
            <div className={'dropdown-container function-list' + (isInfluxFunctionOpened ? ' open' : '')}>
                <button className={'btn btn-outline-primary default round-btn'} onClick={() => {
                    setInfluxFunctionOpened(!isInfluxFunctionOpened);
                    setSelectedFunction(false);
                }}>
                    <span style={{fontStyle: 'italic', fontSize: '0.8rem', fontWeight: 'bold'}}>F(x)</span>
                </button>
                <ClickOutside onClick={() => setInfluxFunctionOpened(false)}>
                    <div className={'dropdown' + (isInfluxFunctionOpened ? ' open' : '')}>
                        <ul>
                            {transformationFunctionList.map((func, i) => {
                                if(func.code !== 'HOLT_WINTERS') {
                                    return <li key={'trans-' + i}
                                               onClick={() => onSelectFunction({
                                                   ...func,
                                                   functionCode: func.code,
                                                   sourceType: 'TRANSFORMATION_FUNCTION'
                                               })}>
                                        <span>{t('functionName.' + func.name)}</span>
                                    </li>;
                                }
                            })}
                        </ul>
                    </div>
                </ClickOutside>
            </div>
            <div className={'dropdown-container function-list' + (isInfluxFunctionOpened ? ' open' : '')}>

                <button className={'btn btn-outline-primary default round-btn'} onClick={() => {
                    setDemandFunctionOpened(!isDemandFunctionOpened);
                    setSelectedFunction(false);
                }}>
                    <span style={{fontStyle: 'italic', fontSize: '0.8rem', fontWeight: 'bold'}}>DR</span>
                </button>
                <ClickOutside onClick={() => setDemandFunctionOpened(false)}>
                    <div className={'dropdown' + (isDemandFunctionOpened ? ' open' : '')}>
                        <ul>
                            {analyticFunctionList.map((func, i) => {
                                // выводим все функции кроме агрегаторов и функций прогнозирования
                                if (func.code !== 'ELECTRICITYCOSTCALCULATION' && func.code !== 'DEMAND_RESPONSE_RRMSE' && func.code !== 'ANALYSISPREDICTIONCLD'
                                    && func.code !== 'DEMAND_RESPONSE_CHECK' && func.code !== 'DEMAND_RESPONSE_BOOLEAN'
                                    && func.code !== 'PEAK_PREDICTION_ML' && func.code !== 'PEAK_PREDICTION_STATISTICAL'
                                    && func.code !== 'PREDICTION-HOLT-WINTERS') {
                                    return <li key={'anal-' + i} onClick={() => onSelectFunction({
                                        ...func,
                                        functionCode: func.code,
                                        sourceType: 'ANALYTIC_FUNCTION'
                                    })}>
                                        <span>{t('functionName.' + func.name)}</span>
                                    </li>;
                                }
                            })}
                        </ul>
                    </div>
                </ClickOutside>
            </div>
            <div className={'dropdown-container function-list' + (isInfluxFunctionOpened ? ' open' : '')}>
                <button className={'btn btn-outline-primary default round-btn'} onClick={() => {
                    setPredictionFunctionOpened(!isPredictionFunctionOpened);
                    setSelectedFunction(false);
                }}>
                    <span style={{fontStyle: 'italic', fontSize: '0.8rem', fontWeight: 'bold'}}>P</span>
                </button>
                <ClickOutside onClick={() => setPredictionFunctionOpened(false)}>
                    <div className={'dropdown' + (isPredictionFunctionOpened ? ' open' : '')}>
                        <ul>
                            {analyticFunctionList.map((func, i) => {
                                if (func.code === 'PEAK_PREDICTION_ML' || func.code === 'PEAK_PREDICTION_STATISTICAL' || func.code === 'ANALYSISPREDICTIONCLD'
                                    || func.code === 'PREDICTION-HOLT-WINTERS' || func.code === 'DEMAND_RESPONSE_BASELINE') {
                                    return <li key={'analytic-' + i} onClick={() => onSelectFunction({
                                        ...func,
                                        functionCode: func.code,
                                        sourceType: 'ANALYTIC_FUNCTION'
                                    })}>
                                        <span>{t('functionName.' + func.name)}</span>
                                    </li>;
                                }
                            })}
                            {transformationFunctionList.map((func) => {
                                if(func.code === 'HOLT_WINTERS'){
                                    return <li key={'holt'} onClick={() => onSelectFunction({
                                        ...func,
                                        functionCode: func.code,
                                        sourceType: 'TRANSFORMATION_FUNCTION'
                                    })}>
                                        <span>{t('functionName.' + func.name)}</span>
                                    </li>;
                                }
                            })}
                        </ul>
                    </div>
                </ClickOutside>
            </div>
            <Modal onCancel={() => {
                setOpenConfigurator(false);
                setSelectedFunction(null);
            }} footer={false} isClosable={false}
                   isOpen={isOpenConfigurator}
                   title={localeService.isRussian() ? 'Параметры применения функции' : 'Function Configuration'}>
                {selectedFunction && !error && !isFetching &&
                <form>
                    <div className={'row'} style={{padding: 0, display: 'flex', width: '100%', position: 'relative'}}>
                        <InputText label={t('name')}
                                   value={selectedFunction.parameters.dataSourceName}
                                   onChange={text => setSelectedFunction({
                                       ...selectedFunction,
                                       parameters: {...selectedFunction.parameters, dataSourceName: text}
                                   })}/>
                        <ColorPicker style={{margin: '0 1rem'}} color={selectedFunction.parameters.color}
                                     setColor={(color) =>
                                         setSelectedFunction({
                                             ...selectedFunction,
                                             parameters: {...selectedFunction.parameters, color: color}
                                         })}/>
                    </div>

                    {selectedFunction.sourceType === 'TRANSFORMATION_FUNCTION' &&
                    <div style={{marginTop: '20px'}}>{
                        selectedFunction.functionParams.map((param, i) => {
                            return (<InputText key={'funcParam' + i}
                                               label={selectedFunction.code === 'HOLT_WINTERS' ?
                                                   (t('holtWintersArgs.' + i))
                                                   :((localeService.isRussian() ? 'Параметр ' : 'Parameter ') + (i + 1))}
                                               isDecimal={selectedFunction.code !== 'HOLT_WINTERS'}
                                               value={param}
                                               onChange={(text) => {
                                                   let params = selectedFunction.functionParams;
                                                   params[i] = text;
                                                   setSelectedFunction({
                                                       ...selectedFunction,
                                                       functionParams: params
                                                   });
                                               }}
                            />);
                        })
                    }
                    </div>
                    }
                    {selectedFunction.sourceType === 'ANALYTIC_FUNCTION' &&
                    <AddAnalyticParametersToGroupChart datetimeFilter={datetimeFilter}
                                                       analyticFunction={selectedFunction}
                                                       dataSources={dataSources}
                                                       onChangeParam={func => {
                                                           setSelectedFunction({...func});
                                                       }}/>
                    }
                    <hr/>
                    <div className={'d-flex justify-content-center'}>
                        <button type="button" className={'btn btn-outline-success'} onClick={(e) => {
                            e.preventDefault();
                            if (selectedFunction.parameters.dataSource === null) {
                                setError(localeService.isRussian() ? 'Выберите источник данных, к которому необходимо применить функцию' : 'Select Data Source');
                            } else {
                                // setOpenConfigurator(false);
                                onSubmitFunction();
                            }
                        }}>{t('apply')}</button>
                        <button className={'btn btn-outline-danger'} onClick={() => {
                            setOpenConfigurator(false);
                            setSelectedFunction(null);
                        }}>{t('cancel')}</button>

                    </div>
                </form>
                }

                {isFetching &&
                <Loader waitText={localeService.isRussian() ? 'Загрузка функции...' : 'Loading function...'}/>}

                <AlertError isOpen={error !== null} onCancel={() => setError(null)} message={error}/>


            </Modal>
        </>
    );
}

AddFunctionToGroupChart.propTypes = {
    transformationFunctionList: PropTypes.array,
    analyticFunctionList: PropTypes.array,
    onApplyFunction: PropTypes.func,
    dataSources: PropTypes.array,
    datetimeFilter: PropTypes.object,
    widgetWidth: PropTypes.number
};


const mapStateToProps = state => {
    const transformationFunctionList = state.analyticReducer.transformationFunctionList;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {transformationFunctionList, analyticFunctionList, datetimeFilter};
};


export default connect(mapStateToProps, null)(AddFunctionToGroupChart);