import {localeService} from '../services/locale.service';

export const widgetsConstants = {
    CAPACITY_COST_WIDGET_TYPES: [
        {id: 0, code: 'COST_MAKING', name: localeService.isRussian() ? 'Составляющие стоимости' : 'Cost-making'},
        {id: 1, code: 'AVERAGE_COST', name: localeService.isRussian() ? 'Среднепривиденная цена' : 'Average cost'},
        {
            id: 2,
            code: 'ELECTRICITY_COST',
            name: localeService.isRussian() ? 'Стоимость электроэнергии' : 'Electricity cost'
        }
    ]
};

export const hoursLabels = ['0:00-1:00', '1:00-2:00', '2:00-3:00', '3:00-4:00', '4:00-5:00', '5:00-6:00',
    '6:00-7:00', '7:00-8:00', '8:00-9:00', '9:00-10:00', '10:00-11:00', '11:00-12:00',
    '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00',
    '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-0:00'];


export const timeGroupList = [
    {
        value: 'auto',
        name: 'Auto',
        seconds: 'auto'
    },
    {
        value: '1s',
        name: '1s',
        seconds: 1,
    },
    {
        value: '15s',
        name: '15 seconds',
        seconds: 15
    },
    {
        value: '30s',
        name: '30 seconds',
        seconds: 30
    },
    {
        value: '1m',
        name: '1 minute',
        seconds: 60
    },
    {
        value: '15m',
        name: '15 minutes',
        seconds: 900
    },
    {
        value: '30m',
        name: '30 minutes',
        seconds: 1800
    },
    {
        value: '1h',
        name: '1 hour',
        seconds: 3600
    },
    {
        value: '3h',
        name: '3 hours',
        seconds: 10800
    },
    {
        value: '6h',
        name: '6 hours',
        seconds: 21600
    },
    {
        value: '12h',
        name: '12 hours',
        seconds: 43200
    },
    {
        value: '24h',
        name: '24 hours',
        seconds: 86400
    },
    {
        value: '7d',
        name: '1 week',
        seconds: 604800
    }
];