import React, {useEffect, useState} from 'react';
import {localeService} from '../../services/locale.service';
import InputText from '../../components/InputText';
import {useTranslation} from 'react-i18next';
import Modal from '../../components/Modal';
import {capacityService} from '../../services/capacity.service';
import CreateVirtualVariable from './CreateVirtualVariable';
import svgDelete from '../../sources/images/interface/basket.svg';
import {ReactSVG} from 'react-svg';
import {Link} from 'react-router-dom';
import {deviceService} from '../../services';
import Loader from '../../components/Loader';
import AlertError from '../../components/AlertError';

function AddVirtualDevice() {
    const [device, setDevice] = useState({name: '', variableList: []});
    const [createVariable, setCreateVariable] = useState(false);
    const [capacityTree, setCapacityTree] = useState([]);
    const {t} = useTranslation();
    const [addDevice, setAddDevice] = useState({isFetching: false, isOpen: false, status: '', message: ''});

    useEffect(() => {
        capacityService.getCapacityTree()
            .then((response) => {
                setCapacityTree(response.list);
            });
    }, []);

    const removeVariable = (virtVar) => {
        const variableList = device.variableList;
        setDevice({...device, variableList: variableList.filter(el => el.id !== virtVar.id)});
    };

    const onAddDevice = (e) => {
        e.preventDefault();
        setAddDevice({...addDevice, error: ''});
        if(device.name === ''){
            setAddDevice({...addDevice, error: localeService.isRussian() ? 'Введите имя устройства!' : 'Enter Device Name!'});
        } else if(device.variableList.length === 0){
            setAddDevice({...addDevice, error: localeService.isRussian() ? 'Добавьте хотя бы одну переменную!' : 'Add at least one variable!'});
        } else {
            setAddDevice({...addDevice, isFetching: true, isOpen: true});
            deviceService.addVirtualDevice(device).then(
                () => {
                    setAddDevice({...addDevice, isFetching: false, status: 'success', isOpen: true});
                },
                error => {
                    setAddDevice({...addDevice, isFetching: false, status: 'fail', message: 'Error: ' + error, isOpen: true});
                }
            );
        }

    };

    return <form>
        <InputText className={'mt3'} label={localeService.isRussian() ? 'Имя устройства' : 'Device Name'} value={device.name}
                   onChange={text => setDevice({...device, name: text})} isRequired={true}/>
        <table className={'table mt3'} cellSpacing={0} cellPadding={0}>
            <thead>
            <tr>
                <th>{localeService.isRussian() ? 'Имя переменной' : 'Variable name'}</th>
                <th>{localeService.isRussian() ? 'Генератор' : 'Generator'}</th>
                <th>{t('capacity')}</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {device.variableList.length === 0 &&
            <tr>
                <td colSpan={4}>{localeService.isRussian() ? 'Список переменных пуст' : 'No device variables'}</td>
            </tr>
            }
            {device.variableList && device.variableList.map((virtVar, i) => {
                return (<tr key={i}>
                    <td>{virtVar.name}</td>
                    <td>
                        <small>{(virtVar.generator && virtVar.generator.type) || (localeService.isRussian() ? 'Нет' : 'None')}</small>
                    </td>
                    <td>{virtVar.capacity.name}</td>
                    <td>
                        <button type={'button'} className={'svg-btn danger'}
                                onClick={() => removeVariable(virtVar)}>
                            <ReactSVG src={svgDelete}/>
                        </button>
                    </td>
                </tr>);
            })}
            </tbody>
        </table>
        <AlertError message={addDevice.error} isOpen={addDevice.error} onCancel={() => setAddDevice({...addDevice, error: ''})}/>
        <div className={'d-flex mt-4'}>
            <button type={'button'} className={'btn btn-outline-primary default adding-btn'} onClick={() => setCreateVariable(true)}>
                {localeService.isRussian() ? 'Добавить переменную' : 'Add variable'}
            </button>
            <button type={'button'} className={'btn btn-outline-success'} onClick={(e) => onAddDevice(e)}>
                {localeService.isRussian() ? 'Добавить устройство' : 'Add Device'}
            </button>
            <Link to="/device_list/0">
                <button type={'button'} className={'btn btn-outline-danger'} onClick={() => {}}>
                    {t('cancel')}
                </button>
            </Link>
        </div>

        <Modal
            title={localeService.isRussian() ? 'Добавление переменной виртуального устройства' : 'Add virtual device variable'}
            isOpen={createVariable}
            footer={false}
            onCancel={() => setCreateVariable(false)}>
            <CreateVirtualVariable onCancel={() => setCreateVariable(false)}
                                   onAddVariable={(virtualVariable) => {
                                       setCreateVariable(false);
                                       setDevice({...device, variableList: [...device.variableList, virtualVariable]});

                                   }}
                                   capacityTree={capacityTree}/>
        </Modal>
        <Modal isOpen={addDevice.isOpen} footer={false} onCancel={() => setAddDevice({...addDevice, isOpen: false})}>
            {addDevice.status === 'success' && <div>
                <h5>{localeService.isRussian() ? 'Устройство успешно добавлено!' : 'The device has been successfully added!'}</h5>
                <Link to={'/device_list/0'}><button className={'btn btn-outline-success'}>Ok</button></Link>
            </div>}
            {addDevice.status === 'fail' &&
            <div className="alert alert-danger">{addDevice.message}</div>
            }
            {addDevice.isFetching && <Loader waitText={t('wait')}/>}
        </Modal>
    </form>;

}

export default AddVirtualDevice;