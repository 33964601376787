import React from 'react';
import PropTypes from 'prop-types';
import {apiConstants, dashboardConstants} from '../../../constants';
import {localeService} from '../../../services/locale.service';
import TableWidgetView from './TableWidgetView';
import Modal from '../../../components/Modal';
import TableWidgetProps from './TableWidgetCreate';
import {withSize} from 'react-sizeme';

function TableWidget({widgetProps, mode, onCloseEditSingleMode, size}) {

    return<>
        {mode === dashboardConstants.VIEW_MODE &&
        <TableWidgetView widgetProps={widgetProps} size={size}/>
        }
        {mode === dashboardConstants.EDITION_MODE && (!widgetProps.parameters || !widgetProps.parameters.tableContent) &&
        <div style={{width: '90%', height: '90%', textAlign: 'center'}}>
            {widgetProps.image && widgetProps.image.id && <img style={{maxWidth: '100%', maxHeight: '100%'}} src={apiConstants.API_URL + '/file/download?fileId=' + widgetProps.image.id}/>}
        </div>
        }
        {mode === dashboardConstants.EDIT_SINGLE_MODE &&
        <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => onCloseEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
            <TableWidgetProps widgetProps={widgetProps}/>
        </Modal>
        }
    </>;
}


TableWidget.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object
};

export default withSize()(TableWidget);