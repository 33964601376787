/* eslint-disable no-unused-vars */
import {useTranslation} from 'react-i18next';
import React from 'react';
import CustomSelect from 'components/CustomSelect';
import CustomSelectDate from 'components/CustomSelectDate';
import CustomInputTime from 'components/CustomInputTime';
import CustomInput from 'components/CustomInput';
import {Field, Form, useField} from 'react-final-form';
import {ReactSVG} from 'react-svg';
import svgCancel from 'sources/images/trash.svg';
import PropTypes from 'prop-types';
import moment from 'moment';
import {nanoid} from 'nanoid';


const isObject = (data) => {
    return Object.keys(data).length > 0;
};

/**
 *
 * @param availableVariables {Array} Cписок достпных переменных
 * @param condition {{prefix:string,payload:{}}} Условие объеденения
 * @param onChange {} Изменение модели
 * @returns {*}
 */
function ControlCondition({
                              availableVariables,
                              condition,
                              onChange,

                              name,
                              onRemoveAction,
                              conditionIndex,
                              addCondition,
                              passportList
                          }) {

    const {t} = useTranslation();
    const field = useField(`${name}.type.type`);


    const typeValue = field.input.value !== 'VARIABLE_VARIABLE' ? 'VARIABLE' : field.input.value;

    //----

    //-- data arrays
    const groupConditions = [
        {name: '-', value: 'EMPTY'},
        {name: t('scenario.or'), value: 'OR'},
        {name: t('scenario.and'), value: 'AND'}
    ];

    const defaultOperators = [
        {name: '>', value: 'GT'},
        {name: '!=', value: 'NE'},
        {name: '==', value: 'EQ'},
        {name: '>=', value: 'GE'},
        {name: '<', value: 'LT'},
        {name: '=<', value: 'LE'}
    ];
    const variablesType = [
        {name: t('value'), value: {type: 'VARIABLE'}},
        {name: t('scenario.variable'), value: {type: 'VARIABLE_VARIABLE'}},
      
    ];
    const memorizedVariables = React.useMemo(() => availableVariables.map(item => ({
        name: item.variableName,
        value: {value: item, type: typeValue}
    })), [availableVariables.length, typeValue]);


    const sources = [
        {name: t('scenario.calendar'), value: {type: 'CURRENT_DATE'}},
        {name: t('scenario.time'), value: {type: 'CURRENT_TIME'}},
        {name: t('scenario.week_day'), value: {type: 'CURRENT_WEEK_DAY'}},
        ...memorizedVariables,
    ];


    const weekDays = [
        {name: t('week_days.monday'), value: 1},
        {name: t('week_days.tuesday'), value: 2},
        {name: t('week_days.wednes­day'), value: 3},
        {name: t('week_days.thursday'), value: 4},
        {name: t('week_days.friday'), value: 5},
        {name: t('week_days.saturday'), value: 6},
        {name: t('week_days.sunday'), value: 7}
    ];


    const findSource = (item, input) => {

        if (!input.value.value && item.value.type === 'VARIABLE') {

            return false;
        }
        if (input.value.value && item.value.value) {
            return input.value.value.variableName === item.value.value.variableName;
        }

        return input.value.type === item.value.type;

    };


    const findVariableType = (item, input) => {
        if (input.value !== 'VARIABLE_VARIABLE') {
            return item.value.type === 'VARIABLE';
        } else return item.value.type === input.value;
    };

    const types = {
        PASSPORT: (<div className="ml1 mr2" style={{width: '650px'}}>
            <Field name={`${name}.payload.comparisonValue`}>
                {({input}) => (<CustomSelect
                    isRequired={true}
                    key={nanoid()}
                    valueList={passportList}
                    value={input.value}
                    onSelect={e => input.onChange(e.value)}
                />)}
            </Field>


        </div>),
        CURRENT_DATE: (
            <div className="ml1 mr1" style={{width: '220px'}}>
                <Field name={`${name}.payload.comparisonValue`}>
                    {({input}) => (<CustomSelectDate
                        isRequired={true}
                        format={'yyyy-MM-dd'}
                        key={nanoid()}
                        selectedDate={Date.parse(input.value)}
                        onChange={(e) => input.onChange(e._i.toISOString().slice(0, 10))}
                    />)}
                </Field>
            </div>
        ),
        CURRENT_WEEK_DAY: (<div className="ml1 mr1" style={{width: '210px'}}>
                <Field name={`${name}.payload.comparisonValue`}>
                    {({input}) => (<CustomSelect
                        isRequired={true}
                        key={nanoid()}
                        valueList={weekDays}
                        value={weekDays.find(week =>
                            week.value === Number(input.value))}
                        onSelect={(e) => {
                            input.onChange(e.value);
                        }}
                    />)}
                </Field>
            </div>

        ),
        CURRENT_TIME: (<div className="ml1" style={{width: '230px'}}>
            <Field name={`${name}.payload.comparisonValue`}>
                {({input}) => (<CustomInputTime
                    isRequired={true}
                    key={nanoid()}
                    value={input.value}
                    onChange={(e) => input.onChange(e.slice(0, 5))}
                />)}
            </Field>


        </div>),
        VARIABLE: (<div className="ml1" style={{width: '225px'}}>
            <Field name={`${name}.payload.comparisonValue`}>
                {({input}) => (<CustomInput
                    isRequired={true}
                    key={nanoid()}
                    value={input.value}
                    onChange={(e) => {
                        input.onChange(e);
                    }}
                    isInteger={true}
                />)}
            </Field>
        </div>),

        VARIABLE_VARIABLE: (<div className="ml1 mr2" style={{width: '210px'}}>
            <Field name={`${name}.payload.comparisonVariable`}>
                {({input}) => (<CustomSelect
                    isRequired={true}
                    key={nanoid()}
                    valueList={memorizedVariables || []}
                    value={memorizedVariables.find(item => findSource(item, input))}
                    onSelect={(e) => {
                        input.onChange(e.value);
                    }}
                />)}
            </Field>
        </div>),
    };

    return (
        <div className="scenario-right">
            <div style={{width: '300px'}}>
                <Field name={`${name}.type`}>
                    {({input, meta}) => <CustomSelect
                        isRequired={true}
                        key={nanoid()}
                        label={t('scenario.if')}
                        valueList={sources}
                        value={sources.find((item) => findSource(item, input))}
                        onSelect={e => input.onChange(e.value)}
                    />}

                </Field>
            </div>

            <div style={{marginLeft: '0.5rem', width: '4rem'}}>
                <Field name={`${name}.payload.comparisonOperator`}>
                    {({input, meta}) => <CustomSelect
                        isRequired={true}
                        key={nanoid()}
                        label=""
                        color={'@default-text-color'}
                        fontWeight="bold"
                        valueList={defaultOperators}
                        value={defaultOperators.find(item => item.value === input.value)}
                        onSelect={e => input.onChange(e.value)}
                    />}

                </Field>

            </div>
            <div style={{marginLeft: '0.5rem'}}>
                <Field name={`${name}.type.type`}>
                    {({input, meta}) => <CustomSelect
                        isRequired={true}
                        key={nanoid()}
                        label=""
                        color={'@default-text-color'}
                        fontWeight="bold"
                        valueList={variablesType}
                        value={variablesType.find(item => findVariableType(item, input))
                        }
                        onSelect={e => {
                            input.onChange(e.value.type);
                        }}

                    />}

                </Field>

            </div>
            {types[field.input.value]}


            {<div style={{marginLeft: '1rem', width: '5rem'}}>
                <Field name={`${name}.group`}>
                    {({input, meta}) => <CustomSelect
                        isRequired={true}
                        key={nanoid()}
                        color={'@default-text-color'}
                        fontWeight="bold"
                        valueList={groupConditions}
                        value={groupConditions.find(item => {
                            return input.value === item.value;
                        })}
                        onSelect={e => input.onChange(e.value)}
                    />}

                </Field>

            </div>}
            {onRemoveAction && (
                <div style={{marginLeft: '1rem'}}>
                    <button
                        className={'btn btn-outline-primary round-btn default edit-variable-btn'}
                        onClick={(e) => {
                            e.preventDefault();
                            onRemoveAction(conditionIndex);
                        }}
                    >
                        <ReactSVG src={svgCancel}/>
                    </button>
                </div>
            )}
            {addCondition && (
                <div style={{marginLeft: '1rem'}}>
                    <button
                        className={'btn btn-outline-primary round-btn default edit-variable-btn'}
                        onClick={(e) => {
                            e.preventDefault();

                            addCondition();
                        }}
                    >
                        +
                    </button>
                </div>
            )}
        </div>
    );
}

ControlCondition.propTypes = {
    availableVariables: PropTypes.array,
    condition: PropTypes.object,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    conditionIndex: PropTypes.number,
    onRemoveAction: PropTypes.func,
    addCondition: PropTypes.func,
    passportList: PropTypes.array,
};
export default ControlCondition;
