/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../services/locale.service';
import {useTranslation} from 'react-i18next';
import '../../styles/device-list.scss';
import {deviceService} from '../../services/device.service';
import AlertError from '../../components/AlertError';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import svgEdit from '../../sources/images/interface/edit.svg';
import {ReactSVG} from 'react-svg';
import EditDeviceVariable from './EditDeviceVariable';
import svgBackArrow from '../../sources/images/interface/arrow-right.svg';
import svgAdd from '../../sources/images/interface/add.svg';
import svgDelete from '../../sources/images/interface/basket.svg';
import {Link, useHistory} from 'react-router-dom';
import InputText from '../../components/InputText';
import AddVirtualVariableToExistsDevice from './AddVirtualVariableToExistsDevice';
import {Button, Map, Placemark, YMaps} from 'react-yandex-maps';
import SetDeviceTimeZone from './SetDeviceTimeZone';
import {deviceConstants} from '../../constants';

function Device({deviceId}) {
    const history = useHistory();

    const [device, setDevice] = useState(null);
    const [variableList, setVariableList] = useState([]);
    const [isFetchingDevice, setFetchingDevice] = useState(false);
    const [isFetchingVariables, setFetchingVariables] = useState(false);
    const [editableVariable, setEditableVariable] = useState(null);
    const [canEdit, setCanEdit] = useState(false);
    const [isRenameOpened, setRenameOpened] = useState(false);
    const [isDeleteOpened, setDeleteOpened] = useState(false);
    const [isAddCoordinatesOpened, setAddCoordinatesOpened] = useState(false);
    const [error, setError] = useState('');
    const [coords, setCoords] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        loadDevice();
    }, []);

    const returnCoords = () => {
        setCoords([device.lat, device.lng]);
        setAddCoordinatesOpened(false);
    };

    const openAddCoordinates = () => {
        setAddCoordinatesOpened(true);
    };

    const loadDevice = () => {
        setFetchingDevice(true);
        deviceService.getDevice(deviceId)
            .then(
                response => {
                    const device = response.result;
                    setDevice(device);
                    if(device.lat){
                        setCoords([device.lat, device.lng]);
                    }else{
                        setDevice({...device, lat: 55.751574, lng: 37.573856});
                        setCoords([55.751574, 37.573856]);
                    }
                    setVariableList(device.variabels);
                    setFetchingDevice(false);
                    if (!device.isVirtual) {
                        setFetchingVariables(true);
                        deviceService.getRealDeviceVariables(device.id)
                            .then(
                                result => {
                                    setVariableList(result.variables);
                                    setCanEdit(true);
                                },
                                error => {
                                    setError(error);
                                }
                            )
                            .finally(() => {
                                    setFetchingVariables(false);
                                }
                            );
                    } else {
                        setVariableList(device.variabels);
                        setCanEdit(true);
                    }


                }, error => {
                    setFetchingDevice(false);
                    setError(error);
                }
            );
    };

    return (
        <>
            <nav className="page-header">
                <ul className="nav-left breadcrumb">
                    <li className={'as-a-btn'} onClick={() => history.goBack()}>
                        <ReactSVG className={'svg-icon previous-btn'} src={svgBackArrow}/>
                        <b>{t('device_list')}</b>
                    </li>
                    <li className={'text-ellipsis-200'}>{device ? device.name : '...'}</li>
                    <li> {t('device_variable_list')}</li>
                </ul>
                <ul className={'nav-right'}>
                    <li>
                        <Link to="/add_device">
                            <button type={'button'}
                                    onClick={() => {
                                    }}
                                    className={'success svg-btn '}
                                    title={localeService.isRussian() ? 'Подключить устройство' : 'Add Device'}>
                                <ReactSVG src={svgAdd}/>
                            </button>
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className="container-700">
                {device &&
                <>


                    <div className={'view-device'}>
                        <div className={'device-info'}>
                            <span className={'title'}>
                            {localeService.isRussian() ? 'Информация об устройстве' : 'Device Details'}
                            </span>

                            <div className={'d-flex justify-content-between'}>
                                <div className={'d-flex justify-content-start'}>
                                    <label>{t('name')}</label>&#160;
                                    <strong>{device.name}</strong>
                                    <button className={'svg-btn sm default ml1'}
                                            onClick={() => {
                                                setDevice({...device, newName: device.name});
                                                setRenameOpened(true);
                                            }}
                                            title={localeService.isRussian() ? 'Переименовать устройство' : 'Rename Device'}>
                                        <ReactSVG src={svgEdit}/>
                                    </button>
                                </div>
                                <button className={'btn svg-btn danger'}
                                        title={localeService.isRussian() ? 'Удалить устройство' : 'Delete'}
                                        onClick={() => setDeleteOpened(true)}>
                                    <ReactSVG src={svgDelete}/>
                                </button>
                            </div>

                            <p><label>GUID</label>{device.guid}</p>
                            <p><label>{t('device.protocol')}</label>{device.deviceProtocol.name}</p>

                            {device.detail &&
                            <>
                                <p><label>{t('device.brand')}</label>{device.detail.brand}</p>
                                <p><label>{t('device.model')}</label>{device.detail.model}</p>
                                <p><label>{t('device.serial_number')}</label>{device.detail.serialNumber}</p>
                                <p><label>IP</label>{device.detail.ip}</p>
                                <p><label>{t('device.gateway')}</label>{device.detail.gateway}</p>
                                <p><label>{t('device.mac')}</label>{device.detail.mac}</p>
                                <p><label>{t('device.channel')}</label>{device.detail.internetChannel}</p>
                            </>}

                            {!device.detail &&
                            <>
                                <p>
                                    <label>{localeService.isRussian() ? 'Виртуальное' : 'Virtual'}</label>{t(device.isVirtual + '')}
                                </p>
                                {device.networkConfig &&
                                <p><label>Host</label>{JSON.parse(device.networkConfig).host}</p>}
                                {device.networkConfig &&
                                <p><label>Port</label>{JSON.parse(device.networkConfig).port}</p>}
                            </>
                            }

                            <p className={'w-100'}>
                                <label>{localeService.isRussian() ? 'Координаты' : 'Coordinates'}</label>
                                {device.lat && (device.lat + ', ' + device.lng)}
                                {!device.lat && (localeService.isRussian() ? 'Не заданы' : 'Not set')}
                                <button className={'svg-btn sm default ml1'}
                                        onClick={() => {
                                            openAddCoordinates();
                                        }}
                                        title={localeService.isRussian() ? 'Задать координаты' : 'Set Coordinates'}>
                                    <ReactSVG src={svgEdit}/>
                                </button>
                            </p>
                            <p className={'w-100'}>
                                <label>{localeService.isRussian() ? 'Временная зона' : 'Time Zone'}</label>
                                {device.timeZone}
                                <SetDeviceTimeZone deviceId={device.id} onChange={() => loadDevice()}/>
                            </p>
                        </div>

                        {variableList &&
                        <table className={'variable-list'} cellPadding={0} cellSpacing={0}>
                            <thead>
                            <tr>
                                <th width="40px" style={{textAlign: 'center'}}>N</th>
                                <th>{t('name')}</th>
                                <th width="80px" style={{textAlign: 'center'}}>{t('is_active')}</th>
                                {!device.isVirtual &&
                                <th width="120px" style={{textAlign: 'left'}}>{t('timeout')}</th>}
                                {device.isVirtual &&
                                <th width="120px" style={{textAlign: 'left'}}>{t('generator')}</th>}
                                <th width="30%">
                                    {t('capacity')}
                                </th>

                                {/*столбец для кнопок действий*/}
                                <th width="0">
                                    {device && device.isVirtual &&
                                    <AddVirtualVariableToExistsDevice deviceId={device.id} onSuccess={() => loadDevice()}/>
                                    }
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {variableList.map((deviceVar, i) => {
                                return <tr key={i} className={'editable-row'}>
                                    <td style={{textAlign: 'center'}}>{i + 1}</td>
                                    <td>{deviceVar.name}</td>
                                    <td style={{textAlign: 'center'}}>
                                        {t(deviceVar.isActive + '')}
                                    </td>
                                    {!device.isVirtual && <td> {(deviceVar.periodUpdate / 1000)} </td>}
                                    {device.isVirtual && <td title={deviceVar.virtualConfig.config}>
                                        {deviceVar.virtualConfig.type && deviceConstants.generatorList.find(el => el.value === deviceVar.virtualConfig.type).name}
                                    </td>}
                                    <td>{deviceVar.capacity && deviceVar.capacity.name}</td>

                                    <td className={'editable-col'}>
                                        {canEdit && <button className={'btn default edit-variable-btn'}
                                                            onClick={() => setEditableVariable({
                                                                ...deviceVar,
                                                                deviceId: device.id,
                                                                protocol: device.deviceProtocol
                                                            })}>
                                            <ReactSVG src={svgEdit}/>
                                        </button>}
                                    </td>
                                </tr>;
                            })
                            }
                            </tbody>
                        </table>
                        }
                    </div>

                    {/*Окно для переименования/удаления виртуальной переменной*/}
                    {device.isVirtual &&
                    <Modal isOpen={editableVariable !== null} isClosable={false} footer={false}
                           title={localeService.isRussian() ? 'Редактирование переменной устройства' : 'Edit Device Variable'}>
                        <EditDeviceVariable onCancel={(needToReload) => {
                            setEditableVariable(null);
                            if (needToReload) {
                                loadDevice();
                            }
                        }
                        }
                                            device={device}
                                            editableVariable={editableVariable}/>
                    </Modal>
                    }

                    {/*Окно для редактирования MQTT переменной*/}
                    {device.deviceProtocol.name === 'MQTT' &&

                    <Modal isOpen={editableVariable !== null} isClosable={false} footer={false}
                           title={localeService.isRussian() ? 'Редактирование переменной устройства' : 'Edit Device Variable'}>
                        <EditDeviceVariable onCancel={(needToReload) => {
                            setEditableVariable(null);
                            if (needToReload) {
                                loadDevice();
                            }
                        }}
                                            device={device}
                                            variableList={variableList}
                                            editableVariable={editableVariable}/>
                    </Modal>
                    }


                    {/*Переименование устройства*/}
                    <Modal isOpen={isRenameOpened} isClosable={false} footer={false} width={'600px'}
                           title={localeService.isRussian() ? 'Переименовать устройство' : 'Rename Device'}>
                        <form>
                            <InputText label={t('name')} value={device.newName}
                                       onChange={text => setDevice({...device, newName: text})}/>
                        </form>
                        <div className={'d-flex justify-content-center'}>
                            <button className={'btn btn-outline-success'} onClick={() => {
                                deviceService.renameDevice(device.id, device.newName).then(
                                    response => {
                                        if (response.status === 'SUCCESS') {
                                            setRenameOpened(false);
                                            loadDevice();
                                        }
                                    }
                                ).finally(() => setRenameOpened(false));
                            }}>{t('apply')}</button>
                            <button className={'btn btn-outline-primary ml2 danger'}
                                    onClick={() => setRenameOpened(false)}>{t('cancel')}</button>
                        </div>
                    </Modal>


                    {/*Добавление координат*/}
                    <Modal isOpen={isAddCoordinatesOpened} isClosable={false} footer={false}
                           title={localeService.isRussian() ? 'Добавление координат' : 'Adding coordinates'}>
                        <div>
                            <YMaps>
                                <Map
                                    onClick={(e) => setCoords(e.get('coords'))}
                                    style={
                                        {
                                            width: '100%',
                                            height: '300px',
                                        }
                                    }
                                    state={
                                        {center: [coords[0], coords[1]],//device.lat ? [device.lat, device.lng] : [55.751574, 37.573856],
                                        zoom: 9,
                                        controls: [],
                                        }
                                    }>
                                    <Placemark
                                        geometry={[coords[0], coords[1]]/*device ? [device.lat, device.lng] : ''*/}
                                        options={{
                                            iconColor: 'red',
                                        }}/>
                                </Map>
                            </YMaps>
                        </div>
                        <div>
                            <form>
                                <InputText label={'Координата по X'} value={coords[1]}
                                           onChange={text => {
                                               setCoords([coords[0], text]);
                                           }
                                           }/>
                            </form>
                            <form>
                                <InputText label={'Координата по Y'} value={coords[0]}
                                           onChange={text => {
                                               setCoords([text, coords[1]]);
                                           }}/>
                            </form>
                            <div className={'d-flex justify-content-center'}>
                                <button className={'btn btn-outline-success'}
                                        onClick={() => {
                                            deviceService.deviceLocation(deviceId, coords[0], coords[1]).then(
                                                response => {
                                                    if (response.status === 'SUCCESS') {
                                                        setAddCoordinatesOpened(false);
                                                        loadDevice();
                                                    }
                                                }
                                            ).catch((e) => alert(e));
                                        }}>{t('apply')}</button>
                                <button className={'btn btn-outline-danger'}
                                        onClick={() => {
                                            returnCoords();
                                        }}>{t('cancel')}</button>
                            </div>
                        </div>
                    </Modal>


                    {/*Удаление устройства*/}
                    <Modal isOpen={isDeleteOpened} isClosable={false} footer={false} width={'600px'}
                           title={localeService.isRussian() ? 'Удаление устройства' : 'Device Deleting'}>
                        <h5>{localeService.isRussian() ? 'Вы уверены, что хотите удалить устройство' : 'Are you sure, you want to delete Device'}{' "' + device.name + '"?'}</h5>
                        <div className={'d-flex justify-content-center'}>
                            <button className={'btn btn-outline-danger mr2'} onClick={() => {
                                deviceService.deleteDevice(deviceId).then(
                                    response => {
                                        if (response.status === 'SUCCESS') {
                                            setDeleteOpened(false);
                                            history.goBack();
                                        }
                                    }, error => {
                                        setDeleteOpened(false);
                                        setError('Cannot Delete Device: ' + JSON.stringify(error));
                                    }
                                ).finally(() => setDeleteOpened(false));
                            }}>{t('yes')}</button>
                            <button className={'btn btn-outline-primary default'}
                                    onClick={() => setDeleteOpened(false)}>{t('cancel')}</button>
                        </div>
                    </Modal>

                </>
                }
                <AlertError message={error} isOpen={error !== ''}
                            onCancel={() => setError('')}/>
            </div>

            {isFetchingVariables &&
            <Loader
                waitText={localeService.isRussian() ? 'Загрузка переменных' : 'Loading data source variables.'}/>}

            {isFetchingDevice &&
            <Loader waitText={localeService.isRussian() ? 'Загрузка устройства' : 'Loading Device.'}/>}
        </>
    );
}

Device.propTypes = {
    deviceId: PropTypes.number
    // device: PropTypes.object.isRequired
};


export default Device;

