import React, {useState} from 'react';
import FileInput from '../../components/FileInput';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {localeService} from '../../services/locale.service';
import SetImportConfiguration from './SetImportConfiguration';
import Modal from '../../components/Modal';
import {importService} from '../../services';
import Loader from '../../components/Loader';
import AlertError from '../../components/AlertError';

function UploadFile({onUpload}) {
    const {t} = useTranslation();
    const [isOpenFileProps, setOpenFileProps] = useState(false);
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [isCheckingFile, setCheckingFile] = useState(false);

    const onInputFile = (fileInput) => {
        setFile(fileInput);
        setOpenFileProps(true);
        setError('');
    };

    const checkFile = (importConf) => {
        setOpenFileProps(false);
        if (file.name.toLowerCase().endsWith('.csv')) {
            setCheckingFile(true);
            importService.uploadCSVFile(file, importConf.delimiter, importConf.datetimeFormat).then(
                results => {
                    setCheckingFile(false);
                    onUpload({preparseredData: results, importConf: importConf});
                }, error => {
                    setCheckingFile(false);
                    setError(error);
                    setFile(null);
                }
            );
        }
        if (file.name.toLowerCase().endsWith('.xml')) {
            setCheckingFile(true);
            importService.uploadXMLFile(file).then(
                results => {
                    setCheckingFile(false);
                    onUpload({preparseredData: results, importConf: importConf});
                }, error => {
                    setCheckingFile(false);
                    setError(error);
                    setFile(null);
                }
            );
        }
        if (file.name.toLowerCase().endsWith('.xls') || file.name.toLowerCase().endsWith('.xlsx')) {
            setCheckingFile(true);
            importService.uploadEXCELFile(file, importConf.datetimeFormat).then(
                results => {
                    setCheckingFile(false);
                    onUpload({preparseredData: results, importConf: importConf});
                }, error => {
                    setCheckingFile(false);
                    setError(error);
                    setFile(null);
                }
            );
        }
    };

    return (<div>
        <div className={'container-700'}>
            {/*Вывод ошибок и предупреждений*/}
            {error &&
            <AlertError onCancel={() => setError('')} isOpen={error !== ''} message={error}/>
            }

            {/*Лоадер при парсинге файла*/}
            {isCheckingFile &&
            <Loader
                waitText={localeService.isRussian() ? 'Идет проверка кофигурации импорта...' : 'Import configuration is being checked...'}/>
            }

            {/*Загрузка файла*/}
            {!isCheckingFile &&
            <FileInput name="uploadedFile"
                       label={localeService.isRussian() ? 'Для начала импорта загрузите файл и задайте настройки импорта' : 'To start import select file and set import configuration.'}
                       buttonText={t('choose_file') + ' ...'}
                       accept=".csv,.xml,.xls,.xlsx"
                       onChange={(fileInput) => onInputFile(fileInput)}/>}
        </div>

        {/*Модальное окно для настройки парсера*/}
        <Modal isOpen={isOpenFileProps} onCancel={() => setOpenFileProps(false)} footer={false}
               title={localeService.isRussian() ? 'Настройки импорта' : 'Import Properties'}>
            {file && <SetImportConfiguration onSetProps={(fileProps) => checkFile(fileProps)} fileName={file.name}/>}
        </Modal>


    </div>);
}

UploadFile.propTypes = {
    onUpload: PropTypes.func
};

export default UploadFile;