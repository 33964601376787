import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants/index';
import {connect} from 'react-redux';
import {
      XAxis, YAxis, CartesianGrid, Tooltip, Legend, ScatterChart, ZAxis, Scatter
} from 'recharts';
import {dashboardService} from '../../../services';
import moment from 'moment';
import { withSize } from 'react-sizeme';
import CustomTooltip from '../../../components/CustomTooltip';
import CustomLegend from '../../../components/CustomLegend';


function DotsCloudChartView({widgetProps, datetimeFilter, size}){

    const [widgetData, setWidgetData] = useState(widgetProps);

    useEffect(() => {
        dashboardService.loadWidgetDataTimeGrouped(widgetData.dataSources, datetimeFilter, size.width).then(
            dataSources => {
                setWidgetData({...widgetData, dataSources: dataSources});
            }
        );
    },[datetimeFilter]);

    return(
        <div>
            <div className={'widget-management-console'}>

            </div>
            <ScatterChart width={size.width}
                          height={(100 * widgetProps.h) - 18}>
                <CartesianGrid />
                <Tooltip
                    content={(e) => e.active && <CustomTooltip label={e.label} widgetData={widgetData}/>}
                    cursor={{stroke: '@default-color', strokeWidth: 2, opacity: '0.7'}}/>
                <Legend align={'center'} content={() => <CustomLegend widgetDataSources={widgetData.dataSources} functionDataSources={[]}/>} />
                <XAxis
                    dataKey="time_upload"
                    name={'datetime'}
                    tickFormatter={(number) => {
                        return moment.unix(number).format(moment.duration(datetimeFilter.finishDate.diff(datetimeFilter.startDate)).asHours() > 24 ? 'DD/MM HH:mm' : 'HH:mm:ss');
                    }}
                    type="number"
                    tickCount={(size.width / 60) + 1}
                    domain={[moment(datetimeFilter.startDate).unix().valueOf(), moment(datetimeFilter.finishDate).unix().valueOf()]}
                />
                <YAxis type="number" dataKey={'value'} name="value"/>
                <ZAxis range={[100]}/>
                {widgetData.dataSources && widgetData.dataSources.map((source, i) => {
                    const param = source.parameters;
                    return (<Scatter isAnimationActive={false} data={source.data} fill={param.color} name={param.dataSourceName} shape="circle" key={'scatter' + i}/>);
                })
                }
            </ScatterChart>
        </div>
    );

}

DotsCloudChartView.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

export default withSize()(connect(mapStateToProps, null)(DotsCloudChartView));
