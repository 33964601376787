import React from 'react';
import SearchDeviceByGUID from './SearchDeviceByGUID';
import {localeService} from '../../services/locale.service';
import {useTranslation} from 'react-i18next';
import CheckBox from '../../components/CheckBox';
import SelectCapacityTree from '../../components/SelectCapacityTree';
import {capacityService} from '../../services/capacity.service';
import InputText from '../../components/InputText';
import {deviceService} from '../../services';
import AlertError from '../../components/AlertError';
import AlertSuccess from '../../components/AlertSuccess';
import Loader from '../../components/Loader';

function AddMQTTDevice() {
    const {t} = useTranslation();
    const [foundedDevice, setFoundedDevice] = React.useState(null);
    const [capacityTree, setCapacityTree] = React.useState([]);
    const [isFetching, setFetching] = React.useState(false);
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState('');

    React.useEffect(() => {
        capacityService.getCapacityTree()
            .then((response) => {
                setCapacityTree(response.list);
            });
    }, []);

    const addDevice = () => {
        const addDevice = {
            id: foundedDevice.id,
            name: foundedDevice.name,
            active: true,
            relations: []
        };
        foundedDevice.variables.map((deviceVar) => {
            if (deviceVar.capacity) {
                const variable = {
                    variableId: deviceVar.id,
                    capacityID: deviceVar.capacity.id,
                    active: deviceVar.active,
                    timeout: deviceVar.timeout
                };
                addDevice.relations.push(variable);
            }
        });
        console.log(addDevice);

        setFetching(true);
        deviceService.addRealDevice(addDevice).then(
            result => {
                console.log(result);
                setFoundedDevice(null);
                setSuccess(localeService.isRussian() ? 'Устройство успешно добавлено!' : 'The device is successdully added!');
            }
            ,error => {
                setError(error.toString());
            }
        ).finally(() => {
            setFetching(false);
        });
    };

    return <div className={'container-700'}>
        <SearchDeviceByGUID onFindDevice={device => setFoundedDevice(device)}/>
        <AlertSuccess isOpen={success !== ''} message={success} onCancel={() => setSuccess('')}/>
        {isFetching && <Loader waitText={localeService.isRussian() ? 'Подождите, идет добавление устройства...' : 'Adding device...'}/>}
        {foundedDevice && !isFetching &&
        <div>
            <form>
                {foundedDevice.deviceDetail &&
                <div className={'device-info'}>
                        <span
                            className={'title'}>{localeService.isRussian() ? 'Информация об устройстве' : 'Device Details'}</span>
                    <p><label>GUID</label>{foundedDevice.guid}</p>
                    <p><label>Name</label>{foundedDevice.name}</p>
                    <p><label>Brand</label>{foundedDevice.deviceDetail.brand}</p>
                    <p><label>Model</label>{foundedDevice.deviceDetail.model}</p>
                    <p><label>Serial Number</label>{foundedDevice.deviceDetail.serialNumber}</p>
                    <p><label>IP</label>{foundedDevice.deviceDetail.ip}</p>
                    <p><label>Gateway</label>{foundedDevice.deviceDetail.gateway}</p>
                    <p><label>MAC</label>{foundedDevice.deviceDetail.mac}</p>
                    <p><label>Internet Channel</label>{foundedDevice.deviceDetail.internetChannel}</p>
                </div>

                }
                <InputText required={true} label={localeService.isRussian() ? 'Имя устройства' : 'Device name'}
                           placeholder={localeService.isRussian() ? 'Введите имя устройства...' : 'Type device name'}
                           value={foundedDevice.name}
                           onChange={(text) => {
                               setFoundedDevice({...foundedDevice, name: text});
                           }}/>
                <div className="form-group">
                    <table className={'variable-list'} cellPadding={0} cellSpacing={0}>
                        <thead>
                        <tr>
                            <th width="40px" style={{textAlign: 'center'}}>N</th>
                            <th>{t('name')}</th>
                            <th width="80px" style={{textAlign: 'center'}}>{t('is_active')}</th>
                            <th width="120px" style={{textAlign: 'center'}}>{t('timeout')}</th>
                            <th width="40%">
                                {t('capacity')}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            foundedDevice.variables.map((deviceVar, i) => {
                                return <tr key={i} style={{height: '3rem'}}>
                                    <td style={{textAlign: 'center'}}>{i + 1}</td>
                                    <td>{deviceVar.name}</td>
                                    <td style={{textAlign: 'center'}}>
                                        <CheckBox checked={deviceVar.active}
                                                  inTable={true}
                                                  onChange={() => {
                                                      const device = foundedDevice;
                                                      setFoundedDevice({
                                                          ...device,
                                                          variables: device.variables.map(v => {
                                                                  if (v.name === deviceVar.name) {
                                                                      return {...deviceVar, active: !deviceVar.active};
                                                                  } else {
                                                                      return v;
                                                                  }
                                                              }
                                                          )
                                                      });
                                                  }}/>
                                    </td>
                                    <td><InputText
                                        inTable={true}
                                        isDecimal={true}
                                        onChange={(text) => {
                                            setFoundedDevice({
                                                ...foundedDevice,
                                                variables: foundedDevice.variables.map(v => {
                                                        if (v.name === deviceVar.name) {
                                                            return {...deviceVar, timeout: text * 1000};
                                                        } else {
                                                            return v;
                                                        }
                                                    }
                                                )
                                            });
                                        }}
                                        value={deviceVar.timeout / 1000}/>
                                    </td>
                                    <td>
                                        {capacityTree &&
                                        <SelectCapacityTree capacityTree={capacityTree}
                                                            capacity={deviceVar.capacity}
                                                            width={300}
                                                            inTable={true}
                                                            onSelect={(capacity) => {
                                                                setFoundedDevice({
                                                                    ...foundedDevice,
                                                                    variables: foundedDevice.variables.map(v => {
                                                                            if (v.name === deviceVar.name) {
                                                                                return {...deviceVar, capacity: capacity};
                                                                            } else {
                                                                                return v;
                                                                            }
                                                                        }
                                                                    )
                                                                });
                                                            }}/>}
                                    </td>
                                </tr>;
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <AlertError onCancel={() => setError('')} isOpen={error !== ''} message={error}/>
                <div className="text-right">
                    <button
                        className={'btn btn-outline-success'}
                        onClick={() => addDevice()}>
                        {t('add_device')}
                    </button>
                </div>
            </form>
        </div>
        }
    </div>
        ;

}

export default AddMQTTDevice;