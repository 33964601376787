import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {RadialBar, RadialBarChart} from 'recharts';
import {dashboardConstants} from '../../../constants';
import {connect} from 'react-redux';
import {withSize} from 'react-sizeme';
import {dashboardService} from '../../../services';
import Loader from '../../../components/Loader';
import * as numeral from 'numeral';
import {localeService} from '../../../services/locale.service';
import Popover from '../../../components/Popover';
import AddAnalyticParametersToGroupChart from '../../../components/AddAnalyticParametersToGroupChart';
import {analyticService} from '../../../services/analyticService';
import Modal from '../../../components/Modal';
import {ReactSVG} from 'react-svg';
import svgSettings from '../../../sources/images/interface/settings.svg';
import {useTranslation} from 'react-i18next';

function FunctionWidgetView({widgetProps, datetimeFilter, size, analyticFunctionList}) {
    const {t} = useTranslation();

    const widgetSize = {width: size.width, height: (100 * widgetProps.h) - 40};

    const initialValue = [{
        name: 'bgd',
        uv: 1000,
        pv: 1000,
        fill: 'white'
    }, {
        name: widgetProps ? widgetProps.dataSourceName : widgetProps.name,
        uv: 0,
        pv: 1000,
        fill: widgetProps.parameters.groupColor || '#216ba5'
    }];
    const [functionResult, setFunctionResult] = useState({chartData: initialValue});
    const [isFetching, setFetching] = useState(false);
    const [warning, setWarning] = useState('');
    const [isOpenAnalyticParameters, setOpenAnalyticParameters] = useState(false);

    let mainDataSources = [];
    widgetProps.dataSources.map(ds => {
        if (ds.parameters.type === 'MAIN_DATASOURCE') {
            let mainDataSource = ds;
            mainDataSource.additionalDataSources = [];
            widgetProps.dataSources.map(addDS => {
                if (addDS.parameters.parentDataSource && addDS.parameters.parentDataSource === parseInt(mainDataSource.sourceID)) {
                    mainDataSource.additionalDataSources.push(addDS);
                }
            });
            mainDataSources.push(mainDataSource);
        }
    });

    const [groupFunctionParam, setGroupFunctionParams] = useState(widgetProps.parameters.groupFunction || {
        functionCode: null
    });

    useEffect(() => {
        if (widgetProps.parameters.groupFunction && widgetProps.parameters.groupFunction.functionCode.startsWith('DEMAND')) {
            const analyticFunc = analyticFunctionList.find(el => el.code === groupFunctionParam.functionCode);
            const args = analyticService.initAnalyticFunctionParameters(analyticFunc, datetimeFilter);

            const dataSourcesArgs = mainDataSources.map(ds => {
                return {
                    dsId: ds.id,
                    dsName: ds.parameters.dataSourceName,
                    args: args.filter(arg => (arg.count === -1 || (['discharge_start_hour', 'discharge_duration', 'discharge_value'].findIndex(el => el === arg.name) >= 0))),
                    additionalDataSources: ds.additionalDataSources
                };
            });

            const groupFunc = {
                ...analyticFunc,
                ...groupFunctionParam,
                args: args.filter(el => ['discharge_start_hour', 'discharge_duration', 'discharge_value'].findIndex(name => name === el.name) < 0),
                dataSourcesArgs: dataSourcesArgs
            };

            setGroupFunctionParams(groupFunc);
        } else {
            reloadFunctionData();
        }
    }, [datetimeFilter]);

    const reloadFunctionData = () => {
        setFetching(true);
        Promise.all(
            mainDataSources.map(ds => {
                return dashboardService.getAggregationFunctionData(ds.id, datetimeFilter.startDate, datetimeFilter.finishDate,
                    groupFunctionParam.functionCode, groupFunctionParam.functionParams, null)
                    .then(
                        result => {
                            return result.list;
                        }, () => {
                            return [];
                        }
                    );
            })
        ).then(
            functionResults => {
                let result = 0;
                let errorNumber = 0;
                functionResults.map(el => {
                    if (el.length > 0 && el[0].value) {
                        result += el[0].value;
                    } else {
                        errorNumber++;
                    }
                });
                if (errorNumber > 0) {
                    setWarning(localeService.isRussian() ? 'Не все данные успешно рассчитаны' : 'Not all data is successfully calculated');
                }
                applyResult(result);
            }
        ).finally(
            () => {
                setFetching(false);
            }
        );
    };

    const calculateAnalyticFunctions = () => {
        setFetching(true);
        Promise.all(
            mainDataSources.map(ds => {
                const analyticFunction = {...groupFunctionParam};
                const dsArgs = groupFunctionParam.dataSourcesArgs.filter(el => el.dsId === ds.id);
                if (dsArgs.length === 1) {
                    analyticFunction.args = [...analyticFunction.args, ...dsArgs[0].args];
                }
                return analyticService.calculateAnalyticFunction(ds.id, analyticFunction, datetimeFilter)
                    .then(
                        result => {
                            return result;
                        }, () => {
                            return [];
                        }
                    );
            })
        ).then(
            functionResults => {
                let result = 0;
                let errorNumber = 0;
                functionResults.map(el => {
                    if (el.length > 0 && el[0].value) {
                        result += el[0].value;
                    } else {
                        errorNumber++;
                    }
                });
                if (errorNumber > 0) {
                    setWarning(localeService.isRussian() ? 'Не все данные успешно рассчитаны' : 'Not all data is successfully calculated');
                }
                applyResult(result);
            }
        ).finally(
            () => {
                setFetching(false);
            }
        );
    };

    const applyResult = (result) => {
        const name = widgetProps.parameters.groupDataSourceName;
        const uv = (result !== undefined && result !== 'false' && result !== 'true') ? result : (result === 'false' ? 0 : 1000);
        const pv = (result !== undefined && result !== 'false' && result !== 'true') ? Math.pow(10, result.toFixed().toString().length) : 1000;
        setFunctionResult({
            chartData: [
                {
                    name: 'bgd',
                    uv: pv,
                    pv: pv,
                    fill: 'white'
                }, {
                    name: name,
                    uv: uv,
                    pv: pv,
                    fill: widgetProps.parameters.groupColor
                }],
            value: result
        });
    };

    return <div>
        {widgetProps.parameters.groupFunction && widgetProps.parameters.groupFunction.functionCode.startsWith('DEMAND') &&
        <div className={'widget-management-console'}>
            <button type="button"
                    className={'btn btn-outline-primary default round-btn'}
                    onClick={() => setOpenAnalyticParameters(true)}>
                <ReactSVG src={svgSettings}/>
            </button>
        </div>
        }
        {isFetching && <Loader/>}
        {!isFetching &&
        <RadialBarChart width={size.width}
                        height={widgetSize.height} innerRadius="60%"
                        outerRadius="100%" barSize={30} data={functionResult.chartData} startAngle={220}
                        endAngle={-40}>
            <RadialBar minAngle={0} background clockWise={true} dataKey="uv"
                       label={{position: 'insideStart', fill: '#fff'}}/>
        </RadialBarChart>}
        {!isFetching && <div className={'function-value'} style={{color: widgetProps.parameters.groupColor}}>
            {functionResult.value === undefined ? '?' : (functionResult.value !== 'true' && functionResult.value !== 'false') ? numeral(functionResult.value).format('0.0 a') : functionResult.value}
        </div>}

        {warning !== '' &&
        <Popover type={'WARNING'} position={'top-left'}>
            <p>{warning}</p>
        </Popover>
        }
        <Modal isOpen={isOpenAnalyticParameters} onCancel={() => setOpenAnalyticParameters(false)} isClosable={false}
               title={localeService.isRussian() ? 'Настройка дополнительных параметров для расчетов' : 'Function Configurations'}
               footer={false}>
            <form>
                {widgetProps.parameters.groupFunction && widgetProps.parameters.groupFunction.functionCode.startsWith('DEMAND') && groupFunctionParam.args &&
                <AddAnalyticParametersToGroupChart datetimeFilter={datetimeFilter} analyticFunction={groupFunctionParam}
                                                   dataSources={mainDataSources}
                                                   onChangeParam={funcParams => {
                                                       setGroupFunctionParams(funcParams);
                                                       // setSelectedFunction({...func});
                                                   }}/>
                }
                <div className={'row'}>
                    <button onClick={(e) => {
                        e.preventDefault();
                        setOpenAnalyticParameters(false);
                        calculateAnalyticFunctions();
                    }}
                            className={'btn btn-outline-success'}>
                        {t('apply')}
                    </button>
                    <button onClick={() => setOpenAnalyticParameters(false)}
                                     className={'btn btn-outline-danger'}>
                    {t('cancel')}
                </button>
                </div>
            </form>
        </Modal>
    </div>;
}


FunctionWidgetView.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    datetimeFilter: PropTypes.object,
    analyticFunctionList: PropTypes.array
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    return {datetimeFilter, analyticFunctionList};
};

export default withSize()(connect(mapStateToProps, null)(FunctionWidgetView));