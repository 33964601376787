import {dashboardService} from '../../../../services';
import moment from 'moment';

export function getDataSourceByType(dsList, type) {
    let dataSource = null;
    dsList.map((ds) => {
        if (ds.parameters.type === type) {
            dataSource = ds;
        }
    });
    return dataSource;
}

export function initWidgetDataSources(dsList, dateTimeFilter) {
    let dataSources = [];

    //загружаем данные для основного источника данных с группировкой по времени 30мин
    let mainDS = getDataSourceByType(dsList, 'MAIN_DATA_SOURCE');
    return dashboardService.loadWidgetData([mainDS], {...dateTimeFilter, timeGroup: '30m'}).then(
        results => {
            dataSources.push({type: 'MAIN_DATA_SOURCE', props: results[0]});

            // //загружаем данные для всех остальных И.Д. с группировкой по времени 1час
            // return dashboardService.loadWidgetData(dsList.filter(el => el.parameters.type !== 'MAIN_DATA_SOURCE'), {...dateTimeFilter, timeGroup: '60m'}).then(
            //     results => {
            //         results.map(res => {
            //             if (res.parameters.type === 'SNE_DATA_SOURCE') {
            //                 dataSources.push({type: 'SNE_DATA_SOURCE', props: res});
            //             }
            //             if (res.parameters.type === 'SNE_DATA_SOURCE_1') {
            //                 dataSources.push({type: 'SNE_DATA_SOURCE_1', props: res});
            //             }
            //             if (res.parameters.type === 'SNE_DATA_SOURCE_2') {
            //                 dataSources.push({type: 'SNE_DATA_SOURCE_2', props: res});
            //             }
            //             if (res.parameters.type === 'VIE_DATA_SOURCE') {
            //                 dataSources.push({type: 'VIE_DATA_SOURCE', props: res});
            //             }
            //             if (res.parameters.type === 'OGO_DATA_SOURCE') {
            //                 dataSources.push({type: 'OGO_DATA_SOURCE', props: res});
            //             }
            //             if (res.parameters.type === 'OGO_DATA_SOURCE_1') {
            //                 dataSources.push({type: 'OGO_DATA_SOURCE_1', props: res});
            //             }
            //             if (res.parameters.type === 'OGO_DATA_SOURCE_2') {
            //                 dataSources.push({type: 'OGO_DATA_SOURCE_2', props: res});
            //             }
            //         });
            //
            return dataSources;
            //
            //         // return Promise.all(
            //         //     dataSources.map(ds => {
            //         //         return widgetService.
            //         //     })
            //         // );
            //     }
            // );

        }
    );

}


export function calculateDictOfTimeForHolePeriod(dot, dateTimeFilter) {
    let data = [];
    const hours = dot.map(d => d.split(','));
    let date = moment(dateTimeFilter.startDate);
    while (date <= dateTimeFilter.finishDate) {
        hours.map(hh => {
                data.push(date.format('YYYY-MM-DD ') + hh[0] + ',' + date.format('YYYY-MM-DD ') + hh[1]);
            }
        );
        date.add(1, 'days');
    }
    return data;
}

export function getDictOfTime(data) {
    let dictOfTime = [];
    let moreThanNull = false;
    data.map((d, i) => {
        if (d.value > 0 && !moreThanNull) {
            moreThanNull = true;
            dictOfTime.push([moment.unix(d.time_upload).format('YYYY-MM-DD HH:mm:SS')]);
        }
        if (d.value === 0 && moreThanNull) {
            moreThanNull = false;
            dictOfTime[dictOfTime.length - 1].push(moment.unix(d.time_upload).format('YYYY-MM-DD HH:mm:SS'));
        }
        if (i === data.length - 1 && moreThanNull) {
            dictOfTime[dictOfTime.length - 1].push(moment.unix(d.time_upload).format('YYYY-MM-DD HH:mm:SS'));
        }
    });
    return dictOfTime.map(dt => dt.join(','));
}

export function calculateTimeSeries(dateTimeFilter, dot, value) {
    console.log('calculateTimeSeries', dot, value);
    const dictOfTime = calculateDictOfTimeForHolePeriod(dot, dateTimeFilter);
    let data = [{time_upload: moment(dateTimeFilter.startDate).unix(), value: 0}];

    dictOfTime.map(dot => {
        let d = dot.split(',');
        data.push({time_upload: moment(d[0]).unix(), value: value});
        data.push({time_upload: moment(d[1]).unix(), value: 0});
    });

    data.push({time_upload: moment(dateTimeFilter.finishDate).unix(), value: 0});
    return data;
}

export function initSNE() {
    return [
        {value: false, type: 'BOOLEAN', argName: 'mode_energy_storage', name: 'Включение/выключение СНЭ'},
        {value: '200', type: 'FLOAT', argName: 'power_return_energy_storage', name: 'Мощность при разгрузке'},
        {value: [], type: 'DICT_OF_TIME', argName: 'time_return_energy_storage', name: 'Продолжительность разгрузки'},
        {value: '200', type: 'FLOAT', argName: 'power_charging_energy_storage', name: 'Мощность восполнения'},
        {value: [], type: 'DICT_OF_TIME', argName: 'time_charging_energy_storage1', name: 'Время восполнения 1'},
        {value: [], type: 'DICT_OF_TIME', argName: 'time_charging_energy_storage2', name: 'Время восполнения 2'}
    ];
}

export function initVIE() {
    return [
        {value: false, type: 'BOOLEAN', argName: 'mode_res', name: 'Включение/выключение ВИЭ'},
        {value: '200', type: 'FLOAT', argName: 'power_return_res', name: 'Мощность при разгрузке'},
        {value: [], type: 'DICT_OF_TIME', argName: 'time_return_res', name: 'Продолжительность разгрузки'}
    ];
}

export function initOGO() {
    return [
        {value: false, type: 'BOOLEAN', argName: 'mode_generating_equipment', name: 'Включение/выключение УН'},
        {value: '200', type: 'FLOAT', argName: 'power_return_generating_equipment', name: 'Мощность снижения УН'},
        {value: [], type: 'DICT_OF_TIME', argName: 'time_return_generating_equipment', name: 'Время снижения'},
        {value: '200', type: 'FLOAT', argName: 'power_charging_generating_equipment1', name: 'Мощность восполнения 1'},
        {value: [], type: 'DICT_OF_TIME', argName: 'time_charging_generating_equipment1', name: 'Время восполнения 1'},
        {value: '200', type: 'FLOAT', argName: 'power_charging_generating_equipment2', name: 'Мощность восполнения 2'},
        {value: [], type: 'DICT_OF_TIME', argName: 'time_charging_generating_equipment2', name: 'Время восполнения 2'}
    ];
}


export function buildArgs(sne, vie, ogo, dateTimeFilter, timeOffset) {
    let args = [];
    if (sne[0].value) {
        args.push({
            name: sne[0].argName,
            values: ['true']
        });
        if (sne[2].value.length > 0) {
            args.push({
                name: sne[1].argName,
                values: [sne[1].value]
            });
            args.push({
                name: sne[2].argName,
                values: addDateToTimeArray(sne[2].value, dateTimeFilter, timeOffset)
            });
        }
        if (sne[4].value.length > 0 || sne[5].value.length > 0) {
            args.push({
                name: sne[3].argName,
                values: [sne[3].value]
            });
            if (sne[4].value.length > 0) {
                args.push({
                    name: sne[4].argName,
                    values: addDateToTimeArray(sne[4].value, dateTimeFilter, timeOffset)
                });
            }
            if (sne[5].value.length > 0) {
                args.push({
                    name: sne[5].argName,
                    values: addDateToTimeArray(sne[5].value, dateTimeFilter, timeOffset)
                });
            }
        }

    }

    if (ogo[0].value) {
        args.push({
            name: ogo[0].argName,
            values: ['true']
        });
        if (ogo[2].value.length > 0) {
            args.push({
                name: ogo[1].argName,
                values: [ogo[1].value]
            });
            args.push({
                name: ogo[2].argName,
                values: addDateToTimeArray(ogo[2].value, dateTimeFilter, timeOffset)
            });
        }
        if (ogo[4].value.length > 0) {
            args.push({
                name: ogo[3].argName,
                values: [ogo[3].value]
            });
            args.push({
                name: ogo[4].argName,
                values: addDateToTimeArray(ogo[4].value, dateTimeFilter, timeOffset)
            });
        }
        if (ogo[6].value.length > 0) {
            args.push({
                name: ogo[5].argName,
                values: [ogo[5].value]
            });
            args.push({
                name: ogo[6].argName,
                values: addDateToTimeArray(ogo[6].value, dateTimeFilter, timeOffset)
            });
        }
    }

    if (vie[0].value) {
        args.push({
            name: vie[0].argName,
            values: ['true']
        });
        if (vie[2].value.length > 0) {
            args.push({
                name: vie[1].argName,
                values: [vie[1].value]
            });
            args.push({
                name: vie[2].argName,
                values: addDateToTimeArray(vie[2].value, dateTimeFilter, timeOffset)
            });
        }
    }
    return args;
}

function addDateToTimeArray(values, dateTimeFilter, timeOffset) {
    let date = moment(dateTimeFilter.startDate);
    let newValues = [];
    while (date <= dateTimeFilter.finishDate) {
        values.map(valString => {
            const splitValues = valString.split(',');
            const start = moment(date.format('yyyy-MM-DD ') + splitValues[0]).subtract(timeOffset, 'minutes');
            const end = moment(date.format('yyyy-MM-DD ') + splitValues[1]).subtract(timeOffset, 'minutes');
            newValues.push(start.format('yyyy-MM-DD HH:mm:ss') + ',' + end.format('yyyy-MM-DD HH:mm:ss'));
        });
        date.add(1, 'days');
    }
    console.log(newValues);
    return newValues;
}

// const calculateFlexibility = () => {
//     let funcProps = {
//         name: 'flexibility',
//         code: 'FLEXIBILITY',
//         args: [
//             //OGO
//             {
//                 count: 1,
//                 info: 'enable - building a new profile with generating equipmentdisable - building a new profile without generating equipment',
//                 name: 'mode_generating_equipment',
//                 type: 'Boolean',
//                 values: ['true']
//             },
//             {
//                 count: 1,
//                 info: 'Electric power storage discharge power, kW*h',
//                 name: 'power_return_generating_equipment',
//                 type: 'FLOAT',
//                 values: [200.0]
//             },
//             {
//                 count: 1,
//                 info: 'Set of dictionaries containing time intervals. Example: "2019-12-01_07:00:00,2019-12-01_08:00:00"',
//                 name: 'time_return_generating_equipment',
//                 type: 'DICT_OF_TIME',
//                 values: getDictOfTime(widgetDataSources.find(el => el.type === 'OGO_DATA_SOURCE').props.data)
//             },
//             {
//                 count: 1,
//                 info: 'Electrical power replenishment of generating equipment, kW*h',
//                 name: 'power_charging_generating_equipment1',
//                 type: 'FLOAT',
//                 values: [200.0]
//             },
//             {
//                 count: 1,
//                 info: 'Electrical power replenishment of generating equipment, kW*h',
//                 name: 'power_charging_generating_equipment2',
//                 type: 'FLOAT',
//                 values: [200.0]
//             },
//             {
//                 count: 1,
//                 info: 'Set of dictionaries containing time 1st intervals. Example: "2019-12-01_00:00:00,2019-12-01_03:00:00"',
//                 name: 'time_charging_generating_equipment1',
//                 type: 'DICT_OF_TIME',
//                 values: getDictOfTime(widgetDataSources.find(el => el.type === 'OGO_DATA_SOURCE_1').props.data)
//             },
//             {
//                 count: 1,
//                 info: 'Set of dictionaries containing time 2nd intervals. Example: "2019-12-01_00:00:00,2019-12-01_03:00:00"',
//                 name: 'time_charging_generating_equipment2',
//                 type: 'DICT_OF_TIME',
//                 values: getDictOfTime(widgetDataSources.find(el => el.type === 'OGO_DATA_SOURCE_2').props.data)
//             },
//
//             //SNE
//             {
//                 count: 1,
//                 info: 'enable - building a new profile with energy storagedisable - building a new profile without energy storage',
//                 name: 'mode_energy_storage',
//                 type: 'Boolean',
//                 values: ['true']
//             },
//             {
//                 count: 1,
//                 info: 'Electric power storage discharge power, kW*h',
//                 name: 'power_return_energy_storage',
//                 type: 'FLOAT',
//                 values: [200.0]
//             },
//             {
//                 count: 1,
//                 info: 'Set of dictionaries containing time intervals. Example: "2019-12-01_07:00:00,2019-12-01_08:00:00","2019-12-02_00:05:00,2019-12-02_08:00:00"',
//                 name: 'time_return_energy_storage',
//                 type: 'DICT_OF_TIME',
//                 values: getDictOfTime(widgetDataSources.find(el => el.type === 'SNE_DATA_SOURCE').props.data)
//             },
//             {
//                 count: 1,
//                 info: 'Electric power storage charge, kW*h',
//                 name: 'power_charging_energy_storage',
//                 type: 'FLOAT',
//                 values: [200.0]
//             },
//             {
//                 count: 1,
//                 info: 'Set of dictionaries containing time 1st intervals. Example: "2019-12-01_00:00:00,2019-12-01_03:00:00","2019-12-02_00:00:00,2019-12-02_04:00:00"',
//                 name: 'time_charging_energy_storage',
//                 type: 'DICT_OF_TIME',
//                 values: getDictOfTime(widgetDataSources.find(el => el.type === 'SNE_DATA_SOURCE_1').props.data)
//             },
//
//             // {
//             //     count: 1,
//             //     info: 'Set of dictionaries containing time 2nd intervals. Example: "2019-12-01_00:00:00,2019-12-01_03:00:00","2019-12-02_00:00:00,2019-12-02_04:00:00"',
//             //     name: 'time_charging_energy_storage2',
//             //     type: 'DICT_OF_TIME',
//             //     values: getDictOfTime(widgetDataSources.find(el => el.type === 'SNE_DATA_SOURCE_2').props.data)
//             // },
//
//
//             //VIE
//             {
//                 count: 1,
//                 info: 'enable - building a new profile with RES (renewable energy sources)disable - building a new profile without RES',
//                 name: 'mode_res',
//                 type: 'Boolean',
//                 values: ['true']
//             },
//             {
//                 count: 1,
//                 info: 'Electrical power of the replenished energy source, kW*h',
//                 name: 'power_return_res',
//                 type: 'FLOAT',
//                 values: [200.0]
//             },
//             {
//                 count: 1,
//                 info: 'Set of dictionaries containing time intervals. Example: "2019-12-01_07:00:00,2019-12-01_08:00:00","2019-12-02_00:05:00,2019-12-02_08:00:00"',
//                 name: 'time_return_res',
//                 type: 'DICT_OF_TIME',
//                 values: getDictOfTime(widgetDataSources.find(el => el.type === 'VIE_DATA_SOURCE').props.data)
//             }
//         ],
//         outputsCount: 1,
//         functionCode: 'FLEXIBILITY',
//         sourceType: 'ANALYTIC_FUNCTION',
//         parameters: {
//             dataSourceName: 'F(x): flexibility',
//             color: '#b73159',
//             dataSource: {
//                 id: widgetDataSources[0].props.id,
//                 name: widgetDataSources[0].props.name
//             }
//         }
//     };
//
//     analyticService.getAnalyticWithResultId(widgetDataSources.find(el => el.type === 'MAIN_DATA_SOURCE').props.id, funcProps, datetimeFilter).then(
//         result => {
//             console.log(result);
//         }
//     );
// };