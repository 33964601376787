/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import {useTranslation} from 'react-i18next';
import {VariableContext} from './ScenarioEditor';
import {FieldArray} from 'react-final-form-arrays';
import ControlCondition from './controls/ControlCondition';
import {scenarioConstants} from 'constants/scenario.constants';
import {useField} from 'react-final-form';
import ControlSetValue from './controls/ControlSetValue';

//#region  Ast Parser


/**
 * Base implementation of UUID
 */
const uuidv4 = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });

/**
 * Convert conditions and add uuid identifier
 * @param {} conditions
 */
const convertConditions = (conditions) =>
    conditions.map((item) => ({...item, uuid: uuidv4()}));

const ScenarioControl = ({source, saveAction, name}) => {

    const {conditions} = source;
    const {t} = useTranslation();
    const otherwiseFeild = useField(`${name}.payload.actionOtherwise`);
    const actionField = useField(`${name}.payload.actionThen`);
    const validateForm = (values) => {

    };
    return (
        <VariableContext.Consumer>
            {({passports, variables}) =>
                <React.Fragment>
                    <div className="scenario-control mr2">
                        <div className="scenario-container">
                            <div className="condition-container">
                                <FieldArray name={`${name}.payload.conditons`} validate={validateForm}>
                                    {({fields}) => fields.map((name, index) => {

                                            return <ControlCondition
                                                key={index}
                                                name={name}
                                                isSetter={false}
                                                availableVariables={variables}
                                                passportList={passports}
                                                addCondition={() => fields.insert(index + 1, scenarioConstants.CONDITION_SAMPLE)}
                                                onRemoveAction={fields.length > 1 ? () => fields.remove(index) : null}
                                            />;
                                        }
                                    )}
                                </FieldArray>
                                <hr/>
                                {actionField.input.value && (
                                    <ControlSetValue
                                        key="f-28"
                                        passportList={passports}
                                        availableVariables={variables}
                                        label={(<>{t('scenario.then')}:</>)}
                                        name={`${name}.payload.actionThen`}
                                    />
                                )}

                                {otherwiseFeild.input.value && (
                                    <ControlSetValue
                                        availableVariables={variables}
                                        passportList={passports}
                                        label={(<>{t('scenario.otherwise')}:</>)}
                                        name={`${name}.payload.actionOtherwise`}/>
                                )}
                            </div>
                        </div>
                    </div>
                </React.Fragment>

            }
        </VariableContext.Consumer>

    )
        ;
};

ScenarioControl.defaultProps = {
    availableVariables: []
};
ScenarioControl.propTypes = {
    name: PropTypes.string.isRequired,
    source: PropTypes.object,
    availableVariables: PropTypes.array,
    saveAction: PropTypes.func,
};

export default ScenarioControl;
