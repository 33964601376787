import UnworkedWidget from './UnworkedWidget';
import {dashboardConstants} from '../../../constants/index';
import WidgetChart from './WidgetChart';
import PieChart from './PieWidget';
import TextWidget from './TextWidget';
import FunctionWidget from './FunctionWidget';
import DotsCloudChart from './DotsCloudChart';
import Benchmarking from './Benchmarking';
import TableWidget from './TableWidget';
import PivotTableWidget from './pivotTable/PivotTableWidget';
import GroupChart from './GroupChart';
import LinesWidget from './LinesWidget';
import DataSetter from './DataSetter';
import CapacityProfile from './CapacityProfile';
import CapacityCost from './CapacityCost';
import GroupFunctionWidget from './GroupFunctionWidget';
import ElectricityStorage from './ElectricityStorage';
import BarChart from './BarChart';
import ButtonWidget from './ButtonWidget';
import SwitcherWidget from './SwitcherWidget';
import SliderWidget from './SliderWidget';
import MapWidget from './MapWidget';
import MnemoScheme from './MnemoScheme';
import Strategy from './widgetStrategy/Strategy';
import GroupUnloading from './groupUnloadingPlanningWidget/GroupUnloading';
import UnloadingPlanningWidget from './unloadingPlanningWidget/UnloadingPlanningWidget';
import SurplusAndDeficitWidget from './surplusAndDeficitWidgets/SurplusAndDeficitWidget';

export const widgetList = {
    [dashboardConstants.WIDGET_LINE_CHART]: WidgetChart,
    [dashboardConstants.WIDGET_STEP_CHART]: WidgetChart,
    [dashboardConstants.WIDGET_COLUMN_CHART]: BarChart,
    [dashboardConstants.WIDGET_DOTS_CHART]: DotsCloudChart,
    [dashboardConstants.WIDGET_TABLE]: TableWidget,
    [dashboardConstants.WIDGET_TEXT]: TextWidget,
    [dashboardConstants.WIDGET_BENCHMARKING]: Benchmarking,
    [dashboardConstants.WIDGET_PIE_CHART]: PieChart,
    [dashboardConstants.WIDGET_DOUGHNUT_CHART]: PieChart,
    [dashboardConstants.WIDGET_LINES]: LinesWidget,
    [dashboardConstants.WIDGET_SLIDER]: SliderWidget,
    [dashboardConstants.WIDGET_CHECKBOX]: SwitcherWidget,
    [dashboardConstants.WIDGET_BUTTON]: ButtonWidget,
    [dashboardConstants.WIDGET_TIME]: UnworkedWidget,
    [dashboardConstants.WIDGET_FUNCTION]: FunctionWidget,
    [dashboardConstants.WIDGET_DATA_SETTER]: DataSetter,
    [dashboardConstants.WIDGET_PIVOT_TABLE]: PivotTableWidget,
    [dashboardConstants.WIDGET_GROUP_CHART]: GroupChart,
    [dashboardConstants.WIDGET_GROUP_FUNCTION]: GroupFunctionWidget,
    [dashboardConstants.WIDGET_ELECTRICITY_COST]: CapacityCost,
    [dashboardConstants.WIDGET_CAPACITY_PROFILE]: CapacityProfile,
    [dashboardConstants.WIDGET_ELECTRICITY_STORAGE]: ElectricityStorage,
    [dashboardConstants.WIDGET_MAP]: MapWidget,
    [dashboardConstants.WIDGET_MNEMOSCHEME]: MnemoScheme,
    [dashboardConstants.WIDGET_DEMAND_MANAGEMENT_STRATEGY]: Strategy,
    [dashboardConstants.WIDGET_GROUP_UNLOADING]: GroupUnloading,
    [dashboardConstants.WIDGET_SURPLUS_DEFICIT_UNLOADING]: SurplusAndDeficitWidget,
    [dashboardConstants.WIDGET_UNLOADING_PLANNING]: UnloadingPlanningWidget
};
