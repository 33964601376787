import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../services/locale.service';
import Modal from './Modal';
import svgShow from '../sources/images/interface/show.svg';
import svgHide from '../sources/images/interface/hide.svg';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

function ShowDataSourceInfo({dataSources, onChange}) {
    const [isOpened, setOpened] = React.useState(false);
    const {t} = useTranslation();

    return (
        <>
            <button type="button"
                    title={localeService.isRussian() ? 'Информация о источниках данных' : 'Data Source Info'}
                    style={{position: 'absolute', bottom: '-1rem', right: '0rem'}}
                    className={'btn btn-link'} onClick={() => setOpened(true)}>
                {localeService.isRussian() ? 'Просмотр легенды' : 'Show Legend'}
                {/*<ReactSVG src={svgInfo}/>*/}
            </button>
            <Modal onCancel={() => setOpened(false)} isOpen={isOpened} isClosable={true} footer={false} width={800}
                   title={localeService.isRussian() ? 'Источники данных виджета' : 'Data Sources Info'}>
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th>{t('data_source_name')}</th>
                            <th>{t('unit')}</th>
                            <th>{t('color')}</th>
                            <th>{t('device_name')}</th>
                            <th>{t('capacity')}</th>
                            <th>{localeService.isRussian() ? 'Видимость' : 'Disabled'}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {dataSources.map((ds, i) => {
                        return <tr key={i}>
                            <td>{ds.parameters.dataSourceName}</td>
                            <td>{ds.parameters.unit}</td>
                            <td><div style={{width: '2rem', height: '2rem', backgroundColor: ds.parameters.color}}/></td>
                            <td>
                                {ds.device && <Link to={`/device/${ds.device.id}`}>{ds.device.name}</Link>}
                            </td>
                            <td>
                                {ds.capacity && <p>{ds.capacity.name}</p>}
                            </td>
                            <td>
                                <button className={'btn btn-outline-secondary'} onClick={() => {
                                    ds.disabled = (ds.disabled ? false : true);
                                    onChange(dataSources);
                                }}>
                                    <ReactSVG src={ds.disabled ? svgHide : svgShow}/>
                                    <span>{localeService.isRussian() ? (ds.disabled ? 'Показать' : 'Скрыть') : (ds.disabled ? 'Show' : 'Hide')}</span>
                                </button>
                            </td>
                        </tr>;
                    })}
                    </tbody>
                </table>
            </Modal>
        </>
    );
}

ShowDataSourceInfo.propTypes = {
    dataSources: PropTypes.array,
    onChange: PropTypes.func
};

export default ShowDataSourceInfo;