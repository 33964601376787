import {dashboardConstants} from '../../../constants';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import CapacityProfileProps from './CapacityProfileCreate';
import CapacityProfileView from './CapacityProfileView';
import React from 'react';
import PropTypes from 'prop-types';
import {hoursLabels} from '../../../constants/widgets.constants';

function CapacityProfile({widgetProps, mode, onCloseEditSingleMode}) {
    return (
        <>
            {mode === dashboardConstants.VIEW_MODE &&
            <CapacityProfileView widgetProps={widgetProps}/>
            }
            {mode === dashboardConstants.EDITION_MODE &&
            <div className={'scrollable-zone'}>
                <table className="table" style={{margin: 0 + 'px', border: '1px border grey'}}>
                    <thead>
                    <tr>
                        <th>{localeService.isRussian() ? 'Часы' : 'Hours'}</th>
                        {
                            [...Array(30).keys()].map((_, j) => {
                                return (<th key={j}>{j + 1}</th>);
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {[...Array(24).keys()].map((_, i) => {
                        return (
                            <tr key={i}>
                                <td>{hoursLabels[i]}</td>
                                {
                                    [...Array(30).keys()].map((_, j) => {
                                        return <td key={j}>{}</td>;
                                    })
                                }
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>}
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => onCloseEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <CapacityProfileProps widgetProps={widgetProps} onClose = {() => onCloseEditSingleMode()}/>
            </Modal>
            }
        </>
    );
}

CapacityProfile.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default CapacityProfile;