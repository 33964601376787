import {FieldArray} from 'react-final-form-arrays';
import React from 'react';
import {Field} from 'react-final-form';
import CustomInput from 'components/CustomInput';
import {uuidv4} from '../../../utils/uuid';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import svgTrash from '../../../sources/images/trash.svg';

const Multicast = () => {
    const {t} = useTranslation();
    return (
        <FieldArray name="multicasts">
            {({fields}) => (
                <React.Fragment>
                    <div className="flex-row flex-row__baseline mb1">
                        <h4>
                            Рассылка
                        </h4>
                        <button
                            className="ml1 btn btn-outline-primary default"
                            onClick={e => {
                                e.preventDefault();
                                fields.push(({
                                    uuid: uuidv4(),
                                    phone: '',
                                    email: ''
                                }));
                                console.log(fields);
                            }}
                            title={t('save')}
                        >Добавить рассылку
                        </button>
                    </div>
                    <div>
                        {fields.map((item, idx) => (
                            <div className="flex-row" key={idx}>
                                <Field
                                    name={`${item}.phone`}>
                                    {({input}) => (
                                        <CustomInput
                                            isRequired={true}
                                            {...input}
                                            isInteger={false}
                                            label="Телефон"
                                        />
                                    )}
                                </Field>
                                <Field
                                    name={`${item}.email`}>
                                    {({input}) => (
                                        <CustomInput
                                            isRequired={true}
                                            {...input}
                                            isInteger={false}
                                            label="Email"
                                        />
                                    )}
                                </Field>
                                <button
                                    className="btn btn-outline-primary round-btn default edit-variable-btn"
                                    onClick={e => {
                                        e.preventDefault();
                                        fields.remove(idx);
                                    }}
                                    title={t('save')}
                                >
                                    <ReactSVG src={svgTrash}/>
                                </button>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            )}
        </FieldArray>
    );
};
export default Multicast;
