/* eslint-disable react/prop-types */


import '../../styles/capacity.scss';
import '../../styles/capacity-view.scss';

import React, {useEffect, useMemo, useState} from 'react';

import Accordion from './components/Accordion';
import CapacityTreeView from './components/CapacityTreeView';
import Loader from '../../components/Loader';
import Modal from './../../components/Modal';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import ScenarioList from '../scenario/ScenarioList';
import {capacityService} from 'services';
import defaultImage from '../../sources/images/img_banner.svg';
import {localeService} from '../../services/locale.service';
// import svgAdd from 'sources/images/interface/add.svg';
import svgBlocks from '../../sources/images/interface/dot-matrix.svg';
import svgEdit from '../../sources/images/interface/edit.svg';
import svgTrash from '../../sources/images/trash.svg';
import svgTree from '../../sources/images/interface/bullets.svg';
import {useTranslation} from 'react-i18next';
import CapacitySearch from '../capacity/components/CapacitySearch';
import CapacityForm from './components/CapacityForm';
import CapacityPassportList from './components/Passport/CapacityPassportList';
import CreateCapacity from './components/CreateCapacity';
import CapacityVariableList from './components/CapacityVariableList';
import {apiConstants} from '../../constants/api.constants';


/**
 * Представление списка переменных ввиде представления
 * @param {*} param0
 */
const CapacityListView = ({
                              list,
                              setList,
                              activeCapacity,
                              setActiveCapacity,
                              onDeleteCapacity,
                              // onCreateCapacity,
                          }) => {
    return (
        <div className={'capacity-grid '}>
            <React.Fragment>
                {list &&
                list.length > 0 &&
                list.map((subCapacity) => (
                    <CapacityInnerView
                        innerCapacity={subCapacity}
                        onDeleteCapacity={onDeleteCapacity}
                        active={activeCapacity === subCapacity.id}
                        setActive={setActiveCapacity}
                        setList={setList}
                        key={subCapacity.id}
                    />
                ))}
            </React.Fragment>
        </div>
    );
};

/**
 * Внутреннее представление нагрузки
 */
const CapacityInnerView = ({
                               innerCapacity,
                               setList,
                               active,
                               setActive
                           }) => {
    const hasChildren = innerCapacity.child !== undefined && innerCapacity.child.length > 0;
    const {fileId} = innerCapacity;
    const activateItem = () => {
        setActive(innerCapacity);
    };
    const moveInside = () => {
        if (hasChildren) {
            setList(innerCapacity);
        }
    };

    return (
        <React.Fragment>
            <div className={`capacity-item ${hasChildren ? 'capacity-folder ' : ''} ${active ? 'capacity-item-selected' : ''}`}
                onClick={() => activateItem()}
                onDoubleClick={() => moveInside()}
            >
   <div className={'image'}>
                    {!fileId && <img src={defaultImage} alt=""/>}
                    {innerCapacity.img && (
                        <img
                            src={innerCapacity.img.type + ',' + innerCapacity.img.content}
                            alt={'Capacity Icon'}
                        />
                    )}
                    {fileId && (
                        <img
                            alt={'Capacity Icon'}
                            src={apiConstants.API_URL + '/file/download?fileId=' + fileId}
                        />
                    )}
                </div>
                <label>{innerCapacity.name}</label>
            </div>
        </React.Fragment>
    );
};

/**
 * Хлебные крошки
 * @constructor
 */
const BreadCumbView = ({capacityRoutes, selectRoute}) =>
    capacityRoutes && (
        <React.Fragment>
            <ol className="breadcrumb">
                {capacityRoutes.length > 0 &&
                capacityRoutes.map((cap, i) => {
                    return (
                        <li key={'li' + i} onClick={() => selectRoute(cap)}>
                            {cap.name}
                        </li>
                    );
                })}
            </ol>
        </React.Fragment>
    );

//функция рендеринга интерфейса для просмотра списка нагрузок
function CapacityList() {
    //инициализация библиотеки перевода интерфейса
    const {t} = useTranslation();

    const [size, changeSize] = React.useState(true);

    const [viewMode, setViewMode] = useState('blocks');
    // eslint-disable-next-line no-unused-vars

    const [deleteModal, setDeleteModal] = useState(false);
    // const [createModal, setCreateModal] = useState(false);

    //флаг открытия диалогового окна для редактирования нагрузки
    const [editModal, setEditModal] = useState(false);
    //
    const [capacityTree, setCapacityTree] = useState([]);
    const [isFetchingCapacityList, setIsFetchingCapacityList] = useState(false);
    //инициализация выбранной переменной
    const [selectedCapacity, setSelectedCapacity] = useState(undefined);

    const [capacityList, setList] = React.useState(capacityTree);
    const [routes, addRoute] = React.useState([]);

    // Инициализация функции обновления нагрузки после действий
    const updateCapacity = (capacity) => {
        capacityService.getCapacity(capacity.id).then((loadedCapacity) => {
            setSelectedCapacity(loadedCapacity);
        });
    };

    /**
     *
     * @param {{capacity:Object,parent:Object}} childrenNode
     */
    const findPath = (childrenNode) => {

        if (!childrenNode.parent)
            return [childrenNode.capacity];

        return [childrenNode.capacity, ...findPath(childrenNode.parent)];
    };
    const findNode = (id) => {
        if (!id) {
            return null;
        }
        let data = null;
        const localFind = (prev, current) => {

            if (current.id === id) {

                data = prev;
            } else {
                if (current.isGroup && current.child) {
                    current.child.forEach(child => localFind(current, child));
                }

            }
        };
        capacityTree.forEach(child => {
            localFind(null, child);
        });
        return data;
    };
    const parentCapacity = useMemo(() =>
            findNode(selectedCapacity && selectedCapacity.id)
        , [selectedCapacity, capacityTree.length]);


    /**
     * Обработка нажатия при выборе в хлебных крошках
     * @param capacity
     */
    const selectRoute = (capacity) => {

        const index = routes.findIndex((item) => item.id === capacity.id);
        if (index !== -1) {
            if (index === 0) {
                addRoute([...routes.slice(0, 1)]);
            } else {
                if (routes.length - 1 !== index) {
                    addRoute([...routes.slice(0, index + 1)]);
                }
            }
        } else {
            addRoute([...routes, capacity]);
        }

        setList(capacity.child);
    };
    /**
     * Уставновление списка дочерних элементов нагрузки
     * @param {Capacity} capacity
     */
    const setListData = (capacity) => {
        if (capacity.child !== undefined) {
            addRoute([...routes, capacity]);
            setList(capacity.child);
        }
    };
    /**
     * Выбор нагрузки
     * @param {Capacity} capacity
     */
    const selectCapacityItem = (capacity) => {
        selectCapacity(capacity);
        changeSize(true);
    };
    /**
     * Перезагрузка дерева нагрузок
     */
    const reloadCapacityTree = () => {
        setIsFetchingCapacityList(true);
        capacityService.getCapacityTree().then((response) => {
            setIsFetchingCapacityList(false);
            setCapacityTree(response.list);

            if (routes.length > 1) {
                const last = routes[routes.length - 1];
                capacityService.getCapacityChildren(last.id).then(res => {

                    setList(res.list);
                }).catch(() => {
                    addRoute([
                        {
                            name: localeService.isRussian() ? 'Корень' : 'Root',
                            child: response.list,
                        },
                    ]);
                    setList(response.list);
                });
            } else {
                addRoute([
                    {
                        name: localeService.isRussian() ? 'Корень' : 'Root',
                        child: response.list,
                    },
                ]);
                setList(response.list);
            }

        });
    };
    useEffect(() => {
        reloadCapacityTree();
    }, []);

    const selectCapacity = (capacity) => {
        setSelectedCapacity(capacity);
    };
    /**
     * Проверка есть ли у паспорта объектов доступные нагрузки
     * @param {Capacity} capacity Нагрузка
     */
    const checkPassportArray = (capacity) => {
        const {passportStorage, passportDelivery, passportConnect} = capacity;
        const arr = [passportStorage, passportDelivery, passportConnect];
        return arr.filter((item) => item !== undefined);
    };
    /**
     * Удаление выбранной нагрузки
     */
    const deleteCapacity = () => {
        const elem = checkPassportArray(selectedCapacity);
        if (elem.length > 0) {
            alert('Вы не можете удалить нагрузку у которой есть паспорта');
        } else
            capacityService
                .deleteLoad(selectedCapacity.id)
                .then(() => {
                    reloadCapacityTree();
                    setSelectedCapacity(undefined);
                })
                .then(() => setDeleteModal(false));
    };

    return (
        <>
            <section className=" ml1">
                <nav className="page-header section-view">
                    <ul className="nav-left">
                        <li>
                            {/*Компонент "Хлебные крошки"*/}
                            <BreadCumbView capacityRoutes={routes} selectRoute={selectRoute} />
                        </li>
                        <li>
                            {/*Переключение режима просмотра "Плитка"*/}
                            <button
                                type={'button'}
                                className={
                                    'btn btn-outline-primary default round-btn ' +
                                    (viewMode === 'blocks' ? 'active' : '')
                                }
                                onClick={() => setViewMode('blocks')}
                                title={
                                    localeService.isRussian()
                                        ? 'Режим просмотра: плитка'
                                        : 'Swith view mode to Blocks'
                                }
                            >
                                <ReactSVG src={svgBlocks}/>
                            </button>
                        </li>
                        <li>
                            {/*Переключение режима просмотра "Дерево"*/}
                            <button
                                type={'button'}
                                className={
                                    'btn btn-outline-primary default round-btn ' +
                                    (viewMode === 'tree' ? 'active' : '')
                                }
                                onClick={() => setViewMode('tree')}
                                title={
                                    localeService.isRussian()
                                        ? 'Режим просмотра: дерево'
                                        : 'Swith view mode to Tree'
                                }
                            >
                                <ReactSVG src={svgTree}/>
                            </button>
                        </li>
                        <li className={'ps-3'}>
                            {/*Кнопка создания нагрузки*/}
                            <CreateCapacity
                                capacityTree={capacityTree} //передача дочернему компоненту переменную дерева нагрузок
                                onSuccess={() => reloadCapacityTree()} //вызов метода рендеринга списка нагрузок при успешном создании нагрузки
                            />
                        </li>
                    </ul>
                </nav>
            </section>

            {isFetchingCapacityList && <Loader waitText={t('wait')}/>}
            <div className="row">
                <CapacitySearch capacityTree={capacityTree} onSelect={(c) => {
                    if (c.parent) {
                        setListData(c.parent.capacity);
                        const arr = [...findPath(c), {
                            name: localeService.isRussian() ? 'Корень' : 'Root',
                            child: capacityTree,
                        }].reverse();
                        arr.pop();
                        addRoute(arr);

                    } else
                        setListData([]);


                    selectCapacityItem(c.capacity);
                }}/>
                <div className="col-50 scrollable-container"
                     style={{maxHeight: 'calc(100Vh - 16rem)', overflowY: 'auto'}}>
                    {viewMode == 'blocks' ? (
                        <CapacityListView
                            activeCapacity={selectedCapacity && selectedCapacity.id}
                            setActiveCapacity={selectCapacityItem}
                            list={capacityList}
                            setList={setListData}
                            onDeleteCapacity={(capacity) => {
                                setSelectedCapacity(capacity);
                                setDeleteModal(true);
                            }}
                            size={size}
                        />
                    ) : (
                        <CapacityTreeView
                            treeRoute={routes}
                            capacityTree={capacityTree}
                            selectedCapacity={selectedCapacity}
                            onSelect={(cap) => {
                                selectCapacityItem(cap);
                                setListData(cap);
                            }}
                        />
                    )}
                </div>

                <div className="ps-5 col-50 capacity-detail">
                    {!selectedCapacity &&
                    <p className={'help-text'}>{localeService.isRussian() ? 'Выберите нагрузку' : 'Select Capacity'}</p>}
                    <header className="capacity-detail__header">
                        {selectedCapacity && (
                            <p className={'help-text'}>
                                {t('selected_capacity')}:
                                <span className="ms-3 capacity-detail__header__text">
                                    {selectedCapacity.name}
                                </span>
                            </p>
                        )}
                        {selectedCapacity && (
                            <React.Fragment>
                                <div className="capacity-detail__header__actions">
                                    <button //кнопка для редактирования выбранной нагрузки
                                        className={'btn btn-outline-primary default round-btn '}
                                        onClick={() => setEditModal(true)} //обработчик нажатия - изменения со
                                        title={t('edit')}
                                    >
                                        <ReactSVG src={svgEdit}/>
                                    </button>
                                    <button
                                        className={'btn ms-3 btn-outline-primary default round-btn '}
                                        onClick={() => setDeleteModal(true)}
                                        title={t('delete')}
                                    >
                                        <ReactSVG src={svgTrash}/>
                                    </button>
                                </div>
                            </React.Fragment>
                        )}
                    </header>
                    {selectedCapacity && (
                        <div className="capacity-detail__content">
                            <Accordion
                                label={localeService.isRussian() ? 'Паспорта' : 'Passports'}
                            >
                                {selectedCapacity &&
                                <CapacityPassportList capacity={selectedCapacity}/>}
                            </Accordion>
                            <Accordion
                                label={
                                    localeService.isRussian()
                                        ? 'Сценарии управления'
                                        : 'Scenarios'
                                }
                            >
                                {selectedCapacity && (
                                    <ScenarioList capacity={selectedCapacity}/>
                                )}
                            </Accordion>
                            <Accordion
                                label={
                                    localeService.isRussian()
                                        ? 'Переменные нагрузки'
                                        : 'Capcity Variable List'
                                }
                            >
                                {selectedCapacity && (
                                    <CapacityVariableList capacity={selectedCapacity}/>
                                )}
                            </Accordion>
                            {/*<Accordion*/}
                            {/*label={*/}
                            {/*localeService.isRussian()*/}
                            {/*? 'Дашборды'*/}
                            {/*: 'Dashboards'*/}
                            {/*}*/}
                            {/*>*/}
                            {/*{selectedCapacity && (*/}
                            {/*<section className="passport-list">*/}
                            {/*{selectedCapacity && <Loader waitText={t('wait')} />}*/}
                            {/*<div className="mr1 ml1">*/}
                            {/*<ListView*/}
                            {/*img={svgCode}*/}
                            {/*renderName={(source) => source.name}*/}
                            {/*viewAllText={localeService.isRussian() ? 'Cмотреть все' : 'View all'}*/}
                            {/*/>*/}
                            {/*</div>*/}
                            {/*</section>*/}
                            {/*)}*/}
                            {/*</Accordion>*/}
                        </div>
                    )}
                </div>
            </div>
            <React.Fragment>
                <Modal
                    title={'Вы точно хотите удалить нагрузку'}
                    isOpen={deleteModal}
                    footer={false}
                    onCancel={() => {
                        setDeleteModal(false);
                    }}
                >
                    <div className={'d-flex justify-content-center'}>
                        <button
                            className="btn btn-outline-primary default "
                            onClick={() => setDeleteModal(false)}
                        >
                            {localeService.isRussian() ? 'Отмена' : 'Cancel'}
                        </button>
                        <button
                            className="ms-5 btn btn-outline-danger "
                            onClick={() => deleteCapacity()}
                        >
                            {localeService.isRussian() ? 'Удалить нагрузку' : 'Delete Capacity'}
                        </button>
                    </div>
                </Modal>
                <Modal //диалоговое окно для редактирования нагрузки
                    isOpen={editModal} //флаг открытия окна
                    footer={false} //не использовать "подвал" по-умолчанию
                    onCancel={() => { //обработчик события отмены редактирования - закрыть окно
                        setEditModal(false);
                    }}
                >
                    <CapacityForm //вызов компонента с формой для редактирования нагрузки
                        titleText={localeService.isRussian() ? 'Редактирование нагрузки' : 'Edit Capacity'}//заголовок формы
                        capacityTree={capacityTree} //передача дочернему компоненту переменной дерева нагрузок
                        parentCapacity={parentCapacity} //передача дочернему компоненту параметров родительской нагрузки
                        selectedCapacity={selectedCapacity} //передача дочернему компоненту параметры редактируемой нагрузки
                        buttonText={'apply'} //название активной кнопки
                        onCancel={(result) => { //обработчик отмены
                            setEditModal(false); //закрытие окна
                            if (result && result.needToReload) { //проверка на применение изменений
                                updateCapacity(result); //вызов функции редактирования нагрузки
                                reloadCapacityTree();//вызов функции для перерисовки дерева нагрузок
                            }

                        }}
                    />
                </Modal>
            </React.Fragment>
        </>
    );
}

CapacityInnerView.propTypes = {
    innerCapacity: PropTypes.object,
    active: PropTypes.bool,
    setList: PropTypes.func,
    setActive: PropTypes.func,
    onDeleteCapacity: PropTypes.func,
};

CapacityListView.propTypes = {
    list: PropTypes.array,
    setList: PropTypes.func,
    activeCapacity: PropTypes.number,
    setActiveCapacity: PropTypes.func,
    size: PropTypes.bool,
    onDeleteCapacity: PropTypes.func,
    onCreateCapacity: PropTypes.func,
};

export default CapacityList;
