import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {deviceService} from '../../services';
import {localeService} from '../../services/locale.service';
import {useTranslation} from 'react-i18next';
import Loader from '../../components/Loader';
import '../../styles/device-list.scss';
import {Pagination} from '../../components/Pagination';
import Device from './Device';
import {ReactSVG} from 'react-svg';
import svgBackArrow from '../../sources/images/interface/arrow-right.svg';
import {Link, useHistory} from 'react-router-dom';
import svgAdd from '../../sources/images/interface/add.svg';
import InputText from '../../components/InputText';

function DeviceList({page}) {
    const history = useHistory();
    const {t} = useTranslation();
    const [isFetchingDeviceList, setFetchingDeviceList] = useState(false);
    const [deviceList, setDeviceList] = useState([]);
    const [pagination, setPagination] = useState({number: page, countPerPage: 20, count: 0});
    const [viewDevice, setViewedDevice] = useState(null);
    const [searchCriteria, setSearchCriteria] = useState('');

    useEffect(() => {
        setPagination({...pagination, number: page});
        getDeviceListPage(page);
    }, [page]);

    const getDeviceListPage = (pageNumber) => {
        setFetchingDeviceList(true);
        deviceService.getDeviceList(pageNumber, pagination.countPerPage, searchCriteria).then(
            results => {
                setPagination({
                    ...pagination,
                    number: pageNumber,
                    count: Math.round((results.total + pagination.countPerPage - 1) / pagination.countPerPage) - 1
                });
                setDeviceList(results.content);
                setFetchingDeviceList(false);
            }
        );
    };


    return <>
        <nav className="page-header">
                {!viewDevice &&
                <ul className="nav-left">
                <li><h5>{localeService.isRussian() ? 'Список устройств' : 'Device List'}</h5></li>
                </ul>
                }
                {viewDevice &&
                <ul className="nav-left breadcrumb">
                        <li className={'as-a-btn'} onClick={() => setViewedDevice(null)}>
                                <ReactSVG className={'svg-icon previous-btn'} src={svgBackArrow}/>
                                <b>{t('device_list')}</b>
                        </li>
                        <li className={'text-ellipsis-200'}>{viewDevice.name}</li>
                        <li> {t('device_variable_list')}</li>
                </ul>
                }
                <ul className={'nav-right'}>
                    <li>
                        <Link to="/add_device">
                            <button type={'button'}
                                    onClick={() => {}}
                                    className={'success svg-btn '}
                                    title={localeService.isRussian() ? 'Подключить устройство' : 'Add Device'}>
                                <ReactSVG src={svgAdd}/>
                            </button>
                        </Link>
                    </li>
                    <li>
                        <form onSubmit={(e) => {e.preventDefault(); getDeviceListPage(0);}}>
                            <InputText label={localeService.isRussian() ? 'Поиск:' : 'Search:'} value={searchCriteria}
                                       onChange={text => {
                                           setSearchCriteria(text);
                                       }}/>
                        </form>
                    </li>
                    <li>
                        <button className={'btn btn-outline-primary default'} onClick={()=>getDeviceListPage(0)}>{localeService.isRussian() ? 'Найти' : 'Find'}</button>
                    </li>
                </ul>
        </nav>
        {isFetchingDeviceList && <Loader waitText={t('wait')}/>}
        {viewDevice && <Device device={viewDevice}/>}
        {deviceList && deviceList.length > 0 && !viewDevice &&
        <div>
            <table className={'device-list-table'} cellPadding={0} cellSpacing={0}>
                <thead>
                <tr>
                    <th width="40px" style={{textAlign: 'center'}}>{t('#')}</th>
                    <th>{t('name')}</th>
                    <th>GUID</th>
                    <th>{t('type')}</th>
                    <th>{t('is_active')}</th>

                </tr>
                </thead>
                <tbody>
                {deviceList.map((device, i) => {
                    return (
                        <tr onClick={() => {
                            history.push('/device/' + device.id);
                        }} key = {i}>
                            <td width="40px"
                                style={{textAlign: 'center'}}>{pagination.number * pagination.countPerPage + (i + 1)}</td>
                            <td>{device.name}</td>
                            <td>{device.guid}</td>
                            <td>{device.deviceProtocol.name}</td>
                            <td>{t(device.isActive + '')}</td>

                        </tr>

                    );
                })}
                </tbody>
            </table>
            <Pagination pageCount={pagination.count} pageNumber={pagination.number}
                        onSelectPage={(number) => history.push('/device_list/'+ number)}/>
        </div>
        }
    </>;
}

DeviceList.propTypes = {
    page: PropTypes.number
};

DeviceList.defaultProps = {
    page: 0
};

export default DeviceList;