import moment from 'moment/moment';
import {localeService} from './locale.service';

export const renderDataForCsvReport = (dataSources, functionDataSources) => {
    let headers = [{label: localeService.isRussian() ? 'Дата / Время' : 'Datetime', key: 'time_upload'}];
    let datetimeArray = [];
    dataSources.map((ds) => {
        headers.push({
            label: ds.parameters.dataSourceName + (ds.parameters.unit ? (' ,' + ds.parameters.unit) : ''),
            key: ds.id.toString()
        });
        if (ds.data) {
            ds.data.map(el => {
                if (!datetimeArray.includes(el.time_upload)) {
                    datetimeArray.push(el.time_upload);
                }
            });
        }
    });
    functionDataSources.map((fds, i) => {
        headers.push({label: fds.parameters.dataSourceName, key: 'func' + i});
        if (fds.data) {
            fds.data.map(el => {
                if (!datetimeArray.includes(el.time_upload)) {
                    datetimeArray.push(el.time_upload);
                }
            });
        }
    });

    let data = [];
    datetimeArray.sort((a, b) => b - a).map(timeUpload => {
        let dataRow = {};
        dataRow.time_upload = moment.utc(moment.unix(timeUpload)).format('DD/MM HH:mm:ss');
        dataSources.map((ds) => {
            let dataIdx = ds.data.findIndex(el => el.time_upload === timeUpload);
            if (dataIdx < 0) {
                dataRow[ds.id.toString()] = '';
            } else {
                dataRow[ds.id.toString()] = ds.data[dataIdx].value;
            }

        });
        functionDataSources.map((fds, i) => {
            let dataIdx = fds.data.findIndex(el => el.time_upload === timeUpload);
            if (dataIdx < 0) {
                dataRow['func' + i] = '';
            } else {
                dataRow['func' + i] = fds.data[dataIdx].value;
            }
        });
        data.push(dataRow);
    });
    return({data: data, headers: headers});

};