import React from 'react';
import '../styles/preloader.scss';
import {localeService} from '../services/locale.service';
import {ReactSVG} from 'react-svg';
import svgNoData from '../sources/images/interface/no-data.svg';

function EmptyData() {

        return (
            <div className={'empty-data'}>
                <ReactSVG src={svgNoData}/>
                <span>{localeService.isRussian() ? 'Нет данных для отображения' : 'No data to display'}</span>
            </div>
        );
}

export default EmptyData;