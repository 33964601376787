import React from 'react';
import PropTypes from 'prop-types';
import {dashboardService} from '../../../services';
import {useTranslation} from 'react-i18next';
import {apiConstants, dashboardConstants} from '../../../constants';
import Slider from 'react-slick';
import {SizeMe} from 'react-sizeme';
import DropItem from './DropItem';
import ReactGridLayout from 'react-grid-layout';
import Widget from '../widgets/Widget';
import {updateWidgetsPosition} from '../../../redux/actions/dashboardActions';
import {localeService} from '../../../services/locale.service';
import {connect} from 'react-redux';
import Loader from '../../../components/Loader';
import DropZone from './DropZone';
import Backend from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';

function CreateWidgetsGrid({widgetList, updateWidgetsPosition}) {
    const {t} = useTranslation();
    const [widgetTypeList, setWidgetTypeList] = React.useState({informationWidgets: null, controlWidgets: null});
    const [isFetchingWidgetTypeList, setIsFetchingWidgetTypeList] = React.useState(false);
    const [isBlocked, setBlocked] = React.useState(false);

    React.useEffect(() => {
        setIsFetchingWidgetTypeList(true);
        dashboardService.getWidgetTypeList()
            .then(
                widgetList => {
                    setWidgetTypeList(widgetList);
                    setIsFetchingWidgetTypeList(false);
                }
            );
    }, []);

    React.useEffect(() => {
        if (widgetList.findIndex(el => el.mode === dashboardConstants.EDIT_SINGLE_MODE) >= 0) {
            setBlocked(true);
        } else {
            setBlocked(false);
        }
    }, [widgetList]);

    return (
        <SizeMe>{({size}) =>
            <DndProvider backend={Backend}>
            <>
                {isFetchingWidgetTypeList && <Loader waitText={localeService.isRussian() ? 'Загрузка списка типов виджетов...' : 'Loading widget type list...'}/>}
                {!isFetchingWidgetTypeList && widgetTypeList.informationWidgets &&
                <Slider style={{width: size.width + 'px', outline: 'none', marginBottom: '4rem'}} dots={true} sliderSize={1}>
                    {widgetTypeList.informationWidgets.map((slide, i) => {
                        return (
                            <div key={'info' + i}>
                                {slide.map((widget, j) => {
                                    return (
                                        <DropItem key={'drag-info-' + i + j} widget={widget}>
                                            <div key={'info-widget-' + i + j} className="сhoose-dashboard-item"
                                                 style={{width: 'calc(' + (size.width / 8) + 'px - 1rem)'}}>
                                                <label>{t(widget.name)}</label>
                                                {widget.img && widget.img.id && <img
                                                    src={apiConstants.API_URL + '/file/download?fileId=' + widget.img.id}/>}
                                            </div>
                                        </DropItem>
                                    );
                                })}
                            </div>
                        );
                    })
                    }
                    {widgetTypeList.controlWidgets.map((slide, i) => {
                        return (
                            <div key={'control' + i}>
                                {slide.map((widget, j) => {
                                    return (
                                        <DropItem key={'drag-info-' + i + j} widget={widget}>
                                            <div key={'info-widget-' + i + j} className="сhoose-dashboard-item"
                                                 style={{width: 'calc(' + (size.width / 8) + 'px - 1rem)'}}>
                                                <label>{t(widget.name)}</label>
                                                {widget.img && widget.img.id && <img
                                                    src={apiConstants.API_URL + '/file/download?fileId=' + widget.img.id}/>}
                                            </div>
                                        </DropItem>
                                    );
                                })}
                            </div>
                        );
                    })
                    }
                </Slider>
                }
                <DropZone>
                    <ReactGridLayout className="dashboard-grid" layout={widgetList} cols={6} rowHeight={100}
                                     onLayoutChange={(items) => {
                                         updateWidgetsPosition(items);
                                     }}
                                     isDraggable={!isBlocked}
                                     width={size.width - 32} margin={[8, 8]}>
                        {widgetList.map(widget => {
                            return <div key={widget.id} data-grid={widget}>
                                <Widget mode={widget.mode} widgetProps={widget}
                                        sizeParams={{
                                            w: widget.w,
                                            h: widget.h,
                                            width: (size.width / 6 * widget.w) - 80,
                                            height: (100 * widget.h) - 120
                                        }}
                                />
                            </div>;
                        })}
                    </ReactGridLayout>
                </DropZone>
            </>
            </DndProvider>}
        </SizeMe>
    );
}

CreateWidgetsGrid.propTypes = {
    widgetList: PropTypes.array,
    updateWidgetsPosition: PropTypes.func
};

const mapStateToProps = state => {
    const widgetList = state.dashboardReducer.widgetList;
    return {widgetList};
};
const mapDispatchToProps = {
    updateWidgetsPosition: updateWidgetsPosition
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateWidgetsGrid);