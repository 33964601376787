import React, {useEffect, useState} from 'react';
import {localeService} from '../../services/locale.service';
import ReactGridLayout from 'react-grid-layout';
import WidgetCarousel from './WidgetCarousel';
import '../../styles/dashboard.scss';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import {DndProvider} from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import DropZone from './components/DropZone';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    clearDashboard,
    setDataSourceList,
    setEditedWidgets,
    updateDashboardList,
    updateWidgetsPosition
} from '../../redux/actions/dashboardActions';
import {SizeMe} from 'react-sizeme';
import {dashboardConstants} from '../../constants';
import Widget from './widgets/Widget';
import Modal from '../../components/Modal';
import InputText from '../../components/InputText';
import {useTranslation} from 'react-i18next';
import {capacityService, dashboardService, deviceService} from '../../services';
import Loader from '../../components/Loader';
import {Link, useHistory} from 'react-router-dom';
import AlertError from '../../components/AlertError';
import {uploadFunctionList} from '../../redux/actions/analyticsActions';
import {analyticService} from '../../services/analyticService';
import AlertSuccess from '../../components/AlertSuccess';

//функция рендеринга интерфейса для редактирования дашборда
function EditDashboard({
                           dashboardId, setEditedWidgets, widgetList, clearDashboard,
                           updateWidgetsPosition, uploadFunctionList, setDataSourceList, updateDashboardList
                       }) {
    //инициализация библиотеки для управления историей просмотра
    const history = useHistory();
    //инициализация библдиотеки перевода языка интерфейса
    const {t} = useTranslation();
    //инициализация переменной для блокирования интерфейса при сохранении/изменени
    const [isBlocked, setBlocked] = useState(false);
    //инициализация переменной названия дашборда
    const [dashboardName, setDashboardName] = useState('');
    //инициализация переменной "идет загрузка виджетов"
    const [isFetchingWidgetList, setFetchingWidgetList] = useState(false);
    //инициализация переменной для подтвержлдения действий при удалении дашборда
    const [confirmDelete, setConfirmDelete] = useState(false);
    //инициализация переменной "идет удаление"
    const [isDeleting, setDeleting] = useState(false);
    //инициализация переменной ошибки
    const [error, setError] = useState('');
    //инициализация переменной дашборда
    const [dashboard, setDashboard] = useState(null);
    //инициализация переменной "успешно удалён"
    const [isDeleted, setDeleted] = useState(false);
//инициализация переменной состояния формы
    const [createDashboardForm, setCreateDashboardForm] = useState({
        isOpen: false,
        isFetching: false,
        newDashboardID: null,
        error: ''
    });

    const deleteDashboard = () => {
        setDeleting(true);
        dashboardService.deleteDashboard(dashboardId).then(
            () => {
                dashboardService.getDashboardList()
                    .then((dbList) => {
                        updateDashboardList(dbList);
                    });
                setDeleted(true);
            }, error => {
                setError(error);
            }
        ).finally(() => {
            setDeleting(false);
        });
    };
//обработчик события при изменении ID дашборда
    useEffect(() => {
        setFetchingWidgetList(true);
        clearDashboard();
        analyticService.getFunctionList().then(
            functionList => {
                uploadFunctionList(functionList);
            }
        );
        dashboardService.getDashboard(dashboardId).then(
            dashboard => {
                setDashboard(dashboard);
                setDashboardName(dashboard.name);
                dashboardService.getDashboardWidgetList(dashboardId)
                    .then(
                        widgetList => {
                            setEditedWidgets(widgetList.map(w => {
                                return {
                                    ...w, dataSources: w.dataSources.map(ds => {
                                        return {...ds, name: ds.parameters.dataSourceName};
                                    })
                                };
                            }));
                            setFetchingWidgetList(false);
                            const dsList = [];
                            widgetList.map(w => {
                                w.dataSources.map(ds => {
                                    if (dsList.findIndex(el => el.sourceID === ds.sourceID) < 0) {
                                        dsList.push({...ds, id: Number.parseInt(ds.sourceID)});
                                    }
                                });
                            });
                            Promise.all(dsList.map(ds => {
                                if(ds.sourceID !== '0' && !ds.capacity){
                                    return capacityService.getCapacityByVariableId(Number.parseInt(ds.sourceID)).then(
                                        capResult => {
                                            const capacity = capResult.result;
                                            return deviceService.getVariablesByCapacity(capacity.id).then(
                                                capacityVariables => {
                                                    let sourceVariable = capacityVariables.find(el => el.id === Number.parseInt(ds.sourceID));
                                                    return {...sourceVariable, capacity: capacity, ...ds};
                                                }
                                            );
                                        }, () => {
                                            return {...ds};
                                        }
                                    );
                                }else{
                                    return ds;
                                }
                            })).then(dsFullList => {
                                setDataSourceList(dsFullList);
                            });
                        }
                    );
            }
        );
    }, [dashboardId]);
//обработчик события при изменении списка виджетов
    useEffect(() => {
        if (widgetList.findIndex(el => el.mode === dashboardConstants.EDIT_SINGLE_MODE) >= 0) {
            setBlocked(true);
        } else {
            setBlocked(false);
        }
    }, [widgetList]);


    //инициализация функции редактирования дашборда
    const saveDashboard = (e) => {
        e.preventDefault();
        setCreateDashboardForm({...createDashboardForm, isFetching: true});//обновление переменной состояния запроса
        dashboardService.editDashboard({ //вызов метода API для запроса редактирования дашборда
            id: dashboardId, //id дашбодра
            name: dashboard.name,//старое название дашборда
            newName: dashboardName,//новое название дашборда
            widgetList: widgetList//список виджетов
        })
            .then(resp => {//ответ на запрос
                if (resp.dashboardID && resp.widgetResults.findIndex(el => el.status === 'FAIL') < 0) {//проверка на ошибки при сохранении виджетов
                    setCreateDashboardForm({//обновление состояния переменной
                        ...createDashboardForm,
                        newDashboardID: resp.dashboardID,//ID дашборда
                        isFetching: false//состояние запроса
                    });
                } else {
                    setCreateDashboardForm({//обновление состояния переменной
                        ...createDashboardForm,
                        isFetching: false,//состояние запроса
                        error: localeService.isRussian() ? 'Не удалось сохранить дашборд!' : 'Cannot save dashboard' //обновление состояния ошибки
                    });
                }
            }, error => {//обработчик ошибки запроса
                setCreateDashboardForm({...createDashboardForm, isFetching: false, error: JSON.stringify(error)});//обновление состояния ошибки
            });
    };

//функция отмены изменения дашборда
    const closeSavingForm = () => {
        setCreateDashboardForm({
            isOpen: false,
            isFetching: false,
            newDashboardID: null,
            error: ''
        });
    };
//возврат сгенерированного HTML кода
    return (
        <SizeMe>{({size}) =>
            <>
                <AlertSuccess isOpen={isDeleted} onCancel={() => history.push('/')}
                              style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                              message={localeService.isRussian() ? 'Дашборд успешно удален   ' : 'The dashboard is successfully deleted   '}
                              isClosable={false}/>

                {!isDeleted &&
                <>
                    <nav className="page-header">
                        <ul className="nav-left">
                            <li><h5>{localeService.isRussian() ? 'Редактирование дашборда' : 'Edit Dashboard'}</h5></li>
                        </ul>
                        <ul className="nav-right">
                            <li>
                                <button className={'btn btn-outline-primary default'}
                                        onClick={e => {
                                            e.preventDefault();
                                            history.goBack();
                                        }
                                        }>
                                    {localeService.isRussian() ? 'Назад к просмотру дашборда' : 'Back to Dashboard'}
                                </button>
                            </li>
                            <li>
                                <button className={'btn btn-outline-success'} onClick={() => setCreateDashboardForm({
                                    ...createDashboardForm,
                                    isOpen: true
                                })}>{localeService.isRussian() ? 'Сохранить дашборд' : 'Save Dashboard'}</button>
                            </li>
                            <li>
                                <button className={'btn btn-outline-danger'}
                                        onClick={e => {
                                            e.preventDefault();
                                            setConfirmDelete(true);
                                        }
                                        }>
                                    {localeService.isRussian() ? 'Удалить дашборд' : 'Remove Dashboard'}
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <DndProvider backend={Backend}>
                        <div className={'container-fluid'}>
                            <div className={'row'}>
                                <WidgetCarousel/>
                            </div>
                            <div className={'dashboard-editor-container'}>
                                <h5>{localeService.isRussian() ? 'Окно размещения элементов' : 'Item Placement Window'}</h5>
                                {!isFetchingWidgetList &&
                                <DropZone>
                                    <ReactGridLayout className="dashboard-grid" layout={widgetList} cols={6}
                                                     rowHeight={80}
                                                     onLayoutChange={(items) => {
                                                         updateWidgetsPosition(items);
                                                     }}
                                                     isDraggable={!isBlocked}
                                                     width={size.width - 32} margin={[8, 8]}>
                                        {widgetList.map(widget => {
                                            return <div key={widget.id} data-grid={widget}>
                                                <Widget mode={widget.mode} widgetProps={widget}
                                                        sizeParams={{
                                                            w: widget.w,
                                                            h: widget.h,
                                                            width: (size.width / 6 * widget.w) - 80,
                                                            height: (100 * widget.h) - 120
                                                        }}/>
                                            </div>;
                                        })}
                                    </ReactGridLayout>
                                </DropZone>
                                }
                                {isFetchingWidgetList && <Loader/>}
                            </div>
                            {widgetList.length > 0 && <div className={'row'}>
                                <div className={'to-right'}>
                                    <button className={'btn btn-outline-success'} onClick={() => setCreateDashboardForm({
                                        ...createDashboardForm,
                                        isOpen: true
                                    })}>{localeService.isRussian() ? 'Сохранить дашборд' : 'Save Dashboard'}</button>
                                </div>
                            </div>
                            }

                        </div>

                    </DndProvider>
                    {/*вывод диалогового окна с формой для сохранения изменений редактирования дашборда*/}
                    <Modal isOpen={createDashboardForm.isOpen}
                           isClosable={false}
                           footer={false}
                           title={localeService.isRussian() ? 'Редактирование дашборда' : 'Edit Dashboard'}>
                        <form>
                            {/*ввод названия дашборда*/}
                            <InputText label={localeService.isRussian() ? 'Название дашборда' : 'Dashboard Name'}
                                       value={dashboardName} onChange={(text) => setDashboardName(text)}/>
                            {!createDashboardForm.isFetching && !createDashboardForm.newDashboardID && !createDashboardForm.error &&
                            <div className={'d-flex justify-content-end'}>
                                {dashboardName !== '' && <button className={'btn btn-outline-success'}
                                                                 onClick={(e) => saveDashboard(e)}>{t('save')}</button>}
                                <button className={'btn btn-outline-danger'}
                                        onClick={() => closeSavingForm()}>{t('cancel')}</button>
                            </div>}
                            {/*вызов компонента для отображения ошибки*/}
                            <AlertError isOpen={createDashboardForm.error !== ''} message={createDashboardForm.error}
                                        onCancel={() => closeSavingForm()}
                            />
                            {createDashboardForm.isFetching && <Loader
                                waitText={localeService.isRussian() ? 'Сохранение дашборда...' : 'Saving dashboard...'}/>}
                            {!createDashboardForm.isFetching && createDashboardForm.newDashboardID &&
                            <div className={'row'}>
                                <div
                                    className={'d-flex justify-content-end'}>{localeService.isRussian() ? 'Дашборд успешно создан!' : 'Dshboard is successfully created!'}
                                </div>
                                <div className={'d-flex justify-content-end'}>
                                    <Link to={'/dashboard/' + createDashboardForm.newDashboardID}>
                                        <button type="button" className={'btn btn-outline-success'}>Ok</button>
                                    </Link>
                                </div>
                            </div>
                            }
                        </form>
                    </Modal>

                    <Modal isOpen={confirmDelete}
                           isConfirm={true}
                           title={localeService.isRussian() ? 'Удаление дашборда' : 'Removing Dashboard'}
                           onCancel={() => setConfirmDelete(false)}
                           width={'400px'}
                           onSubmit={() => deleteDashboard()}>
                        {!isDeleting && localeService.isRussian() ? 'Вы уверенны, что хотите удалить дашборд?' : 'Are you sure, you want to delete Dashboard?'}
                        {isDeleting &&
                        <Loader
                            waitText={localeService.isRussian() ? 'Удаление дашборда...' : 'Deleting dashboard...'}/>}
                        <AlertError isOpen={error !== ''} isClosable={false} message={error}/>
                    </Modal>
                </>}
            </>
        }
        </SizeMe>
    );
}

EditDashboard.propTypes = {
    dashboardId: PropTypes.number,
    widgetList: PropTypes.array,
    clearDashboard: PropTypes.func,
    updateWidgetsPosition: PropTypes.func,
    uploadFunctionList: PropTypes.func,
    setEditedWidgets: PropTypes.func,
    setDataSourceList: PropTypes.func,
    updateDashboardList: PropTypes.func
};

const mapStateToProps = state => {
    const widgetList = state.dashboardReducer.widgetList;
    return {widgetList};
};
const mapDispatchToProps = {
    clearDashboard: clearDashboard,
    uploadFunctionList: uploadFunctionList,
    updateWidgetsPosition: updateWidgetsPosition,
    setEditedWidgets: setEditedWidgets,
    setDataSourceList: setDataSourceList,
    updateDashboardList: updateDashboardList
};
export default connect(mapStateToProps, mapDispatchToProps)(EditDashboard);
