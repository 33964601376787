import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import AddAdditionalParamsToCostCalculationWidget from '../../../components/AddAdditionalParamsToCostCalculationWidget';
import Loader from '../../../components/Loader';
import {
    capacityCostCalculationService,
    initAdditionalParams
} from '../../../services/capacityCostCalculation.service';
// import {localeService} from '../../../services/locale.service';
import {withSize} from 'react-sizeme';
import {updateWidget} from '../../../redux/actions/dashboardActions';

//компонент виджета для расчета стоимости электроэнергии
function CapacityCostView({widgetProps, datetimeFilter, analyticFunctionList, updateWidget}) {
    //инициализация функции для расчета
    const electricityCostFunction = analyticFunctionList.filter(el => el.name === 'electricity-cost-calculation');
    //инициализация библиотеки для перевода
    const {t} = useTranslation();
    //инициализация переменной для дополнительных параметров для расчета
    const [additionalParameters, setAdditionalParameters] = React.useState(initAdditionalParams(widgetProps, electricityCostFunction));
    //состояние загрузки
    const [isFetching, setFetching] = React.useState(false);
    //список методов для расчета
    const [capacityCostMethods, setCapacityCostMethods] = React.useState(capacityCostCalculationService.initMethods(widgetProps.parameters.method.name, 'ELECTRICITY_COST'));


    //функция-обработчик при первой загрузке компонента
    // React.useEffect(() => {
    //     submitFunction(additionalParameters);
    // }, [datetimeFilter]);

    //функция-обработчик нажатия кнопки для запуска расчета
    const submitFunction = (additionalArgs) => {
        if (electricityCostFunction.length === 1) {
            setFetching(true);
            capacityCostCalculationService.runCalculate(capacityCostMethods, additionalArgs, widgetProps, datetimeFilter).then(
                response => {
                    setCapacityCostMethods(response);

                    //добавляем настройки виджета в redux в предустановки
                    updateWidget({...widgetProps, parameters: {...widgetProps.parameters, presets: {additionalArgs: additionalArgs} }});
                }
            ).finally(() => setFetching(false));
        }
    };
    //рендеринг компонента
    return <div>
        <div className={'widget-management-console'}>
            {/*вывзов компонента для настройки дополнительных параметров*/}
            <AddAdditionalParamsToCostCalculationWidget funcArgs={additionalParameters}
                                                        onSubmit={(args) =>{
                                                            setAdditionalParameters(args);
                                                            submitFunction(args);
                                                        }
                                                            }/>
        </div>
        {/*загрузчик*/}
        {isFetching && <Loader/>}
        {!isFetching && /*widgetProps.parameters.viewType.code === 'COST_MAKING' &&*/
        <table className={'table'}>
            <thead>
            <tr>
                <th>{t('name')}</th>
                <th>{t('value')}</th>
            </tr>
            </thead>
            <tbody>
            {capacityCostMethods.map((method, mId) => {
                return <tr key={mId}>
                    <td>{method.description}</td>
                    <td>{method.value}</td>
                </tr>;
            })}
            <tr>
                <th>Итого</th>
                <th>{capacityCostMethods.reduce((total,item) => total + parseFloat(item.value), 0).toFixed(3) || ''}</th>
            </tr>
            </tbody>
        </table>
        }
        {/*{!isFetching && widgetProps.parameters.viewType.code === 'AVERAGE_COST' &&*/}
        {/*<table className={'table'}>*/}
            {/*<thead>*/}
            {/*<tr>*/}
                {/*<th>{t('name')}</th>*/}
                {/*<th>{t('value')}</th>*/}
            {/*</tr>*/}
            {/*</thead>*/}
            {/*<tbody>*/}
            {/*{capacityCostMethods.map((method, mId) => {*/}
                {/*return <tr key={mId}>*/}
                    {/*<td>{method.description}</td>*/}
                    {/*<td>{method.value.toFixed(3)}</td>*/}
                {/*</tr>;*/}
            {/*})}*/}
            {/*{capacityCostMethods[0] && capacityCostMethods[0].value !== '?' && capacityCostMethods[1] && capacityCostMethods[1].value !== '?' &&*/}
            {/*<tr>*/}
                {/*<th>{localeService.isRussian() ? 'Среднепривиденная цена' : 'Average cost'}</th>*/}
                {/*<th>{(capacityCostMethods[1].value / capacityCostMethods[0].value).toFixed(2)}</th>*/}
            {/*</tr>}*/}
            {/*</tbody>*/}
        {/*</table>*/}
        {/*}*/}

        {/*{!isFetching && widgetProps.parameters.viewType.code === 'ELECTRICITY_COST' &&*/}
        {/*<PieChart width={chartWidth}*/}
                  {/*height={chartHeight}>*/}
            {/*<Pie*/}
                {/*data={capacityCostMethods}*/}
                {/*dataKey={'value'}*/}
                {/*nameKey={'name'}*/}
                {/*innerRadius={0}*/}
                {/*outerRadius={Math.min(chartWidth, chartHeight) / 2 - 40}*/}
                {/*label={(labelProps) => {*/}
                    {/*return numeral(labelProps.value).format('0.0 a');*/}
                {/*}}*/}
                {/*labelLine={true}*/}
                {/*isAnimationActive={false}*/}
            {/*>*/}
                {/*{*/}
                    {/*capacityCostMethods.map((entry, index) => (*/}
                        {/*<Cell key={`cell-${index}`} fill={entry.color}/>*/}
                    {/*))*/}
                {/*}*/}
            {/*</Pie>*/}
            {/*<Legend/>*/}
            {/*<Tooltip/>*/}
        {/*</PieChart>}*/}

        {/*{!isFetching && widgetProps.parameters.viewType.code === 'ELECTRICITY_COST' &&*/}
        {/*<table>*/}
            {/*{capacityCostMethods.map((method, i) => (<tr key={`method${i}`}>*/}
                {/*<td>{method.description}</td>*/}
                {/*<td>{method.value}</td>*/}
            {/*</tr>))}*/}

        {/*</table>}*/}

    </div>;
}

CapacityCostView.propTypes = {
    widgetProps: PropTypes.object,
    datetimeFilter: PropTypes.object,
    analyticFunctionList: PropTypes.array,
    size: PropTypes.object,
    updateWidget: PropTypes.func
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    return {datetimeFilter, analyticFunctionList};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};

export default withSize()(connect(mapStateToProps, mapDispatchToProps)(CapacityCostView));