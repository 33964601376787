import React, {useState} from 'react';
import {localeService} from '../services/locale.service';
import svgRu from '../sources/images/interface/russia.svg';
import svgEn from '../sources/images/interface/england.svg';
import svgShow from '../sources/images/interface/show.svg';
import svgHide from '../sources/images/interface/hide.svg';
import { ReactSVG } from 'react-svg';
import {useTranslation} from 'react-i18next';
import AlertError from '../components/AlertError';
import {useHistory, useLocation} from 'react-router-dom';
import qs from 'query-string';
import {loginService} from '../services/login.service';
import AlertSuccess from '../components/AlertSuccess';


function ConfirmEmail() {
    let location = useLocation();
    const history = useHistory();

    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const token = qs.parse(location.search).token || '';
    const warningText = localeService.isRussian() ?
        'Пароль должен содержать не меньше 6-ти символов латинского алфавита, и состоять не менее чем из одной заглавной буквы, одного спец символа и одной цифры!'
        :
        'The password should contain at least 6 characters and consist of at least one capital letter, one special symbol and one digit!';

    function handleSubmit() {
        setError('');
        if (password && password2 && password === password2) {
            const reg = /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g;
            const test = reg.test(password);
            if (test) {
                setFetching(true);
                loginService.confirmEmail(password, token).then(
                    () => {
                        setSuccess(localeService.isRussian() ? 'Ваша учетная запись успешно подтверждена! Перейдте на страницу авторизации для входа в систему.' : 'Your account nas been succesfully confirm! ');
                    },(err) => {
                        setError('Error! ' + (err.errorCode || ''));
                    }
                ).finally(() => setFetching(false));
            } else{
                setError(warningText);
            }
        }else{
            if(password === ''){
                setError(localeService.isRussian() ? 'Введите пароль' : 'Enter password');
            } else if (password2 === '') {
                setError(localeService.isRussian() ? 'Повторите пароль' : 'Repeat password');
            } else if (password !== password2) {
                setError(localeService.isRussian() ? 'Пароли не совпадают' : 'Passwords don\'t match');
            }
        }
    }

    return <>
        <div className="registration-page">
                <span className="logo">
                    <b className="navbar-brand"
                       style={{
                           color: '@default-color',
                           fontSize: '32px',
                           lineHeight: '70px',
                           letterSpacing: '-1px',
                           fontKerning: 'none',
                           margin: '0 10px',
                           padding: 0
                       }}>
                            EMS
                        </b>
                </span><br/>
            <h5 style={{
                marginTop: '-1rem',
                marginBottom: '3rem'
            }}>{localeService.isRussian() ? 'Завершение регистрации' : 'Complete Registration'}</h5>
            {!success &&
            <form>
                <div className={'form-group'}>
                    <label
                        htmlFor="password">{localeService.isRussian() ? 'Придумайте пароль' : 'Enter password'}</label>
                    <div className="input-group understroke">
                        <input type={!showPassword ? 'password' : 'text'} className="form-control"
                               value={password} onChange={el => setPassword(el.target.value)}/>
                        <span className="input-group-addon">
                                <button type="button" className={'svg-btn'}
                                        onClick={() => setShowPassword(!showPassword)}>
                                    <img style={{width: 20, height: 20}} src={showPassword ? svgShow : svgHide}
                                         alt={'Show symbols'}/>
                                </button>
                            </span>
                    </div>
                </div>
                <div className={'form-group'}>
                    <label
                        htmlFor="password">{localeService.isRussian() ? 'Повторите пароль' : 'Repeat password'}</label>
                    <div className="input-group understroke">
                        <input type={!showPassword ? 'password' : 'text'} className="form-control"
                               value={password2} onChange={el => setPassword2(el.target.value)}/>
                        <span className="input-group-addon">
                                <button type="button" className={'svg-btn'}
                                        onClick={() => setShowPassword(!showPassword)}>
                                    <img style={{width: 20, height: 20}} src={showPassword ? svgShow : svgHide}
                                         alt={'Show symbols'}/>
                                </button>
                            </span>
                    </div>
                </div>
                <div className="form-group center">
                    {error === '' &&
                    <button type="button" className={'btn btn-outline-success'} onClick={handleSubmit}>
                        {isFetching ?
                            <small>{t('wait')}</small> : (localeService.isRussian() ? 'Подтвердить' : 'Confirm')}
                    </button>
                    }
                    <AlertSuccess isOpen={error == ''} message={warningText}/>
                    <AlertError isOpen={error !== ''} message={error} onCancel={() => setError('')}/>
                </div>
                <div className={'form-group'}>
                    <div className={'to-right'}>
                        <button type={'button'} className={'btn btn-outline-primary svg-btn mr1'}
                                onClick={() => localeService.setLanguage('ru')}>
                            <ReactSVG src={svgRu}/></button>
                        <button type={'button'} className={'btn btn-outline-primary svg-btn'}
                                onClick={() => localeService.setLanguage('en')}>
                            <ReactSVG src={svgEn}/>
                        </button>
                    </div>
                </div>
            </form>
            }
            <AlertSuccess isOpen={success !== ''} message={success} btnName={localeService.isRussian() ? 'Авторизация' : 'Authorize'} onCancel={() => {
                history.push('/login');
            }}/>
        </div>

    </>;
}

export default ConfirmEmail;