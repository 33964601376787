/* eslint-disable no-unused-vars */
import React from 'react';

import { scenarioConstants } from '../../constants';
import PropTypes from 'prop-types';
import { scenarioService } from '../../services/scenario.service';
import ScenarioEditor from './components/ScenarioEditor';

const AddScenario = ({ onCancel, onCreate }) => {
  const [selectedScenary] = React.useState({ ...scenarioConstants.SCENARIO_SAMPLE });
  const saveData = (scenario) => {
    delete scenario['scriptId'];
    scenarioService
      .saveScenario(scenario)
      .then(() => {
        onCreate(true, undefined);
      })
      .catch((err) => onCreate(false, err));
  };

  return (
    <div>
      {selectedScenary && (
        <ScenarioEditor
          onCancel={onCancel}
          scenarioModel={selectedScenary}
          onCreate={saveData}
        />
      )}
    </div>
  );
};
AddScenario.propTypes = {
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
};
export default AddScenario;
