import React from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import MnemoschemeWidgetCreate from './MnemoschemeWidgetCreate';
import MnemoschemeWidgetView from './MnemoschemeWidgetView';
import {withSize} from 'react-sizeme';
import {apiConstants} from '../../../constants/api.constants';
import {imageService} from '../../../services/image.service';

function MnemoScheme({widgetProps, mode, onCloseEditSingleMode, size, sizeParams}) {
    const [image, setImage] = React.useState(null);

    React.useEffect(() => {
        if(widgetProps.parameters && widgetProps.parameters.image){
            imageService.getImage(widgetProps.parameters.image)
                .then(image => {
                    setImage(image);
                });
        }
    }, []);

    return (
        <>
            {mode === dashboardConstants.VIEW_MODE &&
            <MnemoschemeWidgetView widgetProps={widgetProps} size={size}/>
            }

            {mode === dashboardConstants.EDITION_MODE && image &&
            <div className={'mnemoscheme'} style={{
                position: 'relative',
                width: sizeParams.width
            }}>
                <img src={image} style={{
                    userSelect: 'none',
                    maxWidth: sizeParams.width,
                    height: 'auto'
                }}
                />
            </div>}
            {mode === dashboardConstants.EDITION_MODE && !image &&
            <div className={'widget-preview'} style={{width: size.width - (12*2) , height: (70 * widgetProps.h) - 30 }}>
                {widgetProps.image && widgetProps.image.id && <img style={{maxWidth: '100%', maxHeight: '100%'}} src={apiConstants.API_URL + '/file/download?fileId=' + widgetProps.image.id}/>}
            </div>
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE}
                   width={800}
                   isClosable={false} footer={false} onCancel={() => onCloseEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <MnemoschemeWidgetCreate widgetProps={widgetProps} size={size} onClose = {() => onCloseEditSingleMode()}/>
            </Modal>
            }
        </>
    );
}

MnemoScheme.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    size: PropTypes.object,
    sizeParams: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};
export default withSize()(MnemoScheme);