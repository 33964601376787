import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Select from '../../components/Select';
import {localeService} from '../../services/locale.service';
import {connect} from 'react-redux';
import InputText from '../../components/InputText';
import {ReactSVG} from 'react-svg';
import svgDelete from '../../sources/images/interface/basket.svg';
import ColorPicker from '../ColorPicker';
import {dashboardService} from '../../services';
import {useTranslation} from 'react-i18next';
import AddLinkToDashboard from './components/AddLinkToDashboard';

function EditWidgetDataSource({
                                  dataSourceNumber, widgetDataSource, onChangeDataSource, dataSourceList, onDeleteDataSource,
                                  withColor, withUnit, removable, withAdditionalDataSources, isCollapsed, withLinkToDashboard
                              }) {

    const {t} = useTranslation();
    const initNewDS = {
        idx: new Date().getTime(),
        parameters: {
            dataSourceName: '',
            color: dashboardService.getRandomColor()
        }
    };

    const [dataSource, setDataSource] = useState(widgetDataSource);
    const [showAdditionalDS, setShowAdditionalDS] = useState(false);

    useEffect(() => {
        if (widgetDataSource.idx === 'new') {
            onChangeDataSource(initNewDS);
        }
        setDataSource(widgetDataSource.idx === 'new' ? initNewDS : widgetDataSource);
    }, [widgetDataSource]);

    const onChange = (ds) => {
        setDataSource({...dataSource, ...ds});
        onChangeDataSource({...dataSource, ...ds});
    };

    return (
        !isCollapsed ?
            <>
                {dataSource.idx !== 'new' &&
                <div className={'group'}>
                    <label
                        className={'group-header'}>{localeService.isRussian() ? 'Источник данных ' : 'Data Source '}{dataSourceNumber}</label>

                    {dataSourceList.length === 0 &&
                    <p>{localeService.isRussian() ? 'Добавьте источники данных дашборда на вкладке "Источники данных"' : 'Add Data Sources for Dashbord on the tab "Data Sources"'}</p>}

                    <div className={'d-flex justify-content-between'}>
                        {dataSourceList.length > 0 &&
                        <Select label={localeService.isRussian() ? 'Выберите источник данных' : 'Select Data Source'}
                                type={'datasource_with_info'}
                                valueList={dataSourceList || []}
                                value={widgetDataSource}
                                onSelect={(ds) => {
                                    let newDS = dataSource;
                                    if (newDS.additionalDataSources) {
                                        newDS.additionalDataSources.map((ads) => {
                                            ads.parameters.parentDataSource = ds.id;
                                        });
                                    }
                                    onChange({
                                        ...ds,
                                        sourceID: ds.id,
                                        parameters: {...newDS.parameters, dataSourceName: ds.name}
                                    });
                                }}
                                />}
                    </div>

                    <div className={'row'} style={{padding: 0, display: 'flex', width: '100%'}}>
                        <InputText label={localeService.isRussian() ? 'Имя источника данных' : 'Data Source Name'}
                                   value={dataSource.parameters.dataSourceName || ''}
                                   onChange={text => onChange({
                                       ...dataSource,
                                       parameters: {...dataSource.parameters, dataSourceName: text}
                                   })}/>
                    </div>

                    {withUnit && <InputText label={t('unit')}
                                            value={dataSource.parameters.unit || ''}
                                            onChange={text => onChange({
                                                ...dataSource,
                                                parameters: {...dataSource.parameters, unit: text}
                                            })}/>}
                    {withAdditionalDataSources &&
                    <button className={'btn btn-outline-primary default'} onClick={(e) => {
                        e.preventDefault();
                        setShowAdditionalDS(true);
                    }}>
                        {localeService.isRussian() ? 'Добавить дополнительные источники данных' : 'Add Additional Data Sources'}
                    </button>}
                    {widgetDataSource.additionalDataSources && showAdditionalDS &&
                    <>
                        <p><b>{localeService.isRussian() ? 'Дополнительные параметры' : 'Additional Data Sources'}</b>
                        </p>
                        {dataSource.additionalDataSources.map((ads, i) => {
                            return <Select key={'additional-ds-' + i} label={ads.parameters.type}
                                           valueList={dataSourceList || []}
                                           value={ads}
                                           onSelect={(ds) => {
                                               let additionalDSList = dataSource.additionalDataSources;
                                               additionalDSList[i] = {
                                                   sourceID: ds.id,
                                                   name: ds.name,
                                                   parameters: {...ads.parameters, dataSourceName: ds.name}
                                               };
                                               onChange({
                                                   ...dataSource,
                                                   additionalDataSources: additionalDSList
                                               });
                                           }}/>;
                        })}
                    </>
                    }

                    {withLinkToDashboard &&
                    <AddLinkToDashboard selectedDashboard={dataSource.parameters.linkToDashboard} onChange={(db) => {
                        onChange({
                            ...dataSource,
                            parameters: {...dataSource.parameters, linkToDashboard: {id: db.id, name: db.name}}
                        });
                    }}/>
                    }

                    <div className={'d-flex justify-content-between'}>
                        {withColor &&
                        <ColorPicker style={{margin: '0 1rem'}} color={dataSource.parameters.color} setColor={(color) =>
                            onChange({...dataSource, parameters: {...dataSource.parameters, color: color}})}/>}
                        {removable &&
                        <div className={'d-flex justify-content-end'}>
                            <button className={'btn btn-outline-danger'}
                                    title={localeService.isRussian() ? 'Удалить источник данных из виджета' : 'Delete Data Source'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onDeleteDataSource(dataSource);
                                    }}>
                                <ReactSVG src={svgDelete}/>
                                <span>{localeService.isRussian() ? 'Удалить источник данных из виджета' : 'Delete Data Source'}</span>
                            </button>
                        </div>
                        }
                    </div>

                </div>
                }
            </>
            : <>
                <div className={'group'}>
                    <label
                        className={'group-header'}>{localeService.isRussian() ? 'Источник данных ' : 'Data Source '}{dataSourceNumber}</label>
                    <div className={'row'}
                         style={{padding: 0, display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <p>{dataSource.parameters.dataSourceName || (localeService.isRussian() ? 'Источник данных не выбран' : 'Data Source is not selected')}</p>
                        {removable &&
                        <button className={'danger svg-btn ml1'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onDeleteDataSource(dataSource);
                                }}>
                            <ReactSVG src={svgDelete}/>
                        </button>
                        }
                    </div>
                </div>
            </>

    )
        ;
}

EditWidgetDataSource.propTypes = {
    widgetDataSource: PropTypes.object,
    onChangeDataSource: PropTypes.func,
    dataSourceList: PropTypes.array,
    dataSourceNumber: PropTypes.number,
    onDeleteDataSource: PropTypes.func,
    withColor: PropTypes.bool,
    withUnit: PropTypes.bool,
    removable: PropTypes.bool,
    withAdditionalDataSources: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    withLinkToDashboard: PropTypes.bool
};

EditWidgetDataSource.defaultProps = {
    withColor: true,
    withUnit: true,
    removable: true,
    withAdditionalDataSources: false,
    isCollapsed: false,
    withLinkToDashboard: false
};
const mapStateToProps = state => {
    const dataSourceList = state.dashboardReducer.dataSourceList;
    return {dataSourceList};
};
// const mapDispatchToProps = {
//     updateWidget: updateWidget
// };
export default connect(mapStateToProps, null)(EditWidgetDataSource);
