import React from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import svgWarning from '../sources/images/interface/warning.svg';

function InputText({label, value, onChange, isCeloe, isInteger, isDecimal, disable, warningText, isTextarea, placeHolder, inputType}) {

    const onChangeHandle = (text) => {
        if (isCeloe){
            text = text.trim().replace(/\./g, '');

            if (isNaN(text)) {

                if (text[0] === '-') {
                    text = text.replace(/\D/g, '');
                    text = '-' + text;
                } else {
                    text = text.replace(/\D/g, '');
                }
            }
            if (text[0] === '0' || text[1] === '0' && text[0]==='-') {
                text = text.replace('0','');
            }
        }

        if (isInteger) {
            text = text.trim().replace(/\./g, '');

            if (isNaN(text)) {

                if (text.substring(0, 1) === '-') {
                    text = text.replace(/\D/g, '');
                    text = '-' + text;
                } else {
                    text = text.replace(/\D/g, '');
                }
            }
            if(!parseInt(text)){
                text = '';
            }else{
                text = parseInt(text) + '';
            }
        }
        if (isDecimal) {
            text = text.replace(/[^0-9.]/g, '');
            if(text.startsWith('00')){
                text = '0';
            }

            if(text.indexOf('.') >= 0 && text.length > (text.indexOf('.') + 1)){
                text = parseFloat(text) + '';
            }
        }
        onChange(text);
        return text;
    };


    return <div className={'mb-3 layout-input-group'}>
            <label style={{whiteSpace: 'normal'}}>{label}</label>
        {!isTextarea &&
        <input className={'form-control'}
               disabled={disable}
               type={inputType}
               value={value}
               placeholder={placeHolder}
               onChange={e => {
                   e.preventDefault();
                   onChangeHandle(e.target.value);
               }}
               />
        }
        {isTextarea &&
        <textarea style={{width: '100%'}} rows={5}
                  className={'form-control'}
                  placeholder={placeHolder}
                  value={value}
                  onChange={el => onChangeHandle(el.target.value)}>
                        {value}
                    </textarea>
        }
        {warningText &&
        <div className={'warning-message'}>
            <ReactSVG src={svgWarning}/>
            <div>{warningText}</div>
        </div>}
    </div>;
}

InputText.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    isCeloe: PropTypes.bool,
    isInteger: PropTypes.bool,
    isDecimal: PropTypes.bool,
    disable: PropTypes.bool,
    warningText: PropTypes.string,
    isTextarea: PropTypes.bool,
    placeHolder: PropTypes.string,
    notInline: PropTypes.bool,
    inTable: PropTypes.bool,
    inputType: PropTypes.string,
    info: PropTypes.string,
    className: PropTypes.string
};

InputText.defaultProps = {
    label: '',
    value: '',
    onChange: () => {
    },
    isRequired: false,
    isCeloe: false,
    isInteger: false,
    isDecimal: false,
    disable: false,
    warningText: '',
    isTextarea: false,
    notInline: false,
    inputType: 'text',
    info: null,
    inTable: false,
    className: ''
};

export default InputText;