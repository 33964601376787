import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import {useTranslation} from 'react-i18next';
import InputText from '../../../components/InputText';
import EditWidgetDataSource from '../EditWidgetDataSource';
import Select from '../../../components/Select';

function CapacityProfileCreate({widgetProps, updateWidget, dashboardDataSources, analyticFunctionList}) {
    const [widget, setWidget] = useState({...widgetProps, parameters: {...widgetProps.parameters}});
    const {t} = useTranslation();
    const [functionArgs, setFunctionArgs] = useState({});
    
    const createDataSource = () => {
        const dataSources = [...widget.dataSources, {
            idx: new Date().getTime(), parameters: {dataSourceName: '', type: 'MAIN_DATASOURCE'},
            additionalDataSources: [
                {idx: 'peakHourDS', parameters: {dataSourceName: '', type: 'PEAK_HOURS_DATASOURCE'}},
            ]
        }];
        setWidget({...widget, dataSources: dataSources});
    };

    React.useEffect( () => {
        let dsList = [];
        widgetProps.dataSources.map(ds => {
            if (ds.parameters.type === 'MAIN_DATASOURCE') {
                let mainDataSource = ds;
                mainDataSource.additionalDataSources = [];
                widgetProps.dataSources.map(addDS => {
                    if (addDS.parameters.parentDataSource && addDS.parameters.parentDataSource === parseInt(mainDataSource.sourceID)) {
                        mainDataSource.additionalDataSources.push(addDS);
                    }
                });
                dsList.push(mainDataSource);
            }
        });
        setWidget({...widgetProps, dataSources: dsList});
    }, []);
    
    React.useEffect(() => {     
        // парсим функцию расчета электроэнергии для добавления обязательных параметров к виджету
        const electricityCostFunction = analyticFunctionList.filter(el => el.name === 'electricity-cost-calculation');
        if (electricityCostFunction.length === 1) {
            let funcArgs = {};
            const regionArg = electricityCostFunction[0].args.filter(el => el.name === 'region');
            if (regionArg.length === 1) {
                funcArgs.region = {
                    ...regionArg[0],
                    options: regionArg[0].options.map((op, i) => {
                        return {
                            id: i,
                            name: op,
                            code: op
                        };
                    })
                };
            }
            const retailerArg = electricityCostFunction[0].args.filter(el => el.name === 'retailer');
            if (retailerArg.length === 1) {
                funcArgs.retailer = {
                    ...retailerArg[0],
                    options: retailerArg[0].options.map((op, i) => {
                        return {
                            id: i,
                            name: op,
                            code: op
                        };
                    })
                };
            }          
            setFunctionArgs(funcArgs);
        }

    }, []);

    return (
        <div>
            <form>
                <InputText label={t('name')} value={widget.name}
                           onChange={(text) => setWidget({...widget, name: text})}/>

                {functionArgs.region &&
                <Select isRequired={true}
                        value={widget.parameters && widget.parameters.region || null}
                        valueList={functionArgs.region.options}
                        onSelect={region => setWidget({...widget, parameters: {...widget.parameters, region: region, method:'peak_hours'}})}
                        label={localeService.isRussian() ? 'Регион' : 'Region'}>
                </Select>
                }
                {functionArgs.retailer &&
                <Select isRequired={true}
                        value={widget.parameters && widget.parameters.retailer || null}
                        valueList={functionArgs.retailer.options}
                        onSelect={retailer => setWidget({...widget, parameters: {...widget.parameters, retailer: retailer}})}
                        label={localeService.isRussian() ? 'Код поставщика электроэнергии' : 'Electricity supplier code'}>
                </Select>
                }

                <div className={'row'}>
                    <p className={'help-text'}>
                        <b>{localeService.isRussian() ? 'Настройка источников данных' : 'Data Sources Configuration'}</b>
                        <br/>
                        {widget.dataSources.length === 0 && <p>{localeService.isRussian() ? 'Добавьте источник данных!' : 'Please, add Data Source!'}</p>}
                    </p>
                </div>
                {widget.dataSources.length > 0 && widget.dataSources.map((dataSource, key) => {
                    if (dataSource.parameters.type === 'MAIN_DATASOURCE')
                    return <EditWidgetDataSource key={key} withAdditionalDataSources={true} dataSourceNumber={key + 1} widgetDataSource={dataSource}
                                                 onChangeDataSource={(ds) => {
                                                     widget.dataSources[key] = ds;
                                                     setWidget(widget);
                                                 }}
                                                 withColor={false}
                                                 onDeleteDataSource={(dsIndex) => {
                                                     setWidget({
                                                         ...widget,
                                                         dataSources: widget.dataSources.filter(el => el.idx !== dsIndex)
                                                     });
                                                 }}
                    />;
                })}

                <div className={'d-flex justify-content-center'}>
                    {dashboardDataSources.length > 0 && widget.dataSources.length < 1 &&
                    <button type={'button'} className={'btn btn-outline-primary default'} id={'add-button'}
                            onClick={() => {
                                createDataSource();
                            }}
                    >                           
                        {localeService.isRussian() ? 'Добавить источник данных' : 'Add Data Source'}
                    </button>                  
                    }                      
                    <button type={'button'} className={'btn btn-outline-success'}
                            onClick={() => {
                                let allDataSources = [];
                                widget.dataSources.map((ds) => {
                                    if (ds.parameters.type === 'MAIN_DATASOURCE') {
                                        allDataSources = [...allDataSources, ds, ...ds.additionalDataSources];
                                    }
                                });
                                updateWidget({...widget, dataSources: allDataSources, mode: dashboardConstants.EDITION_MODE});
                                console.log(widget);
                            }}>
                        {localeService.isRussian() ? 'Сохранить' : 'Save'}
                    </button>
                    <button type={'button'} className={'btn btn-outline-danger'}
                            onClick={() => {
                                updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                            }}>
                        {t('cancel')}
                    </button>
                </div>
            </form>
        </div>
    );
}

CapacityProfileCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array,
    analyticFunctionList: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    return {dashboardDataSources, analyticFunctionList};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(CapacityProfileCreate);