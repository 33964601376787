import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import svgEdit from '../sources/images/interface/settings.svg';
import Modal from './Modal';
import {localeService} from '../services/locale.service';
import DictOfTime from './DictOfTime';
import {useTranslation} from 'react-i18next';
import InputTextLayOut from './InputTextLayOut';
import SelectLayOut from './SelectLayOut';

function AddAdditionalParamsToCostCalculationWidget({funcArgs, onSubmit}) {
    const {t} = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const [args, setArgs] = useState(funcArgs);

    React.useEffect(() => {
        setArgs(funcArgs);
    }, [funcArgs]);

    return <>
        <button type="button"
                className={'btn svg-btn primary'}
                style={{position: 'absolute', right: 0, top: 0, padding: 0, zIndex: 0}}
                onClick={() => setOpen(true)}>
            <ReactSVG src={svgEdit}/>
        </button>
        <Modal onCancel={() => setOpen(false)} isOpen={isOpen} width={800}
               onSubmit={() => {
                   setOpen(false);
                   onSubmit(args);
               }}
               isClosable={false}
               title={localeService.isRussian() ? 'Дополнительные параметры для расчетов' : 'Additional Parameters'}>
            <form>
                {args.map((param, i) => {
                    if (param.type === 'DICT_OF_TIME') {
                        return <DictOfTime key={'dot-' + i}
                                           value={param.values}
                                           label={t('analytic_parameter.' + param.name)}
                                           onChange={(newValues) => {
                                               const newArgs = (args.map(
                                                   el => {
                                                       if (el.name === param.name) {
                                                           return {...el, values: newValues};
                                                       } else {
                                                           return el;
                                                       }
                                                   }
                                               ));
                                               setArgs(newArgs);
                                           }}/>;
                    }
                    if (param.count > 0 && param.type !== 'DICT_OF_TIME') {
                        return [...Array(param.values.length).keys()].map((_, j) => {
                            if (param.type === 'FLOAT') {
                                return <InputTextLayOut key={'float-' + j + i}
                                                          info={param.info}
                                                          label={t('analytic_parameter.' + param.name) + (param.count > 1 ? (' (' + (j + 1) + ')') : '')}
                                                          value={param.values[j]}
                                                          isDecimal={true}
                                                          onChange={(text) => {
                                                              const newArgs = (args.map(
                                                                  el => {
                                                                      if (el.name === param.name) {
                                                                          let values = el.values;
                                                                          values[j] = text;//parseFloat(text);
                                                                          return {...el, values: values};
                                                                      } else {
                                                                          return el;
                                                                      }
                                                                  }
                                                              ));
                                                              setArgs(newArgs);
                                                          }}/>;
                            } else if (param.type === 'SELECT') {
                                let selectValues = [...Array(param.options.length).keys()].map((_, op) => {
                                    return {name: param.options[op], idx: op};
                                });
                                return <SelectLayOut key={'select-' + j + i}
                                               label={t('analytic_parameter.' + param.name)}
                                                     valueList={selectValues}
                                                     value={{name: (param.values[0] || '')}}
                                               onSelect={(val) => {
                                                   const newArgs = (args.map(
                                                       el => {
                                                           if (el.name === param.name) {
                                                               let values = el.values;
                                                               values[j] = val.name;//parseFloat(text);
                                                               return {...el, values: values};
                                                           } else {
                                                               return el;
                                                           }
                                                       }
                                                   ));
                                                   setArgs(newArgs);
                                               }}/>;
                            } else {
                                return <InputTextLayOut key={'param-' + i + j}
                                                  label={t('analytic_parameter.' + param.name)}
                                                  value={param.values[j]}
                                                  onChange={(text) => {
                                                      const newArgs = (args.map(
                                                          el => {
                                                              if (el.name === param.name) {
                                                                  let values = el.values;
                                                                  values[j] = text;
                                                                  return {...el, values: values};
                                                              } else {
                                                                  return el;
                                                              }
                                                          }
                                                      ));
                                                      setArgs(newArgs);
                                                  }}/>;
                            }
                        });
                    }
                })
                }
            </form>
        </Modal>

    </>;
}

AddAdditionalParamsToCostCalculationWidget.propTypes = {
    funcArgs: PropTypes.array,
    onSubmit: PropTypes.func
};

export default AddAdditionalParamsToCostCalculationWidget;