import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {useTranslation} from 'react-i18next';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import InputText from '../../../components/InputText';
import EditWidgetDataSource from '../EditWidgetDataSource';
import {dashboardService} from '../../../services';
import Select from '../../../components/Select';
import {widgetsConstants} from '../../../constants/widgets.constants';

function CapacityCostCreate({widgetProps, updateWidget, analyticFunctionList}) {
    const [widget, setWidget] = useState({...widgetProps, parameters: {...widgetProps.parameters, viewType: 'ELECTRICITY_COST'}});
    const {t} = useTranslation();

    const [functionArgs, setFunctionArgs] = useState({});

    React.useEffect(() => {

        // добавляем пустой источник данных по умолчанию
        if (widget.dataSources.length === 0) {
            const dataSources = [...widget.dataSources, {
                idx: new Date().getTime(),
                parameters: {
                    dataSourceName: '',
                    color: dashboardService.getRandomColor()
                }
            }];
            setWidget({...widget, dataSources: dataSources});
        }

        // парсим функцию расчета электроэнергии для добавления обязательных параметров к виджету
        const electricityCostFunction = analyticFunctionList.filter(el => el.name === 'electricity-cost-calculation');
        if (electricityCostFunction.length === 1) {
            let funcArgs = {};
            const regionArg = electricityCostFunction[0].args.filter(el => el.name === 'region');
            if (regionArg.length === 1) {
                funcArgs.region = {
                    ...regionArg[0],
                    options: regionArg[0].options.map((op, i) => {
                        return {
                            id: i,
                            name: op,
                            code: op
                        };
                    })
                };
            }
            const retailerArg = electricityCostFunction[0].args.filter(el => el.name === 'retailer');
            if (retailerArg.length === 1) {
                funcArgs.retailer = {
                    ...retailerArg[0],
                    options: retailerArg[0].options.map((op, i) => {
                        return {
                            id: i,
                            name: op,
                            code: op
                        };
                    })
                };
            }

            const methodArg = electricityCostFunction[0].args.filter(el => el.name === 'method');
            if (methodArg.length === 1) {
                funcArgs.method = {
                    ...methodArg[0],
                    options: methodArg[0].options.filter(el => (el !== 'energy_storage' && el !== 'peak_hours')).map((op) => {
                        return {
                            name: op,
                            code: op
                        };
                    })
                };
                funcArgs.method.options.push({
                    name: 'five_category_val_total',
                    code: 'five_category_val_total'});
                funcArgs.method.options.push({
                    name: 'six_category_val_total',
                    code: 'six_category_val_total'});
            }
            setFunctionArgs(funcArgs);
            console.log('funcArgs', funcArgs);
        }

    }, []);

    return <>
        <form>
            <InputText label={t('name')} value={widget.name}
                       onChange={(text) => setWidget({...widget, name: text})}/>

            {/*устанавливаем тип виджета (
            составляющие стоимости (таблица),
            среднепривиденная цена (таблица),
            стоимость электроэнергии (диаграмма))*/}
            {!widget.parameters.viewType && <Select label={localeService.isRussian() ? 'Тип виджета' : 'Widget Type'}
                    isRequired={true}
                    value={widget.parameters.viewType || null}
                    onSelect={(type) => setWidget({...widget, parameters: {...widget.parameters, viewType: type}})}
                    valueList={widgetsConstants.CAPACITY_COST_WIDGET_TYPES}/>}

            {widget.parameters.viewType && functionArgs.region &&
            <Select isRequired={true}
                    value={widget.parameters.region}
                    valueList={functionArgs.region.options}
                    onSelect={region => setWidget({...widget, parameters: {...widget.parameters, region: region}})}
                    label={localeService.isRussian() ? 'Регион' : 'Region'}>
            </Select>
            }
            {widget.parameters.viewType && functionArgs.retailer &&
            <Select isRequired={true}
                    value={widget.parameters.retailer}
                    valueList={functionArgs.retailer.options}
                    onSelect={retailer => setWidget({
                        ...widget,
                        parameters: {...widget.parameters, retailer: retailer}
                    })}
                    label={localeService.isRussian() ? 'Код поставщика электроэнергии' : 'Electricity supplier code'}>
            </Select>
            }
            {widget.parameters.viewType && functionArgs.method &&
            <Select isRequired={true}
                    translateName={true}
                    value={widget.parameters.method}
                    valueList={functionArgs.method.options}
                    onSelect={method => {
                        let w = {...widget};
                        w.parameters = {...widget.parameters, method: method};
                        if(['five_category_val_total','six_category_val_total'].includes(method.name)){
                            if(w.dataSources.findIndex(el => el.parameters.type==='ADDITIONAL_DATA_SOURCE') < 0) {
                                w.dataSources = [...widget.dataSources, {
                                    idx: new Date().getTime(),
                                    parameters: {
                                        dataSourceName: '',
                                        color: dashboardService.getRandomColor(),
                                        type: 'ADDITIONAL_DATA_SOURCE'
                                    }
                                }];
                            }
                        }else{
                            w.dataSources = widget.dataSources.filter(el => el.parameters.type !== 'ADDITIONAL_DATA_SOURCE');
                        }
                        setWidget(w);
                    }}
                    label={localeService.isRussian() ? 'Ценовая категория' : 'Cost category'}>
            </Select>
            }

            {widget.dataSources.map((ds, key) => {
                return <EditWidgetDataSource key={key}
                                             withAdditionalDataSources={false}
                                             dataSourceNumber={key + 1}
                                             widgetDataSource={ds}
                                             onChangeDataSource={(ds) => {
                                                 widget.dataSources[key] = ds;
                                                 setWidget(widget);
                                             }}
                                             onDeleteDataSource={() => {
                                                 let dsList = widget.dataSources;
                                                 dsList.splice(key, 1);
                                                 if (key >= 0) {
                                                     setWidget({...widget, dataSources: dsList});
                                                 }
                                             }}
                                             removable={false}
                                             withColor={false}
                                             withUnit={false}
                />;
            })
            }
            <div className={'d-flex justify-content-center'}>
                <button type={'button'} className={'btn btn-outline-success'}
                        onClick={() => {
                            updateWidget({...widget, mode: dashboardConstants.EDITION_MODE});
                        }}>
                    {localeService.isRussian() ? 'Сохранить' : 'Save'}
                </button>
                <button type={'button'} className={'btn btn-outline-danger'}
                        onClick={() => {
                            updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                        }}>
                    {t('cancel')}
                </button>
            </div>
        </form>
    </>;
}

CapacityCostCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array,
    analyticFunctionList: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    return {dashboardDataSources, analyticFunctionList};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(CapacityCostCreate);