import React from 'react';
import PropTypes from 'prop-types';

function CheckBox({label, checked, onChange, inTable, isDisabled, className, title}) {

    return <div className={'d-flex align-items-center checkbox-item ' + className} style={{cursor: isDisabled ? 'not-allowed' : 'pointer'}} title={title}
                onClick={() => {
                    if(!isDisabled) {
                        onChange(!checked);
                    }
                }}>
        <input className={'form-check-input' + (inTable ? 'in-table' : 'understroke')} type="checkbox" style={{pointerEvents: 'none'}}
               disabled={isDisabled}
               checked={checked} readOnly/>
        {label && <label className="ms-2 form-check-label" style={{cursor: isDisabled ? 'not-allowed' : 'pointer'}}>{label}</label>}

    </div>;
}

CheckBox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    inTable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string
};

CheckBox.defaultProps = {
    isDisabled: false
};

export default CheckBox;