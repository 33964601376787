import React from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants';
import {Cell, Pie, PieChart} from 'recharts';
import {widgetsService} from '../../../services/widget.service';

function PieWidgetPreview({widgetProps, sizeParams}) {

    const [previewData, setPreviewData] = React.useState([]);

    React.useEffect(()=> {
        setPreviewData(widgetsService.generateTestData(widgetProps.dataSources));
    }, []);

    return (
        <div>
            <PieChart width={sizeParams.width}
                      height={sizeParams.height - 40}>
                <Pie
                    data={previewData}
                    dataKey={'value'}
                    nameKey={'name'}
                    innerRadius={widgetProps.widgetType === dashboardConstants.WIDGET_DOUGHNUT_CHART ? (Math.min(sizeParams.width, sizeParams.height) / 2 - 60) : 0}
                    outerRadius={Math.min(sizeParams.width, (sizeParams.height - 40)) / 2}
                    labelLine={true}
                    isAnimationActive={false}
                >
            {
                previewData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color}/>
                ))
            }
                </Pie>
                </PieChart>
        </div>
    );
}

PieWidgetPreview.propTypes = {
    widgetProps: PropTypes.object,
    closeEditSingleMode: PropTypes.func,
    sizeParams: PropTypes.object
};

export default PieWidgetPreview;