import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ClickOutside from '../../../../components/ClickOutside';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../components/Modal';
import {localeService} from '../../../../services/locale.service';
import {dashboardService} from '../../../../services/index';
import InputText from '../../../../components/InputText';
import ColorPicker from '../../../ColorPicker';
import Select from '../../../../components/Select';
import AlertError from '../../../../components/AlertError';
import Loader from '../../../../components/Loader';
import AddAnalyticParametersToChart from '../../../../components/AddAnalyticParametersToChart';
import {analyticService} from '../../../../services/analyticService';
import {PredictionFunctionList} from '../../../../constants/analytics.constants';

//компонент для расчета прогнозирования
function AddPredictionFunctionToChart({onApplyFunction, analyticFunctionList, dataSources, datetimeFilter}) {
    //библиотека перевода
    const {t} = useTranslation();
    //инициализация переменной состояния выпадающего списка
    const [isDropDownOpened, setDropDownOpened] = useState(false);
    //инициализация переменной выбранной функции
    const [selectedFunction, setSelectedFunction] = useState(null);
    //инициализация переменной состояния диалогового окна
    const [isOpenConfigurator, setOpenConfigurator] = useState(false);
    //инициализация переменной ошибки
    const [error, setError] = useState(null);
    //инициализация переменной состояния загрузки
    const [isFetching, setFetching] = useState(false);
    //функция-обработчик выбора функции
    const onSelectFunction = (func) => {
        //обновление состояния переменной выбранной функции
        setSelectedFunction({
            ...func,
            idx: new Date().getTime(),
            functionParams: analyticService.initTransformationFunctionParameters(func),
            parameters: {
                dataSourceName: 'F(x): ' + func.name,
                color: dashboardService.getRandomColor(),
                dataSource: dataSources.length === 1 ? {
                    id: dataSources[0].id,
                    name: dataSources[0].parameters.dataSourceName
                } : null
            },
            args: analyticService.initAnalyticFunctionParameters(func, datetimeFilter)
        });
        setDropDownOpened(false);
        setOpenConfigurator(true);
    };
    //функция-обработчик применения параметров и запрос расчета аналитики
    const onSubmitFunction = () => {
        //обновление состояния загрузки
        setFetching(true);
        const dataSourceId = selectedFunction.parameters.dataSource.id;
        //запрос расчета прогнозирования
        analyticService.getAnalyticWithResultId(dataSourceId, selectedFunction, datetimeFilter).then(
            response => {
                //обработчик ответа на запрос
                if (!response || !response.data || response.data.length === 0) {
                    setError(localeService.isRussian() ? 'Не удалось применить функцию. Попробуйте использовать другие параметры.' : 'Cannot apply function. Try to change function parameters.');
                } else {
                    //формирование объекта для просмотра результата расчета
                    const functionDataSource = {
                        ...selectedFunction,
                        resultId: response.resultId,
                        data: response.data,
                        dataSourceId: dataSourceId,
                        datetimeFilter: datetimeFilter
                    };
                    //обновление состояний переменных для отображения формы
                    onApplyFunction(functionDataSource);
                    setOpenConfigurator(false);
                    setDropDownOpened(false);
                    setSelectedFunction(null);
                    onApplyFunction(functionDataSource);
                }
            }
        ).finally(
            () => {
                setFetching(false);
            }
        );
    };
    //функция-обработчик применения параметров и запрос расчета аналитики для предиктора Хольта-Винтерса
    const onSubmitHolterWintersFunction = () => {
        setFetching(true);
        dashboardService.getWidgetFunctionData(selectedFunction.parameters.dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate,
            selectedFunction.functionCode, selectedFunction.functionParams)
            .then(
                result => {
                    const functionDataSource = {
                        ...selectedFunction,
                        data: result.list
                    };
                    return functionDataSource;
                }, () => {
                    setError(localeService.isRussian() ? 'Не удалось применить функцию. Попробуйте использовать другие параметры.' : 'Cannot apply function. Try to change function parameters.');
                }
            ).then(
            functionDataSource => {
                setOpenConfigurator(false);
                setDropDownOpened(false);
                setSelectedFunction(null);
                onApplyFunction({...functionDataSource, args: selectedFunction.args});
            }
        ).finally(
            () => {
                setFetching(false);
            }
        );
    };
    //возврат сгенерированного HTML кода
    return (<>
        <div className={'dropdown-container function-list' + (isDropDownOpened ? ' open' : '')}>
            {/*кнопка для открытия выпадающего списка функций*/}
            <button className={'btn btn-outline-primary default round-btn'}
                    title={localeService.isRussian() ? 'Функции прогнозирования' : 'Prediction Functions'}
                    onClick={() => {
                setDropDownOpened(!isDropDownOpened);
                setSelectedFunction(false);
            }}>
                <span style={{fontStyle: 'italic', fontSize: '0.8rem', fontWeight: 'bold'}}>P</span>
            </button>
            <ClickOutside onClick={() => setDropDownOpened(false)}>
                <div className={'dropdown' + (isDropDownOpened ? ' open' : '')}>
                    <ul>
                        {/*перебор всех аналитических функций*/}
                        {analyticFunctionList.map((func, i) => {
                            {/*проверка на вывод только функций прогнозирования*/}
                            if (PredictionFunctionList.includes(func.code)) {
                                return <li key={'analytic-' + i} onClick={() => onSelectFunction({
                                    ...func,
                                    functionCode: func.code,
                                    sourceType: 'ANALYTIC_FUNCTION'
                                })}>
                                    <span>{t('functionName.' + func.name)}</span>
                                </li>;
                            }
                        })}
                    </ul>
                </div>
            </ClickOutside>
            {/*модальное окно для конфигурации параметров расчета выбранной функции*/}
            <Modal onCancel={() => {
                setOpenConfigurator(false);
                setSelectedFunction(null);
            }} footer={false} isClosable={false}
                   isOpen={isOpenConfigurator}
                   title={localeService.isRussian() ? 'Параметры применения функции' : 'Function Configuration'}>
                {selectedFunction && !error && !isFetching &&
                <form>
                    {/*выбор источника данных, по которому производится расчет*/}
                    <Select label={localeService.isRussian() ? 'Источник данных' : 'Data Source'}
                            placeholder={localeService.isRussian() ? 'Выберите источник данных' : 'Select data source'}
                            valueList={dataSources.map(ds => {
                                return {id: ds.id, name: ds.parameters.dataSourceName};
                            })}
                            onSelect={(ds) => setSelectedFunction({
                                ...selectedFunction,
                                parameters: {...selectedFunction.parameters, dataSource: ds}
                            })}
                            defaultValue={dataSources.length === 1 ? {
                                id: dataSources[0].id,
                                name: dataSources[0].parameters.dataSourceName
                            } : null}/>
                    {/*настройка параметров отображения графика расчета - название и цвет*/}
                    <div className={'d-flex justify-content-between'}>
                        <InputText label={t('name')}
                                   value={selectedFunction.parameters.dataSourceName}
                                   onChange={text => setSelectedFunction({
                                       ...selectedFunction,
                                       parameters: {...selectedFunction.parameters, dataSourceName: text}
                                   })}/>
                        <ColorPicker style={{margin: '0 1rem'}} color={selectedFunction.parameters.color}
                                     setColor={(color) =>
                                         setSelectedFunction({
                                             ...selectedFunction,
                                             parameters: {...selectedFunction.parameters, color: color}
                                         })}/>
                    </div>
                    {/*добавление настройки для аргумента функции*/}
                    {selectedFunction && selectedFunction.args && selectedFunction.args.length > 0 &&
                    <AddAnalyticParametersToChart datetimeFilter={datetimeFilter} demandFunction={selectedFunction}
                                                   onChangeParam={func => {
                                                       setSelectedFunction({...selectedFunction, ...func});
                                                   }}/>
                    }
                    {/*добавление настройки для аргументов предиктора Хольта-Винтерса*/}
                    {selectedFunction.sourceType === 'TRANSFORMATION_FUNCTION' &&
                    <div style={{marginTop: '20px'}}>{
                        selectedFunction.functionParams.map((param, i) => {
                            return (<InputText key={'funcParam' + i}
                                               label={selectedFunction.code === 'HOLT_WINTERS' ?
                                                   (t('holtWintersArgs.' + i))
                                                   :((localeService.isRussian() ? 'Параметр ' : 'Parameter ') + (i + 1))}
                                // isDecimal={true}
                                               value={param}
                                               onChange={(text) => {
                                                   let params = selectedFunction.functionParams;
                                                   params[i] = text;
                                                   setSelectedFunction({
                                                       ...selectedFunction,
                                                       functionParams: params
                                                   });
                                               }}
                            />);
                        })
                    }
                    </div>
                    }
                    <hr/>
                    <div className={'d-flex justify-content-center'}>
                        {/*кнопка для применения параметров и запуска расчета*/}
                        <button type="button" className={'btn btn-outline-success'} onClick={(e) => {
                            e.preventDefault();
                            if (selectedFunction.parameters.dataSource === null) {
                                setError(localeService.isRussian() ? 'Выберите источник данных, к которому необходимо применить функцию' : 'Select Data Source');
                            } else {
                                // setOpenConfigurator(false);
                                if(selectedFunction.functionCode === 'HOLT_WINTERS'){
                                    onSubmitHolterWintersFunction();
                                }else{
                                    onSubmitFunction();
                                }

                            }
                        }}>{t('apply')}</button>
                        {/*кнопка для отмены расчета*/}
                        <button className={'btn btn-outline-danger'} onClick={() => {
                            setOpenConfigurator(false);
                            setSelectedFunction(null);
                        }}>{t('cancel')}</button>
                    </div>
                </form>
                }
                {/*отображение загрузчика*/}
                {isFetching &&
                <Loader waitText={localeService.isRussian() ? 'Загрузка функции...' : 'Loading function...'}/>}
                {/*отображение ошибки*/}
                <AlertError isOpen={error !== null} onCancel={() => setError(null)} message={error}/>
            </Modal>
        </div>
    </>);
}

AddPredictionFunctionToChart.propTypes = {
    analyticFunctionList: PropTypes.array,
    transformationFunctionList: PropTypes.array,
    onApplyFunction: PropTypes.func,
    dataSources: PropTypes.array,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    const transformationFunctionList = state.analyticReducer.transformationFunctionList;
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {analyticFunctionList, datetimeFilter, transformationFunctionList};
};

export default connect(mapStateToProps, null)(AddPredictionFunctionToChart);