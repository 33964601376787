import {dashboardConstants} from '../../constants';
import moment from 'moment';
import {dashboardService} from '../../services';

const initialState = {
    widgetList: [],
    datetimeFilter: {
        startDate:  moment().subtract(30, 'minutes').utc(),
        finishDate: moment.utc(),
        datetimeRange: dashboardConstants.DATETIME_FILTER_LIST[5],
        interval: dashboardConstants.TIMER_INTERVAL_LIST[0]
    },
    dataSourceList: [],
    zoomHistory: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case dashboardConstants.UPDATE_DASHBOARD_LIST: {
            return {...state, dashboardList: action.dashboardList};
        }
        case dashboardConstants.UPDATE_DASHBOARD_TAG_LIST: {
            return {...state, dashboardTagList: action.dashboardTagList};
        }
        case dashboardConstants.ADD_WIDGET: {
            const widget = {
                id: 'new_' + new Date().getTime(),
                type: action.widget.type,
                widgetTypeId: action.widget.id,
                widgetType: action.widget.widgetType,
                w: action.widget.additional.minWidth,
                h: action.widget.additional.minHeight,
                x: 0,
                y: 0,
                minW: action.widget.minWidth,
                minH: action.widget.minHeight,
                maxW: action.widget.maxWidth,
                maxH: action.widget.maxHeight,
                draggableHandle: 'draggable-handle',
                isDraggable: true,
                name: '',
                image: action.widget.img,
                dataSources: [],
                mode: dashboardConstants.EDITION_MODE
            };
            const newWidgetList = state.widgetList;
            newWidgetList.map(el => {
                el.y = el.y += widget.h;
            });
            return {...state, widgetList: [...newWidgetList, widget]};
        }
        case dashboardConstants.UPDATE_DASHBOARD_WIDGETS_POSITION: {
            let widgetList = state.widgetList.map((widget) => {
                const newWidget = action.widgetList.find(el => el.i === widget.id.toString());
                if(newWidget){
                    widget.w = newWidget.w;
                    widget.h = newWidget.h;
                    widget.x = newWidget.x;
                    widget.y = newWidget.y;
                }

                return widget;
            });
            return {...state, widgetList: widgetList };
        }
        case dashboardConstants.SET_DATASOURCE_LIST: {
            return {...state, dataSourceList: action.dataSourceList};
        }
        case dashboardConstants.UPDATE_WIDGET: {
            let newItems = state.widgetList.filter(el => el.id !== action.widget.id);
            return {...state, widgetList: [...newItems, action.widget]};
        }
        case dashboardConstants.GET_WIDGET_LIST: {
            return state.widgetList;
        }
        case dashboardConstants.CLEAR_DASHBOARD: {
            return {...state, widgetList: [],
                datetimeFilter: {
                    startDate:  moment().subtract(30, 'minutes').utc(),
                    finishDate: moment.utc(),
                    datetimeRange: dashboardConstants.DATETIME_FILTER_LIST[5],
                    interval: dashboardConstants.TIMER_INTERVAL_LIST[0]
                },
                dataSourceList: [],
                zoomHistory: []};
        }
        case dashboardConstants.DELETE_WIDGET: {
            if(!action.widgetId.toString().startsWith('new_')){
                dashboardService.deleteWidget(action.widgetId);
            }
            const newItems = state.widgetList.filter(el => el.id !== action.widgetId);
            return {...state, widgetList: newItems};
        }
        case dashboardConstants.SET_DATETIME_FILTER: {
            return {...state,
                datetimeFilter: {...action.datetimeFilter,
                    timeGroup: dashboardService.calculateTimeGroup(action.datetimeFilter)
                }, zoomHistory: []};
        }
        case dashboardConstants.ZOOM_OUT: {
            const zoomHistory = state.zoomHistory;
            return {...state, datetimeFilter: zoomHistory[zoomHistory.length - 1], zoomHistory: zoomHistory.slice(0, -1)};
        }
        case dashboardConstants.ZOOM_IN: {
            return {...state, datetimeFilter: {...action.datetimeFilter,
                    timeGroup: dashboardService.calculateTimeGroup(action.datetimeFilter)
                }, zoomHistory: [...state.zoomHistory, state.datetimeFilter]};
        }
        case dashboardConstants.SET_EDITED_WIDGETS: {
            let widgetList = action.widgetList.map((widget) => {
                return {...widget,
                    w: widget.parameters.w,
                    h: widget.parameters.h,
                    x: widget.parameters.x,
                    y: widget.parameters.y,
                    mode: dashboardConstants.EDITION_MODE} ;
            });
            return {...state, widgetList: widgetList };
        }
        default:
            return state;
    }
}
