import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import moment from 'moment';
import Popover from '../../../components/Popover';
import InputText from '../../../components/InputText';
import Modal from '../../../components/Modal';
import {useTranslation} from 'react-i18next';
import {dashboardService} from '../../../services/dashboard.service';
import Loader from '../../../components/Loader';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';

function DataSetterView({widgetProps}) {
    const {t} = useTranslation();
    const [dsValues, setDSValues] = useState(widgetProps.dataSources.map(ds => {
        return {id: ds.id, name: ds.parameters.dataSourceName, value: ''};
    }));

    const [isOpen, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(null);
    const [isFetching, setFetching] = useState(false);

    const setInitialState = () => {
        setError('');
        setSuccess(null);
        setDSValues(widgetProps.dataSources.map(ds => {
            return {id: ds.id, name: ds.parameters.dataSourceName, value: ''};
        }));
    };

    const setData = () => {

        let isValid = true;
        dsValues.map((ds) => {
            if (ds.value === undefined || ds.value === null || ds.value === '') {
                isValid = false;
            }
        });

        if (!isValid) {
            setError(localeService.isRussian() ? 'Заполните все поля' : 'Fill all the fields!');
        } else {
            setFetching(true);

            Promise.all(
                dsValues.map((ds) => {
                    const dateSet = moment().add(widgetProps.parameters.timeOffset, 'hours').format('YYYY-MM-DD HH:mm:ss');
                    return dashboardService.widgetDataSet(ds.id, dateSet, ds.value).then(
                        result => {
                            return result;
                        }
                    );
                })
            ).then(
                result => {
                    let error = '';
                    let success = true;
                    result.map((r) => {
                        if (r.status !== 'SUCCESS') {
                            success = false;
                            error += (' ' + r.errorMsg + '. ');
                        }
                    });

                    setError(error);
                    setSuccess(success);
                }
            ).finally(() => setFetching(false));
        }

    };


    return <>
        <div style={{
            height: (100 * widgetProps.h) - (widgetProps.name !== '' ? 40 : 0),
            display: 'flex',
            alignItems: 'center'
        }}>
            <button className={'btn btn-outline-success form-control'}
                    onClick={() => setOpen(true)}>{widgetProps.parameters.btnName}</button>
        </div>
        <Modal title={widgetProps.name} isOpen={isOpen} isClosable={false} footer={false}
               onCancel={() => setOpen(false)}>
            {!isFetching && error === '' && success === null && <form>
                <div className={'row'} style={{position: 'relative'}}>
                    <Popover>
                        <p className={'text-helper'}>{(localeService.isRussian() ? 'Время записи: ' : 'Record time: ') + moment().add(widgetProps.parameters.timeOffset, 'hours').format('DD MMM YYYY HH:mm:ss')}</p>
                    </Popover>
                </div>
                {dsValues.map((dsValue, i) => {
                    return <InputText label={dsValue.name} key={i}
                                      value={dsValue.value}
                                      isDecimal={true}
                                      onChange={(text) => setDSValues(dsValues.map(el => el.id === dsValue.id ? {
                                          ...el,
                                          value: text
                                      } : el))}/>;
                })}
                <div className={'d-flex justify-content-center'}>
                    <button className={'btn btn-outline-success'}
                            onClick={(e) => {
                                e.preventDefault();
                                setData();
                            }}>{widgetProps.parameters.btnName}</button>
                    <button className={'btn btn-outline-primary default'}
                            onClick={() => setOpen(false)}>{t('cancel')}</button>
                </div>
            </form>
            }
            {isFetching && <Loader/>}
            <AlertError isOpen={error !== ''} message={error} onCancel={() => setError('')}/>
            <AlertSuccess isOpen={success === true}
                          onCancel={() => {
                              setOpen(false);
                              setInitialState();
                          }}
                          message={localeService.isRussian() ? 'Данные были успешно записаны' : 'The data was successfully recorded'}/>
        </Modal>
    </>;
}

DataSetterView.propTypes = {
    widgetProps: PropTypes.object
};

export default DataSetterView;