import {analyticsConstants} from '../../constants/analytics.constants';

const initialState = {
    transformationFunctionList: [],
    analyticFunctionList: [],
    aggregationFunctionList: [],
    scriptList: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case analyticsConstants.GET_TRANSFORMATION_FUNCTION_LIST: {
            return {...state, transformationFunctionList: action.list};
        }
        case analyticsConstants.GET_ANALYTIC_FUNCTION_LIST: {
            return {...state, analyticFunctionList: action.analyticFunctionList};
        }
        case analyticsConstants.GET_SCRIPT_LIST:
            return {...state, scriptList: action.scriptList};
        case analyticsConstants.UPLOAD_FUNCTION_LIST: {
            return {...state, ...action.functionList};
        }
        default:
            return state;
    }
}


