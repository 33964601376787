import React, {useEffect, useState} from 'react';
import '../../styles/scenario.scss';
import {scenarioService} from '../../services/scenario.service';
import {useTranslation} from 'react-i18next';
import Loader from '../../components/Loader';
import {ReactSVG} from 'react-svg';
import PropTypes from 'prop-types';
import ListView from '../../components/ListView';
import svgPower from '../../sources/images/power.svg';
import svgStop from '../../sources/images/stop.svg';
import svgEdit from '../../sources/images/interface/edit.svg';
import svgCancel from '../../sources/images/interface/cancel.svg';
import svgCode from '../../sources/images/process.svg';
import Modal from '../../components/Modal';
import '../../styles/passport-view.scss';
import '../../styles/passport-view.scss';
import '../../styles/list-view.scss';

import AddScenario from './AddScenario';
import EditScenario from './EditScenario';
import {useHistory} from 'react-router-dom';
import {localeService} from './../../services/locale.service';
import SmartLoader from '../../components/SmartLoader';
import scenarioTransform from '../../utils/scenarioTransform';

const ScenarioList = ({capacity}) => {
    const history = useHistory();
    const [activeModal, setActiveModal] = React.useState(false);
    const [activeEditModal, setActiveEditModal] = React.useState(false);
    const [selectedScenary, setSelectedScenary] = React.useState(undefined);
    const [scenaryList, setList] = React.useState([]);
    const [isFetch, setFetching] = useState(true);
    const {t} = useTranslation();
    const [formStatus, setFormStatus] = useState(undefined);

    const updateList = () => {
        
        if (capacity) {
            scenarioService
                .getScenarioList()
                .then((data) => {
                    setList(data.list.filter(scenario => {
                        if (scenario.capacityViews && scenario.capacityViews.length > 0) {
                            const ids = scenario.capacityViews.map(({id}) => id);
                            if (ids.includes(capacity.id)) {
                                return true;
                            }
                        }
                        return false;
                    }).map(scenario => scenarioTransform(scenario)));

                    setFetching(false);

                })
                .catch(() => {

                    setFetching(false);
                });
        } else {
            setFetching(false);
        }
    };
    const startScript = (scenario, actionType) => {
        if (actionType === 'ACTIVATE') {
            scenario.active = !scenario.active;
            scenario.scriptId = scenario.id;

            scenarioService.saveScenario(scenario).then(() => {
                updateList();
            });
        } else {
            scenarioService.deleteScenario(scenario.id).then(() => {
                updateList();
            });
        }
    };
    const changeFormStatus = (status, modalAction) => {
        if (status) {
            updateList();
            modalAction(false);
        } else setFormStatus(false);
    };

    useEffect(() => {
        updateList();

    }, [capacity]);
    return (
        <section className="passport-list">
            {isFetch && <Loader waitText={t('wait')}/>}
            <div className="mr1 ml1">
                <ListView
                    img={svgCode}
                    src={scenaryList.sort((a, b) => a.id - b.id)}
                    renderName={(source) => source.name}
                    onClick={() => {
                    }}
                    viewAllText={localeService.isRussian() ? 'Cмотреть все' : 'View all'}
                    onViewAll={() => history.push('/scenario_list')}
                    statusRender={(source) =>
                        source.active === true
                            ? localeService.isRussian()
                            ? 'Активен'
                            : 'Enabled'
                            : localeService.isRussian()
                            ? 'Не активен'
                            : 'Disabled'
                    }
                    footerAction={() => (
                        <React.Fragment>
                            <button
                                type="button"
                                className={'btn btn-outline-success'}
                                onClick={() => {
                                    setActiveModal(true);
                                }}
                            >
                                {localeService.isRussian()
                                    ? 'Создать сценарий'
                                    : 'Create scenario'}
                            </button>
                        </React.Fragment>
                    )}
                    renderActions={(source) => (
                        <React.Fragment>
                            <div>
                                <button
                                    className={'btn svg-btn'}
                                    onClick={() => {
                                        startScript(source, 'ACTIVATE');
                                    }}
                                    title={t(
                                        source.active ? 'scenario.activate' : 'scenario.stop'
                                    )}
                                >
                                    <ReactSVG src={source.active ? svgStop : svgPower}/>
                                </button>
                            </div>
                            <div>
                                <button
                                    className={'btn svg-btn'}
                                    title={t('edit')}
                                    onClick={() => {
                                        setSelectedScenary(source);
                                        setActiveEditModal(true);
                                    }}
                                >
                                    <ReactSVG src={svgEdit}/>
                                </button>
                            </div>
                            <div>
                                <button
                                    className={'btn svg-btn'}
                                    title={t('delete')}
                                    onClick={() => {
                                        startScript(source, 'REMOVE');
                                    }}
                                >
                                    <ReactSVG src={svgCancel}/>
                                </button>
                            </div>
                        </React.Fragment>
                    )}
                />
            </div>

            <Modal
                title={'Создание сценария'}
                isOpen={activeModal}
                // isClosable={false}
                footer={false}
                width={800}
                // onCancel={() => {
                //     setActiveModal(false);
                // }}
            >
                <SmartLoader
                    waitText="Загрузка сценария"
                    status={formStatus}
                    failText="Сценарий создан не успешно"
                    actionButton={() => (
                        <React.Fragment>
                            <button
                                className="ml1 btn btn-outline-primary default "
                                onClick={() => setActiveModal(false)}
                            >
                                Принять
                            </button>
                        </React.Fragment>
                    )}
                >
                    <AddScenario
                        onCreate={(status) => {
                            changeFormStatus(status, setActiveModal);
                        }}
                        onCancel={() => setActiveModal(false)}
                    />
                </SmartLoader>
            </Modal>

            <Modal
                title={'Изменение сценария'}
                isOpen={activeEditModal}
                footer={false}
                isClosable={false}
                width={800}
                onCancel={() => {
                    setActiveEditModal(false);
                }}
            >
                <SmartLoader
                    waitText="Загрузка сценария"
                    failText="Сценарий изменен не успешно"
                    status={formStatus}
                    actionButton={() => (
                        <React.Fragment>
                            <button
                                className="ml1 btn btn-outline-primary default "
                                onClick={() => setActiveEditModal(false)}
                            >
                                Принять
                            </button>
                        </React.Fragment>
                    )}
                >
                    {selectedScenary && (
                        <EditScenario
                            scenario={selectedScenary}
                            onCreate={(status) => {
                                changeFormStatus(status, setActiveEditModal);
                            }}
                            onCancel={() => setActiveEditModal(false)}
                        />
                    )}
                </SmartLoader>
            </Modal>
        </section>
    );
};
ScenarioList.propTypes = {
    capacity: PropTypes.object,
};
export default ScenarioList;
