import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import '../styles/sidebar.scss';
import dashboardSvg from '../sources/images/interface/dashboard.svg';
import addSvg from '../sources/images/interface/add.svg';
import userSvg from '../sources/images/interface/user2.svg';
import {ReactSVG} from 'react-svg';
import {localeService} from '../services/locale.service';
import 'react-contexify/dist/ReactContexify.css';

import {connect} from 'react-redux';
import {updateDashboardList, updateDashboardTagList} from '../redux/actions/dashboardActions';
import {dashboardService} from '../services/dashboard.service';

import {Item, Menu, Separator, Submenu, useContextMenu} from 'react-contexify';
import InputText from '../components/InputText';
import Modal from '../components/Modal';

//функция для рендеринга интерфейса для вывода списка дашбордов (ДБ)
function SideBar({dashboardList, dashboardTagList, updateDashboardList, updateDashboardTagList}) {
    //флаг видимости списка ДБ
    const [showDBList, setShowDBList] = useState(false);
    //инициализация переменной списка тегов
    const [tagList, setTagList] = useState([]);
    //инициализация переменной контекстногно меню для управления дашбордом
    const [contextDashboard, setContextDashboard] = useState(null);
    //инициализация переменной для нового тега
    const [newTagName, setNewTagName] = useState('');
    //инициализация переменной открытого окна для создания тега
    const [isCreateTagOpened, setCreateTagOpened] = useState(false);
    //
    React.useEffect(() => {
        reloadDashboardList();
    }, []);


    //нициализация функции рендеринга списка дашбордов
    const reloadDashboardList = () => {
        //запрос метода API для получения списка дашбордов
        dashboardService.getDashboardList()
            .then((dbList) => {//ответ - список дашдордов
                updateDashboardList(dbList);//вызов метода для обновления списка дашбордов в менеджере состояний Redux
                return dbList;//возврат списка дашбордов
            }).then((dashboardList) => {
            dashboardService.getTagList()//получение списка тегов (категорий) дашбордов
                .then(
                    response => {
                        console.log('response.tegs', response.tegs);
                        setTagList(response.tegs);//обновление состояние переменной списка тегов
                        return response.tegs;//возврат списка тегов
                    }
                )
                .then(
                    tagList => {
                        Promise.all(//запрос списка дашбордов по каждому тегу
                            tagList.map((tag) => {
                                //запрос метода API на получение дашбордов по тегу
                                return dashboardService.getDashboardListByTag(tag).then(
                                    resp => {
                                        //вывод json переменной с тегом и списком дашббордов
                                        return {tag: tag, dashboardList: resp.dashboards};
                                    }
                                );
                            })
                        ).then(
                            tList => {//вывод списка тегов и дашбордов
                                let dbList = [...dashboardList];//инициализация списка дашбордов
                                let dbTagList = [];//инициализация списка тегов с дашбордами
                                tList.map(tag => {//перебор массива тегов с дашбордами
                                    if(tag.dashboardList && tag.dashboardList.length > 0){//проверка на "пустые" теги (теги с пустым списком дашбордов)
                                        tag.dashboardList.map(db => {
                                            const dbIdx = dbList.findIndex(el => el.id === db.id);
                                            if (dbIdx > -1) {
                                                dbList.splice(dbIdx, 1);
                                            }
                                        });
                                        dbTagList.push(tag);//заполнение нового списка тегов
                                    }
                                });
                                dbList.sort((a, b) => a.id - b.id).map(el => dbTagList.push(el));//сортировка тегов по порядку
                                updateDashboardTagList(dbTagList);//обновление списка тегов дашбордов в менеджере состояний edux
                            }
                        );
                    }
                );
        });
    };

    //инициализация переменной контекстного меню
    const {show, hideAll} = useContextMenu({
        id: 'DASHBOARD-MENU'
    });
//функция для отображения контекстного меню
    function displayMenu(e, db, fromTag) {
        console.log('fromTag', fromTag);
        setContextDashboard({...db, fromTag: fromTag});
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        show(e);
    }
//функция для перемещения дашборда в тег
    const moveDashboardToTag = (dashboardId, tag) => {
        dashboardService.addTagToDashboard(dashboardId, tag).then(
            () => {
                reloadDashboardList();
            }
        );
    };
//инициализация функции для создания тега
    const createTag = () => {
        moveDashboardToTag(contextDashboard.id, newTagName);
        setNewTagName('');
        setCreateTagOpened(false);
        reloadDashboardList();
    };
//инициализация функции для удаления дашборда из тега
    const removeDashboardFromTag = (dashboardId, tag) => {
        console.log('removeDashboardFromTag',dashboardId,tag);
        dashboardService.deleteTagFromDashboard(dashboardId, tag).then(
            () => {
                reloadDashboardList();
            }
        );
    };
//возврат сгенерированного HTML кода
    return (
        <div className={'sidebar-container'}>
            <div className={'sidebar'}>
                {/*сылка для перехода в личный кабинет*/}
                <Link className={'sidebar-btn'} to="/office">
                    <button type={'button'} className={'sidebar-btn yellow-hover'}>
                        <ReactSVG src={userSvg}/>
                        <label>{localeService.isRussian() ? 'Кабинет' : 'My Cabinet'}</label>
                    </button>
                </Link>
                {/*сылка для перехода на страницу создания дашборда*/}
                <Link className={'sidebar-btn'} to="/create_dashboard">
                    <button type={'button'} className={'sidebar-btn green-hover'}>
                        <ReactSVG src={addSvg}/>
                        <label>{localeService.isRussian() ? 'Создать дашборд' : 'Add Dashboard'}</label>
                    </button>
                </Link>
                {/*кнопка для раскрытия списка дашбордов*/}
                <button type={'button'} className={'sidebar-btn blue-hover ' + (showDBList ? 'active' : '')}
                        onClick={() => setShowDBList(!showDBList)}>
                    <ReactSVG src={dashboardSvg}/>
                    <label>{localeService.isRussian() ? 'Дашборды' : 'Dashboard List'}</label>
                </button>
            </div>
            <div className={'dashboard-list ' + (showDBList ? 'open' : '')} onMouseLeave={() => {
                setShowDBList(false);
                hideAll();
            }}>
                <h5>{localeService.isRussian() ? 'Мои дашборды' : 'My Dashboard List'}</h5>
                {/*отображение списка дашбордов*/}
                {dashboardList &&
                <ul className={'dashboard-tree'} style={{paddingBottom: '3rem'}}>
                    {/*{tagList.length > 0 && tagList.map((tag, i) => {*/}
                    {/*    if(tag.dashboardList && tag.dashboardList.length > 0){*/}
                    {/*        /!*вывод группы дашбордов по тегу*!/*/}
                    {/*        return <DashboardGroup key={i} dashboardGroup={tag} setShowDBList={setShowDBList} displayMenu={(e, db) => displayMenu(e, db, tag)}/>;*/}
                    {/*    }*/}
                    {/*})}*/}
                    {dashboardTagList && dashboardTagList.map((db, i) => {
                        if(db.tag){
                            return <DashboardGroup key={i} dashboardGroup={db} setShowDBList={setShowDBList} displayMenu={(e, db, tag) => displayMenu(e, db, tag)}/>;
                        }else {
                            return (
                                <li key={i} className={'tree-item'} onContextMenu={(e) => displayMenu(e, db, null)}>
                                    <Link to={'/dashboard/' + db.id}
                                          title={db.name}
                                          key={i}
                                          onClick={() => setShowDBList(false)}
                                    >{db.name}
                                    </Link>
                                </li>
                            );
                        }
                        }
                    )}

                    {/*компонент контекстного меню*/}
                     <Menu id={'DASHBOARD-MENU'}>
                        <Item disabled>
                            {contextDashboard && contextDashboard.name}
                        </Item>
                        <Separator/>
                        {contextDashboard && !contextDashboard.fromTag && <Submenu label="Переместить дашборд">
                            {tagList.map((tag, i) => {
                                return <Item key={i} onClick={() => moveDashboardToTag(contextDashboard.id, tag)}>
                                    {tag}
                                </Item>;
                            })}
                            <Separator/>
                            <Item key={'createTag'} onClick={() => setCreateTagOpened(true)}>
                                Создать ярлык
                            </Item>
                        </Submenu>}
                        {contextDashboard && contextDashboard.fromTag &&
                        <Item key={'remove_from_group'} onClick={() => removeDashboardFromTag(contextDashboard.id, contextDashboard.fromTag)}>
                                    Удалить дашборд из группы
                                </Item>}
                        <Separator/>
                        <Item key={'close'} onClick={() => hideAll()}>
                            Закрыть меню
                        </Item>
                    </Menu>
                    {/*диалоговое окно для создания тега*/}
                    <Modal isClosable={false}
                           width={'500px'}
                           isOpen={isCreateTagOpened}
                           title={localeService.isRussian() ? 'Создание нового ярлыка' : 'Create New Tag'}
                           onCancel={() => setCreateTagOpened(false)}
                           onSubmit={() => createTag()}>
                        <form>
                            <InputText notInline={true} label={localeService.isRussian() ? 'Введите название ярлыка' : 'Tag Name'}
                                       value={newTagName} onChange={text => setNewTagName(text)}/>
                        </form>
                    </Modal>
                </ul>
                }
            </div>
        </div>
    );
}

//функция для вывода списка дашбордов тега (группы)
function DashboardGroup({dashboardGroup, setShowDBList, displayMenu}) {
    const [isOpen, setIsOpen] = useState(false);
    return (<li className={'tree-item db-group'} key={dashboardGroup.tag + 'name'} onClick={() => setIsOpen(!isOpen)}>
            <div className={'db-group-title'}>
                <span className={'db-group-collapsed ' + (isOpen ? 'open' : '')}/>
                <span className={'db-group-name'}>{dashboardGroup.tag}</span>
            </div>
            <ul className={'dashboard-tree tree-parent' + (isOpen ? ' open' : '')} key={dashboardGroup.tag + 'ul'}>
                {dashboardGroup.dashboardList && dashboardGroup.dashboardList.sort((a,b) => a.name.localeCompare(b.name)).map((db, i) => {
                    return <li key={i} className={'tree-item'} onContextMenu={(e) => displayMenu(e, db, dashboardGroup.tag)}>
                        <Link to={'/dashboard/' + db.id}
                              title={db.name}
                              key={db.tag + i}
                              onClick={() => setShowDBList(false)}
                        >{db.name}
                        </Link></li>;
                })}
            </ul>
        </li>
    );
}

DashboardGroup.propTypes = {
    dashboardGroup: PropTypes.object,
    setShowDBList: PropTypes.func,
    displayMenu: PropTypes.func
};

SideBar.propTypes = {
    dashboardList: PropTypes.array,
    dashboardTagList: PropTypes.array,
    updateDashboardList: PropTypes.func,
    updateDashboardTagList: PropTypes.func
};

const mapStateToProps = state => {
    const dashboardList = state.dashboardReducer.dashboardList;
    const dashboardTagList = state.dashboardReducer.dashboardTagList;
    return {dashboardList, dashboardTagList};
};

const mapDispatchToProps = {
    updateDashboardList: updateDashboardList,
    updateDashboardTagList: updateDashboardTagList
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);


