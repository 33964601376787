import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {updateWidget} from '../../../redux/actions/dashboardActions';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import {useTranslation} from 'react-i18next';
import EditWidgetDataSource from '../EditWidgetDataSource';
import AddFunctionToDatasource from '../AddFunctionToDatasource';
import InputText from '../../../components/InputText';

function FunctionWidgetCreate({widgetProps, updateWidget, dashboardDataSources}) {
    const [widget, setWidget] = useState(widgetProps);
    const {t} = useTranslation();

    const createDataSource = () => {
        const dataSources = [...widget.dataSources, {idx: 'new', parameters: {}}];
        setWidget({...widget, dataSources: dataSources});
    };

    return (
        <div>
            <form>
                <InputText label={t('name')} value={widget.name}
                           onChange={(text) => setWidget({...widget, name: text})}/>

                {widget.dataSources.length > 0 && widget.dataSources.map((dataSource, key) => {
                    return <EditWidgetDataSource key={key} dataSourceNumber={key + 1} widgetDataSource={dataSource}
                                                 onChangeDataSource={(ds) => {
                                                     widget.dataSources[key] = ds;
                                                     setWidget(widget);
                                                 }}
                                                 onDeleteDataSource={(dsIndex) => {
                                                     setWidget({
                                                         ...widget,
                                                         dataSources: widget.dataSources.filter(el => el.idx !== dsIndex)
                                                     });
                                                 }}
                    />;
                })}

                {widget.dataSources.length > 0 &&
                <AddFunctionToDatasource appliedFunction={{functionCode: widget.dataSources[0].parameters.functionCode, functionParams: widget.dataSources[0].parameters.functionParams, name: widget.dataSources[0].parameters.functionCode}}
                                         onApplyFunction={(appliedFunction) => {
                                             setWidget({
                                                 ...widget,
                                                 dataSources: widget.dataSources.map(ds => {
                                                     return {...ds, parameters: {...ds.parameters, ...appliedFunction}};
                                                 })
                                             });
                                         }}/>
                }

                <div className={'d-flex justify-content-center'}>
                    {dashboardDataSources.length > 0 && widget.dataSources.length === 0 &&
                    <button type={'button'} className={'btn btn-outline-primary default'}
                            onClick={() => createDataSource()}>
                        {localeService.isRussian() ? 'Добавить источник данных' : 'Add Data Source'}
                    </button>
                    }
                    <button type={'button'} className={'btn btn-outline-success'}
                            onClick={() => {
                                updateWidget({...widget, mode: dashboardConstants.EDITION_MODE});
                            }}>
                        {localeService.isRussian() ? 'Сохранить' : 'Save'}
                    </button>
                    <button type={'button'} className={'btn btn-outline-danger'}
                            onClick={() => {
                                updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE});
                            }}>
                        {t('cancel')}
                    </button>
                </div>
            </form>
        </div>
    );
}

FunctionWidgetCreate.propTypes = {
    widgetProps: PropTypes.object,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(FunctionWidgetCreate);