import React from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import CapacityCostConfig from './CapacityCostCreate';
import CapacityCostView from './CapacityCostView';

function CapacityCost({widgetProps, mode, onCloseEditSingleMode}) {
    return (
        <>
            {mode === dashboardConstants.VIEW_MODE &&
            <CapacityCostView widgetProps={widgetProps}/>
            }
            {mode === dashboardConstants.EDITION_MODE &&
            <table className={'table'}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {[...Array(3).keys()].map((_,i) => {
                    return <tr key={i}>
                        <td>{'parameter_' + i}</td>
                        <td>{}</td>
                    </tr>;
                })}
                </tbody>
            </table>
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => onCloseEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <CapacityCostConfig widgetProps={widgetProps} onClose = {() => onCloseEditSingleMode()}/>
            </Modal>
            }
        </>
    );
}

CapacityCost.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default CapacityCost;