export const scenarioConstants = {
    SCENARIO_SAMPLE: {
        'active': true,
        'delaySeconds': 0,
        name: '',

        'scriptStatementList': {
            'statements': [
                {
                    'type': 'IF',
                    'payload': {
                        'conditons': {
                            'type': 'EMPTY',
                            'condition1':
                                {
                                    'type': 'VARIABLE',
                                    'payload': {}
                                },
                        },
                        'actionThen': {
                            'type': 'SET_VARIABLE',
                            'payload': {

                                'setValue': 0
                            },

                        },
                        'actionOtherwise': {
                            'type': 'SET_VARIABLE',
                            'payload': {

                                'setValue': 0
                            },

                        }
                    },
                }
            ],
        }
    },
    STATEMENT_SAMPLE: {
        'type': 'IF',
        'payload': {
            'conditons': [{
                group: 'EMPTY',
                type: {type: 'VARIABLE'},
                payload: {comparisonValue: '', comparisonOperator: 'EQ'},
            }],
            // 'conditons': {
            //     'type': 'EMPTY',
            //     'condition1':
            //         {
            //             'type': 'VARIABLE_VARIABLE',
            //             'payload': {}
            //         },
            // },
            'actionThen': {
                // 'type': 'SET_VARIABLE',
                'type': {'type': 'SET_VARIABLE'},
                'payload': {

                    'setValue': 0
                },

            },
            'actionOtherwise': {
                // 'type': 'SET_VARIABLE',
                'type': {'type': 'SET_VARIABLE'},
                'payload': {

                    'setValue': 0
                },

            }
        },
    },
    CONDITION_SAMPLE: {
        group: 'EMPTY',
        type: {type: 'VARIABLE'},
        payload: {comparisonValue: '', comparisonOperator: 'EQ'},
    }
};



// export const scenarioConstants = {
//     SCENARIO_SAMPLE: {
//         'active': true,
//         'delaySeconds': 0,
//         name: '',
//
//         'scriptStatementList': {
//             'statements': [
//                 {
//                     'type': 'IF',
//                     'payload': {
//                         'conditons': {
//                             'type': 'EMPTY',
//                             'condition1':
//                                 {
//                                     'type': 'VARIABLE',
//                                     'payload': {}
//                                 },
//                         },
//                         'actionThen': {
//                             'type': 'SET_VARIABLE',
//                             'payload': {
//
//                                 'setValue': 0
//                             },
//
//                         },
//                         'actionOtherwise': {
//                             'type': 'SET_VARIABLE',
//                             'payload': {
//
//                                 'setValue': 0
//                             },
//
//                         }
//                     },
//                 }
//             ],
//         }
//     },
//     STATEMENT_SAMPLE: {
//         'type': 'IF',
//         'payload': {
//             'conditons': {
//                 'type': 'EMPTY',
//                 'condition1':
//                     {
//                         'type': 'VARIABLE_VARIABLE',
//                         'payload': {}
//                     },
//             },
//             'actionThen': {
//                 'type': 'SET_VARIABLE',
//                 'payload': {
//
//                     'setValue': 0
//                 },
//
//             },
//             'actionOtherwise': {
//                 'type': 'SET_VARIABLE',
//                 'payload': {
//
//                     'setValue': 0
//                 },
//
//             }
//         },
//     },
//     CONDITION_SAMPLE: {
//         group: 'EMPTY',
//         type: {type: 'VARIABLE'},
//         payload: {comparisonValue: '', comparisonOperator: 'EQ'},
//     }
// };
