import React from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import svgWarning from '../sources/images/interface/warning.svg';

function FileInput({label, buttonText, accept, onChange, errorMessage, isLoading, fileName}) {

    let fileChecker = React.createRef();
    const handleChange = (event) => {
        event.preventDefault();
        onChange(event.target.files[0]);
        event.target.value = '';
    };

    const handleClick = () => {
        fileChecker.current.click();
    };
    return (
        <form>
            <p className={'help-text'}>{label}</p>
            <input type="file" ref={fileChecker} accept={accept} onChange={(e) => handleChange(e)}
                   style={{display: 'none'}}/>
            <button type="button" className={'btn btn-outline-primary default'} onClick={() => handleClick()}
                    disabled={isLoading}>{isLoading ?
                <img src={'/src/sources/images/interface/loader-ellipsis.gif'}
                     alt="loading..."/> : buttonText}</button>
            {fileName && <b className={'ml2'}>{fileName}</b>}
            {errorMessage &&
            <div className={'warning-message'}>
                <ReactSVG src={svgWarning}/>
                <div>{errorMessage}</div>
            </div>}
        </form>
    );
}

FileInput.propTypes = {
    label: PropTypes.string,
    buttonText: PropTypes.string,
    accept: PropTypes.string,
    onChange: PropTypes.func,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    fileName: PropTypes.string
};

export default FileInput;
