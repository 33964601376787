/* eslint-disable react/prop-types */
import {Component} from 'react';
import ReactDOM from 'react-dom';

class Portal extends  Component {
    constructor(props) {
        super(props);
        let el = document.createElement('div');
        // el.className = 'modal-portal';
        el.className = 'modal fade show';
        el.style = 'display: block; background-color: rgba(0,0,0,0.5);';
        el.ariaModal = 'true';
        el.role = 'dialog';
        el.tabIndex = '-1';
        el.dataBsBackdrop = 'static';
        el.dataBsKeyboard = 'false';
        this.el = el;
    }

    componentDidMount() {
        document.body.appendChild(this.el);
    }

    componentWillUnmount() {
        document.body.removeChild(this.el);
    }

    render() {
        const { children } = this.props;

        return ReactDOM.createPortal(children, this.el);
    }
}

export default Portal;