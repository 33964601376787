import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../../components/Modal';
import InputTextLayOut from '../../../../../components/InputTextLayOut';

function EditUnloadValue({dataSource, onChange}) {
    const [value, setValue] = React.useState(dataSource.unloadValue);
    const [isOpen, setOpen] = React.useState(false);

    React.useEffect(() => {
        setValue(dataSource.unloadValue);
    },[dataSource]);

    const onCancel = () => {
        setValue(dataSource.unloadValue);
        setOpen(false);
    };

    return (
        <div className={'d-flex justify-content-center'}>
            <button className={'btn btn-squared'} style={{borderColor: '#c2c2c2', backgroundColor: 'white'}}
                    onClick={() => setOpen(!isOpen)} title={'Изменить величину разгрузки'}>
                {value}
            </button>
            <Modal isOpen={isOpen}
                   title={'Изменить величину разгрузки'}
                   onCancel={() => onCancel()} footer={false}>
                <form>
                    <InputTextLayOut
                        label={`Величина разгрузки "${dataSource.parameters.dataSourceName}", ${dataSource.parameters.unit || 'кВт'}`}
                        value={value}
                        isDecimal={true}
                        onChange={t => setValue(t)}/>
                </form>
                <div className={'modal-footer'}>
                    <button className={'btn btn-outline-secondary'} onClick={() => onCancel()}>Отмена</button>
                    <button className={'btn btn-outline-success'} onClick={() => {
                        onChange(value);
                        setOpen(false);
                    }}>Применить
                    </button>
                </div>
            </Modal>
        </div>
    );
}

EditUnloadValue.propTypes = {
    dataSource: PropTypes.object,
    onChange: PropTypes.func
};

export default EditUnloadValue;