import React from 'react';
import svgEdit from '../../sources/images/interface/edit.svg';
import svgCancel from '../../sources/images/interface/cancel.svg';

import svgPower from '../../sources/images/power.svg';
import svgStop from '../../sources/images/stop.svg';

import {ReactSVG} from 'react-svg';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

// eslint-disable-next-line no-unused-vars
const ScenarioTable = ({source, onSelect, action}) => {
    const {t} = useTranslation();
    return (
        <table className={'device-list-table'} cellPadding={0} cellSpacing={0}>
            <thead>
            <tr>
                <th width="40px" style={{textAlign: 'center'}}>
                    N
                </th>
                <th>{t('name')}</th>
                <th width="10px" style={{textAlign: 'center'}}>
                    {t('status')}
                </th>

                <th width="0"></th>
                <th width="0"></th>
                <th width="0"></th>
            </tr>
            </thead>
            <tbody>
            {source &&
            source
                .sort((a, b) => a.id - b.id)
                .map((row) => (
                    <tr key={row.id} className={'editable-row'}>
                        <td style={{textAlign: 'center'}}>{row.id}</td>
                        <td onClick={() => action(row, 'EDIT')}>{row.name}</td>
                        <td style={{textAlign: 'center'}}>
                            <div
                                className={`status ${
                                    row.active ? 'status-active' : 'status-disactive '
                                }`}
                            >
                                &nbsp;
                            </div>
                        </td>
                        <td width="40px" className={'editable-col'}>
                            <button
                                title={t(
                                    row.active ? 'scenario.activate' : 'scenario.stop'
                                )}
                                className={'btn btn-outline-primary round-btn default edit-variable-btn'}
                                onClick={() => {
                                    action(row, 'ACTIVATE');
                                }}
                            >
                                <ReactSVG src={row.active ? svgPower : svgStop}/>
                            </button>
                        </td>
                        <td width="40px" className={'editable-col'}>
                            <button
                                className={'btn btn-outline-primary round-btn default edit-variable-btn'}
                                title={t('edit')}
                                onClick={() => {
                                    action(row, 'EDIT');
                                }}
                            >
                                <ReactSVG src={svgEdit}/>
                            </button>
                        </td>
                        <td width="40px" className={'editable-col'}>
                            <button
                                className={'btn btn-outline-primary round-btn default edit-variable-btn'}
                                title={t('delete')}
                                onClick={() => {
                                    action(row, 'REMOVE');
                                }}
                            >
                                <ReactSVG src={svgCancel}/>
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

ScenarioTable.propTypes = {
    source: PropTypes.array,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
    action: PropTypes.func,
};
export default ScenarioTable;
