import React from 'react';
import PropTypes from 'prop-types';
import {Bar, BarChart, Cell, Tooltip, XAxis, YAxis, CartesianGrid} from 'recharts';
import numeral from 'numeral';
import {widgetsService} from '../../../services/widget.service';

function BarChartPreview({widgetProps, sizeParams}) {
    const [previewData, setPreviewData] = React.useState([]);
    React.useEffect(()=> {
        setPreviewData(widgetsService.generateTestData(widgetProps.dataSources));
    }, []);

    return <BarChart
        width={sizeParams.width}
        height={sizeParams.height}
        data={previewData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name"
               type="category"/>
        <YAxis type="number" domain={['auto', 'auto']}
               tickFormatter={(value) => {
                   return numeral(value).format('0.0 a');
               }}/>
        <Tooltip/>
        <Bar dataKey="value" >
            {/*<LabelList dataKey="value" position="outside" style={{fill: 'white', stroke: '#005ea3', strokeWidth: '0.015rem'}}/>*/}
            {
                previewData.map((entry, index) => (
                    <Cell key={`cell-${index}`}
                          fill={entry.color}
                    />
                ))
            }
        </Bar>
    </BarChart>;
}


BarChartPreview.propTypes = {
    widgetProps: PropTypes.object,
    sizeParams: PropTypes.object
};

export default BarChartPreview;