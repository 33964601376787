import moment from 'moment/moment';
import {dashboardService} from '../../../../../services';

export const pivotService = {
    getDSNameByType,
    reloadPivotTableData
};

function getDSNameByType(type) {
    if (type === 'MAIN_DS') {
        return 'Основной источник данных';
    } else if (type === 'UNLOAD_PROMISE_DS') {
        return 'Готовность к разгрузке';
    } else if (type === 'UNLOAD_FACT_DS') {
        return 'Фактически разгружено';
    } else {
        return type;
    }
}

async function getAdditionalParams(readyToUnlodDS, unlodedDS, date){
    const start = moment(date).hours(0).minutes(0).seconds(0).milliseconds(0);
    const finish = moment(date).hours(23).minutes(59).seconds(59).milliseconds(599);

    let promise = dashboardService.getAggregationFunctionData(readyToUnlodDS.id, start, finish, 'AGGREGATIONS_SUM', null)
            .then(result1 => {
                const readyToUpload = result1.list[0].value;
                return dashboardService.getAggregationFunctionData(unlodedDS.id, start, finish, 'AGGREGATIONS_SUM', null)
                    .then(result2 => {
                        const unlodedDS = result2.list[0].value;
                        return ({
                            timeUpload: moment(date),
                            readyToUpload: readyToUpload,
                            factUpload: unlodedDS / 2
                        });
                    }, () => {
                        return ({
                            timeUpload: moment(date),
                            readyToUpload: readyToUpload,
                            factUpload: null
                        });
                    });
            }, () => {
                return null;
            });
    if(readyToUnlodDS && unlodedDS) {
        let result = await promise;
        return result;
    }else{
        return Promise.resolve({
            timeUpload: moment(date),
            readyToUpload: null,
            factUpload: null
        });
    }
}

function reloadPivotTableData(dataSources, dateArray) {
    return new Promise((resolve, reject) => {
        let mainDS = dataSources.filter(el => el.parameters.type === 'MAIN_DS');
        let dataSourcesWithData = Array.from(Array(mainDS.length), () => null);
        if (mainDS && mainDS.length > 0) {
            mainDS.map((ds, dsIdx) => {
                let dataList = Array.from(Array(dateArray.length), () => null);
                dateArray.map((date, dateIdx) => {
                    const readyToUnlodDS = dataSources.find(el => el.parameters.type === 'UNLOAD_PROMISE_DS' && el.parameters.parentId === +ds.sourceID);
                    const unlodedDS = dataSources.find(el => el.parameters.type === 'UNLOAD_FACT_DS' && el.parameters.parentId === +ds.sourceID);
                    if (readyToUnlodDS && unlodedDS) {
                        getAdditionalParams(readyToUnlodDS, unlodedDS, date).then(dateData => {
                            dataList[dateIdx] = dateData;
                            if (dataList.every(el => el !== null)) {
                                dataSourcesWithData[dsIdx] = {...ds, data: dataList};
                            }
                            if (dataSourcesWithData.every(el => el !== null)) {
                                resolve(dataSourcesWithData);
                            }
                        }, () => {
                            reject('Ошибка вычислений! Проверьте настройки виджета!');
                        });
                    } else {
                        reject('Ошибка вычислений! Проверьте настройки виджета!');
                    }

                });
            });
        } else {
            reject('Ошибка вычислений! Проверьте настройки виджета!');
        }
    });




//return Promise.all(dataSources.filter(el => el.parameters.type === 'MAIN_DS').map((ds) => {
       // return Promise.all(dateArray.map((date) => {
       //
       //      const start = moment(date).hours(0).minutes(0).seconds(0).milliseconds(0);
       //      const finish = moment(date).hours(23).minutes(59).seconds(59).milliseconds(599);
       //      const readyToUnlodDS = dataSources.find(el => el.parameters.type === 'UNLOAD_PROMISE_DS' && el.parameters.parentId === +ds.sourceID);
       //      const unlodedDS = dataSources.find(el => el.parameters.type === 'UNLOAD_FACT_DS' && el.parameters.parentId === +ds.sourceID);
       //      if(readyToUnlodDS && unlodedDS){
       //          return Promise.all([readyToUnlodDS, unlodedDS].map(addDs => {
       //              return dashboardService.getAggregationFunctionData(addDs.id, start, finish, 'AGGREGATIONS_SUM', null)
       //                  .then(result => {
       //                      const functionResult = result.list[0] ? result.list[0].value : undefined;
       //                      return functionResult;
       //                  });
       //          })).then(results => {
       //              const data = {
       //                  timeUpload: moment(date),
       //                  readyToUpload: results[0],
       //                  factUpload:results[1]
       //              };
       //              return data;
       //          });
       //      }
       //
       //  })).then(dayResults => {
       //      ds.data = dayResults;
       //      return ds;
       //  });

}

// function _OldreloadPivotTableData(dataSources, dateArray) {
//     const findAdditionalDataSource = (id, type) => {
//         const parentDSID = Number.parseInt(id);
//         let dataSource = null;
//         dataSources.map(el => {
//             if (el.parameters.parentDataSource === parentDSID && el.parameters.type === type) {
//                 dataSource = el;
//             }
//         });
//         return dataSource;
//     };
//     return Promise.all(dataSources.map((ds) => {
//         // const dsProps = JSON.parse(ds.parameters);
//         if (ds.parameters.type === 'MAIN_DATASOURCE') {
//             const valueDataSource = findAdditionalDataSource(ds.sourceID, 'VALUE_DATASOURCE');
//             return Promise.all(dateArray.map((date, dIdx) => {
//                 return getDashboardWidgetDataSourceTimeGroup1s(valueDataSource.id, moment(date).hours(0).minutes(0).seconds(0).milliseconds(0), moment(date).hours(23).minutes(59).seconds(59).milliseconds(599))
//                     .then(result => {
//                         const data = {
//                             dataIdx: dIdx,
//                             date: date.unix(),
//                             count: result.list.length
//                         };
//                         if (data.count > 0) {
//                             data.value = result.list[result.list.length - 1].value;
//                         }
//                         return data;
//                     })
//                     .then(data => {
//                         if (data.count > 0) {
//                             const startHourDataSource = findAdditionalDataSource(ds.sourceID, 'START_HOUR_DATASOURCE');
//                             if (startHourDataSource) {
//                                 return getDashboardWidgetDataSourceTimeGroup1s(startHourDataSource.id, moment(date).hours(0).minutes(0).seconds(0).milliseconds(0), moment(date).hours(23).minutes(59).seconds(59).milliseconds(599))
//                                     .then(result => {
//                                         if (result.list.length > 0) {
//                                             data.startHour = result.list[result.list.length - 1].value;
//                                             return data;
//                                         }
//                                         return {
//                                             dataIdx: dIdx,
//                                             date: date.unix(),
//                                             count: 0
//                                         };
//                                     })
//                                     .then(data2 => {
//                                         const durationDataSource = findAdditionalDataSource(ds.sourceID, 'DURATION_DATASOURCE');
//                                         if (durationDataSource) {
//                                             return getDashboardWidgetDataSourceTimeGroup1s(durationDataSource.id, moment(date).hours(0).minutes(0).seconds(0).milliseconds(0), moment(date).hours(23).minutes(59).seconds(59).milliseconds(599))
//                                                 .then(result => {
//                                                     if (result.list.length > 0) {
//                                                         data2.duration = result.list[result.list.length - 1].value;
//                                                         return data2;
//                                                     }
//                                                     return {
//                                                         dataIdx: dIdx,
//                                                         date: date.unix(),
//                                                         count: 0
//                                                     };
//                                                 });
//                                         } else {
//                                             return {
//                                                 dataIdx: dIdx,
//                                                 date: date.unix(),
//                                                 count: 0
//                                             };
//                                         }
//                                     });
//                             } else {
//                                 return {
//                                     dataIdx: dIdx,
//                                     date: date.unix(),
//                                     count: 0
//                                 };
//                             }
//                         } else {
//                             return {
//                                 dataIdx: dIdx,
//                                 date: date.unix(),
//                                 count: 0
//                             };
//                         }
//                     });
//             })).then(
//                 result => {
//                     return {...ds, dataArray: result};
//                 });
//         } else {
//             return ds;
//         }
//     })).then(
//         dataSources => {
//             return dataSources;
//         });
// }

export const pivotTableTypes = ['MAIN_DS', 'UNLOAD_PROMISE_DS', 'UNLOAD_FACT_DS'];



