import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {dashboardService} from '../../../services';
import {withSize} from 'react-sizeme';
import {connect} from 'react-redux';
import {dashboardConstants} from '../../../constants';
import WidgetLoader from '../../../components/WidgetLoader';
import {Bar, BarChart, Cell, LabelList, Tooltip, XAxis, YAxis} from 'recharts';
// import {localeService} from '../../../services/locale.service';

function LinesWidgetView({widgetProps, datetimeFilter, size}) {
    const [widgetData, setWidgetData] = useState([]);
    const [isUpdating, setUpdating] = useState(false);
    const chartWidth = size.width;
    const chartHeight = (100 * widgetProps.h) - 40;

    /**
     *Обновление данных по изменению @datetimeFilter
     * */
    useEffect(() => {
        setUpdating(true);
        Promise.all(
            widgetProps.dataSources.map((dataSource) => {
                if (dataSource.parameters) {
                    return dashboardService.getWidgetFunctionData(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate, dataSource.parameters.functionCode, dataSource.parameters.functionParams, size.width)
                        .then(result => {
                                return {
                                    name: dataSource.parameters.dataSourceName,
                                    color: dataSource.parameters.color,
                                    value: (result.list && result.list[0]) ? Number.parseFloat(result.list[0].value.toFixed(2)) : null
                                };
                            }
                        );
                } else {
                    return null;
                }
            })
        ).then(widgetData => {
            setWidgetData(widgetData);
        }).finally(
            () => setUpdating(false)
        );
    }, [datetimeFilter]);

    const renderShapeBar = (e) => {
        const {fill, x, y, width, height} = e;
            if(width) {
               return <rect x={x} y={y} rx={height / 2} ry={height / 2}
                      width={width} height={height}
                      style={{fill: fill, stroke: 'none'}}/>;
            }

    };

    return <>
        {/*{isUpdating && <Loader waitText={localeService.isRussian() ? 'Загрузка данных...' : 'Uploading data...'}/>}*/}
        {isUpdating && <WidgetLoader/>}
        {widgetData.length > 0 &&
        <BarChart
            width={chartWidth}
            height={chartHeight}
            data={widgetData}
            layout="vertical"
        >
            <XAxis type="number" axisLine={false} tickLine={false} tick={false}/>
            <YAxis dataKey="name"
                   type="category"
                   tickSize={10}
                   width={chartWidth / 4}
                   axisLine={false} tickLine={false}/>
            <Tooltip/>
            <Bar dataKey="value"
                 barSize={20}
                 shape={(props) => renderShapeBar(props)}
                 background={{fill: '#eee'}}>
                <LabelList dataKey="value" position="outside" style={{fill: 'white', stroke: '@default-color', strokeWidth: '0.015rem'}}/>
                {
                    widgetData.map((entry, index) => (
                        <Cell key={`cell-${index}`}
                              fill={entry.color}
                        />
                    ))
                }
            </Bar>
        </BarChart>
        }
    </>;
}


LinesWidgetView.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

export default withSize()(connect(mapStateToProps, null)(LinesWidgetView));