import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../services/locale.service';
import {ReactSVG} from 'react-svg';
import svgSave from '../sources/images/interface/save.svg';
import Modal from './Modal';
import SelectDeviceVariableFromCapacityTree from './SelectDeviceVariableFromCapacityTree';
import {connect} from 'react-redux';
import {analyticService} from '../services/analyticService';

function SaveFunctionResultToVariable({functionDataSource, capacityTree}) {

    const [isOpen, setOpen] = React.useState(false);
    const [variableToSave, setVariable] = React.useState(null);

    const saveResults = () => {
        analyticService.saveAnalyticsToVariable(functionDataSource.dataSourceId, functionDataSource.datetimeFilter.startDate, functionDataSource.datetimeFilter.finishDate, functionDataSource.resultId).then(
            result => {
                console.log(result);
            }
        );
    };

    return (
        <>
            <button className={'default svg-btn small'} title={localeService.isRussian() ? 'Сохранить функцию в переменную' : 'Save Function Result to Data Source Variable'}
                    onClick={() => setOpen(true)}>
                <ReactSVG src={svgSave}/>
            </button>
            <Modal isOpen={isOpen} onCancel={() => setOpen(false)} isClosable={false}
                   title={localeService.isRussian() ? 'Сохранение результатов выполнения функции в переменную' : 'Saving function results to device variable'}>
                <SelectDeviceVariableFromCapacityTree capacityTree={capacityTree} onSelect={v => setVariable(v)}/>
                {variableToSave && <button onClick={() => saveResults()}>Save</button>}
            </Modal>
        </>
    );
}

SaveFunctionResultToVariable.propTypes = {
    functionDataSource: PropTypes.object,
    capacityTree: PropTypes.array
};

const mapStateToProps = state => {
    const capacityTree = state.capacityReducer.capacityTree;
    return {capacityTree};
};

export default connect(mapStateToProps, null)(SaveFunctionResultToVariable);