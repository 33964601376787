import React from 'react';
import PropTypes from 'prop-types';

function FieldView({label, value}) {

    return <div className={'field-view'}>
            <label>{label}</label>
            <span className={'value'}>{value}</span>
        </div>;
}

FieldView.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

export default FieldView;