import React from 'react';
import '../styles/preloader.scss';
import PropTypes from 'prop-types';

function Loader({waitText}) {

        return (
            <div className={'page-loader'}>
                {waitText && <span className={'wait-text'}>{waitText}</span>}
                <div className="gooey">
                    <span className="dot" />
                    <div className="dots">
                        <span />
                        <span />
                        <span />
                    </div>
                </div>
            </div>
        );
}

Loader.propTypes = {
    waitText: PropTypes.string
};

Loader.defaultProps = {
    waitText: null
};

export default Loader;