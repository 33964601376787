import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {dashboardService} from '../../services/index';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import Slider from 'react-slick';
import Loader from '../../components/Loader';
import {localeService} from '../../services/locale.service';
import { SizeMe } from 'react-sizeme';
import '../../styles/widget-carousel.scss';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import DropItem from './components/DropItem';
import {apiConstants} from '../../constants';
import SelectDBDataSources from './components/SelectDBDataSources';

function WidgetCarousel() {
    const {t} = useTranslation();
    const [widgetList, setWidgetList] = useState({informationWidgets: null, controlWidgets: null});
    const [isFetchingWidgetTypeList, setIsFetchingWidgetTypeList] = useState(false);
    useEffect(() => {
        setIsFetchingWidgetTypeList(true);
        dashboardService.getWidgetTypeList()
            .then(
                widgetList => {
                    setWidgetList(widgetList);
                    setIsFetchingWidgetTypeList(false);
                }
            );
    }, []);

    return (
        <SizeMe>{({size}) =>
            <div>
                {isFetchingWidgetTypeList && <Loader
                    waitText={localeService.isRussian() ? 'Загрузка списка типов виджетов...' : 'Loading widget type list...'}/>}
                {!isFetchingWidgetTypeList &&
                <Tabs className="ems-tabs">
                    <TabList className="ems-tab-list">
                        <Tab className="btn-tab-default">{t('data_sources')}</Tab>
                        <Tab className="btn-tab-default">{t('information_panels')}</Tab>
                        <Tab className="btn-tab-default">{t('control_panels')}</Tab>
                    </TabList>
                    <TabPanel>
                        <SelectDBDataSources />
                    </TabPanel>
                    <TabPanel>
                        {widgetList.informationWidgets &&
                        <Slider style={{width: size.width + 'px', outline: 'none'}} dots={true} sliderSize={1}>
                            {widgetList.informationWidgets.map((slide, i) => {
                                return (
                                    <div key={'info' + i}>
                                        {slide.map((widget, j) => {
                                            return (
                                                <DropItem key={'drag-info-' + i + j} widget={widget}>
                                                    <div key={'info-widget-' + i + j} className="сhoose-dashboard-item"
                                                         style={{width: 'calc(' + (size.width / 8) + 'px - 1rem)'}}>
                                                        <label>{t(widget.name)}</label>
                                                        {widget.img && widget.img.id && <img
                                                            src={apiConstants.API_URL + '/file/download?fileId=' + widget.img.id}/>}
                                                    </div>
                                                </DropItem>
                                            );
                                        })}
                                    </div>
                                );
                            })
                            }
                        </Slider>
                        }
                    </TabPanel>
                    <TabPanel>
                        {widgetList.controlWidgets &&
                        widgetList.controlWidgets.map((slide, i) => {
                            return (
                                <div key={'control' + i}>
                                    {slide.map((widget, j) => {
                                        return <DropItem key={'drag-control-' + i + j} widget={widget}>
                                            <div key={'control-widget-' + i + j} className="сhoose-dashboard-item"
                                                     style={{width: 'calc(' + (size.width / 8) + 'px - 1rem)'}}>
                                                    <label>{t(widget.name)}</label>
                                                    {widget.img && widget.img.id && <img
                                                        src={apiConstants.API_URL + '/file/download?fileId=' + widget.img.id}/>}
                                                </div>
                                        </DropItem>;
                                    })}
                                </div>
                            );
                        })

                        }
                    </TabPanel>

                </Tabs>
                }
            </div>
        }
        </SizeMe>
    );
}
//
// WidgetCarousel.propTypes = {
//     onAddWidget: PropTypes.func.isRequired
// }

export default WidgetCarousel;