/**
 *
 * @param scenario
 * @returns {*}
 */
export default (scenario) => {
    if (scenario.capacityViews && scenario.capacityViews.length > 0) {
        return {...scenario, capacityIds: scenario.capacityViews.map(({id}) => id)};
    }
    return scenario;
};
