/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {localeService} from '../services/locale.service';
import '../styles/dropdown.scss';
import ClickOutside from './ClickOutside';
import {deviceService} from '../services';


function SelectDeviceVariableFromCapacityTree({capacityTree, device, onSelect, isAlwaysOpen, isDisabled}) {

    const [isOpen, setIsOpen] = useState(isAlwaysOpen);

    const [selectedDevice, setSelectedDevice] = useState(device);

    React.useEffect(() => {
        setSelectedDevice(device);
    }, [device]);

    return <div className={'dropdown-container' + (isOpen ? ' open' : '') + (isAlwaysOpen ? ' is-always-open' : '') + (isDisabled ? ' disabled' : '')}>
        {!isAlwaysOpen &&
        <span className={'selected-item'} onClick={() => setIsOpen(!isOpen)}>{selectedDevice && selectedDevice.name || (localeService.isRussian() ? 'Выберите переменную устройства' : 'Select device variable')}</span>}
        {!isDisabled && <ClickOutside disabled={isAlwaysOpen} onClick={() => setIsOpen(isAlwaysOpen)}>
            <div className={'dropdown' + (isOpen ? ' open' : '')}>
                <div className={'dropdown-content'}>
                    <ul>
                        <li><span>{localeService.isRussian() ? 'Корневая нагрузка' : 'Root Capacity'}</span></li>
                        <ul className={'tree-parent open'}>
                            {capacityTree.map((capacity) => {
                                return <CapacityChild key={'cap-' + capacity.id} capacity={capacity}
                                                      onSelect={(device) => {
                                                          setIsOpen(false);
                                                          setSelectedDevice(device);
                                                          onSelect(device);
                                                      }}/>;
                            })}
                        </ul>
                    </ul>
                </div>
            </div>
        </ClickOutside>}
    </div>;
}

function CapacityChild({capacity, onSelect}) {
    const [isOpen, setIsOpen] = useState(false);
    const [isVarsOpen, setVarsIsOpen] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [variableList, setVariableList] = useState(null);
    const onGetCapacityVariables = (capacityId) => {
        setVarsIsOpen(!isVarsOpen);
        if(!isVarsOpen) {
            setFetching(true);
            deviceService.getVariablesByCapacity(capacityId).then(
                results => setVariableList(results)
            ).finally(() => setFetching(false));
        }
    };
    return (<>
            <li>
                <div className={'tree-item'}>
                    {capacity.child && capacity.child.length > 0 &&
                    <button type={'button'} className={'toggle-tree-item'}
                            onClick={() => setIsOpen(!isOpen)}>{isOpen ? '-' : '+'}</button>}
                    <span onClick={() => onGetCapacityVariables(capacity.id)}>{capacity.name}</span>
                </div>
            </li>
            {isFetching && <li>{localeService.isRussian() ? 'Загрузка переменных' : 'Loading device variables...'}</li>}
            {variableList && variableList.length === 0 && <li>{localeService.isRussian() ? 'Список переменных пуст' : 'Empty variable list'}</li>}
            {variableList && variableList.length > 0 &&
                <ul className={'device-variable-list tree-parent ' + (isVarsOpen ? 'open' : '')}>
                    {variableList.map((deviceVariable) => {
                        return <li key={'variable-' + deviceVariable.id} onClick={() => onSelect(deviceVariable)}>{deviceVariable.name}</li>;
                    })}
                </ul>
            }

            {capacity.child && capacity.child.length > 0 &&
            <ul className={'tree-parent' + (isOpen ? ' open' : '')}>
                {capacity.child.map(childCapacity => {
                    return <CapacityChild key={'cap-' + childCapacity.id} capacity={childCapacity}
                                          onSelect={onSelect}/>;
                })}
            </ul>
            }
        </>
    );
}


SelectDeviceVariableFromCapacityTree.propTypes = {
    capacity: PropTypes.object,
    capacityTree: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    isAlwaysOpen: PropTypes.bool,
    isDisabled: PropTypes.bool
};

SelectDeviceVariableFromCapacityTree.defaultProps = {
    isAlwaysOpen: false,
    isDisabled: false
};

export default SelectDeviceVariableFromCapacityTree;