import React from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../../../constants/DragDropItemTypes';
import PropTypes from 'prop-types';
import {addWidget} from '../../../redux/actions/dashboardActions';
import { connect } from 'react-redux';

function DropItem(props){
    const [{ isDragging }, drag] = useDrag({
        item: { ...props.widget, widgetTypeId: props.widget.id, widgetType: props.widget.type, type: ItemTypes.DASHBOARD_ITEM },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                props.addWidget(item);
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0.4 : 1;
    return (
        <div ref={drag} style={{display: 'inline-block', opacity }}>
            {props.children}
        </div>
    );
}

DropItem.propTypes = {
    children: PropTypes.node,
    widget: PropTypes.object,
    addWidget: PropTypes.func
};

const mapDispatchToProps = {
    addWidget: addWidget
};

export default connect( null, mapDispatchToProps )(DropItem);
