import React, {useState} from 'react';
import {Map, Placemark, YMaps, ZoomControl} from 'react-yandex-maps';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {updateWidget} from '../../../redux/actions/dashboardActions';
// import {localeService} from '../../../services/locale.service';
// import {dashboardService} from '../../../services';
import '../../../styles/maps.scss';
import {localeService} from '../../../services/locale.service';
import ReactDOMServer from 'react-dom/server';
import {dashboardService} from '../../../services/dashboard.service';

function MapWidgetView({widgetProps, size, datetimeFilter, updateWidget}) {
    const [state, setState] = useState(null);

    const setAllState = (point) => {
        setState({...state, center: [point.lat, point.lng]});
    };

    const initPointList = (dataSources) => {
        let pList = [];
        dataSources.map((dataSource) => {
            if(dataSource.device) {
                console.log(dataSource);
                let pIdx = pList.findIndex(el => el.lat === dataSource.device.lat && el.lng === dataSource.device.lng);
                if (pIdx < 0) {
                    pList.push({dataSources: [dataSource], lat: dataSource.device.lat, lng: dataSource.device.lng});
                } else {
                    pList[pIdx].dataSources.push(dataSource);
                }
            }
        });
        return pList;
    };

    const [pointList, setPointList] = useState([]);

    const saveMapPresets = (center, zoom) => {
        updateWidget({
            ...widgetProps, parameters: {
                ...widgetProps.parameters, presets: {
                    ...widgetProps.parameters.presets,
                    center: center,
                    zoom: zoom
                }
            }
        });
    };

    React.useEffect(() => {
        if (widgetProps.parameters && widgetProps.parameters.function) {
            Promise.all(
                widgetProps.dataSources.map((dataSource) => {
                    if (dataSource.parameters) {
                        return dashboardService.getWidgetFunctionData(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate, widgetProps.parameters.function.functionCode, widgetProps.parameters.function.functionParams, null)
                            .then(result => {
                                    return {
                                        ...dataSource,
                                        value: (result.list && result.list[0]) ? Number.parseFloat(result.list[0].value.toFixed(2)) : null
                                    };
                                }
                            );
                    } else {
                        return null;
                    }
                })
            ).then(dsList => {
                setPointList(initPointList(dsList));
                let widgetPresets = widgetProps.parameters.presets;
                if (widgetPresets && widgetPresets.center && widgetPresets.zoom) {
                    setState({...state, center: widgetPresets.center, zoom: widgetPresets.zoom});
                } else {
                    if (dsList.length > 0) {
                        setState({zoom: 6, center: [dsList[0].device.lat, dsList[0].device.lng]});
                    }
                }
            });
        } else {
            setPointList(initPointList(widgetProps.dataSources));
            let widgetPresets = widgetProps.parameters.presets;
            if (widgetPresets && widgetPresets.center && widgetPresets.zoom) {
                setState({...state, center: widgetPresets.center, zoom: widgetPresets.zoom});
            } else {
                if (widgetProps.dataSources.length > 0) {
                    setState({
                        zoom: 6,
                        center: [widgetProps.dataSources[0].device.lat, widgetProps.dataSources[0].device.lng]
                    });
                } else {
                    setState({zoom: 6, center: [61.52401, 105.318756]});
                }
            }

        }


    }, [datetimeFilter]);

    const renderPointContent = (point) => {
        console.log('point', point);
        return ReactDOMServer.renderToString(<div>
            {point.dataSources.map((pointDS, pKey) => {
                return <p key={'p' + pKey}>
                    <hr/>
                    <p>{localeService.isRussian() ? 'Источник данных: ' : 'Data Source: '}{pointDS.parameters.dataSourceName}</p>
                    <p>{localeService.isRussian() ? 'Устройство: ' : 'Device: '}{pointDS.device.name}</p>
                    {widgetProps.parameters && widgetProps.parameters.function &&
                    <p>{widgetProps.parameters.function.functionCode + ': ' + pointDS.value}</p>}
                    <p>{(localeService.isRussian() ? 'Нагрузка: ' : 'Source: ')} {pointDS.capacity.name}</p>
                    {pointDS.parameters.linkToDashboard &&
                    <a href={'/dashboard/' + pointDS.parameters.linkToDashboard.id}
                          title={pointDS.parameters.linkToDashboard.name}
                    >{pointDS.parameters.linkToDashboard.name}
                    </a>}
                </p>;
            })
            }
        </div>);
    };

    return (
        <YMaps>
            {state && <Map
                onActionend={el => {
                    let model = el.originalEvent.target._yandexState._model;
                    saveMapPresets(model.center, model.zoom);
                }}
                style={
                    {
                        width: size.width + 6,
                        height: (widgetProps.h * 100) + 6,
                        top: -36,
                        left: -3,
                        position: 'absolute'
                    }
                }
                defaultState={state}>
                <ZoomControl options={{position: 'left'}}/>
                {pointList.length > 0 && pointList.map((point, key) => {
                    return <Placemark
                        key={key}
                        onClick={() => setAllState(point)}
                        geometry={[point.lat, point.lng]}
                        options={{
                            iconColor: point.dataSources[0].parameters.color,
                        }}
                        properties={{
                            iconColor: point.dataSources[0].parameters.color,
                            // iconContent: (widgetProps.parameters && widgetProps.parameters.function ? (point.value) : ''),
                            // balloonContentHeader: (localeService.isRussian() ? 'Имя переменной: ' : 'Variable name: ') + point.dataSources[0].parameters.dataSourceName,
                            balloonContentBody: renderPointContent(point)
                        }}
                        modules={[
                            'geoObject.addon.balloon'
                        ]}
                    />;
                })}
            </Map>}
        </YMaps>
    );
}

MapWidgetView.propTypes = {
    widgetProps: PropTypes.object,
    datetimeFilter: PropTypes.object,
    size: PropTypes.object,
    updateWidget: PropTypes.func
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};
const mapDispatchToProps = {
    updateWidget: updateWidget
};

export default connect(mapStateToProps, mapDispatchToProps)(MapWidgetView);
