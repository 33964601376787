import React from 'react';
import PropTypes from 'prop-types';
import {dashboardService} from '../../../../services/index';
import {connect} from 'react-redux';
import Loader from '../../../../components/Loader';
import '../../../../styles/group-unloading.scss';
import numeral from 'numeral';
import {CartesianGrid, ComposedChart, Line, Tooltip, XAxis, YAxis} from 'recharts';
import moment from 'moment/moment';
import CheckBox from '../../../../components/CheckBox';
import CustomTooltip from '../../../../components/CustomTooltip';

function UnloadingPlanningWidgetView({widgetProps, datetimeFilter, size}) {
    const [isFetching, setFetching] = React.useState(false);
    const [mainDataSource, setMainDataSource] = React.useState(null);
    const [widgetDatetimeFilter, setWidgetDatetimeFilter] = React.useState(datetimeFilter);
    const [drDays, setDRDays] = React.useState([]);

    React.useEffect(() => {
        setFetching(true);
        const dtFilter = {...datetimeFilter};
        dtFilter.datetimeRange = {name: 'choose_month', value: 'custom_month'};
        dtFilter.startDate = moment(dtFilter.startDate.startOf('month')).seconds(0).minutes(0).hours(0);
        dtFilter.finishDate = moment(dtFilter.finishDate.endOf('month').add(1, 'days')).seconds(0).minutes(0).hours(0);
        dtFilter.timeGroup = '1h';
        dtFilter.interval = {value: 0, name: 'Off'};
        setWidgetDatetimeFilter(dtFilter);

        setDRDays(Array.from(Array(dtFilter.finishDate.diff(dtFilter.startDate, 'days'))).map((_, i) => ({
            id: i,
            ds: null,
            data: [],
            name: ''
        })));

        dashboardService.loadWidgetData(widgetProps.dataSources.filter(a => a.parameters.type === 'MAIN_DATA_SOURCE'), dtFilter).then(
            dataSources => {
                setMainDataSource(dataSources[0]);
            }
        ).finally(() => setFetching(false));
    }, [datetimeFilter]);

    const setDrDay = (day, ds) => {
        const days = [...drDays];
        if (days[day.id].ds === ds.id) {
            days[day.id] = {...days[day.id], ds: null, data: []};
            setDRDays(days);
        } else {
            const dayFilter = {...widgetDatetimeFilter};
            dayFilter.startDate = moment(widgetDatetimeFilter.startDate).date(day.id + 1).seconds(0).minutes(0).hours(0);
            dayFilter.finishDate = moment(widgetDatetimeFilter.startDate).date(day.id + 2).hours(0).seconds(0).minutes(0);
            dashboardService.loadWidgetData([{...mainDataSource}, ds], dayFilter).then(
                dayDataSources => {
                    if (dayDataSources[0].data.length === dayDataSources[1].data.length) {
                        let dayData = dayDataSources[0].data.map((d, i) => ({
                            time_upload: d.time_upload,
                            value: d.value + dayDataSources[1].data[i].value
                        }));
                        return dayData;
                    } else {
                        return [];
                    }
                }
            ).then(data => {
                days[day.id] = {...days[day.id], ds: ds.id, data: data,
                    parameters: {
                    color: 'green',
                    dataSourceName: '+ ' + ds.parameters.dataSourceName}};
                setDRDays(days);
            });
        }
    };

    return <>
        {isFetching && <Loader/>}
        {!isFetching && mainDataSource &&
        <div className={'d-flex flex-column'}>
                <ComposedChart width={size.width}
                               height={((100 * widgetProps.h) - (widgetProps.dataSources.length * 34))}>
                    <CartesianGrid strokeDasharray='3 3'/>
                    <XAxis
                        dataKey="time_upload"
                        tickFormatter={(number) => {
                            return moment.unix(number).format(moment.duration(widgetDatetimeFilter.finishDate.diff(widgetDatetimeFilter.startDate)).asHours() > 24 ? 'DD/MM HH:mm' : 'HH:mm:ss');
                        }}
                        // interval={'preserveStart'}
                        // dx={24}
                        // tickCount={drDays.length + 2}
                        // allowDataOverflow={true}
                        type="number"
                        // allowDecimals={false}
                        // scale={'utc'}
                        ticks={[...drDays.map(day => moment(widgetDatetimeFilter.startDate.date(day.id + 1).seconds(0).minutes(0).hours(0)).unix().valueOf())]}
                        domain={[moment(widgetDatetimeFilter.startDate).unix().valueOf(), moment(widgetDatetimeFilter.finishDate).unix().valueOf()]}
                    />
                    <YAxis dataKey="value"
                           type="number" domain={['auto', 'auto']}
                           tickFormatter={(value) => {
                               return numeral(value).format('0.0 a');
                           }}
                    />
                    <Tooltip
                        content={(e) => e.active &&
                            <CustomTooltip label={e.label} widgetData={{dataSources: [mainDataSource]}} functionDataSources={drDays}/>}
                        cursor={{stroke: 'black', strokeWidth: 2, opacity: '0.7'}}/>
                    <Line
                        type={'stepAfter'}
                        dataKey="value"
                        data={mainDataSource.data}
                        stroke={'#909090'}
                        strokeWidth={'2px'}
                        strokeOpacity={0.8}
                        name={mainDataSource.parameters.dataSourceName}
                        key={'lineChart' + mainDataSource.data.id}
                        background={{fill: '#eee'}}
                        dot={false}
                        activeDot={false}
                        animationDuration={0}
                    />
                    {drDays.map((day, i) => {
                        if (day.data) {
                            return <Line
                                type={'stepAfter'}
                                dataKey="value"
                                data={day.data}
                                stroke={'green'}
                                strokeWidth={'2px'}
                                strokeOpacity={0.8}
                                name={'Summ'}
                                key={'summChart' + i}
                                background={{fill: '#eee'}}
                                dot={false}
                                activeDot={false}
                                animationDuration={0}
                            />;
                        }
                    })}

                </ComposedChart>
            <table className={'table'}>
                <thead>
                <tr>
                    <th>
                        <small>{widgetDatetimeFilter.startDate.format('MMM, YYYY')}</small>
                    </th>
                    {drDays.map((day) => {
                        return <th key={`day${day.id}`}>{day.id + 1}</th>;
                    })}
                </tr>
                </thead>
                <tbody>
                {widgetProps.dataSources.filter(b => b.parameters.type === 'ADDITIONAL_DATA_SOURCE').map((ds, dsIdx) => (
                    <tr key={`ds${dsIdx}`}>
                        <td>{ds.parameters.dataSourceName}</td>
                        {drDays.map((day, dayIdx) => (
                            <td key={`day${dsIdx}${dayIdx}`}>
                                <CheckBox inTable={true} checked={day.ds === ds.id}
                                          onChange={() => setDrDay(day, ds)}/>
                            </td>)
                        )}
                    </tr>)
                )}
                </tbody>
            </table>

        </div>}
    </>;
}

UnloadingPlanningWidgetView.propTypes = {
    widgetProps: PropTypes.object,
    datetimeFilter: PropTypes.object,
    size: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

export default (connect(mapStateToProps, null)(UnloadingPlanningWidgetView));