export const dashboardConstants = {
    UPDATE_DASHBOARD_LIST: 'UPDATE_DASHBOARD_LIST',
    UPDATE_DASHBOARD_TAG_LIST: 'UPDATE_DASHBOARD_TAG_LIST',
    ADD_WIDGET: 'ADD_WIDGET',
    SET_WIDGET_PROPERTIES: 'SET_WIDGET_PROPERTIES',
    GET_WIDGET_LIST: 'GET_WIDGET_LIST',
    UPDATE_WIDGET: 'UPDATE_WIDGET',
    CHANGE_DASHBOARD_TIME: 'CHANGE_DASHBOARD_TIME',
    CLEAR_DASHBOARD: 'CLEAR_DASHBOARD',
    DELETE_WIDGET: 'DELETE_WIDGET',
    SET_EDITED_WIDGETS: 'SET_EDITED_WIDGETS',
    UPDATE_DASHBOARD_WIDGETS_POSITION: 'UPDATE_DASHBOARD_WIDGETS_POSITION',
    SET_DATETIME_FILTER: 'SET_DATETIME_FILTER',
    SET_DATASOURCE_LIST: 'SET_DATASOURCE_LIST',
    ZOOM_OUT: 'ZOOM_OUT',
    ZOOM_IN: 'ZOOM_IN',

    EDITION_MODE: 'EDITION_MODE',
    EDIT_SINGLE_MODE: 'EDIT_SINGLE_MODE',
    VIEW_MODE: 'VIEW_MODE',
    VIEW_PROPS_MODE: 'VIEW_PROPS_MODE',
    MANAGE_MODE: 'MANAGE_MODE',
    FULL_WINDOW_MODE: 'FULL_WINDOW_MODE',

    WIDGET_LINE_CHART: 'WIDGET_LINE_CHART',
    WIDGET_STEP_CHART: 'WIDGET_STEP_CHART',
    WIDGET_COLUMN_CHART: 'WIDGET_COLUMN_CHART',
    WIDGET_DOTS_CHART: 'WIDGET_DOTS_CHART',
    WIDGET_TABLE: 'WIDGET_TABLE',
    WIDGET_BUTTON: 'WIDGET_BUTTON',
    WIDGET_TEXT: 'WIDGET_TEXT',
    WIDGET_BENCHMARKING: 'WIDGET_BENCHMARKING',
    WIDGET_PIE_CHART: 'WIDGET_PIE_CHART',
    WIDGET_DOUGHNUT_CHART: 'WIDGET_DOUGHNUT_CHART',
    WIDGET_LINES: 'WIDGET_LINES',
    WIDGET_SLIDER: 'WIDGET_SLIDER',
    WIDGET_CHECKBOX: 'WIDGET_CHECKBOX',
    WIDGET_TIME: 'WIDGET_TIME',
    WIDGET_FUNCTION: 'WIDGET_FUNCTION',
    WIDGET_DATA_SETTER: 'WIDGET_DATA_SETTER',
    WIDGET_PIVOT_TABLE: 'WIDGET_PIVOT_TABLE',
    WIDGET_GROUP_CHART: 'WIDGET_GROUP_CHART',
    WIDGET_GROUP_FUNCTION: 'WIDGET_GROUP_FUNCTION',
    WIDGET_CAPACITY_PROFILE: 'WIDGET_CAPACITY_PROFILE',
    WIDGET_ELECTRICITY_COST: 'WIDGET_ELECTRICITY_COST',
    WIDGET_ELECTRICITY_STORAGE: 'WIDGET_ELECTRICITY_STORAGE',
    WIDGET_MAP: 'WIDGET_MAP',
    WIDGET_MNEMOSCHEME: 'WIDGET_MNEMOSCHEME',
    WIDGET_DEMAND_MANAGEMENT_STRATEGY: 'WIDGET_DEMAND_MANAGEMENT_STRATEGY',
    WIDGET_GROUP_UNLOADING: 'WIDGET_GROUP_UNLOADING',
    WIDGET_SURPLUS_DEFICIT_UNLOADING: 'WIDGET_SURPLUS_DEFICIT_UNLOADING',
    WIDGET_UNLOADING_PLANNING: 'WIDGET_UNLOADING_PLANNING',

    GET_WIDGET_TYPE_LIST: 'GET_WIDGET_TYPE_LIST',

    CREATE_DASHBOARD_REQUEST: 'CREATE_DASHBOARD_REQUEST',
    CREATE_DASHBOARD_SUCCESS: 'CREATE_DASHBOARD_SUCCESS',
    CREATE_DASHBOARD_FAILURE: 'CREATE_DASHBOARD_FAILURE',

    DASHBOARD1_REQUEST: 'DASHBOARD1_REQUEST',
    DASHBOARD1_SUCCESS: 'DASHBOARD1_SUCCESS',
    DASHBOARD1_FAILURE: 'DASHBOARD1_FAILURE',

    DASHBOARD_LIST_REQUEST: 'DASHBOARD_LIST_REQUEST',
    DASHBOARD_LIST_SUCCESS: 'DASHBOARD_LIST_SUCCESS',
    DASHBOARD_LIST_FAILURE: 'DASHBOARD_LIST_FAILURE',

    DASHBOARD_REQUEST: 'DASHBOARD_REQUEST',
    DASHBOARD_SUCCESS: 'DASHBOARD_SUCCESS',
    DASHBOARD_FAILURE: 'DASHBOARD_FAILURE',

    DAY_COLOR: '#fdda33',
    NIGHT_COLOR: '#374a5d',

    DATETIME_FILTER_LIST: [
        {
            name: 'choose_datetime',
            value: 'custom'
        },
        {
            name: 'choose_day',
            value: 'custom_day'
        },
        {
            name: 'choose_month',
            value: 'custom_month'
        },
        {
            name: 'choose_year',
            value: 'choose_year'
        },
        {
            name: 'last_5m',
            value: 'last5,minutes'
        },
        {
            name: 'last_15m',
            value: 'last15,minutes'
        },
        {
            name: 'last_30m',
            value: 'last30,minutes'
        },
        {
            name: 'last_1h',
            value: 'last1,hours'
        },
        {
            name: 'last_3h',
            value: 'last3,hours'
        },
        {
            name: 'last_6h',
            value: 'last6,hours'
        },
        {
            name: 'last_12h',
            value: 'last12,hours'
        },
        {
            name: 'last_24h',
            value: 'last24,hours'
        }
    ],

    TIMER_INTERVAL_LIST: [
        {
            value: 0,
            name: 'Off'
        },
        {
            value: 1,
            name: '1s'
        },
        {
            value: 5,
            name: '5s'
        },
        {
            value: 10,
            name: '10s'
        },
        {
            value: 30,
            name: '30s'
        },
        {
            value: 60,
            name: '1m'
        },
        {
            value: 300,
            name: '5m'
        },
        {
            value: 9000,
            name: '15m'
        },
        {
            value: 18000,
            name: '30m'
        },
        {
            value: 360000,
            name: '1h'
        },
        {
            value: 720000,
            name: '2h'
        }
    ]

};
