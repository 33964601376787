import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function ClickOutside({ children, onClick, disabled }) {
    const refs = React.Children.map(children, () => React.createRef());
    const handleClick = e => {
        const isOutside = refs.every(ref => {
            return ref.current && !ref.current.contains(e.target);
        });
        if (isOutside) {
            onClick();
        }
    };

    useEffect(() => {
        if (!disabled) {
            document.addEventListener('click', handleClick);
        }

        return function() {
            document.removeEventListener('click', handleClick);
        };
    });

    return React.Children.map(children, (element, idx) =>
        React.cloneElement(element, { ref: refs[idx] })
    );
}

ClickOutside.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    disabled: PropTypes.bool
};

ClickOutside.defaultProps = {
    disabled: false
};

export default ClickOutside;