import React, {useState} from 'react';

import ImageUploader from 'components/ImageUploader';
import InputText from 'components/InputText';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import SelectCapacityTree from 'components/SelectCapacityTree';
import {capacityService} from 'services';
import {localeService} from 'services/locale.service';
import {useTranslation} from 'react-i18next';
import {Field, Form} from 'react-final-form';

function CapacityForm({
                          parentCapacity,
                          capacityTree,
                          onCancel,
                          selectedCapacity,
                          titleText,
                          buttonText,
                      }) {
    const {t} = useTranslation();
    const [isFetching] = useState(false);
    const [error, setError] = useState('');
    const handleSubmitForm = (values) => {
        const idValue = values.ownerCapacityId ? values.ownerCapacityId.id : 0;
        capacityService
            .createCapacity({
                ...values,
                ownerCapacityId: idValue,
                group: (idValue) === 0,
            })
            .then(() => {
                onCancel({needToReload: true, id: values.id || null});
            })
            .catch(() => {
                setError(`Ошибка ${titleText}`);
            });
    };
    const validateForm = (formData) => {
        if (!formData.name) {
            return {name: localeService.isRussian() ? 'Введите наименование нагрузки' : 'Enter Required Fields!'};
        }
    };

    return (
        <div>
            {titleText && <>
                <h3 style={{margin: '0 0 2rem 0'}}>{titleText}</h3>
                <hr/>
            </>}

            {!isFetching && !error && (
                <Form
                    validate={validateForm}
                    initialValues={
                        selectedCapacity
                            ? {
                                ...selectedCapacity,
                                ownerCapacityId: parentCapacity,
                            }
                            : {ownerCapacityId: parentCapacity}
                    }
                    onSubmit={handleSubmitForm}
                    render={({handleSubmit, values}) => (
                        <div>
                            <form className="form" onSubmit={handleSubmit}>
                                {values.ownerCapacityId !== 0 && (
                                    <Field name="imgView">
                                        {({input}) => (
                                            <ImageUploader
                                                onChange={(file) => input.onChange(file)}
                                            />
                                        )}
                                    </Field>
                                )}
                                <Field name="name">
                                    {({input, meta}) => (
                                        <InputText
                                            value={input.value}
                                            label={t('name') + ':'}
                                            isRequired={true}
                                            warningText={meta.error}
                                            onChange={(text) => input.onChange(text)}
                                        />
                                    )}
                                </Field>

                                <div className={'input-group understroke required'}>
                                    <div className={'input-group-prepend'}>
                                        <label>
                                            {(localeService.isRussian()
                                                ? 'Родительская нагрузка'
                                                : 'Parent Capacity') + ':'}
                                        </label>
                                    </div>
                                    <Field name="ownerCapacityId">
                                        {({input}) => (
                                            <SelectCapacityTree
                                                capacityTree={capacityTree}
                                                capacity={input.value}
                                                onSelect={(capacity) => input.onChange(capacity)}
                                            />
                                        )}
                                    </Field>
                                </div>
                                {isFetching && <Loader waitText={t('wait')}/>}
                                {error && <div className="alert alert-danger">{error}</div>}
                                <hr/>
                                <div className={'d-flex justify-content-between'}>
                                    <button type={'submit'} className={'btn btn-outline-success'}>
                                        {t(buttonText)}
                                    </button>
                                    {onCancel && (
                                        <button
                                            type="reset"
                                            className={'btn btn-outline-primary default ml2'}
                                            onClick={() => onCancel()}
                                        >
                                            {t('cancel')}
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    )}
                />
            )}
        </div>
    );
}

CapacityForm.propTypes = {
    parentCapacity: PropTypes.number,
    capacityTree: PropTypes.array.isRequired,
    onCancel: PropTypes.func,
    selectedCapacity: PropTypes.object,
    titleText: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
};

CapacityForm.defaultProps = {
    parentCapacity: 0,
    capacityTree: [],
    onCancel: null,
    selectedCapacity: null,
};
export default CapacityForm;
