import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../../services/locale.service';
import moment from 'moment';
import {connect} from 'react-redux';
import {withSize} from 'react-sizeme';
import * as numeral from 'numeral';
import SelectDateMultiple from '../../../../components/SelectDateMultiple';
// import {analyticService} from '../../../../services/analyticService';
import Loader from '../../../../components/Loader';
import {pivotService as pivotTableService} from './components/pivotTableService';
import AlertError from '../../../../components/AlertError';

function PivotTableView({widgetProps, datetimeFilter, size}) {
    const [dateArray, setDateArray] = useState([]);
    const [selectedDateList, setSelectedDateList] = useState([]);
    const [widgetDataSources, setWidgetDataSources] = useState(widgetProps.dataSources);
    const [isFetching, setFetching] = useState(false);
    const [totalInfo, setTotalInfo] = React.useState(null);
    const [error, setError] = useState('');


    useEffect((() => {
        const dateArray = getDateArray(datetimeFilter);
        setDateArray(dateArray);
        setSelectedDateList([]);
        setTotalInfo(null);
        setFetching(true);
        pivotTableService.reloadPivotTableData(widgetProps.dataSources, dateArray).then(
            dsList => {
                setWidgetDataSources(dsList);
            },
            e => {
                console.log('Error', e);
                setError(e);
            }
        ).finally(() => setFetching(false));
    }), [datetimeFilter]);

    const getDateArray = (datetimeFilter) => {
        const dateCount = datetimeFilter.finishDate.diff(datetimeFilter.startDate, 'days') + 1;
        let date = moment(datetimeFilter.startDate).subtract(1, 'days');
        let availableDates = Array.from(Array(dateCount), () => {
            date = moment(date).add(1, 'days');
            return date;
        });
        return availableDates;
    };

    const calculateTotalInfo = (unloadDays) => {
        let totalNeedToUnloadValue = 0;
        let totalRealUnloadValue = 0;
        let readyToUnloadCount = 0;
        let fullUnloadedCount = 0;

        widgetDataSources.filter(el => el.parameters.type === 'MAIN_DS').map((ds) => {
            let needToUnloadValue = 0;
            let realUnloadValue = 0;

            ds.data.map((dsData) => {
                if(unloadDays.findIndex(el => el.isSame(dsData.timeUpload, 'day')) >= 0){
                    needToUnloadValue += dsData.readyToUpload;
                    realUnloadValue += dsData.factUpload;
                    totalNeedToUnloadValue += dsData.readyToUpload;
                    totalRealUnloadValue += dsData.factUpload;
                }
            });
            if(needToUnloadValue > 0){
                readyToUnloadCount++;
                if(needToUnloadValue <= realUnloadValue){
                    fullUnloadedCount++;
                }
            }

        });

        setTotalInfo({
            needToUnloadValue: (totalNeedToUnloadValue/1000).toFixed(1),
            realUnloadValue: (totalRealUnloadValue/1000).toFixed(1),
            readyToUnloadDsCount: readyToUnloadCount,
            completedUnloadDsCount: fullUnloadedCount
        });
    };

    return <>
        <div className={'pivot-table'}>
            <div style={{width: size.width - 300 + 'px'}} className={'summary-table'}>
                {isFetching && <Loader waitText={''}/>}
                <AlertError isOpen={error != ''} message={error} onCancel={() => setError('')}/>
                {!isFetching &&
                <table className="table" style={{margin: 0 + 'px'}}>
                    <tbody>
                    <tr>
                        <td style={{textAlign: 'left'}}>Потребители / День месяца</td>
                        {dateArray.map((date, d) => {
                            let className = '';
                            if (selectedDateList.findIndex(el => el.isSame(date, 'day')) >= 0) {
                                className = 'selected';
                            } else if (date.get('day') === 6 || date.get('day') === 0) {
                                className = 'weekend';
                            }

                            return <td key={d}
                                       className={className}>
                                {date.format('DD')}
                            </td>;
                        })}
                    </tr>
                    {widgetDataSources && widgetDataSources.filter(el => el.parameters.type === 'MAIN_DS').map((ds, i) => {
                        if (ds) {
                            const dsProps = ds.parameters;
                            return (<tr key={'ds-' + i}>
                                <td style={{textAlign: 'left'}}>{dsProps.dataSourceName}</td>
                                {ds.data && ds.data.map((dsData, dIdx) => {
                                    let title = localeService.isRussian() ? 'Не готов' : 'Unready';
                                    let className = 'unready';
                                    const isSelected = selectedDateList.findIndex(el => el.isSame(dsData.timeUpload, 'day')) >= 0;
                                    if (dsData.readyToUpload > 0) {
                                        className = 'ready';
                                        title = localeService.isRussian() ? 'Готов' : 'Ready';
                                        if (isSelected) {
                                            if (dsData.readyToUpload > dsData.factUpload) {
                                                className += ' but_didnt';
                                                title = localeService.isRussian() ? 'Не выполнил разгрузку! (Заявлено / Выполнено)' : 'Ready but did\'t';
                                            }
                                            if (dsData.readyToUpload <= dsData.factUpload) {
                                                className += ' did_it';
                                                title = localeService.isRussian() ? 'Разгрузка успешно выполнена! (Заявлено / Выполнено)' : 'Done';
                                            }
                                        }
                                        // if (dsData.boolean && dsData.boolean === 'error') {
                                        //     className += ' error';
                                        //     title = localeService.isRussian() ? 'Ошибка выполнения скрипта! Не удалось определить статус разгрузки' : 'Error! Cannot get response!';
                                        // }
                                    }
                                    if (isSelected) {
                                        className += ' selected';
                                    }
                                    if (dsData.timeUpload.get('day') === 6 || dsData.timeUpload.get('day') === 0) {
                                        className = 'weekend';
                                    }

                                    return <td key={'ds-data-' + dIdx}
                                               title={title}
                                               className={className}>
                                        {dsData.readyToUpload > 0 && numeral(dsData.readyToUpload.toFixed(2)).format('0.0 a')}
                                        {dsData.readyToUpload > 0 && isSelected && ` / ${numeral(dsData.factUpload.toFixed(2)).format('0.0 a')}`}
                                    </td>;
                                })}

                            </tr>);
                        }
                    })
                    }
                    </tbody>

                </table>
                }
            </div>
            <div className={'summary-info'}>
                <h5>{localeService.isRussian() ? 'Сводная информация за месяц' : 'Summary of the month'}</h5>
                {!isFetching && <SelectDateMultiple disabled={false} datetimeFilter={datetimeFilter}
                                                    onSetDateList={(dateList) => {
                                                        setSelectedDateList(dateList);
                                                        calculateTotalInfo(dateList);
                                                    }}
                                                    label={localeService.isRussian() ? 'Дни разгрузки' : 'Add Target days'}/>
                }

                {!totalInfo && <p className={'mt-5 help-text'}>Выберите дни разгрузки</p>}
                {totalInfo && <div className={'mt-4'}>

                    <div className={'mb-4 d-flex justify-content-between align-items-center'}>
                        <span>Требуемый объем разгрузки (МВт):</span>
                        <b>{totalInfo.needToUnloadValue}</b>
                    </div>
                    <div className={'mb-4 d-flex justify-content-between align-items-center'}>
                        <span>Выполненный объем разгрузки (МВт):</span>
                        <b>{totalInfo.realUnloadValue}</b>
                    </div>
                    <div className={'mb-4 d-flex justify-content-between align-items-center'}>
                        <span>Потребители выполнившие разгрузку:</span>
                        <b style={{color: 'green'}}>{totalInfo.completedUnloadDsCount}</b>
                    </div>
                    <div className={'mb-4 d-flex justify-content-between align-items-center'}>
                        <span>Потребители готовые, но не выполнившие разгрузку:</span>
                        <b style={{color: 'red'}}>{totalInfo.readyToUnloadDsCount - totalInfo.completedUnloadDsCount}</b>
                    </div>
                </div>}
            </div>
        </div>

    </>;
}

PivotTableView.propTypes = {
    widgetProps: PropTypes.object,
    size: PropTypes.object,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

export default withSize()(connect(mapStateToProps, null)(PivotTableView));