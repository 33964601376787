import {apiConstants} from '../constants';
import {apiService} from './api.service';

function getCurrentUser() {
    return apiService.fetchGet(apiConstants.GET_USER);
}

function activateUser() {
    const config = apiService.getDefaultConfig();
    const url = apiService.addProps(apiConstants.USER_ACTIVATE);
    return apiService.fetchURL(url, config);
}

function editUser(user) {
    const body = {
        firstName: user.first_name,
        middleName: user.middle_name,
        lastName: user.last_name};
    return apiService.fetchPost(apiConstants.USER_EDIT, JSON.stringify(body));
}


export const userService = {
    getCurrentUser,
    activateUser,
    editUser
};

