import React from 'react';
import {localeService} from '../services/locale.service';
import InputText from '../components/InputText';
// import {ReactSVG} from 'react-svg';
// import ReactFileReader from 'react-file-reader';
// import svgImage from '../sources/images/interface/image.svg';
import {useTranslation} from 'react-i18next';
import AlertError from '../components/AlertError';
import {apiService} from '../services/api.service';
import {apiConstants} from '../constants';
import AlertSuccess from '../components/AlertSuccess';

function OrganizationCreate() {
    const {t} = useTranslation();
    const [isFetching, setFetching] = React.useState(false);
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState('');
    const [newUser, setNewUser] = React.useState({
        email: '',
        firstName: '',
        middleName: '',
        lastName: '',
        company: ''
    });

    const onRegister = () => {
        if (newUser.email !== '' && newUser.firstName !== '' && newUser.lastName !== '' && newUser.companyName !== '') {
            setFetching(true);
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(newUser)
            };
            apiService.fetchURL(apiConstants.REGISTER_NEW_ORGANIZATION_ADMINISTRATOR, config).then(
                () => {
                    setSuccess(localeService.isRussian() ? 'Чтобы продолжить регистрацию, проверьте вашу электронную почту и подтвердите email. ' : 'To continue registering, check your email. ');
                }, (error) => {
                    if(error === 'ALREDY_EXIST'){
                        setError(localeService.isRussian() ? 'Введенный email уже зарегистрирован в нашей системе!' : 'Entered email is already exist in the System!');
                    }else{
                        setError(localeService.isRussian() ? 'Невозможно зарегистрировать нового пользователя!' : 'Cannot register new user!');
                    }
                }
            ).finally(() => setFetching(false));
        } else {
            setError(localeService.isRussian() ? 'Пожалйуста, заполните все обязательные поля!' : 'Please fill out all required fields!');
        }
    };

    return <>
        <div className="registration-page">

            <span className="logo d-flex mb-4">
                    <b className="navbar-brand"
                       style={{
                           color: '@default-color',
                           fontSize: '2rem',
                           letterSpacing: '-1px',
                           fontKerning: 'none',
                           margin: '0 10px',
                           padding: 0
                       }}>
                            {apiConstants.APP_NAME}
                        </b>
                </span>
            <h5 className={'d-flex justify-content-end mb-5'} style={{
                marginTop: '-1rem',
                textAlign: 'right',
                letterSpacing: '0.2rem'
            }}>{localeService.isRussian() ? 'Регистрация администратора новой компании' : 'Administrator Registration'}</h5>
            <hr/>
            <form>
                {/*<div className={'row'}>*/}
                {/*<div className={'col-30'}>*/}
                {/*<div>*/}
                {/*<ReactFileReader*/}
                {/*fileTypes={['.png', '.jpg', '.gif']}*/}
                {/*base64={false}*/}
                {/*multipleFiles={false}*/}
                {/*handleFiles={(file) => handleFiles(file)}*/}
                {/*disabled={false}*/}
                {/*>*/}
                {/*{company.logo &&*/}
                {/*<img src={company.logo.type + ',' + company.logo.file} alt={'Capacity Icon'}*/}
                {/*style={{*/}
                {/*maxWidth: '100%',*/}
                {/*height: 'auto',*/}
                {/*cursor: 'pointer',*/}
                {/*border: '1px solid grey',*/}
                {/*borderRadius: '0.5rem'*/}
                {/*}}/>}*/}
                {/*</ReactFileReader>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div className={'col-70'}>*/}
                {/*<InputText label={localeService.isRussian() ? 'Название компании' : 'Company Name'}*/}
                {/*value={company.name}*/}
                {/*notInline={true}*/}
                {/*onChange={text => setCompany({...company, name: text})}/>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<h5 style={{*/}
                {/*marginTop: '-2rem',*/}
                {/*textAlign: 'right',*/}
                {/*letterSpacing: '0.2rem'*/}
                {/*}}>{localeService.isRussian() ? 'Данные администратора' : 'Owner information'}</h5>*/}
                {/*<hr/>*/}
                <InputText label={localeService.isRussian() ? 'Название компании' : 'Company Name'}
                           value={newUser.companyName}
                           isRequired={true}
                           onChange={text => setNewUser({...newUser, companyName: text})}/>
                <InputText label={'email'}
                           value={newUser.email}
                           isRequired={true}
                           onChange={text => setNewUser({...newUser, email: text})}/>
                <InputText label={t('last_name')}
                           value={newUser.lastName}
                           isRequired={true}
                           onChange={text => setNewUser({...newUser, lastName: text})}/>
                <InputText label={t('first_name')}
                           value={newUser.firstName}
                           isRequired={true}
                           onChange={text => setNewUser({...newUser, firstName: text})}/>
                <InputText label={t('middle_name')}
                           value={newUser.middleName}
                           onChange={text => setNewUser({...newUser, middleName: text})}/>
                <hr/>
                <div className={'d-flex justify-content-center'}>
                    {!error && !isFetching && !success &&
                    <button className={'btn btn-outline-success'} onClick={() => onRegister()}>
                        {localeService.isRussian() ? 'Зарегистрироваться' : 'Register'}
                    </button>}
                    <AlertError isOpen={error !== ''} message={error} onCancel={() => setError('')}/>
                    <AlertSuccess isOpen={success !== ''} message={success} isClosable={false}/>
                </div>

            </form>
        </div>
    </>;
}

export default OrganizationCreate;