import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import Popover from '../../../components/Popover';
import moment from 'moment/moment';
import {dashboardService} from '../../../services/dashboard.service';
import WidgetLoader from '../../../components/WidgetLoader';

function ButtonWidgetView({widgetProps}) {
    const [isFetching, setFetching] = useState(false);
    const [warning, setWarning] = useState('');

    const setValue = () => {
        setFetching(true);
        setWarning('');
        const dateSet = moment.utc(new Date()).add(widgetProps.parameters.timeOffset, 'hours').format('YYYY-MM-DD HH:mm:ss');
        const value =widgetProps.parameters.btnValue;
        dashboardService.widgetDataSet(widgetProps.dataSources[0].id, dateSet, value).then(
            result => {
                console.log(result);
            }, error => {
                console.log(error);
                setWarning(localeService.isRussian() ? 'Не удалось записать значение!' : 'Cannot record value!');

            }
        ).finally(() => setFetching(false));

    };

    // React.useEffect(() => {
    //     const value = Math.floor(Math.random() * Math.floor(1));
    //     updateBtnState(value);
    // }, []);
    //
    // const updateBtnState = (btnValue) => {
    //     if (btnValue === widgetProps.parameters.btnOnValue) {
    //         setWarning('');
    //         setBtnState({
    //             color: 'success',
    //             text: widgetProps.parameters.btnOnName,
    //             value: btnValue
    //         });
    //     } else if (btnValue === widgetProps.parameters.btnOffValue) {
    //         setWarning('');
    //         setBtnState({
    //             color: 'default',
    //             text: widgetProps.parameters.btnOffName,
    //             value: btnValue
    //         });
    //     } else {
    //         setWarning(localeService.isRussian() ? 'Не удалось получить последнее значение переменной' : 'Cannot get current variable value');
    //     }
    // };


    return (
        <div style={{
            height: (100 * widgetProps.h) - (widgetProps.name !== '' ? 30 : 0) - 12,
            display: 'flex',
            alignItems: 'center',
            marginTop: widgetProps.name === '' ? '-2rem' : 0
        }}>
            {isFetching && <WidgetLoader/>}

            {warning !== '' &&
            <Popover type={'WARNING'} position={'top-left'}>
                <p>{warning}</p>
            </Popover>
            }
            <button className={'btn btn-outline-primary default form-control'}
                    onClick={() => setValue()}>{widgetProps.parameters.btnName}</button>
        </div>
    );
}

ButtonWidgetView.propTypes = {
    widgetProps: PropTypes.object
};

export default ButtonWidgetView;