import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../services/locale.service';
import {ReactSVG} from 'react-svg';
import svgEdit from '../../sources/images/interface/edit.svg';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import AlertError from '../../components/AlertError';
import InputText from '../../components/InputText';
import {apiService} from '../../services/api.service';
import {apiConstants} from '../../constants';
import {useTranslation} from 'react-i18next';
import {dashboardService} from '../../services';
import {connect} from 'react-redux';
import {updateDashboardList} from '../../redux/actions/dashboardActions';

function RenameDashboardModal({dashboard, onRename, updateDashboardList}) {
    const {t} = useTranslation();
    const [isOpen, setOpened] = React.useState(false);
    const [newName, setNewName] = React.useState(dashboard.name);
    const [error, setError] = React.useState(null);
    const [isFetching, setFetching] = React.useState(false);


    const renameDashboard = () => {
        setFetching(true);
        apiService.fetchPost(apiConstants.EDIT_DASHBOARD, JSON.stringify({
            id: dashboard.id,
            name: newName
        })).then(
            () => {
                onRename(newName);
                dashboardService.getDashboardList()
                    .then((dbList) => {
                        updateDashboardList(dbList);
                    });
                setOpened(false);
            },
            error => {
                setError(error);
            }
        ).finally(() => {
            setFetching(false);
        });
    };

    return <>
        <button className={'svg-btn default'}
                title={localeService.isRussian() ? 'Переименовать дашборд':'Rename Dashboard'}
                onClick={() => setOpened(!isOpen)}>
            <ReactSVG src={svgEdit}></ReactSVG>
        </button>


        <Modal onCancel={() => setOpened(false)} footer={false} isClosable={false}
               isOpen={isOpen}
               title={localeService.isRussian() ? 'Переименовать дашборд' : 'Rename Dashboard'}>
            {!error && !isFetching &&
            <form>
                <InputText label={t('name')}
                               value={newName}
                               onChange={text => setNewName(text)}/>

                <div className={'modal-footer'}>
                    <button type="button" className={'btn btn-outline-success'} onClick={(e) => {
                        e.preventDefault();
                        renameDashboard();
                    }}>{t('apply')}</button>
                    <button className={'btn btn-outline-danger'} onClick={() => setOpened(false)}>{t('cancel')}</button>
                </div>
            </form>
            }

            {isFetching &&
            <Loader waitText={localeService.isRussian() ? 'Подождите ...' : 'Please, wait...'}/>}

            <AlertError isOpen={error !== null} onCancel={() => setError(null)} message={error}/>


        </Modal>

    </>;
}

RenameDashboardModal.propTypes = {
  dashboard: PropTypes.object,
    onRename: PropTypes.func,
    updateDashboardList: PropTypes.func
};

const mapDispatchToProps = {
    updateDashboardList: updateDashboardList
};
export default connect(null, mapDispatchToProps)(RenameDashboardModal);