import React from 'react';
import '../styles/preloader.scss';
import svgUpdate from '../sources/images/interface/update.svg';
import {ReactSVG} from 'react-svg';
import PropTypes from 'prop-types';

function WidgetLoader({isSignalStyle}) {

        return (
            <div className={'widget-loader' + (isSignalStyle ? ' signal':'')}>
                <ReactSVG src={svgUpdate}/>
            </div>
        );
}

WidgetLoader.propTypes = {
    isSignalStyle: PropTypes.bool
};

WidgetLoader.defaultProps = {
  isSignalStyle: false
};

export default WidgetLoader;