/*eslint-disable no-unused-vars*/

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../components/Modal';
import {localeService} from '../../../../services/locale.service';
import AlertError from '../../../../components/AlertError';
import Loader from '../../../../components/Loader';
import Select from '../../../../components/Select';
import {capacityCostCalculationService} from '../../../../services/capacityCostCalculation.service';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';
import {dashboardService} from '../../../../services/index';
import ColorPicker from '../../../ColorPicker';
import {ReactSVG} from 'react-svg';
import svgPeakHours from '../../../../sources/images/interface/peak_hours.svg';

function AddPeakHoursToChart({onApplyFunction, analyticFunctionList, dataSources, datetimeFilter}) {
    const {t} = useTranslation();
    const [isConfiguratorOpened, setConfiguratorOpened] = useState(false);
    const [error, setError] = useState(null);
    const [isFetching, setFetching] = useState(false);
    const electricityCostFunction = analyticFunctionList.filter(el => el.name === 'electricity-cost-calculation');
    const [functionArgs, setFunctionArgs] = useState({});
    const [isActive, setActive] = useState(false);
    const [peakHoursParams, setPeakHoursParams] = useState({
        dataSourceId: dataSources && dataSources.length > 0 ? dataSources[0].id : null,
        retailerName: '',
        regionName: '',
        startPeakDate: datetimeFilter.startDate,
        color: dashboardService.getRandomColor()
    });

    React.useEffect(() => {

        if (electricityCostFunction.length === 1) {
            let funcArgs = {};
            const regionArg = electricityCostFunction[0].args.filter(el => el.name === 'region');
            if (regionArg.length === 1) {
                funcArgs.region = {
                    ...regionArg[0],
                    value: '',
                    options: regionArg[0].options.map((op, i) => {
                        return {
                            id: i,
                            name: op,
                            code: op
                        };
                    })
                };
            }
            const retailerArg = electricityCostFunction[0].args.filter(el => el.name === 'retailer');
            if (retailerArg.length === 1) {
                funcArgs.retailer = {
                    ...retailerArg[0],
                    value: '',
                    options: retailerArg[0].options.map((op, i) => {
                        return {
                            id: i,
                            name: op,
                            code: op
                        };
                    })
                };
            }
            setFunctionArgs(funcArgs);
        }

    }, []);

    const runFunction = (e) => {
        e.preventDefault();
        setFetching(true);
        capacityCostCalculationService.getPeakHours(peakHoursParams, datetimeFilter)
            .then(
                result => {
                    if(result.length > 0){
                        const peakHoursDataSource = {
                            name: 'peakHours-'+peakHoursParams.startPeakDate.format('YYYY-MM-DD'),
                            color: peakHoursParams.color,
                            data: result
                        };
                        onApplyFunction(peakHoursDataSource);
                        setActive(true);
                        setConfiguratorOpened(false);
                    } else {
                        setActive(false);
                        setError(localeService.isRussian() ? 'Не удалось применить функцию. Попробуйте использовать другие параметры.' : 'Cannot apply function. Try to change function parameters.');
                    }

                }, () => {
                    setError(localeService.isRussian() ? 'Не удалось применить функцию. Попробуйте использовать другие параметры.' : 'Cannot apply function. Try to change function parameters.');
                }
            )
            .finally(() => setFetching(false));
    };

    return (<>
        <button className={'btn btn-outline-primary default round-btn' + (isActive ? ' active' : '')}
                title={localeService.isRussian() ? 'Показать/скрыть часы сетевой активности' : 'Show/Hide Peak Hours'}
                onClick={() => {
                    setConfiguratorOpened(!isConfiguratorOpened);
                }}>
            <ReactSVG src={svgPeakHours}/>
            {/*<span style={{fontStyle: 'italic', fontSize: '0.8rem', fontWeight: 'bold'}}>PH</span>*/}
        </button>
        <Modal onCancel={() => setConfiguratorOpened(false)} footer={false} isClosable={false}
               isOpen={isConfiguratorOpened}
               title={localeService.isRussian() ? 'Часы пиковой нагрузки' : 'Peak Hours'}>
            {!error && !isFetching &&
            <form>
                {functionArgs.region &&
                <Select isRequired={true}
                        value={peakHoursParams.regionName}
                        valueList={functionArgs.region.options}
                        onSelect={region => setPeakHoursParams({...peakHoursParams, regionName: region.name})}
                        label={localeService.isRussian() ? 'Регион' : 'Region'}>
                </Select>
                }
                {functionArgs.retailer &&
                <Select isRequired={true}
                        value={peakHoursParams.retailerName}
                        valueList={functionArgs.retailer.options}
                        onSelect={retailer => setPeakHoursParams({...peakHoursParams, retailerName: retailer.name})}
                        label={localeService.isRussian() ? 'Код поставщика электроэнергии' : 'Electricity supplier code'}>
                </Select>
                }
                <div className={'row'} style={{padding: 0, display: 'flex', width: '100%', position: 'relative'}}>
                    <div className={'input-group understroke'} style={{justifyContent: 'space-between'}}>
                        <div className={'input-group-prepend'}>
                            <label>{localeService.isRussian() ? 'Стартовая дата получения часов региона' : 'Start Date'}</label>
                            <DatePicker
                                // dateFormat="DD-MMM-YYYY"
                                showPopperArrow={false}
                                selected={new Date(peakHoursParams.startPeakDate)}
                                onChange={date => {
                                    const start = moment(date).startOf('day').utc();
                                    setPeakHoursParams({...peakHoursParams, startPeakDate: start});
                                }}
                            />
                        </div>
                    </div>
                    <ColorPicker style={{margin: '0 1rem'}} color={peakHoursParams.color}
                                 setColor={(color) =>setPeakHoursParams({...peakHoursParams, color: color})}/>
                </div>
            </form>
            }
            {!isFetching && !error &&
            <div className={'d-flex justify-content-center'}>
                <button type={'button'} className={'btn btn-outline-success mr1'}
                        onClick={(e) => runFunction(e)}
                >{t('apply')}</button>
                {isActive &&
                <button type={'button'} className={'btn btn-outline-primary default mr1'}
                                     onClick={() => {
                                         setActive(false);
                                         setConfiguratorOpened(false);
                                         onApplyFunction(null);
                                     }}>
                    {localeService.isRussian() ? 'Отключить функцию' : 'Function Off'}
                </button>
                }
                <button type={'button'} className={'btn btn-outline-danger mr1'}
                        onClick={() => {
                            setConfiguratorOpened(false);
                        }}>
                    {t('cancel')}
                </button>

            </div>
            }

            {isFetching &&
            <Loader waitText={localeService.isRussian() ? 'Загрузка функции...' : 'Loading function...'}/>}

            <AlertError isOpen={error !== null} onCancel={() => setError(null)} message={error}/>
        </Modal>
    </>);
}

AddPeakHoursToChart.propTypes = {
    analyticFunctionList: PropTypes.array,
    onApplyFunction: PropTypes.func,
    dataSources: PropTypes.array,
    datetimeFilter: PropTypes.object,
    widgetWidth: PropTypes.number
};


const mapStateToProps = state => {
    const analyticFunctionList = state.analyticReducer.analyticFunctionList;
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {analyticFunctionList, datetimeFilter};
};


export default connect(mapStateToProps, null)(AddPeakHoursToChart);