import React from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants/index';
import {widgetList} from './WidgetList';
import { ReactSVG } from 'react-svg';
import svgSettings from '../../../sources/images/interface/settings.svg';
import svgBasket from '../../../sources/images/interface/basket.svg';
import {deleteWidget, updateWidget} from '../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import '../../../styles/widget.scss';
import Modal from '../../../components/Modal';
import {localeService} from '../../../services/locale.service';
import AlertError from '../../../components/AlertError';
import {capacityService, deviceService} from '../../../services';

function Widget({widgetProps, mode, deleteWidget, updateWidget, dashboardDataSources, sizeParams}){

    const WidgetComponent = widgetList[widgetProps.widgetType];
    const [error, setError] = React.useState('');
    const [wProps, setWProps] = React.useState(null);

    React.useEffect(() => {
        if(mode === dashboardConstants.VIEW_MODE) {
            {/**
             Добавляем к источнику данных информацию об устройстве
             */}
            Promise.all(widgetProps.dataSources.map(ds => {
                return deviceService.getDeviceBySourceId(Number.parseInt(ds.sourceID)).then(
                    device => {
                        {/**
                         Добавляем к источнику данных информацию о нагрузке
                         */}
                        return capacityService.getCapacityByVariableId(ds.sourceID).then(
                            capacity => {
                                if(capacity.result){
                                    return {...ds, capacity: capacity.result, device: device};
                                }else{
                                    return {...ds, device: device, capacity: null};
                                }
                            }, () => {
                                return {...ds, device: device, capacity: null};
                            }
                        );
                    }, err => {
                        console.log(err);
                        return {...ds, device: null, capacity: null};
                    });
            })).then(
                dsList => {
                    setWProps({...widgetProps, dataSources: dsList});
                }
            );
        }else{
            setWProps(widgetProps);
        }
    }, []);

    return <div className={'widget ' + mode}>
        <div className={'widget-header'}>
            <label title={widgetProps.name !=='' ? widgetProps.name : widgetProps.widgetType}>{dashboardConstants.VIEW_MODE ? widgetProps.name : (widgetProps.name !=='' ? widgetProps.name : widgetProps.widgetType)}</label>
        </div>
        {mode === dashboardConstants.EDITION_MODE &&
        <div className="widget-props-panel non-draggable-area">
            <button key={'setting' + widgetProps.id} className={'btn btn-outline-primary default round-btn'}
                    onClick={() => {
                        if(dashboardDataSources.length > 0){
                            updateWidget({...widgetProps, mode: dashboardConstants.EDIT_SINGLE_MODE});
                        }else{
                            setError(localeService.isRussian() ?
                                'Список используемых источников данных пуст. Добавьте источники данных перейдя по одноименной вкладке.'
                                : 'Dashboard Data Source List is empty. Please add Data Sources on the "Data Sources" tab.');
                        }
                    }}>
                <ReactSVG src={svgSettings}/>
            </button>
            <button key={'close' + widgetProps.id} className={'btn btn-outline-primary default round-btn'} onClick={() => deleteWidget(widgetProps.id)}>
                <ReactSVG src={svgBasket}/>
            </button>
        </div>
        }
        <Modal isOpen={error !== ''} onCancel={() => setError('')} isClosable={false} footer={false}>
            <AlertError message={error} isOpen={error !== ''} onCancel={() => setError('')}/>
        </Modal>
        {wProps && <WidgetComponent widgetProps={wProps} mode={mode} sizeParams={sizeParams}
                         onCloseEditSingleMode = {() => updateWidget({...widgetProps, mode: dashboardConstants.EDITION_MODE})}/>}
    </div>;

}

Widget.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.VIEW_MODE]),
    children: PropTypes.node,
    deleteWidget: PropTypes.func,
    updateWidget: PropTypes.func,
    dashboardDataSources: PropTypes.array,
    sizeParams: PropTypes.object
};

const mapStateToProps = state => {
    const dashboardDataSources = state.dashboardReducer.dataSourceList;
    return {dashboardDataSources};
};

const mapDispatchToProps = {
    deleteWidget: deleteWidget,
    updateWidget: updateWidget
};
export default connect(mapStateToProps, mapDispatchToProps)(Widget);
