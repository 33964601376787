/* eslint-disable no-unused-vars */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import {widgetsService} from '../../../../services/widget.service';
import numeral from 'numeral';
import {withSize} from 'react-sizeme';
import {connect} from 'react-redux';
import {CartesianGrid, ComposedChart, Line, ReferenceArea, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {dashboardService} from '../../../../services';
import Loader from '../../../../components/Loader';
import CheckBox from '../../../../components/CheckBox';
import {
    calculateGbnMinusFactDr,
    calculateTotalData,
    generateHighLigthDifferenceData,
    initTotalData,
    setUnloadValue
} from './components/surplusAndDeficitService';
import '../../../../styles/group-unloading.scss';
import RRMSECalculation from '../components/RRMSECalculation';
import CustomLegend from './components/CustomLegend';
import EditUnloadValue from './components/EditUnloadValue';
import CustomTooltip from '../../../../components/CustomTooltip';
import ChangeHoursWindow from './components/ChangeHoursWindow';

function SurplusAndDeficitWidgetView({widgetProps, datetimeFilter, size}) {
    const [widgetDataSources, setWidgetDataSources] = useState(widgetProps.dataSources);
    const [isFetching, setFetching] = useState(true);
    const [totalData, setTotalData] = React.useState(initTotalData);
    const [dsInGroup, setDsInGroup] = React.useState([]);
    const [widgetDateTimeFilter, setWidgetDateTimeFilter] = React.useState(datetimeFilter);
    const [visibleCharts, setVisibleCharts] = React.useState(['FACT_DS', 'FACT_DR_DS', 'GBN_DS', 'GBN_DR_DS']);
    const [hoursWindow, setWindow] = React.useState(initWindow());
    const [moovingWindow, setMoovingWindow] = React.useState({window: null});
    const [highLigthDifference, setHighLigthDifference] = useState(false);
    const [highLigthDifferenceData, setHighLigthDifferenceData] = React.useState(null);

    React.useEffect(() => {
        setFetching(true);
        const dtFilter = datetimeFilter;
        dtFilter.datetimeRange = {name: 'choose_datetime', value: 'custom_day'};
        dtFilter.startDate = moment(dtFilter.finishDate).seconds(0).minutes(0).hours(8);
        dtFilter.finishDate = moment(dtFilter.finishDate).seconds(0).minutes(0).hours(21);
        dtFilter.timeGroup = '1h';
        dtFilter.interval = {value: 0, name: 'Off'};
        setWidgetDateTimeFilter(dtFilter);
        setWindow(initWindow());

        dashboardService.loadWidgetData(widgetProps.dataSources, dtFilter).then(
            dataSources => {
                dataSources = calculateGbnMinusFactDr(dataSources);
                dataSources = setUnloadValue(dataSources);
                setWidgetDataSources(dataSources);
                if (widgetProps.dataSources.length <= 4) {
                    const factDs = widgetProps.dataSources.find(el => el.parameters.type === 'FACT_DS');
                    setDsInGroup([+factDs.sourceID]);
                }
                setTotalData(calculateTotalData(dsInGroup, totalData, widgetDataSources));
            }
        ).finally(() => setFetching(false));
    }, [datetimeFilter]);

    React.useEffect(() => {
        setTotalData(calculateTotalData(dsInGroup, totalData, widgetDataSources));
    }, [dsInGroup]);

    React.useEffect(() => {
        if (highLigthDifference) {
            setHighLigthDifferenceData(generateHighLigthDifferenceData(dsInGroup, widgetDataSources, totalData, hoursWindow));
        }
    }, [widgetDataSources, totalData, hoursWindow]);

    return (
        <div className={'widget-content' + (isFetching ? ' data-loading' : '')}>
            <div className={'collapsed-widget-console'}>
                <div className={'d-flex'}>
                    <CheckBox label={'Подсветить профицит/дефицит'} checked={highLigthDifference} onChange={() => {
                        setHighLigthDifference(!highLigthDifference);
                        if (!highLigthDifference) {
                            setHighLigthDifferenceData(generateHighLigthDifferenceData(dsInGroup, widgetDataSources, totalData, hoursWindow));
                        }
                    }}/>
                    <ChangeHoursWindow hoursWindow={hoursWindow}
                                       className={'ms-4'}
                                       onChange={w => {
                                           setWindow(w);
                                       }}/>
                </div>

            </div>
            <ComposedChart
                width={size.width}
                height={280}
                onMouseMove={(e) => moovingWindow.window && setMoovingWindow({
                    ...moovingWindow,
                    position: e.activeLabel
                })}
                onMouseUp={() => {
                    if (moovingWindow.window) {
                        if (moovingWindow.window === 'start') {
                            setWindow({...window, start: moovingWindow.position});
                        }
                        if (moovingWindow.window === 'finish') {
                            setWindow({...window, finish: moovingWindow.position});
                        }
                    }
                    setMoovingWindow({window: null});
                }}
            >
                <CartesianGrid strokeDasharray='3 3'/>
                <XAxis
                    dataKey="time_upload"
                    tickFormatter={(number) => {
                        return moment.unix(number).format('HH:mm');
                    }}
                    type="number"
                    scale={'utc'}
                    allowDataOverflow={true}
                    domain={[moment(widgetDateTimeFilter.startDate).unix().valueOf(), moment(widgetDateTimeFilter.finishDate).unix().valueOf()]}
                />
                <YAxis dataKey="value"
                       type="number" domain={['auto', 'auto']}
                       tickFormatter={(value) => {
                           return numeral(value).format('0.0 a');
                       }}
                />
                <Tooltip
                    content={(e) => e.active &&
                        <CustomTooltip label={e.label}
                                       widgetData={{dataSources: [...totalData, highLigthDifference && highLigthDifferenceData.gbnMinusUnloadSumm]}}/>}
                    cursor={{stroke: 'black', strokeWidth: 1, opacity: '0.7'}}/>

                {totalData.map((tData, i) => {
                    if (visibleCharts.includes(tData.parameters.type)) {
                        return (<Line
                            type={'stepAfter'}
                            dataKey="value"
                            data={widgetsService.modifyChartData(tData.data, false)}
                            stroke={tData.parameters.color}
                            strokeWidth={'2px'}
                            strokeOpacity={0.8}
                            name={tData.parameters.dataSourceName}
                            key={`lineChart${i}`}
                            background={{fill: '#eee'}}
                            dot={false}
                            activeDot={false}
                            animationDuration={0}
                        />);
                    }
                })}

                {highLigthDifference && highLigthDifferenceData.gbnMinusUnloadSumm &&
                <Line
                    type={'stepAfter'}
                    dataKey="value"
                    data={widgetsService.modifyChartData(highLigthDifferenceData.gbnMinusUnloadSumm.data, false)}
                    stroke={highLigthDifferenceData.gbnMinusUnloadSumm.parameters.color}
                    strokeWidth={'2px'}
                    strokeOpacity={0.8}
                    name={highLigthDifferenceData.gbnMinusUnloadSumm.parameters.dataSourceName}
                    key={'gbnMinusUnloadSumm'}
                    background={{fill: '#eee'}}
                    dot={false}
                    activeDot={false}
                    animationDuration={0}
                />}

                <ReferenceLine x={moovingWindow.window === 'start' ? moovingWindow.position : hoursWindow.start}
                               stroke="red"
                               style={{cursor: 'col-resize', strokeWidth: 3}}
                               onMouseDown={(e) => e && setMoovingWindow({
                                   window: 'start',
                                   position: hoursWindow.start
                               })}/>
                <ReferenceLine x={moovingWindow.window === 'finish' ? moovingWindow.position : hoursWindow.finish}
                               stroke="red"
                               style={{cursor: 'col-resize', strokeWidth: 3}}
                               onMouseDown={(e) => e && setMoovingWindow({
                                   window: 'finish',
                                   position: hoursWindow.finish
                               })}/>

                <ReferenceArea x1={moment(widgetDateTimeFilter.startDate).unix().valueOf()} x2={hoursWindow.start}
                               strokeOpacity={0.3}/>
                <ReferenceArea x1={hoursWindow.finish} x2={moment(widgetDateTimeFilter.finishDate).unix().valueOf()}
                               strokeOpacity={0.3}/>


                {/*Заливка для подсветки функций*/}
                <defs>
                    <linearGradient id="red" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="red" stopOpacity={0}/>
                        <stop offset="95%" stopColor="red" stopOpacity={0.4}/>
                    </linearGradient>
                    <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="green" stopOpacity={0}/>
                        <stop offset="95%" stopColor="green" stopOpacity={0.4}/>
                    </linearGradient>
                    <linearGradient id="grey" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="grey" stopOpacity={0}/>
                        <stop offset="95%" stopColor="grey" stopOpacity={0.4}/>
                    </linearGradient>
                </defs>

                {/* Подсветка для функций */}
                {highLigthDifference && highLigthDifferenceData.backlightsAreas && highLigthDifferenceData.backlightsAreas.map((backlight, i) => {
                    return <ReferenceArea key={i} fill={backlight.color} x1={backlight.x1} x2={backlight.x2}
                                          y1={backlight.y1 || null} y2={backlight.y2 || null}
                    />;
                })}
            </ComposedChart>

                <CustomLegend visibleCharts={visibleCharts} chartsData={totalData}
                          onChange={(visCharts) => {
                              setVisibleCharts(visCharts);
                          }}/>


            <table className="table group-planning mt-2" style={{margin: 0 + 'px', border: '1px border grey'}}>
                <thead>
                <tr>
                    {widgetDataSources.length > 4 &&
                    <th style={{textAlign: 'center', verticalAlign: 'middle'}}>
                        В составе<br/> группы
                        <CheckBox className={'justify-content-center'} inTable={true}
                                  checked={widgetDataSources.filter(wds => wds.parameters.type === 'FACT_DS').map(el => +el.sourceID).every(el => dsInGroup.includes(el))}
                                  onChange={(checked) => {
                                      if (checked) {
                                          setDsInGroup(widgetDataSources.filter(wds => wds.parameters.type === 'FACT_DS').map(el => +el.sourceID));
                                      } else {
                                          setDsInGroup([]);
                                      }
                                  }}>
                        </CheckBox>
                    </th>}
                    <th style={{verticalAlign: 'middle'}}>{widgetDateTimeFilter.startDate.format('DD/MM/YYYY')}</th>
                    <th style={{verticalAlign: 'middle', textAlign: 'center'}}>Величина разгрузки, кВт</th>
                    <th style={{textAlign: 'center', verticalAlign: 'middle'}}>RRMSE<br/>
                        <small>(без подст. / с подст. / с подст. без пн.)</small>
                    </th>
                    {Array.from(Array(14).keys()).map((h, j) => {
                        let isInWindow = (h + 8) >= moment.unix(hoursWindow.start).hours() && (h + 8) < moment.unix(hoursWindow.finish).hours();
                        return (
                            <th className={isInWindow ? 'hour-window' : 'non-hour-window'}
                                style={{verticalAlign: 'middle'}}
                                key={j}>{`${String(h + 8).padStart(2, '0')}:00`}</th>);
                    })
                    }
                </tr>
                </thead>
                <tbody>
                {!isFetching && widgetDataSources.filter(wds => wds.parameters.type === 'FACT_DS').map((ds, dsIdx) => (
                    <tr key={`factDs${ds.id}`}>
                        {widgetDataSources.length > 4 && <th style={{textAlign: 'center', verticalAlign: 'middle'}}>
                            <CheckBox className={'justify-content-center'} inTable={true}
                                      checked={dsInGroup.includes(parseInt(ds.sourceID))}

                                      onChange={() => {
                                          if (dsInGroup.includes(parseInt(ds.sourceID))) {
                                              setDsInGroup(dsInGroup.filter(el => el !== parseInt(ds.sourceID)));
                                          } else {
                                              setDsInGroup([...dsInGroup, parseInt(ds.sourceID)]);
                                          }
                                      }}/>
                        </th>}
                        <th>{ds.parameters.dataSourceName}</th>
                        <th><EditUnloadValue dataSource={ds} onChange={(value) => {
                            const dsList = JSON.parse(JSON.stringify(widgetDataSources));
                            dsList.find(el => el.id === ds.id).unloadValue = value;
                            setWidgetDataSources(dsList);
                        }}/></th>
                        <th style={{verticalAlign: 'middle'}}>
                            <RRMSECalculation dataSourceId={ds.id} targetDay={datetimeFilter.finishDate}/>
                        </th>
                        {ds.gbnMinusFactDrData.map((d, j) => {
                                let isInWindow = d.time_upload >= hoursWindow.start && d.time_upload < hoursWindow.finish;
                                return <th key={`gbnMinusFacrDr${j}`}
                                           className={isInWindow ? 'hour-window' : 'non-hour-window'}
                                           style={{
                                               verticalAlign: 'middle',
                                               color: (isInWindow && d.value < ds.unloadValue ? 'red' : 'black')
                                           }}>
                                    {d.value.toFixed(2)}
                                </th>;
                            }
                        )}
                    </tr>
                ))}
                {isFetching && <tr>
                    <td colSpan={17}><Loader/></td>
                </tr>}
                </tbody>
            </table>
        </div>
    );


    function initWindow() {
        return {
            start: moment(datetimeFilter.finishDate).seconds(0).minutes(0).hours(12).unix(),
            finish: moment(datetimeFilter.finishDate).seconds(0).minutes(0).hours(16).unix()
        };

    }
}

SurplusAndDeficitWidgetView.propTypes = {
    widgetProps: PropTypes.object,
    size: PropTypes.object,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

export default withSize()(connect(mapStateToProps, null)(SurplusAndDeficitWidgetView));