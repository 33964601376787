import React from 'react';
import PropTypes from 'prop-types';
import {dashboardService} from '../../../services';
import {localeService} from '../../../services/locale.service';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import Loader from '../../../components/Loader';
import SeletTimeGroup from './components/SeletTimeGroup';
import {CSVLink} from 'react-csv';
import {ReactSVG} from 'react-svg';
import svgExportToCSV from '../../../sources/images/interface/export_data.svg';
import {useTranslation} from 'react-i18next';
import {widgetsService} from '../../../services/widget.service';
import {imageService} from '../../../services/image.service';
import SetTableLimits from './components/SetTableLimits';
import {updateWidget} from '../../../redux/actions/dashboardActions';

function TableWidgetView({widgetProps, datetimeFilter, size, updateWidget}) {
    const {t} = useTranslation();
    const [widgetData, setWidgetData] = React.useState(widgetProps);
    const [timeGroup, setTimeGroup] = React.useState(datetimeFilter.timeGroup);
    const [isDropDownOpen, setDropDownOpen] = React.useState(false);
    const [isFetching, setFetching] = React.useState(false);
    const [csvReport, setCSVReport] = React.useState({data: [], headers: [], filename: 'table_widget_data.csv'});
    const [limits, setLimits] = React.useState({isVisible: false, values: []});
    const [initialized, setInitialized] = React.useState(false);

    React.useEffect(() => {
        let widgetPresets = widgetProps.parameters.presets;
        if (widgetPresets && widgetPresets.limits) {
            const limits = widgetsService.initLimitsPresets(widgetProps.parameters.presets, widgetProps.dataSources);
                    setLimits(limits);
                    setInitialized(true);
        }
    }, []);

    React.useEffect(() => {
        if (widgetProps.parameters.type === 'TIME_SERIES') {
            setFetching(true);
            setTimeGroup(datetimeFilter.timeGroup);
            dashboardService.loadWidgetData(widgetData.dataSources, datetimeFilter).then(
                dataSources => {
                    setWidgetData({...widgetData, dataSources: dataSources});
                    renderDataForCsvReport(dataSources);
                    setFetching(false);
                }
            );
        }
        if (widgetProps.parameters.type === 'FUNCTION') {
            setFetching(true);
            Promise.all(
                widgetProps.dataSources.map((dataSource) => {
                    if (dataSource.parameters) {
                        return dashboardService.getWidgetFunctionData(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate, widgetProps.parameters.function.functionCode, widgetProps.parameters.function.functionParams, null)
                            .then(result => {
                                    return {
                                        ...dataSource,
                                        value: (result.list && result.list[0]) ? Number.parseFloat(result.list[0].value.toFixed(2)) : null
                                    };
                                }
                            );
                    } else {
                        return null;
                    }
                })
            ).then(datas => {
                // let nullCount = 0;
                // widgetData.map(el => {
                //     if(el.value === null){
                //         nullCount++;
                //     }
                // });
                // if(nullCount === widgetData.length){
                //     setEmptyData(true);
                // }
                setWidgetData({...widgetData, dataSources: datas});
            }).finally(
                () => setFetching(false)
            );
        }

        // reloadTimeSeries(timeGroupList[0]);
    }, [datetimeFilter, initialized]);

    // Инициализация функции для преобразования экспортируемых данных
    const renderDataForCsvReport = (dataSources) => {
        //инициализация массива для строки заголовков
        let headers = [{label: localeService.isRussian() ? 'Дата / Время' : 'Datetime', key: 'time_upload'}];
        //перебор массива значений источников данных для заполнения строки заголовков названиями источников данных
        dataSources.map((ds) => {
            headers.push({
                label: ds.parameters.dataSourceName + (ds.parameters.unit ? (' ,' + ds.parameters.unit) : ''),
                key: ds.id.toString()
            });
        });
        //инициализация массива для строк данных
        let data = [];
        //заполнение массива
        Array.from(Array(dataSources[0].data.length), (_, i) => {//перебор массива источников данных
            //создание временной переменной строки экспорта
            let dataRow = {};
            //заполнение первой ячейки данных - дата и время экспортируемых значений
            dataRow.time_upload = moment.utc(moment.unix(dataSources[0].data[i].time_upload)).format('DD/MM HH:mm:ss');
            //заполнение последующих ячеек данных значениями источников данных на момент времени
            dataSources.map((ds) => {
                dataRow[ds.id.toString()] = ds.data[i] && ds.data[i].value.toString() || '';
            });
            //добавление переменной строки в массив данных для экспорта
            data.push(dataRow);
        });
        //обновление состояния переменной csvReport
        setCSVReport({...csvReport, data: data, headers: headers});
    };

    const reloadTimeSeries = (tg) => {
        setTimeGroup(tg);
        setDropDownOpen(false);
        if (widgetProps.parameters && widgetProps.parameters.type && widgetProps.parameters.type === 'TIME_SERIES') {
            setFetching(true);
            dashboardService.loadWidgetDataTimeGrouped(widgetData.dataSources, datetimeFilter, null, tg).then(
                dataSources => {
                    renderDataForCsvReport(dataSources);
                    setWidgetData({...widgetData, dataSources: dataSources});
                }
            ).then(() => {
                setFetching(false);
            });
        }
    };

    function renderFunctionTable(dataSources) {
        return <tbody>
        {dataSources && dataSources.map((ds, i) => {
            let isAlertData = false;
            let color = 'inherit';
            if (limits && limits.isVisible && limits.values) {
                let dsLimit = limits.values.find(el => el.dataSource.id === ds.id);
                if (dsLimit && dsLimit.value && ds.value) {
                    isAlertData = dsLimit.value <= Number.parseFloat(ds.value);
                    if (isAlertData) {
                        color = imageService.hexToRGB(dsLimit.color, 0.5);
                    }
                }
            }
            return <tr key={i}>
                <td>{ds.parameters.dataSourceName}</td>
                <td style={{backgroundColor: color}}>{Number.parseFloat(ds.value).toFixed(3)}</td>
            </tr>;
        })}
        </tbody>;
    }

    const renderTimeSeries = (widgetDataSources) => {
        return (<tbody>
        {Array.from(Array(widgetDataSources[0].data.length), (_, i) => {
            return <tr key={i}>
                <td>{moment.utc(moment.unix(widgetDataSources[0].data[i].time_upload)).format('DD/MM HH:mm:ss')}</td>
                {widgetDataSources.map((ds, j) => {
                    return <td key={j}>{ds.data[i] && ds.data[i].value || ''}</td>;
                })}
            </tr>;
        })}
        </tbody>);
    };

    const saveLimitPresets = (limitPreset) => {
        updateWidget({
            ...widgetProps, parameters: {
                ...widgetProps.parameters, presets: {
                    ...widgetProps.parameters.presets,
                    limits: {
                        ...limitPreset, values: limitPreset.values.map(limit => {
                            return {...limit, areaData: []};
                        })
                    }
                }
            }
        });
    };

    return <>
        {widgetProps.parameters && widgetProps.parameters.tableContent && (!widgetProps.parameters.type || widgetProps.parameters.type === 'MANUAL') &&
            <div className={'scrollable-zone'}>
                <table className="table" style={{margin: 0 + 'px', border: '1px border grey'}}>
                    {widgetProps.parameters.showHeader &&
                        <thead>
                        <tr>
                            {
                                widgetProps.parameters.tableContent[0].map((col, j) => {
                                    return (<th key={j}>{col}</th>);
                                })
                            }
                        </tr>
                        </thead>
                    }
                    <tbody>
                    {widgetProps.parameters.tableContent.map((row, i) => {
                        if (widgetProps.parameters.showHeader && i === 0) {
                            return;
                        } else {
                            return (
                                <tr key={i}>
                                    {
                                        row.map((col, j) => {
                                            return <td key={j}>{col}</td>;
                                        })
                                    }
                                </tr>
                            );
                        }
                    })}
                    </tbody>
                </table>
            </div>
        }
        {widgetProps.parameters && widgetProps.dataSources && widgetProps.parameters.type && widgetProps.parameters.type === 'TIME_SERIES' &&
            <div className="time-series-table">
                <div className={'widget-management-console'}>
                    <div className={'dropdown-container datetime-range mr1' + (isDropDownOpen ? ' open' : '')}>
                        <SeletTimeGroup timeGroup={timeGroup} datetimeFilter={datetimeFilter}
                                        onChangeTimeGroup={(el) => reloadTimeSeries(el)}/>
                    </div>
                    {/*компонент библиотеки react-csv для запуска процедуры экспорта данных виджета в CSV файл*/}
                    <CSVLink {...csvReport}>
                        {/*вывод кнопки для запуска экспорта*/}
                        <button type="button"
                                title={localeService.isRussian() ? 'Экспорт данных в CSV' : 'Export data to CSV'}
                                className={'btn btn-outline-primary default round-btn'}
                                onClick={e => e.stopPropagation()}>
                            <ReactSVG src={svgExportToCSV}/>
                        </button>
                    </CSVLink>
                </div>
                {!isFetching &&
                    <div className={'scrollable-zone'}
                         style={{width: size.width + 6, height: widgetProps.h * 100 - 30}}>
                        <table className="table" style={{margin: 0 + 'px', border: '1px border grey'}}>
                            {widgetProps.parameters.showHeader &&
                                <thead>
                                <tr>
                                    <th>{localeService.isRussian() ? 'Дата/Время' : 'Datetime'}</th>
                                    {
                                        widgetProps.dataSources.map((ds, j) => {
                                            return (
                                                <th key={j}>{ds.parameters.dataSourceName}{ds.parameters.unit ? (' ,' + ds.parameters.unit) : ''}</th>);
                                        })
                                    }
                                </tr>
                                </thead>
                            }
                            {widgetData.dataSources.length > 0 && widgetData.dataSources[0].data && widgetData.dataSources[0].data.length === 0 &&
                                <tbody>
                                <tr>
                                    <td colSpan={widgetData.dataSources.length + 1}> {localeService ? 'Нет данных за выбранный период' : 'Empty data'}</td>
                                </tr>
                                </tbody>
                            }
                            {widgetData.dataSources.length > 0 && widgetData.dataSources[0].data && widgetData.dataSources[0].data.length > 0 &&
                                renderTimeSeries(widgetData.dataSources)
                            }
                        </table>
                    </div>}
                {isFetching && <Loader/>}
            </div>}

        {widgetData.parameters && widgetData.dataSources && widgetData.parameters.type && widgetData.parameters.type === 'FUNCTION' &&
            <div>
                <div className={'widget-management-console'}>
                    {limits && <SetTableLimits limits={limits} dataSources={widgetProps.dataSources} onChange={el => {
                        // const newLimits = widgetsService.calculateChartLimitAreas(el, widgetData.dataSources);
                        setLimits(el);
                        renderFunctionTable(widgetData.dataSources, el);
                        saveLimitPresets(el);
                    }}/>}
                </div>
                <table className="table" style={{margin: 0 + 'px', border: '1px border grey', borderCollapse: 'collapse'}}>
                    <thead>
                    <tr>
                        <th>{t('data_source')}</th>
                        <th>{t('value')}</th>
                    </tr>
                    </thead>
                    {renderFunctionTable(widgetData.dataSources, limits)}
                </table>
            </div>
        }
    </>;
}

TableWidgetView.propTypes = {
    widgetProps: PropTypes.object,
    datetimeFilter: PropTypes.object,
    size: PropTypes.object,
    updateWidget: PropTypes.func
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};


const mapDispatchToProps = {
    updateWidget: updateWidget
};

export default (connect(mapStateToProps, mapDispatchToProps)(TableWidgetView));