import React from 'react';
import PropTypes from 'prop-types';
import {widgetsService} from '../../../services/widget.service';
import {Bar, BarChart, Cell, LabelList, XAxis, YAxis} from 'recharts';

function BenchmarkingPreview({widgetProps, sizeParams}) {
    const [previewData, setPreviewData] = React.useState([]);
    React.useEffect(()=> {
        setPreviewData(widgetsService.generateTestData(widgetProps.dataSources));
    }, []);

    const renderShapeBar = (e) => {
        const {fill, x, y, width, height} = e;
        if (width) {
            return <rect x={x} y={y} rx={height / 2} ry={height / 2}
                         width={width} height={height}
                         style={{fill: fill, stroke: 'none'}}/>;
        }
    };

    return (
                <BarChart width={sizeParams.width} height={sizeParams.height} data={previewData} layout="vertical">
                    <XAxis type="number" axisLine={false} tickLine={false} tick={false}/>
                    <YAxis dataKey="name"
                           type="category"
                           tickSize={10}
                           width={sizeParams.width / 4}
                           axisLine={false} tickLine={false}/>
                    <Bar dataKey="value"
                         barSize={20}

                         shape={(props) => renderShapeBar(props)}
                         background={{fill: '#eee'}}>
                        <LabelList dataKey="value" position="outside"
                                   style={{fill: 'white', stroke: '@default-color', strokeWidth: '0.015rem'}}/>
                        {
                            previewData.map((entry, index) => (
                                <Cell key={`cell-${index}`}
                                      fill={entry['color']}
                                />
                            ))
                        }
                    </Bar>
                </BarChart>
    );
}

BenchmarkingPreview.propTypes = {
    widgetProps: PropTypes.object,
    sizeParams: PropTypes.object
};

export default BenchmarkingPreview;