import React, {useState} from 'react';
import {localeService} from '../../services/locale.service';
import {useTranslation} from 'react-i18next';
import AddVirtualDevice from './AddVirtualDevice';
import AddModbusDevice from './AddModbusDevice';
import AddMQTTDevice from './AddMQTTDevice';

function AddDevice() {
    const [deviceType, setDeviceType] = useState(null);
    const {t} = useTranslation();

    return <>
        <nav className="page-header">
            <ul className="nav-left">
                <li><h5>{localeService.isRussian() ? 'Подключение устройства' : 'Add Device'}</h5></li>
            </ul>
        </nav>
        <div className={'container-700'}>
            <h5 className={'mt-5 mb-4'}>{localeService.isRussian() ? 'Tип устройства' : 'Device type'}</h5>
            <div className="d-flex mb-5">
                <button type="button"
                        className={'me-4 btn btn-outline-primary default ' + (deviceType === 'mqtt' ? 'active' : '')}
                        onClick={() => setDeviceType('mqtt')}>MQTT
                </button>
                <button type="button"
                        className={'me-4 btn btn-outline-primary default ' + (deviceType === 'modbus' ? 'active' : '')}
                        onClick={() => setDeviceType('modbus')}>MODBUS
                </button>
                <button type="button"
                        className={'btn btn-outline-primary default ' + (deviceType === 'virtual' ? 'active' : '')}
                        onClick={() => setDeviceType('virtual')}>{t('virtual')}</button>
            </div>

            {deviceType === 'modbus' && <AddModbusDevice/>}
            {deviceType === 'virtual' && <AddVirtualDevice/>}
            {deviceType === 'mqtt' && <AddMQTTDevice/>}
        </div>
    </>;

}

export default AddDevice;