/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {dashboardService} from '../../../../services/index';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import Loader from '../../../../components/Loader';
import '../../../../styles/group-unloading.scss';
import RRMSECalculation from '../components/RRMSECalculation';
import CheckBox from '../../../../components/CheckBox';

function GroupUnloadingView({widgetProps, datetimeFilter, size}) {
    const [isFetching, setFetching] = React.useState(false);
    const [groupDataSources, setGroupDataSources] = React.useState([]);
    const startHour = 8;
    const endHour = 21;
    const [targetDay, setTargetDay] = React.useState(datetimeFilter.finishDate);
    const [totalRows, setTotalRows] = React.useState({idList: [], data: (Array.from(Array(14).keys(), () => '—'))});

    React.useEffect(() => {
        setFetching(true);
        setTotalRows({idList: [], data: (Array.from(Array(14).keys(), () => '—'))});
        const dtFilter = {...datetimeFilter};
        setTargetDay(dtFilter.finishDate);

        dtFilter.datetimeRange = {name: 'choose_datetime', value: 'custom'};
        dtFilter.startDate = moment(dtFilter.finishDate).seconds(0).minutes(0).hours(startHour);
        dtFilter.finishDate = moment(dtFilter.finishDate).seconds(0).minutes(0).hours(endHour);
        dtFilter.timeGroup = '1h';
        dtFilter.interval = {value: 0, name: 'Off'};

        setGroupDataSources([]);

        dashboardService.loadWidgetData(widgetProps.dataSources.filter(el => el.parameters.type === 'ADDITIONAL_DATA_SOURCE'), dtFilter).then(
            dataSources => {
                let groupedDSList = [];
                widgetProps.dataSources.filter(a => a.parameters.type === 'MAIN_DATA_SOURCE').map(mainDS => {
                    let groupDS = {...mainDS, additionalDataSources: []};
                    dataSources.filter(b => b.parameters.parentId === Number.parseInt(mainDS.sourceID)).map(ds => {
                        groupDS.additionalDataSources.push(ds);
                    });
                    groupedDSList.push(groupDS);
                });

                groupedDSList.map(dsGroup => {
                    if (dsGroup.additionalDataSources.every(el => el.data.length === (endHour - startHour + 1))) {
                        let i = 0;
                        let data = [];
                        while (i < 14) {
                            const values = dsGroup.additionalDataSources.map(el => el.data[i].value);
                            data.push({
                                time_upload: i,
                                value: Math.min(...values).toFixed(1)
                            });
                            i++;
                        }
                        dsGroup.data = data;
                    } else {
                        dsGroup.data = [];
                    }

                });
                setGroupDataSources(groupedDSList);
            }
        ).finally(() => setFetching(false));
    }, [datetimeFilter]);

    return <>
        <div className="time-series-table">
            <div className={'scrollable-zone'} style={{width: size.width + 6, height: widgetProps.h * 100 - 30}}>
                <table className="table group-planning" style={{margin: 0 + 'px', border: '1px border grey'}}>
                    <thead>
                    <tr>
                        <th style={{textAlign: 'center', verticalAlign: 'middle'}}>В составе<br/> группы</th>
                        <th style={{verticalAlign: 'middle'}}>{targetDay.format('DD/MM/YYYY')}</th>
                        <th style={{textAlign: 'center', verticalAlign: 'middle'}}>RRMSE<br/>
                            <small>(без подст. / с подст. / с подст. без пн.)</small>
                        </th>
                        {Array.from(Array(14).keys()).map((h, j) => {
                            return (
                                <th style={{verticalAlign: 'middle'}} key={j}>{`${String(h + 8).padStart(2, '0')}:00`}</th>);
                        })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {!isFetching &&
                    groupDataSources.map((dsGroup, i) => {
                        return <CapacityRow group={dsGroup} key={i} targetDay={targetDay} isInTotal={totalRows.idList.includes(dsGroup.id)}
                                            onChangeTotal={() => {
                                                let tRows = {...totalRows};
                                                if(tRows.idList.includes(dsGroup.id)){
                                                    tRows.idList = tRows.idList.filter(el => el !== dsGroup.id);
                                                }else{
                                                    tRows.idList = [...tRows.idList, dsGroup.id];
                                                }
                                                const totalData = Array.from(Array(14).keys()).map(i => {
                                                    if(tRows.idList.length > 0){
                                                        const totalDSList = groupDataSources.filter(el => tRows.idList.includes(el.id)).map(el => el.data[i].value);
                                                        const summ = totalDSList.reduce((total, item) => total + Number.parseFloat(item), 0);
                                                        return summ.toFixed(1);
                                                    }else{
                                                        return '—';
                                                    }

                                                });
                                                tRows.data = totalData;
                                                setTotalRows(tRows);
                                            }}/>;
                    })}
                    {!isFetching &&
                    <tr className={'total-row'}>
                        <th/>
                        <th style={{marginLeft: '1rem'}}>Итоги по группе:</th>
                        <th/>
                        {totalRows.data.map((tData,i) => (<th key={`total${i}`}>{tData}</th>))}
                    </tr>}
                    {isFetching && <tr>
                        <td colSpan={15}><Loader/></td>
                    </tr>}
                    </tbody>
                </table>
            </div>

        </div>
    </>;
}

function CapacityRow({group, targetDay, isInTotal, onChangeTotal}) {
    const [isCollapsed, setCollapsed] = React.useState(true);
    const [groupData, setGroupData] = React.useState(null);

    React.useEffect(() => setGroupData(group), targetDay);

    return [groupData && <tr key={groupData.id} className={'group-row'}>
        <th style={{textAlign: 'center', verticalAlign: 'middle'}}>
            <CheckBox inTable={true} checked={isInTotal} onChange={() => onChangeTotal()} className={'justify-content-center'}/>
        </th>
        <th>
            <button onClick={() => setCollapsed(!isCollapsed)} style={{backgroundColor: '#ffffff'}}
                    className={'dropdown-btn' + (isCollapsed ? ' closed' : ' opened')}>{groupData.parameters.dataSourceName}</button>
        </th>
        <th style={{verticalAlign: 'middle'}}><RRMSECalculation dataSourceId={groupData.id} targetDay={targetDay}/></th>
        {groupData.data.map((data, i) => {
            return <th key={'cData' + i}>{data.value}</th>;
        })}
    </tr>,
        groupData && groupData.additionalDataSources.map((ds, j) => {
            return <tr key={'capDsRow' + j} className={'collapsed-row' + (isCollapsed ? ' closed' : ' opened')}>
                <td></td>
                <td>{ds.parameters.dataSourceName}</td>
                <td></td>
                {ds.data.map((data, k) => {
                    return <td key={'data' + ds.id + '-' + k}>{data.value}</td>;
                })}
            </tr>;
        })];
}


GroupUnloadingView.propTypes = {
    widgetProps: PropTypes.object,
    datetimeFilter: PropTypes.object,
    size: PropTypes.object
};

CapacityRow.propTypes = {
    group: PropTypes.object,
    targetDay: PropTypes.object,
    isInTotal: PropTypes.bool,
    onChangeTotal: PropTypes.func
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

export default (connect(mapStateToProps, null)(GroupUnloadingView));