import {dashboardConstants} from '../../../constants';
import {localeService} from '../../../services/locale.service';
import Modal from '../../../components/Modal';
import PieChartProps from './PieWidgetCreate';
import BenchmarkingView from './BenchmarkingView';
import React from 'react';
import PropTypes from 'prop-types';
import {withSize} from 'react-sizeme';
import BenchmarkingPreview from './BenchmarkingPreview';

function Benchmarking({widgetProps, mode, onCloseEditSingleMode, size, sizeParams}) {
    return (
        <div>
            {mode === dashboardConstants.VIEW_MODE &&
            <BenchmarkingView widgetProps={widgetProps} size={size}/>
            }
            {mode === dashboardConstants.EDITION_MODE &&
            <BenchmarkingPreview widgetProps={widgetProps} sizeParams={sizeParams}/>
            }

            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false} onCancel={() => onCloseEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <PieChartProps widgetProps={widgetProps} onClose = {() => onCloseEditSingleMode()}/>
            </Modal>
            }
        </div>
    );
}

Benchmarking.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    sizeParams: PropTypes.object
};

export default withSize()(Benchmarking);