import React from 'react';
import PropTypes from 'prop-types';
import {apiConstants, dashboardConstants} from '../../../../constants/index';
import {localeService} from '../../../../services/locale.service';
import Modal from '../../../../components/Modal';
import UnloadingPlanningWidgetView from './UnloadingPlanningWidgetView';
import UnloadingPlanningWidgetCreate from './UnloadingPlanningWidgetCreate';
import {withSize} from 'react-sizeme';

function UnloadingPlanningWidget({widgetProps, mode, onCloseEditSingleMode, size}) {
    return (
        <>
            {mode === dashboardConstants.VIEW_MODE &&
            <UnloadingPlanningWidgetView widgetProps={widgetProps} size={size}/>
            }
            {mode === dashboardConstants.EDITION_MODE && (!widgetProps.parameters || !widgetProps.parameters.tableContent) &&
            <div style={{width: '90%', height: '90%', textAlign: 'center'}}>
                {widgetProps.image && widgetProps.image.id && <img style={{maxWidth: '100%', maxHeight: '100%'}} src={apiConstants.API_URL + '/file/download?fileId=' + widgetProps.image.id}/>}
            </div>
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} footer={false} isClosable={false} onCancel={() => onCloseEditSingleMode()}
                   title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <UnloadingPlanningWidgetCreate widgetProps={widgetProps} size={size}/>
            </Modal>
            }
        </>
    );
}

UnloadingPlanningWidget.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    size: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default withSize()(UnloadingPlanningWidget);