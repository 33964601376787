import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../services/locale.service';
import {deviceService} from '../../services/device.service';
import svgEdit from '../../sources/images/interface/edit.svg';
import {ReactSVG} from 'react-svg';
import Modal from '../../components/Modal';
import TimezoneSelect from 'react-timezone-select';
import Loader from '../../components/Loader';
import AlertError from '../../components/AlertError';
import {useTranslation} from 'react-i18next';
import moment from 'moment-timezone';
import '../../styles/filter-panel.scss';

function SetDeviceTimeZone({deviceId, onChange}) {

    const [isOpen, setOpen] = React.useState(false);
    const [isFetching, setFetching] = React.useState(false);
    const [error, setError] = React.useState('');
    const [selectedTimezone, setSelectedTimezone] = React.useState({});
    const {t} = useTranslation();

    const setTimeZone = () => {
        setFetching(true);
        const tz = 'UTC' + moment().tz(selectedTimezone.value).format('Z');
        deviceService.setDeviceTimeZone(deviceId, tz).then(
            result => {
                console.log(tz, result);
                onChange(tz);
                setOpen(false);
            },error => {
                setError(error);
            }
        ).finally(() => {
            setFetching(false);
        });
    };

    return <>
        <button className={'svg-btn sm default ml1'} style={{display: 'block', margin: '4px'}}
                onClick={() => setOpen(true)}
                title={localeService.isRussian() ? 'Задать временную зону' : 'Set Time Zone'}>
            <ReactSVG src={svgEdit}/>
        </button>
        {/*Окно для добавления новой виртуальной переменной*/}
        <Modal isOpen={isOpen} isClosable={true} footer={false} onCancel={() => setOpen(false)} width={'600px'}
               title={localeService.isRussian() ? 'Выбрать временную зону устройства' : 'Select Device TimeZone'}>
            {!isFetching && !error &&
            <>
                <TimezoneSelect
                    value={selectedTimezone}
                    onChange={(tz) => {
                        setSelectedTimezone(tz);
                    }}
                />
                <div className={'d-flex justify-content-center mt2'}>
                    <button type={'button'} className={'btn btn-outline-primary default ml2'}
                            onClick={() => setTimeZone()}>{t('apply')}</button>
                    <button type={'button'} className={'btn btn-outline-danger ml2'}
                            onClick={() => setOpen(false)}>{t('cancel')}</button>
                </div>
            </>
            }
            {isFetching && <Loader/>}
            <AlertError isOpen={error !== ''} message={error} onCancel={() => {
                setError('');
            }}/>
        </Modal>
    </>;
}

SetDeviceTimeZone.propTypes = {
    deviceId: PropTypes.number,
    onChange: PropTypes.func
};

export default SetDeviceTimeZone;