import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../../components/Modal';
import {localeService} from '../../../../../services/locale.service';
import Select from '../../../../../components/SelectLayOut';
import AlertError from '../../../../../components/AlertError';
import {pivotService, pivotTableTypes} from './pivotTableService';

function AddDataSourcesGroup({onChange, dashboardDataSources}) {
    const {t} = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const [error, setError] = useState(null);
    const [groupDataSources, setGroupDataSources] = useState([]);

    const selectDataSource = (ds, dsType) => {
        let groupDSList = [...groupDataSources];
        const dsIdx = groupDSList.findIndex(el => el.parameters.type === dsType);
        if (dsIdx < 0) {
            groupDSList.push(ds);
        } else {
            groupDSList[dsIdx] = ds;
        }
        setGroupDataSources(groupDSList);
    };

    return (<div className={'d-flex justify-content-center mt-5 mb-4'}>
        <button className={'btn btn-outline-primary'}
                title={localeService.isRussian() ? 'Добавить группу источников данных' : 'Add Data Source Group'}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                    setGroupDataSources([]);
                }}>
            {localeService.isRussian() ? 'Добавить группу источников данных' : 'Add Data Source Group'}
        </button>
        <Modal onCancel={() => setOpen(false)} footer={false} isClosable={false} isOpen={isOpen}
               title={localeService.isRussian() ? 'Добавление группы источников данных' : 'Data Source Group Addition'}>
            <form>
                {pivotTableTypes.map((dsType, i) => {
                    let selectedValue = {};
                    const selectedId = groupDataSources.findIndex(el => el.parameters.type === dsType);
                    if (selectedId >= 0) {
                        selectedValue = {
                            id: groupDataSources[selectedId].id,
                            name: groupDataSources[selectedId].parameters.dataSourceName
                        };
                    }
                    return <Select key={`specialDs${i}`} label={pivotService.getDSNameByType(dsType)}
                                   valueList={dashboardDataSources.filter(el => !groupDataSources.some(el2 => el2.id === el.id)).map(dbDS => {
                                       return {
                                           id: dbDS.id,
                                           name: dbDS.name + (dbDS.capacity ? `(${dbDS.capacity.name})` : '')
                                       };
                                   })}
                                   onSelect={(ds) => {
                                       const newDS = dashboardDataSources.find(el => el.id === ds.id);
                                       newDS.parameters = {
                                           dataSourceName: ds.name,
                                           type: dsType
                                       };
                                       newDS.idx = 'new';
                                       selectDataSource(newDS, dsType);
                                   }}
                                   value={selectedValue}
                    />;
                })}
            </form>
            <div className={'modal-footer'}>
                <div className={'d-flex justify-content-center'}>
                    <button type="button" className={'btn btn-outline-success'} onClick={(e) => {
                        e.preventDefault();
                        if (groupDataSources.length !== 3) {
                            setError('Выберите все источники данных!');
                        } else {
                            setOpen(false);
                            let groupDSList = [...groupDataSources];
                            const factDsId = groupDSList.find(el => el.parameters.type === 'MAIN_DS').id;
                            groupDSList.filter(el => el.parameters.type !== 'MAIN_DS').map(el => {
                                el.parameters.parentId = factDsId;
                            });
                            onChange(groupDSList);
                        }
                    }}>{t('add')}</button>
                    <button className={'btn btn-outline-danger'} onClick={() => {
                        setOpen(false);
                        setGroupDataSources([]);
                    }}>{t('cancel')}</button>
                </div>
            </div>
            <AlertError isOpen={error !== null} onCancel={() => setError(null)} message={error}/>
        </Modal>

    </div>);
}

AddDataSourcesGroup.propTypes = {
    dashboardDataSources: PropTypes.array,
    onChange: PropTypes.func
};


export default AddDataSourcesGroup;