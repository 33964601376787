import {apiConstants} from '../constants/index';
import {apiService} from './api.service';
import {loginService} from './login.service';
import moment from 'moment';

export function uploadCSVFile(data, delimiter, datetimeFormat) {
    const formData = new FormData();
    formData.append('file_CSV', data);
    formData.append('delimiter', new Blob([delimiter]));
    formData.append('formatDate', new Blob([datetimeFormat]));
    const user = loginService.getCurrentUser();
    const config = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
            'user-agent-token': user.userAgentToken
            // 'Content-type': 'multipart/form-data'
        },
        body: formData
    };
    const url = apiService.addProps(apiConstants.UPLOAD_CSV_FILE_URL);
    return fetch(url, config)
        .then(response => {
            const jResponse = response.json();
            if (!response.ok) {
                return jResponse.then(() => Promise.reject('Cannot parse file!'));
            }
            return jResponse;
        });
}

export function uploadXMLFile(data) {
    const formData = new FormData();
    formData.append('file_XML', data);
    const user = loginService.getCurrentUser();
    const config = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
            'user-agent-token': user.userAgentToken
            // 'Content-type': 'multipart/form-data'
        },
        body: formData
    };
    const url = apiService.addProps(apiConstants.UPLOAD_80020_FILE_URL);
    return fetch(url, config)
        .then(response => {
            const jResponse = response.json();
            if (!response.ok) {
                return jResponse.then(() => Promise.reject('Cannot parse file!'));
            }
            return jResponse;
        });
}

export function uploadEXCELFile(data, datetimeFormat) {
    const formData = new FormData();
    formData.append('file_EXCEL', data);
    formData.append('formatDate', new Blob([datetimeFormat]));

    const url = apiService.addProps(apiConstants.UPLOAD_EXCEL_FILE_URL);
    const user = loginService.getCurrentUser();
    const config = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.sessionResp.authenticationToken,
            'user-agent-token': user.userAgentToken
            // 'Content-type': 'multipart/form-data'
        },
        body: formData
    };
    return fetch(url, config)
        .then(response => {
            const jResponse = response.json();
            if (!response.ok) {
                return jResponse.then(() => Promise.reject('Cannot parse file!'));
            }
            return jResponse;
        });
}

export function generatePropsPreview(content, selectedColumnList) {
    const previewChart = {};
    const chartData = [];
    for (let i = 1; i < content.length; i++) {
        const row = {};
        row.row = i;
        let j = 1;
        selectedColumnList.map(selectedColumn => {
            row['data' + j] = Number.parseInt(content[i].data[selectedColumn.index]);
            previewChart['color' + j] = '#005ea3';
            previewChart['chartName' + j] = selectedColumn.columnValue.name;
            chartData.push(row);
            j++;
        });
    }
    previewChart.chartData = chartData;
    previewChart.chartCount = selectedColumnList.length;
    return previewChart;
}

export function uploadDataManual(importData, dataSourceID, deviceUUID){
    const body = {
        data: importData.map(data => {
            return {
                timestamp: moment(data.datetime).format('YYYY-MM-DD HH:mm:ss'),
                value: data.value
            };
        }),
        dataSourceID: dataSourceID,
        deviceUUID: deviceUUID
    };
    return apiService.fetchPost(apiConstants.UPLOAD_DATA_MANUAL, JSON.stringify(body));
}

export function importDataFromFile(relationDataList, fileUUID) {
    const body = {
        'fileUUID': fileUUID,
        'relationt': relationDataList
    };

    return apiService.fetchPost(apiConstants.UPLOAD_RELATION_DATA, JSON.stringify(body));
}

export function importDataFrom80020File(relationDataList, fileUUID) {
    const body = {
        'fileUUID': fileUUID,
        ...relationDataList
        // 'relationt': relationDataList
    };

    return apiService.fetchPost(apiConstants.UPLOAD_RELATION_DATA_80020, JSON.stringify(body));
}

/*export function importFromEnergoSphereTest(config){
    return apiService.fetchPost(apiConstans.UPLOAD_FROM_ENERGO_SPHERE_TEST, JSON.stringify(config));
}

export function importFromEnergoSphere(config){
    return apiService.fetchPost(apiConstans.UPLOAD_FROM_ENERGO_SPHERE, JSON.stringify(config));
}*/

export const importService = {
    uploadCSVFile,
    uploadEXCELFile,
    uploadXMLFile,
    importDataFromFile,
    importDataFrom80020File,
    uploadDataManual,
    /*importFromEnergoSphereTest,
    importFromEnergoSphere*/
};
