import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveReactGridLayout from 'react-grid-layout';
import {dashboardConstants} from '../../constants';
import Widget from './widgets/Widget';

function WidgetsGrid({widgetList, size}) {
    return (
        <ResponsiveReactGridLayout className={'widgets-grid'}
                                   cols={6}
                                   width={size.width}
                                   layout={widgetList}
                                   useCSSTransforms={false}
                                   rowHeight={100}
                                   isDraggable={false}
                                   isResizable={false}
        >
            {widgetList.map(el => createElement(el))}
        </ResponsiveReactGridLayout>
    );
}

function createElement(widget) {
    return (
        <div key={widget.id} data-grid={widget}>
            <Widget mode={dashboardConstants.VIEW_MODE} widgetProps={widget}/>
        </div>
    );
}

WidgetsGrid.propTypes = {
    widgetList: PropTypes.array,
    size: PropTypes.object
};

export default WidgetsGrid;