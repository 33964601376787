import React, {useState} from 'react';
import {localeService} from '../services/locale.service';
import { useTranslation } from 'react-i18next';
import {loginService} from '../services/login.service';
import {Link, useHistory, useLocation} from 'react-router-dom';
import svgRu from '../sources/images/interface/russia.svg';
import svgEn from '../sources/images/interface/england.svg';
import svgShow from '../sources/images/interface/show.svg';
import svgHide from '../sources/images/interface/hide.svg';
import { ReactSVG } from 'react-svg';
import '../styles/login.scss';
// import '../styles/buttons.scss';
import {apiConstants} from '../constants';
import {dashboardService} from '../services';
import AlertError from '../components/AlertError';
import Logo from './Logo';

function LoginPage() {

    let history = useHistory();
    let location = useLocation();
    const { t } = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [errorMessage, setError] = useState('');

    React.useEffect(() => {
        loginService.logout();
    }, []);

    const keyDown = (event) => {
        if (event.key === 'Enter'|| event.code === 'NumpadEnter') {
            event.preventDefault();
            handleSubmit();
        }
    };

    function handleSubmit() {
        setSubmitted(true);
        setError('');
        if (username && password) {
            setFetching(true);
            loginService.authorizeUser(username, password).then(
                async response => {
                    if (response) {
                        apiConstants.SESSION_UPDATER_INTERVAL = setInterval(() => loginService.authorizeUser(username, password), 1000 * 60 * 5);
                        let defaultDashboard = localStorage.getItem('ems_default_dashboard_id');
                        if(defaultDashboard === undefined || !parseInt(defaultDashboard)){
                            await dashboardService.getDashboardList().then(
                                dbList => {
                                    if(dbList.length > 0){
                                        defaultDashboard = dbList[0].id;
                                    }
                                }
                            );
                        }
                        let {from} = location.state !== undefined ? location.state : {from: {pathname: defaultDashboard ? ('/dashboard/' + defaultDashboard) : '/'}};
                        history.replace(from);
                    } else {
                        setSubmitted(false);
                        setFetching(false);
                    }
                }, error => {
                    setError(error.toString());
                    setSubmitted(false);
                    setFetching(false);
                }
            );
        }
    }

    return (
            <div className="login-page">
                <div className={'d-flex justify-content-center'}>
                    <Logo/>
                </div>
                <br/>
                <h5>{t('welcome')}</h5>
                <form name="form" onSubmit={() => handleSubmit()}>
                    <div className={'form-group ' + (submitted && !username ? ' has-error' : '')}>
                        <label htmlFor="username">Email</label>
                        <div className="input-group understroke">
                            <input type="text" className="form-control" name="username" value={username}
                                   onKeyDown = {e => keyDown(e)}
                                   onChange={el => setUsername(el.target.value)}/>
                            <span className="input-group-addon"></span>
                        </div>
                        {submitted && !password &&
                        <div className="help-block">{t('login_is_required')}</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">{t('password')}</label>
                        <div className="input-group understroke">
                            <input type={!showPassword ? 'password' : 'text'} className="form-control" name="password"
                                   onKeyDown = {e => keyDown(e)}
                                   value={password} onChange={el => setPassword(el.target.value)}/>
                            <span className="input-group-addon">
                                <button type="button" className={'svg-btn'}
                                        onClick={() => setShowPassword(!showPassword)}>
                                    <img style={{width:20, height:20}} src={showPassword ? svgShow : svgHide} alt={'Show symbols'}/>
                                </button>
                            </span>
                        </div>
                        {submitted && !password &&
                        <div className="help-block">{t('password_is_required')}</div>
                        }
                    </div>
                    {errorMessage &&
                        <AlertError isOpen={errorMessage} message={errorMessage} isClosable={false} onCancel={() => setError('')}/>
                    }
                    {/*{!errorMessage && <div className="form-group center">*/}
                        {/*<button type="button" className={'btn btn-outline-success'} onClick={handleSubmit.bind(this)}>*/}
                            {/*{isFetching ? <small>{t('wait')}</small> : t('sign_in')}*/}
                            {/*</button>*/}
                    {/*</div>}*/}
                    <div className={'d-flex justify-content-between mt-3'}>
                        <button type="button" className={'btn btn-outline-success'}
                                onKeyDown = {e => keyDown(e)}
                                onClick={handleSubmit.bind(this)}>
                            {isFetching ? <small>{t('wait')}</small> : t('sign_in')}
                        </button>
                        <div className={'d-flex'}>
                            <button type={'button'} className={'btn svg-btn ' + (localStorage.getItem('ems-language') === 'ru' ? ' op1' : ' op0_5')}
                                    onClick={() => localeService.setLanguage('ru')}>
                                <ReactSVG src={svgRu}/></button>
                            <button type={'button'} className={'btn svg-btn' + (localStorage.getItem('ems-language') === 'en' ? ' op1' : ' op0_5')}
                                    onClick={() => localeService.setLanguage('en')}>
                                <ReactSVG src={svgEn}/>
                            </button>
                        </div>
                    </div>
                    <div className={'d-flex justify-content-end mt-5'}>
                        <Link to={'/register_organization'}>
                            <span className={'link'}>{localeService.isRussian() ? 'Зарегистрироваться' : 'Registration'}</span>
                        </Link>
                        <a  onClick={e => {
                            e.preventDefault();
                            setError(localeService.isRussian() ? 'Для восстановления пароля свяжитесь с системным администратором' : 'To recover password, please, contact with system administrator!');
                        }}>
                            <span style={{whiteSpace: 'nowrap'}} className={'link'}>{localeService.isRussian() ? 'Забыли пароль?' : 'Recover password'}</span>
                        </a>
                    </div>
                </form>
            </div>
        );
}

export default LoginPage;

