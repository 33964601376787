import React from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import svgWarning from '../sources/images/interface/warning.svg';

function CustomInput({
                         label,
                         value,
                         onChange,
                         isInteger,
                         isDecimal,
                         disable,
                         warningText,
                         isTextarea,
                         placeHolder,
                         inTable,
                         isRequired,
                         ...rest
                     }) {
    const onChangeHandle = (el) => {
        let text = el;
        if (isInteger) {
            text = text.trim().replace(/\./g, '');

            if (isNaN(text)) {
                if (text.substring(0, 1) === '-') {
                    text = text.replace(/\D/g, '');
                    text = '-' + text;
                } else {
                    text = text.replace(/\D/g, '');
                }
            }

            text = parseInt(text) + '';
        }
        if (isDecimal) {
            text = text.replace(/[^0-9.]/g, '');
            if (text.startsWith('00')) {
                text = '0';
            }
            if (text.indexOf('.') >= 0 && text.length > text.indexOf('.') + 1) {
                text = parseFloat(text) + '';
            }
        }
        onChange(text);
    };

    return (
        <div className="scenario-custom">
            {!inTable && (
                <React.Fragment>
                    {label && <label className="scenario-custom__label">{label}</label>}
                </React.Fragment>
            )}
            {!isTextarea && (
                <input
                    disabled={disable}
                    type={isInteger ? 'number' : 'text'}
                    value={isTextarea ? '' : value}
                    placeholder={placeHolder}
                    required={isRequired}

                    {...rest}
                    className="scenario-custom__input"
                    onChange={(el) => {
                        el.preventDefault();
                        onChangeHandle(el.target.value);
                    }}
                />
            )}

            {warningText && (
                <div className={'warning-message'}>
                    <ReactSVG src={svgWarning}/>
                    <div>{warningText}</div>
                </div>
            )}
        </div>
    );
}

CustomInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    isInteger: PropTypes.bool,
    isDecimal: PropTypes.bool,
    disable: PropTypes.bool,
    warningText: PropTypes.string,
    isTextarea: PropTypes.bool,
    placeHolder: PropTypes.string,
    notInline: PropTypes.bool,
    inTable: PropTypes.bool,
    inputType: PropTypes.string,
    info: PropTypes.string,
};

CustomInput.defaultProps = {
    label: '',
    value: '',
    onChange: () => {
    },
    isRequired: false,
    isInteger: false,
    isDecimal: false,
    disable: false,
    warningText: '',
    isTextarea: false,
    notInline: false,
    inputType: 'text',
    info: null,
    inTable: false,
};

export default CustomInput;
