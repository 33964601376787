import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {dashboardService} from '../../../services';
import {withSize} from 'react-sizeme';
import {connect} from 'react-redux';
import {dashboardConstants} from '../../../constants';
import Loader from '../../../components/Loader';
import {Bar, BarChart, Cell, Tooltip, XAxis, YAxis, CartesianGrid} from 'recharts';
import {localeService} from '../../../services/locale.service';
import EmptyData from '../../../components/EmptyData';
import numeral from 'numeral';

function BarChartView({widgetProps, datetimeFilter, size}) {
    const [widgetData, setWidgetData] = useState([]);
    const [isUpdating, setUpdating] = useState(false);
    const chartWidth = size.width;
    const chartHeight = (100 * widgetProps.h) - 40;
    const [isEmptyData, setEmptyData] = useState(false);

    /**
     *Обновление данных по изменению @datetimeFilter
     * */
    useEffect(() => {
        setUpdating(true);
        setEmptyData(false);
        Promise.all(
            widgetProps.dataSources.map((dataSource) => {
                if (dataSource.parameters) {
                    return dashboardService.getWidgetFunctionData(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate, dataSource.parameters.functionCode, dataSource.parameters.functionParams, size.width)
                        .then(result => {
                                return {
                                    name: dataSource.parameters.dataSourceName,
                                    color: dataSource.parameters.color,
                                    value: (result.list && result.list[0]) ? Number.parseFloat(result.list[0].value.toFixed(2)) : null
                                };
                            }
                        );
                } else {
                    return null;
                }
            })
        ).then(widgetData => {
            let nullCount = 0;
            widgetData.map(el => {
                if(el.value === null){
                    nullCount++;
                }
            });
            if(nullCount === widgetData.length){
                setEmptyData(true);
            }

            setWidgetData(widgetData);
        }).finally(
            () => setUpdating(false)
        );
    }, [datetimeFilter]);


    return <>
        {isUpdating && <Loader waitText={localeService.isRussian() ? 'Загрузка данных...' : 'Uploading data...'}/>}
        {isEmptyData && <EmptyData/>}
        {!isUpdating && widgetData.length > 0 && !isEmptyData &&
        <BarChart
            width={chartWidth}
            height={chartHeight}
            data={widgetData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name"
                   type="category"/>
            <YAxis type="number" domain={['auto', 'auto']}
                   tickFormatter={(value) => {
                       return numeral(value).format('0.0 a');
                   }}/>
            <Tooltip/>
            <Bar dataKey="value" >
                {/*<LabelList dataKey="value" position="outside" style={{fill: 'white', stroke: '#005ea3', strokeWidth: '0.015rem'}}/>*/}
                {
                    widgetData.map((entry, index) => (
                        <Cell key={`cell-${index}`}
                              fill={entry.color}
                        />
                    ))
                }
            </Bar>
        </BarChart>
        }
    </>;
}


BarChartView.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

export default withSize()(connect(mapStateToProps, null)(BarChartView));