import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../../services/locale.service';
import {dashboardConstants} from '../../../../constants';
import Modal from '../../../../components/Modal';
import SurplusAndDeficitWidgetCreate from './SurplusAndDeficitWidgetCreate';
import SurplusAndDeficitWidgetView from './SurplusAndDeficitWidgetView';

function SurplusAndDeficitWidget({widgetProps, mode, onCloseEditSingleMode}) {
    return (
        <>
            {mode === dashboardConstants.VIEW_MODE &&
            <SurplusAndDeficitWidgetView widgetProps={widgetProps}/>
            }
            {mode === dashboardConstants.EDIT_SINGLE_MODE &&
            <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false}
                   onCancel={() => onCloseEditSingleMode()} title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
                <SurplusAndDeficitWidgetCreate widgetProps={widgetProps} onClose = {() => onCloseEditSingleMode()}/>
            </Modal>
            }
        </>
    );
}

SurplusAndDeficitWidget.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    sizeParams: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};


export default SurplusAndDeficitWidget;