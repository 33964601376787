import React from 'react';
import PropTypes from 'prop-types';
import {capacityService} from '../../../../services/capacity.service';
import svgEdit from '../../../../sources/images/interface/edit.svg';
import svgTrash from '../../../../sources/images/interface/trash.svg';
import {ReactSVG} from 'react-svg';
import {useTranslation} from 'react-i18next';
import {localeService} from '../../../../services/locale.service';
import Modal from '../../../../components/Modal';
import EditCapacityPassport from './EditCapacityPassport';
import CreateCapacityPassport from './CreateCapacityPassport';
import {deleteCapacityPassport} from '../../../../services/passport.service';

//Компонент для просмотра списка паспортов выбранной нагрузки
function CapacityPassportList({capacity}) {
    //Инициализация библиотеки для перевода
    const {t} = useTranslation();
    //Инициализация переменной списка папортов
    const [passportList, setPassportList] = React.useState([]);
    //Инициализация флага открытия диалогового окна
    const [isOpenAddPassport, openAddPassport] = React.useState(false);
    //Инициализация перемонной просматриваемого паспорта
    const [viewPassport, setViewPassport] = React.useState(null);
    //Инициализация переменной редактируемого паспорта
    const [editPassport, setEditPassport] = React.useState(null);

    //Функция для обновления списка паспортов для выбранной нагрузки
    React.useEffect(() => {
        //вызов функции для запроса списка паспортов
        loadPassportList();
    }, [capacity]);

    //функция для запроса списка паспортов
    const loadPassportList = () => {
        capacityService.getCapacity(capacity.id).then(
            capacity => {
                //обновление состояния переменной списка папортов
                setPassportList(capacity.passportDynamic);
            }
        );
    };

    //рендеринг HTML кода
    return (
        <>
            <ul className={'ems-object-list'}>
                {/*перебор списка паспортов*/}
                {passportList && passportList.map((passport, i) => {
                    return <li key={i}>
                        <label onClick={() => setViewPassport(passport)}>{passport.name}</label>
                        <div className={'action-panel'}>
                            <div>
                                {/*кнопка для вызова окна для установки редактируемого паспорта*/}
                                <button
                                    title={t('edit')}
                                    className={'btn btn-outline-primary round-btn default edit-variable-btn'}
                                    onClick={() => setEditPassport(passport)}>
                                    <ReactSVG src={svgEdit}/>
                                </button>
                            </div>
                            <div>
                                {/*кнопка для удаления паспорта*/}
                                <button
                                    title={t('delete')}
                                    className={
                                        'btn btn-outline-primary round-btn default edit-variable-btn'
                                    }
                                    onClick={() => {
                                        if (window.confirm(localeService.isRussian() ?
                                                'Вы уверены, что хотите удалить паспорт?'
                                                : 'Are you sure, you want to delete passport?')) {
                                            deleteCapacityPassport(passport.id, 'PASSPORT_DYNAMIC').then(() => loadPassportList());
                                        }
                                    }}
                                >
                                    <ReactSVG src={svgTrash}/>
                                </button>
                            </div>
                        </div>
                    </li>;
                })}
            </ul>
            <br/>
            <div className={'d-flex justify-content-end'}>
                {/*кнопка для создания паспорта*/}
                <button type={'button'} className={'btn btn-outline-success'}
                        onClick={() => openAddPassport(true)}>{localeService.isRussian() ? 'Добавить паспорт' : 'Add Passport'}</button>
            </div>
            <hr/>
            {/*диалоговое окно для создания паспорта*/}
            <Modal isOpen={isOpenAddPassport} isClosable={false} footer={false} width={900}
                   title={localeService.isRussian() ? 'Конструктор паспортов' : 'Passport constructor'}>
                {/*вызов компонента для создания паспорта*/}
                <CreateCapacityPassport capacityId={capacity.id}
                                        onCancel={() => openAddPassport(false)}
                                        onSuccess={() => {
                                            loadPassportList();
                                            openAddPassport(false);
                                        }}/>
            </Modal>
            {/*диалоговое окно для просмотра паспорта*/}
            <Modal isOpen={viewPassport !== null} width={800} isClosable={false} footer={false} title={''}>
                {/*вызов компонента для редактирования паспорта с флагом о запрете редактирования*/}
                <EditCapacityPassport
                    capacity={capacity}
                    passport={viewPassport}
                    isEditionMode={false}
                    cancelFunc={() => setViewPassport(null)}
                />
            </Modal>
            {/*диалоговое окно для редактирования паспорта*/}
            <Modal isOpen={editPassport !== null} width={700} isClosable={false} footer={false}
                   onCancel={() => setEditPassport(null)}
                   title={''}>
                {/*вызов компонента для редактирования паспорта с флагом о разрешении редактирования*/}
                <EditCapacityPassport
                    isEditionMode={true}
                    capacity={capacity}
                    passport={editPassport}
                    cancelFunc={() => setEditPassport(null)}
                />
            </Modal>
        </>
    );
}

CapacityPassportList.propTypes = {
    capacity: PropTypes.object
};

export default CapacityPassportList;
