import React from 'react';
import PropTypes from 'prop-types';
import {widgetsService} from '../../../services/widget.service';
import {ScatterChart, CartesianGrid, Line, LineChart, XAxis, YAxis, ZAxis, Scatter} from 'recharts';
import {dashboardConstants} from '../../../constants';

function WidgetChartPreview({widgetProps, sizeParams, isDotsCloud}) {
    const [previewData, setPreviewData] = React.useState([]);

    React.useEffect(()=> {
        if(isDotsCloud){
            setPreviewData(widgetsService.generateTestDotsCloudChartData(widgetProps.dataSources));
        }
        if(!isDotsCloud){
            setPreviewData(widgetsService.generateTestChartData(widgetProps.dataSources));
        }

    }, []);

    return (
        <>
            {!isDotsCloud &&
            <LineChart width={sizeParams.width} height={sizeParams.height} data={previewData}>
                <XAxis dataKey="name"/>
                <YAxis/>
                <CartesianGrid strokeDasharray="3 3"/>
                {previewData.map((chartLineData, i) => {
                    return <Line key={i}
                                 type={widgetProps.widgetType === dashboardConstants.WIDGET_STEP_CHART ? 'stepAfter' : 'line'}
                                 dataKey={'value' + (i)} stroke={chartLineData['color' + i]}/>;
                })}
            </LineChart>
            }
            {isDotsCloud &&
            <ScatterChart width={sizeParams.width} height={sizeParams.height}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis type="number" dataKey="x" />
                <YAxis type="number" dataKey="y"/>
                <ZAxis range={[100]}/>
                {previewData.map((chartLineData, i) => {
                    return <Scatter
                         data={chartLineData.data} fill={chartLineData.color} name={chartLineData.name}
                         shape="circle" key={'scatter' + i}/>;
                })
                }
            </ScatterChart>
            }
            </>
    );
}

WidgetChartPreview.propTypes = {
    widgetProps: PropTypes.object,
    size: PropTypes.object,
    sizeParams: PropTypes.object,
    isDotsCloud: PropTypes.bool
};

WidgetChartPreview.defaultProps = {
    isDotsCloud: false
};

export default WidgetChartPreview;