import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';

function CustomSelectDate({ selectedDate, format, onChange, label }) {
  return (
    <>
      <div className="scenario-custom ">
        {label && <div>
          <label>{label}</label>
        </div>}
        <DatePicker
          dateFormat={format}
          // locale="ru"
          className="scenario-custom__datepicker"
          showPopperArrow={false}
          selected={selectedDate}
          onChange={(date) => {
            onChange(moment(date));
          }}
        />
      </div>
    </>
  );
}

CustomSelectDate.propTypes = {
  selectedDate: PropTypes.object,
  onChange: PropTypes.func,
  format: PropTypes.string,
  label: PropTypes.string
};
CustomSelectDate.defaultProps = {
  format: 'dd MMMM, yyyy'
};

export default CustomSelectDate;
