import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import Popover from '../../../components/Popover';
import moment from 'moment/moment';
import {dashboardService} from '../../../services/dashboard.service';
import WidgetLoader from '../../../components/WidgetLoader';
import Slider from '../../../components/Slider';

function SliderWidgetView({widgetProps}) {
    const [isFetching, setFetching] = useState(false);
    const [warning, setWarning] = useState('');
    const [sliderValue, setSliderValue] = useState(0);

    const setValue = (value) => {
        setFetching(true);
        setWarning('');
        const dateSet = moment.utc(new Date()).add(widgetProps.parameters.timeOffset, 'hours').format('YYYY-MM-DD HH:mm:ss');
        {
            widgetProps.dataSources && widgetProps.dataSources.length > 0 &&
            dashboardService.widgetDataSet(widgetProps.dataSources[0].id, dateSet, value).then(
                result => {
                    setSliderValue(value);
                    console.log(result);
                }, error => {
                    setSliderValue(sliderValue);
                    setWarning(localeService.isRussian() ? 'Не удалось записать значение!' : 'Cannot record value!');
                    console.log(error);
                }
            ).finally(() => setFetching(false));
        }
    };

    React.useEffect(() => {
        setFetching(true);
        {widgetProps.dataSources && widgetProps.dataSources.length > 0 &&
            dashboardService.getWidgetFunctionData(widgetProps.dataSources[0].id, moment(new Date('2000-01-01')), moment(new Date()), 'SELECTORS_LAST', [], 0)
                .then(
                    result => {
                        if (result.list.length > 0) {
                            const value = result.list[0].value;
                            setSliderValue(value);
                        } else {
                            setSliderValue(0);
                            setWarning(localeService.isRussian() ? 'Не удалось получить текущее состояние!' : 'Cannot get current button state!');
                        }
                    }, () => {
                        setSliderValue(0);
                        setWarning(localeService.isRussian() ? 'Не удалось получить текущее состояние!' : 'Cannot get current button state!');
                    }
                ).finally(
                () => setFetching(false)
            );
        }
    }, []);


    return (
        <>
            <div style={{
                minHeight: '2rem',
                height: (100 * widgetProps.h) - (widgetProps.name !== '' ? 100 : 20),
                display: 'flex',
                alignItems: 'center',
                marginTop: widgetProps.name === '' ? '-2rem' : 0
            }}>
                {isFetching && <WidgetLoader/>}
                {warning !== '' &&
                <Popover type={'WARNING'} position={'top-left'}>
                    <p>{warning}</p>
                </Popover>
                }
                <Slider value={sliderValue}
                        onSetValue={value => setValue(value)}
                        min={widgetProps.parameters.minValue}
                        max={widgetProps.parameters.maxValue}/>
            </div>
        </>
    );
}

SliderWidgetView.propTypes = {
    widgetProps: PropTypes.object
};

export default SliderWidgetView;