import {capacityConstants} from '../../constants/capacity.constants';

const initialState = {
    capacityTree: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case capacityConstants.SET_CAPACITY_TREE: {
            return {...state, ...action.capacityTree};
        }
        case capacityConstants.GET_CAPACITY_TREE: {
            return {...state, capacityTree: action.capacityTree};
        }
        default:
            return state;
    }
}


