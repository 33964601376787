import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {localeService} from '../services/locale.service';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import Clock from 'react-live-clock';
import '../styles/header.scss';
import svgClock from '../sources/images/interface/clock.svg';
import svgRu from '../sources/images/interface/russia.svg';
import svgEn from '../sources/images/interface/england.svg';
import Logo from './Logo';

function Header() {
    let location = useLocation();
    const {t} = useTranslation();

    return (
        <div className="navbar-header d-flex justify-content-between">
            <ul className="nav justify-content-end align-items-center" style={{paddingLeft: '1rem'}}>
                <li className={'nav-item me-3'}>
                    <Logo/>
                </li>
                {/*<li className={'nav-item'}>*/}
                {/*<h1 style={{margin: '0 1rem 0 1rem', color: 'black'}}>ECO <img width={'30px'} height={'30px'} style={{transform: 'rotate(309deg)'}} className={'logo-plant-image'} src={logoPlant}/> MONITORING</h1>*/}
                {/*</li>*/}
                <li className={'nav-item me-3'}>
                    <Link to="/capacity" style={{textDecoration: 'none'}}>
                        <button type="button"
                                className={'btn btn-outline-primary ' + (location.pathname === '/capacity' ? 'active' : '')}>{t('capacity_list')}</button>
                    </Link>
                </li>
                <li className={'nav-item me-3'}>
                    <Link to="/import" style={{textDecoration: 'none'}}>
                        <button type="button"
                                className={'btn btn-outline-primary ' + (location.pathname === '/import' ? 'active' : '')}>{t('import')}</button>
                    </Link>

                </li>
                <li className={'nav-item me-3'}>
                    <Link to="/device_list/0" style={{textDecoration: 'none'}}>
                        <button type="button"
                                className={'btn btn-outline-primary ' + (location.pathname.startsWith('/device_list') ? 'active' : '')}> {t('device_list')}</button>
                    </Link>
                </li>
                <li className={'nav-item me-3'}>
                    <Link to="/scenario_list" style={{textDecoration: 'none'}}>
                        <button type="button"
                                className={'btn btn-outline-primary ' + (location.pathname.startsWith('/scenario_list') ? 'active' : '')}> {localeService.isRussian() ? 'Сценарии' : 'Scenarios'} </button>
                    </Link>
                </li>
            </ul>
            <ul className="nav justify-content-end align-items-center">
                <li className={'nav-item'}>
                    <div className="clock">
                        <ReactSVG src={svgClock}/>
                        <Clock format={'HH:mm'} ticking={true}/>
                    </div>
                </li>
                <li className={'nav-item me-3'}>
                    <button type={'button'}
                            className={'svg-btn' + (localStorage.getItem('ems-language') === 'ru' ? ' op1' : ' op0_5')}
                            onClick={() => localeService.setLanguage('ru')}>
                        <ReactSVG src={svgRu}/>
                    </button>
                </li>
                <li className={'nav-item'} style={{marginRight: '1.5rem'}}>
                    <button type={'button'}
                            className={'svg-btn' + (localStorage.getItem('ems-language') === 'en' ? ' op1' : ' op0_5')}
                            onClick={() => localeService.setLanguage('en')}>
                        <ReactSVG src={svgEn}/>
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default Header;


