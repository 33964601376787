import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import Modal from '../../../components/Modal';
import SliderWidgetCreate from './SliderWidgetCreate';
import SliderWidgetView from './SliderWidgetView';
import Slider from '../../../components/Slider';


function SliderWidget({widgetProps, mode, onCloseEditSingleMode}) {
    return <>
        {mode === dashboardConstants.VIEW_MODE &&
        <SliderWidgetView widgetProps={widgetProps}/>
        }
        {mode === dashboardConstants.EDITION_MODE &&
        <form>
            <div style={{
                minHeight: '2rem',
                height: (100 * widgetProps.h) - (widgetProps.name !== '' ? 100 : 60),
                display: 'flex',
                alignItems: 'center',
                marginTop: widgetProps.name === '' ? '-2rem' : 0,
                pointerEvents: 'none'
            }}>
                <Slider disabled={true}/>
            </div>
        </form>
        }
        {mode === dashboardConstants.EDIT_SINGLE_MODE &&
        <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false}
               onCancel={() => onCloseEditSingleMode()}
               title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
            <SliderWidgetCreate widgetProps={widgetProps} onClose={() => onCloseEditSingleMode()}/>
        </Modal>
        }
    </>;
}

SliderWidget.propTypes = {
    widgetProps: PropTypes.object,
    onCloseEditSingleMode: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default SliderWidget;