import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import {useTranslation} from 'react-i18next';
import SelectDeviceVariableFromCapacityTree from '../../../components/SelectDeviceVariableFromCapacityTree';
// import Select from '../../../components/Select';
// import svgDelete from '../../../sources/images/interface/cancel.svg';
// import {ReactSVG} from 'react-svg';

function ChannelProps({channel, importColumns, capacityTree, onChangeChannelProps}) {
    const {t} = useTranslation();

    // const [groupFlag, setFlag] = React.useState({value: 0, name: localeService.isRussian() ? 'нет' : 'no'});
    const [channelDevice, setChannelDevice] = React.useState(null);
    // const [groupIntoDevice, setGroupIntoDevice] = React.useState(null);


    const identifyColumnToChannel = (channel, device) => {
        let importColumnList = [...importColumns];
        const columnIdx = importColumnList.findIndex(el => (!el.isGroup && el.uuidChannel === channel.uuidChannel));
        if (columnIdx < 0) {
            onChangeChannelProps([...importColumnList, {
                uuidChannel: channel.uuidChannel,
                deviceUUID: device.deviceGUID,
                dataSourceID: device.id,
                device: device
            }]);
        } else {
            importColumnList[columnIdx] = {
                ...importColumnList[columnIdx],
                device: device,
                deviceUUID: device.deviceGUID,
                dataSourceID: device.id
            };
            onChangeChannelProps(importColumnList);
        }

        setChannelDevice(device);
    };

    // const addChannelToGroup = (device) => {
    //
    //     const channelsImportProps = importColumns;
    //
    //     // Проверяем, есть ли еще элементы с группировкой в выбранном устройстве
    //     const groupIdx = channelsImportProps.findIndex(el => (el.isGroup && el.dataSourceID === device.id));
    //     if (groupIdx < 0) { //если нет, то создаем новую группу
    //
    //         if (groupFlag.value === -1) { // проверяем чтобы первый элемент в группе был с положительным флагом
    //             alert(localeService.isRussian() ? 'Чтобы сгруппировать каналы необходимо сначала добавить канал (выберите тип группировки "да, сложение")' : 'To group channels, you must first add a channel (choose the "yes, add" type)');
    //             setGroupIntoDevice(null);
    //             setFlag({value: 0, name: localeService.isRussian() ? 'нет' : 'no'});
    //             return;
    //         } else {
    //             const groupedChannel = {
    //                 channelArray: [{uuid: channel.uuidChannel, flag: groupFlag.value}],
    //                 deviceUUID: device.deviceGUID,
    //                 dataSourceID: device.id,
    //                 isGroup: true
    //             };
    //             onChangeChannelProps([...channelsImportProps, groupedChannel]);
    //         }
    //     } else { //если да, то добавляем новый канал в группу
    //         let groupedChannel = channelsImportProps[groupIdx];
    //         groupedChannel.channelArray = [...groupedChannel.channelArray, {
    //             uuid: channel.uuidChannel,
    //             flag: groupFlag.value
    //         }];
    //         channelsImportProps[groupIdx] = groupedChannel;
    //         onChangeChannelProps(channelsImportProps);
    //     }
    //     setGroupIntoDevice(device);
    // };
    //
    // const removeFromGroup = (e) => {
    //     e.preventDefault();
    //     const groupIdx = importColumns.findIndex(el => (el.isGroup && el.dataSourceID === groupIntoDevice.id));
    //     console.log('removeIdx', groupIdx, importColumns);
    //     let channelsGroup = importColumns[groupIdx];
    //     if(channelsGroup.channelArray.length > 1){
    //         channelsGroup.channelArray = channelsGroup.channelArray.filter(el => el.uuid !== channel.uuidChannel);
    //         importColumns[groupIdx] = channelsGroup;
    //         onChangeChannelProps(importColumns);
    //     }else{
    //         const newChannelList = importColumns.filter(el => !(el.isGroup && el.dataSourceID === groupIntoDevice.id));
    //         console.log('newChannelList', newChannelList);
    //         onChangeChannelProps(newChannelList);
    //     }
    //     setFlag({value: 0, name: localeService.isRussian() ? 'нет' : 'no'});
    //     setGroupIntoDevice(null);
    // };

    return (
        <div style={{borderTop: '1px solid #efefef'}}>
            <p>
                <b>{t('code')} {channel.code}</b>
            </p>
            <div className={'input-group'}>
                <div className={'input-group-prepend'}>
                    <label>{localeService.isRussian() ? 'Канал' : 'Channel'}</label>
                </div>
                {channel.desc}
            </div>
            <div className={'input-group'}>
                <div className={'input-group-prepend'}>
                    <label>{localeService.isRussian() ? 'Переменная устройства' : 'Device variable'}</label>
                </div>
                <SelectDeviceVariableFromCapacityTree capacityTree={capacityTree}
                                                      device={channelDevice}
                                                      onSelect={(device) => identifyColumnToChannel(channel, device)}/>
            </div>
            {/*{channelDevice &&*/}
            {/*<Select label={localeService.isRussian() ? 'Группировка' : 'Group'}*/}
                    {/*isUnderstroke={false}*/}
                    {/*isDisabled={groupIntoDevice !== null}*/}
                    {/*valueList={[*/}
                        {/*{value: 0, name: localeService.isRussian() ? 'нет' : 'no'},*/}
                        {/*{value: 1, name: localeService.isRussian() ? 'да, добавление' : 'yes, addition'},*/}
                        {/*{value: -1, name: localeService.isRussian() ? 'да, вычитание' : 'yes, subtraction'}*/}
                    {/*]}*/}
                    {/*value={groupFlag}*/}
                    {/*onSelect={f => setFlag(f)}/>*/}
            {/*}*/}
            {/*{groupFlag.value !== 0 &&*/}
            {/*<div className={'input-group'}>*/}
                {/*<div className={'input-group-prepend'}>*/}
                    {/*<label>{localeService.isRussian() ? 'Группировать с переменная устройства' : 'Group with device variable'}</label>*/}
                {/*</div>*/}
                {/*<SelectDeviceVariableFromCapacityTree capacityTree={capacityTree}*/}
                                                      {/*device={groupIntoDevice}*/}
                                                      {/*isDisabled={groupIntoDevice !== null}*/}
                                                      {/*onSelect={(device) => addChannelToGroup(device)}/>*/}
                {/*{groupIntoDevice && <button className={'btn btn-outline-primary round-btn danger'} style={{marginLeft: '1rem'}} onClick={(e) => removeFromGroup(e)}>*/}
                    {/*<ReactSVG src={svgDelete}/>*/}
                {/*</button>}*/}
            {/*</div>}*/}
        </div>
    );
}

ChannelProps.propTypes = {
    channel: PropTypes.object,
    onChangeChannelProps: PropTypes.func,
    capacityTree: PropTypes.array,
    importColumns: PropTypes.array
};

export default ChannelProps;