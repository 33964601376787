import React from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import svgDelete from '../sources/images/interface/basket.svg';
import {localeService} from '../services/locale.service';
import SaveFunctionResultToVariable from './SaveFunctionResultToVariable';
import ShowDataSourceInfo from './ShowDataSourceInfo';

function CustomLegend({widgetDataSources, functionDataSources, onDeleteFunction}) {
    return (
        <ul className={'legend'}>
            {widgetDataSources.map((dataSource, i) => {
                return <li key={'ds-legend-' + i} style={{color: dataSource.parameters.color}}>
                    <span className="li-text">
                        {dataSource.parameters.dataSourceName}
                        {dataSource.parameters.unit ? (', ' + dataSource.parameters.unit) : ''}
                        </span>
                </li>;
            })}
            {functionDataSources.map((functionDataSource, i) => {
                const dsParams = functionDataSource.parameters;
                return <li key={'ds-legend-' + i} className={'removable'} style={{
                    color: dsParams.color,
                    borderRadius: '0.5rem',
                    padding: '0 0.25rem',
                    border: '1px solid ' + dsParams.color
                }}>
                    <span className="li-text">{dsParams.dataSourceName}</span>
                    <button className={'danger svg-btn small ml1'}
                            title={localeService.isRussian() ? 'Отменить функцию' : 'Delete Function Data Source'}
                            onClick={(e) => {
                                e.preventDefault();
                                onDeleteFunction(functionDataSource);
                            }}>
                        <ReactSVG src={svgDelete}/>
                    </button>
                    {functionDataSource.resultId &&
                    <SaveFunctionResultToVariable functionDataSource={functionDataSource}/>
                    }
                </li>;
            })}
            <li style={{border: 'none'}}>
                <ShowDataSourceInfo dataSources={widgetDataSources}/>
            </li>
        </ul>
    );
}

CustomLegend.propTypes = {
    widgetDataSources: PropTypes.array,
    functionDataSources: PropTypes.array,
    onDeleteFunction: PropTypes.func
};

export default CustomLegend;