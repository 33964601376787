import {analyticService} from './analyticService';
import {dashboardService} from './dashboard.service';

export const capacityCostCalculationService = {
    initMethods,
    runCalculate,
    getPeakHour,
    getPeakHours,
    initAdditionalParams
};

export function initAdditionalParams(widgetProps, electricityCostFunction){
    if (electricityCostFunction.length === 1 && widgetProps.parameters.method ) {
        //Определяем доболнительные параметры функции для ценовой категории
        let additionalParams = [];
        electricityCostFunction[0].args.map(arg => {
            if (widgetProps.parameters.method.name === 'one_category_*') {
                if (['tariff_one_ee', 'tariff_losses_three', 'data_type'].includes(arg.name)) {
                    additionalParams.push({...arg, values: getDefaultValueForParam(arg.name, arg.count)});
                }
            }
            if (widgetProps.parameters.method.name === 'two_category_three_zones_*') {
                if (['tariff_two_three_zones_ee', 'time_two_cat_night_zones', 'time_two_cat_peak_zones', 'tariff_losses_three', 'data_type'].includes(arg.name)) {
                    additionalParams.push({...arg, values: getDefaultValueForParam(arg.name, arg.count)});
                }
            }
            if (widgetProps.parameters.method.name === 'two_category_two_zones_*') {
                if (['tariff_two_two_zones_ee', 'time_two_cat_night_zones', 'tariff_losses_three', 'data_type'].includes(arg.name)) {
                    additionalParams.push({...arg, values: getDefaultValueForParam(arg.name, arg.count)});
                }
            }
            if (widgetProps.parameters.method.name === 'three_category_*') {
                if (['tariff_losses_three', 'tariff_sales', 'actual_volume_other_service', 'volume_other_service', 'data_type'].includes(arg.name)) {
                    additionalParams.push({...arg, values: getDefaultValueForParam(arg.name, arg.count)});
                }
            }
            if (widgetProps.parameters.method.name === 'four_category_*') {
                if (['time_four_cat', 'volume_other_service', 'actual_volume_other_service', 'tariff_sales', 'tariff_maintenance_four', 'tariff_losses_four', 'data_type'].includes(arg.name)) {
                    additionalParams.push({...arg, values: getDefaultValueForParam(arg.name, arg.count)});
                }
            }
            if (widgetProps.parameters.method.name === 'five_category_val_total') {
                if (['tariff_losses_three', 'tariff_sales', 'actual_volume_other_service', 'volume_other_service', 'start_peaks_date','data_type'].includes(arg.name)) {
                    additionalParams.push({...arg, values: getDefaultValueForParam(arg.name, arg.count)});
                }
            }
            if (widgetProps.parameters.method.name === 'six_category_val_total') {
                if (['time_four_cat', 'tariff_losses_four', 'tariff_maintenance_four', 'tariff_sales', 'actual_volume_other_service', 'volume_other_service','start_peaks_date','data_type'].includes(arg.name)) {
                    additionalParams.push({...arg, values: getDefaultValueForParam(arg.name, arg.count)});
                }
            }
        });
        return additionalParams;
    }
}

export function runCalculate(methodList, args, widgetProps, datetimeFilter) {
    let funcName = 'electricity-cost-calculation';
    if(widgetProps.parameters.method.name === 'five_category_val_total' || widgetProps.parameters.method.name === 'six_category_val_total'){
        funcName = 'electricity-cost-calculation-5-6';
    }
    return Promise.all(
        methodList.map(method => {
            const paramFunction = {
                name: funcName,
                args: [...args,
                    {
                        name: 'method',
                        count: 1,
                        values: [method.methodName]
                    },
                    {
                        name: 'region',
                        count: 1,
                        values: [widgetProps.parameters.region.name]
                    },
                    {
                        name: 'retailer',
                        count: 1,
                        values: [widgetProps.parameters.retailer.name]
                    },
                ]
            };
            return analyticService.calculateElectricityCost(widgetProps.dataSources.map(el => el.id), paramFunction, datetimeFilter).then(
                response => {
                    return {...method, value: response.length === 1 ? response[0].value.toFixed(3) : '?'};
                }
            );
        })
    );
    // .then(results => {
    //     console.log('Calculation Results', results);
    // });
}

export function initMethods(priceCategory, widgetType) {
    let methods = [];
    if (widgetType === 'AVERAGE_COST') {
        methods = [
            {
                name: 'val_summ_kw',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_summ_kw',
                description: 'Сумма всех КВт'
            },
            {
                name: 'val_total',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_total',
                description: 'Общая стоимость, руб'
            }
        ];
    }
    if (widgetType === 'ELECTRICITY_COST') {
        methods = [
            {
                name: 'val_transfer',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_transfer',
                description: 'Стоимость за передачу электроэнерги, руб',
                color: dashboardService.getRandomColor(0)
            }
        ];
        if (priceCategory === 'one_category_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee',
                    methodName: 'one_category_val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб',
                    color: dashboardService.getRandomColor(1)
                }
            ];

        }
        if (priceCategory === 'two_category_two_zones_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee_night',
                    methodName: 'two_category_two_zones_val_calculate_ee_night',
                    description: 'Стоимость электроэнергии в ночное время, руб',
                    color: dashboardService.getRandomColor(1)
                },
                {
                    name: 'val_calculate_ee_day',
                    methodName: 'two_category_two_zones_val_calculate_ee_day',
                    description: 'Стоимость электроэнергии в дневное время, руб',
                    color: dashboardService.getRandomColor(2)
                }
            ];
        }
        if (priceCategory === 'two_category_three_zones_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee_night',
                    methodName: 'two_category_three_zones_val_calculate_ee_night',
                    description: 'Стоимость электроэнергии в ночное время, руб',
                    color: dashboardService.getRandomColor(1)
                },
                {
                    name: 'calculate_ee_semipeak',
                    methodName: 'two_category_three_zones_val_calculate_ee_semipeak',
                    description: 'Стоимость электроэнергии в полупиковое время, руб',
                    color: dashboardService.getRandomColor(2)
                },
                {
                    name: 'calculate_ee_peak',
                    methodName: 'two_category_three_zones_val_calculate_ee_peak',
                    description: 'Стоимость электроэнергии в пиковое время, руб',
                    color: dashboardService.getRandomColor(3)
                }
            ];
        }

        if (priceCategory === 'three_category_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб',
                    color: dashboardService.getRandomColor(1)
                },
                {
                    name: 'val_power_cost',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Стоимость мощности, руб',
                    color: dashboardService.getRandomColor(2)
                }
            ];
        }        

        if (priceCategory === 'four_category_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб',
                    color: dashboardService.getRandomColor(1)
                },
                {
                    name: 'val_power_cost',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Стоимость мощности, руб',
                    color: dashboardService.getRandomColor(2)
                },
                {
                    name: 'val_maintenance',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_maintenance',
                    description: 'Стоимость за содержание, руб',
                    color: dashboardService.getRandomColor(3)
                }

            ];
        }

        if (priceCategory === 'five_category_val_total' || priceCategory === 'six_category_val_total') {
            methods = [{
                    name: 'val_total',
                    methodName: priceCategory,
                    description: 'Стоимость электроэнергии, руб',
                    color: dashboardService.getRandomColor(1)
                }];
        }
    }
    if (widgetType === 'COST_MAKING') {
        methods = [
            {
                name: 'val_summ_kw',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_summ_kw',
                description: 'Сумма всех КВт',
            },
            {
                name: 'val_transfer',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_transfer',
                description: 'Стоимость за передачу электроэнерги, руб'
            },
            {
                name: 'val_total',
                methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_total',
                description: 'Общая стоимость, руб'
            }
        ];
        if (priceCategory === 'one_category_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee',
                    methodName: 'one_category_val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб'
                }
            ];

        }
        if (priceCategory === 'two_category_two_zones_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee_night',
                    methodName: 'two_category_two_zones_val_calculate_ee_night',
                    description: 'Стоимость электроэнергии в ночное время, руб'
                },
                {
                    name: 'val_calculate_ee_day',
                    methodName: 'two_category_two_zones_val_calculate_ee_day',
                    description: 'Стоимость электроэнергии в дневное время, руб'
                }
            ];
        }
        if (priceCategory === 'two_category_three_zones_*') {
            methods = [...methods,
                {
                    name: 'val_calculate_ee_night',
                    methodName: 'two_category_three_zones_val_calculate_ee_night',
                    description: 'Стоимость электроэнергии в ночное время, руб'
                },
                {
                    name: 'calculate_ee_semipeak',
                    methodName: 'two_category_three_zones_val_calculate_ee_semipeak',
                    description: 'Стоимость электроэнергии в полупиковое время, руб'
                },
                {
                    name: 'calculate_ee_peak',
                    methodName: 'two_category_three_zones_val_calculate_ee_peak',
                    description: 'Стоимость электроэнергии в пиковое время, руб'
                }
            ];
        }

        if (priceCategory === 'three_category_*') {
            methods = [...methods,
                {
                    name: 'val_sales_add',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_sales_add',
                    description: 'Стоимость сбытовой надбавки, руб'
                },
                {
                    name: 'val_calculate_ee',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб'
                },
                {
                    name: 'val_power_cost',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Стоимость мощности, руб'
                },
                {
                    name: 'val_other_services',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_other_services',
                    description: 'Стоимость за иные услуги, руб'
                }
            ];
        }

        if (priceCategory === 'four_category_*') {
            methods = [...methods,
                {
                    name: 'val_sales_add',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_sales_add',
                    description: 'Стоимость сбытовой надбавки, руб'
                },
                {
                    name: 'val_calculate_ee',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб'
                },
                {
                    name: 'val_power_cost',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Стоимость мощности, руб'
                },
                {
                    name: 'val_other_services',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_other_services',
                    description: 'Стоимость за иные услуги, руб'
                },
                {
                    name: 'val_maintenance',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_maintenance',
                    description: 'Стоимость за содержание (услуга 4 ЦК мощность), руб'
                }

            ];
        }
    }
    if (widgetType === 'STORAGE_EFFECT') {
            methods = [
                {
                    name: 'val_calculate_ee',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_calculate_ee',
                    description: 'Стоимость электроэнергии, руб',
                    color: dashboardService.getRandomColor(1)
                },
                {
                    name: 'val_power_cost',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Стоимость мощности, руб',
                    color: dashboardService.getRandomColor(2)
                },               
                {
                    name: 'val_total',
                    methodName: priceCategory.substring(0, priceCategory.length - 1) + 'val_power_cost',
                    description: 'Общая стоимость, руб',
                    color: dashboardService.getRandomColor(2)
                }
            ];       
    }
    return methods;
}

export function getPeakHour(methodList, args, widgetProps, datetimeFilter) {
    return Promise.all(
        methodList.map(method => {
            const paramFunction = {
                name: 'electricity-cost-calculation',
                args: [...args,
                    {
                        name: 'method',
                        count: 1,
                        values: [method.methodName]
                    },
                    {
                        name: 'region',
                        count: 1,
                        values: [widgetProps.parameters.region.name]
                    },
                    {
                        name: 'retailer',
                        count: 1,
                        values: [widgetProps.parameters.retailer.name]
                    },
                ]
            };
            if(widgetProps.dataSources[0]){
                console.log('getPeakHour', paramFunction);
                return analyticService.calculateElectricityCost(parseInt(widgetProps.dataSources[0].id), paramFunction, datetimeFilter).then(
                    response => {
                        return {...method, value: response.length > 1 ? response : '?'};
                    }
                );
            }

        })
    );
}

export function getPeakHours(params, datetimeFilter) {
    const paramFunction = {
                name: 'electricity-cost-calculation',
                args: [
                    {
                        name: 'method',
                        count: 1,
                        values: ['peak_hours']
                    },
                    {
                        name: 'region',
                        count: 1,
                        values: [params.regionName]
                    },
                    {
                        name: 'retailer',
                        count: 1,
                        values: [params.retailerName]
                    },
                    {
                        name: 'start_peaks_date',
                        count: 1,
                        values: [params.startPeakDate.format('YYYY-MM-DD HH:mm:ss')]
                    }
                ]
            };
            return analyticService.calculateElectricityCost(params.dataSourceId, paramFunction, datetimeFilter);
}


export function getDefaultValueForParam(param){
    let paramValue = [''];
    switch (param) {
        case 'tariff_one_ee':
            paramValue = [4.169];
            break;
        case 'tariff_losses_three':
            paramValue = [0];
            break;
        case 'tariff_two_two_zones_ee':
            paramValue = [2.41442, 6.14821];
            break;
        case 'time_two_cat_night_zones':
            paramValue = ['00:00:00,06:59:00','23:00:00,23:59:00'];
            break;
        case 'time_two_cat_peak_zones':
            paramValue = ['08:00:00,10:59:00','20:00:00,21:59:00'];
            break;
        case 'tariff_two_three_zones_ee':
            paramValue = [2.41442, 4.24053, 9.16245];
            break;
        case 'time_four_cat':
            paramValue = ['08:00:00,10:59:00','20:00:00,21:59:00'];
            break;
        case 'tariff_maintenance_four':
            paramValue = [669.37];
            break;
        case 'tariff_losses_four':
            paramValue = [163.88];
            break;
        case 'tariff_sales':
            paramValue = [0.30329];
            break;
        case 'actual_volume_other_service':
            paramValue = [679106.775];
            break;
        case 'volume_other_service':
            paramValue = [210529.98];
            break;
        case 'start_peaks_date':
            paramValue = ['2022-06'];
            break;
        case 'data_type':
            paramValue = ['power'];
            break;
    }
    return paramValue;
}