import React from 'react';
import PropTypes from 'prop-types';
import '../styles/dict-of-time.scss';
import {localeService} from '../services/locale.service';

function ArrayOfHoursSimple({onChange, label, values}) {
    const [selectMode, setSelectMode] = React.useState(false);
    const [selected, setSelected] = React.useState(values);

    React.useEffect(() => {
        setSelected(values);
    }, [values]);

    const selectTime = (el) => {
        let newSelectedValues = selected;
        const isSelected = newSelectedValues.includes(el);
        if (isSelected) {
            newSelectedValues = newSelectedValues.filter(v => v !== el);
        } else {
            newSelectedValues.push(el);
        }
        onChange(newSelectedValues.sort((a, b) => a - b));
    };

    return <div className={'input-group array-of-hours dict-of-time'}>
        <h3>{label}</h3>
        <div className={'group'} style={{width: '100%', display: 'block'}}>
            <label
                className={'group-header'}>{localeService.isRussian() ? 'Выберите периоды между часами часы' : 'Choose hours'}</label>
            <div className={'hours-line mt1'}
                 onMouseLeave={() => setSelectMode(false)}>
                {[...Array(24).keys()].map(el => {
                    const isSelected = selected.includes(el);
                    return <button key={el}
                                   type={'button'}
                                   style={{}}
                                   className={'hour ' + (isSelected ? ' active' : '')}
                                   onMouseDown={() => {
                                       setSelectMode(true);
                                       selectTime(el);
                                   }}
                                   onMouseUp={() => setSelectMode(false)}
                                   onMouseOver={() => {
                                       if (selectMode) {
                                           selectTime(el);
                                       }
                                   }}>
                        <span className={'label'}>{String(el + 1).padStart(2, '0')}</span>
                    </button>;
                })}
            </div>
        </div>
    </div>;
}

ArrayOfHoursSimple.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    values: PropTypes.array
};

export default ArrayOfHoursSimple;