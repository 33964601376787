/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {dashboardConstants} from '../../../constants/index';
import {updateWidget, zoomIn} from '../../../redux/actions/dashboardActions';
import {connect} from 'react-redux';
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Line,
    ReferenceArea,
    ReferenceLine,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {dashboardService} from '../../../services';
import moment from 'moment';
import numeral from 'numeral';
import {withSize} from 'react-sizeme';
import svgShowDayAndNight from '../../../sources/images/interface/day-night.svg';
import {ReactSVG} from 'react-svg';
import CustomTooltip from '../../../components/CustomTooltip';
import AddFunctionToChart from './components/AddFunctionToChart';
import {widgetsService} from '../../../services/widget.service';
import AddDemandFunctionToChart from './components/AddDemandFunctionToChart';
import {localeService} from '../../../services/locale.service';
import AddPredictionFunctionToChart from './components/AddPredictionFunctionToChart';
import AddPeakHoursToChart from './components/AddPeakHoursToChart';
import ShowChartDataInTable from './components/ShowChartDataInTable';
import {CSVLink} from 'react-csv';
import svgExportToCSV from '../../../sources/images/interface/export_data.svg';
import {renderDataForCsvReport} from '../../../services/export.service';
import SeletTimeGroup from './components/SeletTimeGroup';
import SetChartLimits from './components/SetChartLimits';
import ShowDataSourceInfo from '../../../components/ShowDataSourceInfo';

//компонент виджета "График"
function WidgetChartView({widgetProps, datetimeFilter, size, zoomIn, updateWidget}) {
    //инициализация перменной с параметрами виджета
    const [widgetData, setWidgetData] = useState(widgetProps);
    //инициализация перменной списка результатов функций
    const [functionDataSources, setFunctionDataSources] = useState([]);
    //инициализация перменной данных часов пиковой нагрузки
    const [peakHoursDataSources, setPeakHoursDataSources] = useState([]);
    //инициализация перменной выбора области
    const [selectedArea, setSelectedArea] = useState({left: '', right: ''});
    //инициализация временного ряда день/ночь
    const [dayAndNightDataSource, setDayAndNightDataSource] = useState({isVisible: false});
    //инициализация данных для подсветки функций
    const [functionBacklights, setFunctionBacklights] = useState([]);
    //инициализация нормализованного временного ряда
    const [normalization, setNormalization] = useState(false);
    //инициализация перменной группировки данных по времени
    const [timeGroup, setTimeGroup] = useState(datetimeFilter.timeGroup);
    //инициализация переменной состояния загрузки
    const [isFetching, setFetching] = useState(true);
    //инициализация библиотеки для экспорта данных в CSV файл
    const [csvReport, setCSVReport] = React.useState({data: [], headers: [], filename: 'table_widget_data.csv'});
    //инициализация порогов
    const [limits, setLimits] = useState(widgetsService.initLimitsPresets(widgetProps.parameters.presets, widgetProps.dataSources));
    //инициализация состояния отображения консоли управления аналитикой
    const [showConsole, setShowConsole] = useState(false);
    //функция-обработчик первичной загрузки компонента
    useEffect(() => {
        //проверка на наличяие преднастроек виджета
        let widgetPresets = widgetProps.parameters.presets;
        if (widgetPresets) {
            //парсинг сохраненных функций из преднастроек
            if (widgetPresets.functionDataSources) {
                setFunctionDataSources(widgetPresets.functionDataSources);
                setFetching(true);
                dashboardService.loadFunctionDataSources(widgetProps.parameters.presets.functionDataSources, datetimeFilter, size.width).then(
                    functionDataSources => {
                        setFunctionDataSources(functionDataSources);
                        setFetching(false);
                    }
                );
            }
        }
    }, []);

    //функция-обработчик изменения родительского фильтра времени
    useEffect(() => {
        setTimeGroup(datetimeFilter.timeGroup);
        setSelectedArea({left: '', right: ''});
        setFetching(true);
        //загрузка данных виджета в соответствии с новым периодом
        dashboardService.loadWidgetData(widgetData.dataSources, datetimeFilter).then(
            dataSources => {
                setWidgetData({...widgetData, dataSources: dataSources});
                if (limits.values.length > 0) { //пересчитываем пороги
                    const newLimits = widgetsService.calculateChartLimitAreas(limits, dataSources);
                    setLimits(newLimits);
                }
                //генерируем временной ряд подсветки функций
                const functionBacklights = widgetsService.generateFunctionBacklights(functionDataSources, dataSources);
                setFunctionBacklights(functionBacklights);
                setFetching(false);
                return dataSources;
            }
        );
        //загрузка источников данных для функций
        dashboardService.loadFunctionDataSources(functionDataSources, datetimeFilter, size.width, datetimeFilter.timeGroup).then(
            functionDataSources => {
                setFunctionDataSources(functionDataSources);
                return functionDataSources;
            }
        );
        //генерируем временной ряд дня и ночи
        const dayAndNightDS = widgetsService.generateDayAndNightDataSource(datetimeFilter.startDate, datetimeFilter.finishDate);
        setDayAndNightDataSource({...dayAndNightDS, isVisible: dayAndNightDS.isVisible});
    }, [datetimeFilter]);
    //формирование данных для экспорта
    useEffect(() => {
        const reportData = renderDataForCsvReport(widgetData.dataSources, functionDataSources);
        setCSVReport({...csvReport, ...reportData});
    }, [widgetData, functionDataSources]);
    //функция-обработчик изменения группировки данных по времени
    const onChangeTimeGroup = (tg) => {
        setTimeGroup(tg);
        setFetching(true);
        dashboardService.loadWidgetDataGroupedBy(widgetData.dataSources, datetimeFilter, tg).then(
            dataSources => {
                setWidgetData({...widgetData, dataSources: dataSources});

                //генерируем временной ряд подсветки функций
                const functionBacklights = widgetsService.generateFunctionBacklights(functionDataSources, dataSources);
                setFunctionBacklights(functionBacklights);
                setFetching(false);
            }
        );
        dashboardService.loadFunctionDataSources(functionDataSources, datetimeFilter, size.width, tg).then(
            functionDataSources => {
                setFunctionDataSources(functionDataSources);
            }
        );
    };
    // Зуммироние
    const zoom = () => {
        let {left, right} = selectedArea;
        if (left === right || right === '') {
            setSelectedArea({left: '', right: ''});
            return;
        }
        if (left > right) {
            [left, right] = [right, left];
        }
        zoomIn({
            ...datetimeFilter,
            startDate: moment(moment.unix(left)),
            finishDate: moment(moment.unix(right)),
            datetimeRange: dashboardConstants.DATETIME_FILTER_LIST[0]
        });
    };
    //установить результат функции как источник данных
    const setFunctionDataSourceList = (newFunctDSList) => {
        setFunctionDataSources(newFunctDSList);
        updateWidget({
            ...widgetProps, parameters: {
                ...widgetProps.parameters, presets: {
                    functionDataSources: newFunctDSList.map(el => {
                        const ds = {...el};
                        delete ds.data;
                        return ds;
                    })
                }
            }
        });
    };
    //сохранить пороговые значения в преднастройках
    const saveLimitPresets = (limitsToPresets) => {
        const limitPresets = {
            isVisible: limitsToPresets.isVisible,
            values: limitsToPresets.values.map(limit => {
                return {
                    value: limit.value,
                    dataSource: limit.dataSource,
                    name: limit.name,
                    color: limit.color
                };
            })
        };
        updateWidget({
            ...widgetProps, parameters: {
                ...widgetProps.parameters, presets: {
                    ...widgetProps.parameters.presets,
                    limits: limitPresets
                }
            }
        });
    };
    //рендеринг кода
    return (
        <div className={'widget-content' + (isFetching ? ' data-loading' : '')}>
            <div className={'collapsed-widget-console ' + (showConsole ? 'opened' : 'closed')}>
                {/*кнопка открытия консоли управления*/}
                <button type="button"
                        className={'burger-btn ' + (showConsole ? 'opened' : 'closed')}
                        onClick={() => setShowConsole(!showConsole)}>
                    <div className='menu-bar'>
                        <span className='bar bar1'></span>
                        <span className='bar bar2'></span>
                        <span className='bar bar3'></span>
                    </div>
                </button>
                {showConsole &&
                <div className={'collapsed-menu'}>
                    {/*кнопка показа дня/ночи*/}
                    <button type="button"
                            title={localeService.isRussian() ? 'Показать/Скрыть подсветку дня и ночи' : 'Show/Hide Day and Night Backlight'}
                            className={'btn btn-outline-primary default round-btn' + (dayAndNightDataSource.isVisible ? ' active' : '')}
                            onClick={() => {
                                setDayAndNightDataSource({
                                    ...dayAndNightDataSource,
                                    isVisible: !dayAndNightDataSource.isVisible
                                });
                            }}>
                        <ReactSVG src={svgShowDayAndNight}/>
                    </button>
                    {/*/!*кнопка настройки порогов*!/*/}
                    {limits && <SetChartLimits limits={limits} dataSources={widgetData.dataSources} onChange={el => {
                        const newLimits = widgetsService.calculateChartLimitAreas(el, widgetData.dataSources);
                        setLimits(newLimits);
                        saveLimitPresets(el);
                    }}/>}
                    {/*кнопка нормализации*/}
                    <button type="button"
                            title={localeService.isRussian() ? 'Нормализация' : 'Normalization'}
                            className={'btn btn-outline-primary default round-btn' + (normalization ? ' active' : '')}
                            onClick={() => setNormalization(!normalization)}>
                        <span style={{fontStyle: 'italic', fontSize: '0.8rem', fontWeight: 'bold'}}>N</span>
                    </button>
                    {/*кнопка экспорта данных*/}
                    <button type="button"
                            title={localeService.isRussian() ? 'Экспорт данных в CSV' : 'Export data to CSV'}
                            className={'btn btn-outline-primary default round-btn'} onClick={e => e.stopPropagation()}>
                        <CSVLink {...csvReport}>
                            <ReactSVG src={svgExportToCSV}/>
                        </CSVLink>
                    </button>
                    {/*/!*кнопка расчета пиковых часов*!/*/}
                    <AddPeakHoursToChart dataSources={widgetData.dataSources} onApplyFunction={peakHoursDS => {
                        if (peakHoursDS !== null) {
                            setPeakHoursDataSources([peakHoursDS]);
                        } else {
                            setPeakHoursDataSources([]);
                        }

                    }}/>
                    {/*кнопка показа данных в виде таблицы*/}
                    <ShowChartDataInTable dataSources={widgetData.dataSources}
                                          functionDataSources={functionDataSources}/>
                    {/*кнопка для расчета инфлюк-функций*/}
                    <AddFunctionToChart
                        onApplyFunction={functionDS => {
                            //добавляем настройки виджета в redux в предустановки
                            // addFunctionToPreset(functionDS);
                            setFunctionDataSourceList([...functionDataSources, functionDS]);
                        }}
                        dataSources={widgetData.dataSources} widgetWidth={size.width}/>
                    {/*кнопка для расчета DR функций*/}
                    <AddDemandFunctionToChart
                        onApplyFunction={functionDS => {
                            //добавляем настройки виджета в redux в предустановки
                            // addFunctionToPreset(functionDS);
                            setFunctionDataSourceList([...functionDataSources, functionDS]);
                        }}
                        dataSources={widgetData.dataSources} widgetWidth={size.width}/>
                    {/*кнопка для расчета функций прогнозирования*/}
                    <AddPredictionFunctionToChart
                        onApplyFunction={functionDS => {
                            //добавляем настройки виджета в redux в предустановки
                            // addFunctionToPreset(functionDS);
                            setFunctionDataSourceList([...functionDataSources, functionDS]);
                        }}
                        dataSources={widgetData.dataSources} widgetWidth={size.width}/>
                    {/*кнопка изменения группировки по времени*/}
                    <SeletTimeGroup timeGroup={timeGroup} datetimeFilter={datetimeFilter}
                                    onChangeTimeGroup={tg => onChangeTimeGroup(tg)}/>
                </div>
                }
            </div>
            {/*компонент для отрисовки графиков*/}
            <ComposedChart
                width={size.width}
                height={(100 * widgetProps.h) - 50}
                onMouseDown={(e) => e && !isFetching && setSelectedArea({...selectedArea, left: e.activeLabel})}
                onMouseMove={(e) => !isFetching && selectedArea.left && setSelectedArea({
                    ...selectedArea,
                    right: e.activeLabel
                })}
                onMouseUp={() => zoom()}>
                <CartesianGrid strokeDasharray="3 3"/>
                {/*Отображение дня/ночи */}
                {dayAndNightDataSource.isVisible && dayAndNightDataSource.data && dayAndNightDataSource.data.map((point, i) => {
                    if (i < dayAndNightDataSource.data.length - 1) {
                        return (<ReferenceArea x1={dayAndNightDataSource.data[i].time_upload}
                                               x2={dayAndNightDataSource.data[i + 1].time_upload}
                                               key={'day-n-night-k' + i}
                                               fillOpacity={0.2}
                                               fill={dayAndNightDataSource.data[i].color}/>);
                    }
                })}
                <XAxis
                    dataKey="time_upload"
                    tickFormatter={(number) => {
                        return moment.unix(number).format(moment.duration(datetimeFilter.finishDate.diff(datetimeFilter.startDate)).asHours() > 24 ? 'DD/MM HH:mm' : 'HH:mm:ss');
                    }}
                    allowDataOverflow={true}
                    type="number"
                    allowDecimals={false}
                    scale={'utc'}
                    domain={[moment(moment(datetimeFilter.startDate)).unix().valueOf(), moment(moment(datetimeFilter.finishDate)).unix().valueOf()]}
                />
                <YAxis dataKey="value"
                       type="number" domain={['auto', 'auto']}
                       tickFormatter={(value) => {
                           return numeral(value).format('0.0 a');
                       }}
                />
                <Tooltip
                    content={(e) => e.active && selectedArea.left === '' &&
                        <CustomTooltip label={e.label} widgetData={widgetData}
                                       functionDataSources={functionDataSources}/>}
                    cursor={{stroke: 'black', strokeWidth: 2, opacity: '0.7'}}/>

                {/*Добавляем пороги*/}
                {limits && limits.values.length > 0 && limits.isVisible &&
                limits.values.map((limit, lid) => {
                    return [
                        limit.areaData && !widgetData.dataSources.find(el => el.id === limit.dataSource.id).disabled && limit.areaData.map((areaData, i) => {
                            return <Area key={'limitArea' + limit.dataSource.id + i}
                                         type={widgetProps.widgetType === dashboardConstants.WIDGET_STEP_CHART ? 'stepAfter' : 'line'}
                                         dataKey="value"
                                         data={areaData}
                                         stroke={limit.color}
                                         fillOpacity={0.3}
                                         fill={limit.color}
                            />;
                        }),
                        <ReferenceLine key={'limit' + lid} y={limit.value} stroke={limit.color} label={limit.name}/>
                    ];
                })
                }
                {/*отображение дня/ночи*/}
                {dayAndNightDataSource.data &&
                <Line
                    type={'monotone'}
                    dataKey="value"
                    data={dayAndNightDataSource.data}
                    stroke={'#cccccc'}
                    key={dayAndNightDataSource.id}
                    name={'Day and Night'}
                    background={{fill: '#eee'}}
                    dot={false}
                    activeDot={false}
                />
                }
                {/*отображение графиков источников данных*/}
                {widgetData.dataSources && widgetData.dataSources.map((source) => {
                    if (!source.disabled) {
                        if (widgetProps.widgetType === dashboardConstants.WIDGET_LINE_CHART || widgetProps.widgetType === dashboardConstants.WIDGET_STEP_CHART) {
                            return (<Line
                                type={widgetProps.widgetType === dashboardConstants.WIDGET_STEP_CHART ? 'stepAfter' : 'line'}
                                dataKey="value"
                                data={widgetsService.modifyChartData(source.data, normalization)}
                                stroke={source.parameters.color}
                                strokeWidth={'2px'}
                                strokeOpacity={0.8}
                                name={source.parameters.dataSourceName}
                                key={'lineChart' + source.id}
                                background={{fill: '#eee'}}
                                dot={false}
                                activeDot={false}
                                animationDuration={0}
                            />);
                        }
                        if (widgetProps.widgetType === dashboardConstants.WIDGET_COLUMN_CHART) {
                            return (<Area
                                dataKey="value"
                                type={'stepAfter'}
                                data={widgetsService.modifyChartData(source.data, normalization)}
                                name={source.parameters.dataSourceName}
                                key={'areaChart' + source.id}
                                stroke={source.parameters.color}
                                fillOpacity={0.6}
                                fill={source.parameters.color}
                                animationDuration={0}
                                dot={false}
                                activeDot={false}
                            />);
                        }
                    }
                })}
                {/*отображение графиков функций*/}
                {functionDataSources && functionDataSources.map((source, i) => {
                    let functionSource = [];

                    /*Не показываем временной ряд для пердиктора Хольта-Винтерса от Питона*/
                    if (source.functionCode !== 'PREDICTION-HOLT-WINTERS') {
                        functionSource.push(<Line
                            type={widgetProps.widgetType === dashboardConstants.WIDGET_STEP_CHART ? 'stepAfter' : 'line'}
                            dataKey="value"
                            data={widgetsService.modifyChartData(source.outputsCount > 1 ? source.data[0] : source.data, normalization)}
                            stroke={source.parameters.color}
                            strokeWidth={'2px'}
                            name={source.parameters.dataSourceName}
                            key={'func_ds_' + i}
                            background={{fill: '#eee'}}
                            dot={false}
                            activeDot={false}
                            // strokeDasharray={'5 5'}
                            animationDuration={0}
                        />);
                    }
                    /*Показываем доверительный интервал пердиктора Хольта-Винтерса от Питона. Объединяем два ряда для отображения доверительного интервала*/
                    if (source.functionCode === 'PREDICTION-HOLT-WINTERS' && source.data.length === 3) {
                        const uppedLineData = widgetsService.modifyChartData(source.data[2], normalization);
                        const lowerLineData = widgetsService.modifyChartData(source.data[1], normalization);
                        functionSource.push(<Area
                            type={widgetProps.widgetType === dashboardConstants.WIDGET_STEP_CHART ? 'stepAfter' : 'line'}
                            dataKey='value' key={'porog_1' + i}
                            data={uppedLineData.map((upperLine, ind) => {
                                return {
                                    time_upload: upperLine.time_upload,
                                    value: [
                                        upperLine.value,
                                        lowerLineData[ind].value
                                    ]
                                };
                            })}
                            // stackId={i}
                            stroke='none'
                            dot={false}
                            fillOpacity={0.3}
                            fill='grey'/>);
                    }
                    return functionSource;
                })}

                {peakHoursDataSources && peakHoursDataSources.map((ds) => {
                    return ds.data.map((peakHour, i) => {
                        if (peakHour.value === 1) {
                            return <ReferenceArea key={'peakHour' + i} fill={ds.color} x1={peakHour.time_upload}
                                                  x2={peakHour.time_upload + 3600}
                                                  y1={null} y2={null} fillOpacity={0.2} strokeOpacity={0.3}
                            />;
                        }
                    });
                })}


                {/*Заливка для подсветки функций*/}
                <defs>
                    <linearGradient id="red" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="red" stopOpacity={0}/>
                        <stop offset="95%" stopColor="red" stopOpacity={0.4}/>
                    </linearGradient>
                    <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="green" stopOpacity={0}/>
                        <stop offset="95%" stopColor="green" stopOpacity={0.4}/>
                    </linearGradient>
                    <linearGradient id="grey" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="grey" stopOpacity={0}/>
                        <stop offset="95%" stopColor="grey" stopOpacity={0.4}/>
                    </linearGradient>
                </defs>

                {/* Подсветка для функций */}
                {functionBacklights && functionBacklights.map((backlight, i) => {
                    return <ReferenceArea key={i} fill={backlight.color} x1={backlight.x1} x2={backlight.x2}
                                          y1={backlight.y1 || null} y2={backlight.y2 || null}
                    />;
                })}

                {/*выделение области зума*/}
                {(selectedArea.left && selectedArea.right) &&
                <ReferenceArea x1={selectedArea.left} x2={selectedArea.right}
                               strokeOpacity={0.3}/>}
            </ComposedChart>
            <ShowDataSourceInfo dataSources={widgetData.dataSources} onChange={(dsList) => setWidgetData({...widgetData, dataSources: dsList})}/>
        </div>
    );

}

WidgetChartView.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    datetimeFilter: PropTypes.object,
    zoomIn: PropTypes.func,
    updateWidget: PropTypes.func
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

const mapDispatchToProps = {
    zoomIn: zoomIn,
    updateWidget: updateWidget
};
export default withSize()(connect(mapStateToProps, mapDispatchToProps)(WidgetChartView));
