import React from 'react';
import PropTypes from 'prop-types';
import {localeService} from '../../../services/locale.service';
import {dashboardConstants} from '../../../constants';
import Modal from '../../../components/Modal';
import {useTranslation} from 'react-i18next';
import ButtonWidgetCreate from './ButtonWidgetCreate';
import ButtonWidgetView from './ButtonWidgetView';

function ButtonWidget({widgetProps, mode, closeEditSingleMode}) {
    const {t} = useTranslation();
    return <>
        {mode === dashboardConstants.VIEW_MODE &&
        <ButtonWidgetView widgetProps={widgetProps}/>
        }
        {mode === dashboardConstants.EDITION_MODE &&
        <form>
            <div style={{
                // height: (100 * widgetProps.h) - (widgetProps.name !== '' ? 100 : 60),
                display: 'flex',
                alignItems: 'center',
                // marginTop: widgetProps.name === '' ? '-2rem' : 0,
                pointerEvents: 'none'
            }}>
                <button className={'btn btn-outline-success form-control'}
                        disabled={true}>{(widgetProps.parameters && widgetProps.parameters.btnName) || t('data_setter')}</button>
            </div>
        </form>
        }
        {mode === dashboardConstants.EDIT_SINGLE_MODE &&
        <Modal isOpen={mode === dashboardConstants.EDIT_SINGLE_MODE} isClosable={false} footer={false}
               onCancel={() => closeEditSingleMode()}
               title={localeService.isRussian() ? 'Настройка виджета' : 'Edit Widget'}>
            <ButtonWidgetCreate widgetProps={widgetProps} onClose={() => closeEditSingleMode()}/>
        </Modal>
        }
    </>;
}

ButtonWidget.propTypes = {
    widgetProps: PropTypes.object,
    closeEditSingleMode: PropTypes.func,
    mode: PropTypes.oneOf([dashboardConstants.EDIT_SINGLE_MODE, dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
};

export default ButtonWidget;