import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import CheckBox from '../../../../components/CheckBox';
import InputText from '../../../../components/InputText';
import DictOfTime from '../../../../components/DictOfTime';
import {nanoid} from 'nanoid';

function SetStrategyArgs({funcArgs, onChange, formName}) {

    const [newArgs, setNewArgs] = React.useState(funcArgs);
    const [isOpen, setOpen] = React.useState(false);

    return (
        <>
            <div className={'d-flex me-5'}>
                <button className={'btn btn-link'} onClick={() => setOpen(!isOpen)}>{'Параметры ' + formName}</button>
                {/*<CheckBox key={'isOn'} label={'Использовать ' + formName}*/}
                          {/*checked={newArgs[0].value}*/}
                          {/*onChange={(checked) => {*/}
                              {/*let fArgs = [...newArgs];*/}
                              {/*newArgs[0].value = checked;*/}
                              {/*if(checked){*/}
                                  {/*setOpen(true);*/}
                              {/*}else{*/}
                                  {/*fArgs.map((fArg) => {*/}
                                      {/*if (fArg.type === 'DICT_OF_TIME') {*/}
                                          {/*fArg.value = [];*/}
                                      {/*}*/}
                                  {/*});*/}
                              {/*}*/}
                              {/*setNewArgs(fArgs);*/}
                              {/*onChange(fArgs);*/}
                          {/*}}/>*/}
            </div>
            <Modal isOpen={isOpen} onCancel={() => setOpen(false)} isClosable={false} title={formName} width={800}
                   onSubmit={() => {
                       onChange(newArgs);
                       setOpen(false);
                   }}>
                <form>
                {newArgs.map((fArg, i) => {
                    if (fArg.type === 'BOOLEAN') {
                        return <CheckBox key={'fArg' + i} label={fArg.name}
                                         checked={fArg.value}
                                         onChange={(checked) => {
                                             let fArgs = [...newArgs];
                                             fArgs[i].value = checked;
                                             setNewArgs(fArgs);
                                         }}/>;
                    }
                    if (fArg.type === 'FLOAT') {
                        return <InputText key={'fArg' + i} label={fArg.name} value={fArg.value} isDecimal={true}
                                          onChange={(text) => {
                                              let fArgs = [...newArgs];
                                              fArgs[i].value = text;
                                              setNewArgs(fArgs);
                        }}/>;
                    }
                    if (fArg.type === 'DICT_OF_TIME') {
                        return <DictOfTime key={nanoid()} label={fArg.name} value={fArg.value} onChange={dot => {
                            let fArgs = [...newArgs];
                            fArgs[i].value = dot;
                            setNewArgs(fArgs);
                        }}/>;
                    }
                })}
                </form>
            </Modal>
        </>
    );
}

SetStrategyArgs.propTypes = {
    funcArgs: PropTypes.array,
    onChange: PropTypes.func,
    dateTimeFilter: PropTypes.object,
    formName: PropTypes.string
};

export default SetStrategyArgs;