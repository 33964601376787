import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import svgSettings from '../../../../sources/images/interface/settings.svg';
import Modal from '../../../../components/Modal';
import {useTranslation} from 'react-i18next';
import {localeService} from '../../../../services/locale.service';
import Loader from '../../../../components/Loader';
import {analyticService} from '../../../../services/analyticService';
import AlertError from '../../../../components/AlertError';
import AddParametersToDemandFunction from '../../../../components/AddAnalyticParametersToChart';

//компонент для расчета аналитических функций для виджета "Функция"
function AddDemandParametersToFunctionWidget({dataSource, datetimeFilter, analyticFunctionList, onApplyFunction}) {
    //библиотека для перевода
    const {t} = useTranslation();
    //сотояние открытия диалогового окна для настройки
    const [isOpen, setOpen] = useState(false);
    //состояние загрузки
    const [isFetching, setFetching] = useState(false);
    //параметры функции
    const [functionParams, setFunctionParams] = useState(null);
    //инициализация параметров виджета
    const widgetParams = dataSource.parameters;
    //ингициализация ошибки
    const [error, setError] = useState(null);
    //функция-обработчик события изменения фильтра времени
    useEffect(() => {
        const analyticFunctionIdx = analyticFunctionList.findIndex(el => el.code === dataSource.parameters.functionCode);
        setFunctionParams({
            args: analyticService.initAnalyticFunctionParameters(analyticFunctionList[analyticFunctionIdx], datetimeFilter),
            code: widgetParams.functionCode,
            name: widgetParams.functionName
        });
    }, [datetimeFilter]);
    //функция расчета аналитики
    const calculateFunction = () => {
        setFetching(true);
        //запрос на расчет аналитики
        analyticService.calculateAnalyticFunction(dataSource.id, functionParams, datetimeFilter).then(
            data => {
                if (!data || data.length === 0) {
                    setError(localeService.isRussian() ? 'Не удалось применить функцию. Попробуйте использовать другие параметры.' : 'Cannot apply function. Try to change function parameters.');
                }else{
                    setOpen(false);
                    if(widgetParams.functionCode === 'DEMAND_RESPONSE_RRMSE'){
                        onApplyFunction(data[0].value);
                    }else{
                        onApplyFunction(data.findIndex(el => el.value === 0) >= 0 ? 'false' : 'true');
                    }
                }
            }
        ).finally(
            () => {
                setFetching(false);
            }
        );
    };

    return <>
        {/*кнопка для открытия диалогового окна с конфигурацией параметров функции*/}
        <button className={'btn btn-outline-primary default round-btn'}
                title={localeService.isRussian() ? 'Конфигурация функции' : 'Function Configuration'}
                style={{position: 'absolute', right: '1rem', top: 0}}
                onClick={() => setOpen(true)}>
            <ReactSVG src={svgSettings}/>
        </button>
        {/*диалоговое окно с конфигурацией параметров функции*/}
        <Modal isOpen={isOpen} footer={false} isClosable={false} width={800}
               title={localeService.isRussian() ? 'Конфигурация функции' : 'Function Configuration'}>
            {isFetching && <Loader/>}
            {!isFetching && !error && functionParams &&
            <form>
                {/*вызов компонентов с настройками аргумента функции*/}
                {functionParams.args && functionParams.args.length > 0 &&
                <AddParametersToDemandFunction datetimeFilter={datetimeFilter}
                                               demandFunction={functionParams}
                                               onChangeParam={func => {
                                                   setFunctionParams({...functionParams, ...func});
                                               }}/>
                }
                <hr/>
                <div className={'d-flex justify-content-between'}>
                    {/*отмена расчета*/}
                    <button className={'btn btn-outline-danger'} onClick={() => setOpen(false)}>{t('cancel')}</button>
                    {/*применение расчета*/}
                    <button type="button" className={'btn btn-outline-success'} onClick={(e) => {
                        e.preventDefault();
                        calculateFunction();
                    }}>{t('apply')}</button>
                </div>
            </form>
            }
            <AlertError isOpen={error !== null} message={error} onCancel={() => setError(null)}/>
        </Modal>
    </>;
}

AddDemandParametersToFunctionWidget.propTypes = {
    dataSource: PropTypes.object,
    datetimeFilter: PropTypes.object,
    analyticFunctionList: PropTypes.array,
    onApplyFunction: PropTypes.func
};

export default AddDemandParametersToFunctionWidget;
