import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../../components/Modal';
import moment from 'moment/moment';
import ArrayOfHoursSimple from '../../../../../components/ArrayOfHoursSimple';


function ChangeHoursWindow({hoursWindow, onChange}) {
    const initRange = () => {
        const min = moment.unix(hoursWindow.start).hours();
        const max = moment.unix(hoursWindow.finish).hours();
        let range = [];
        let i = min;
        while(i<max){
            range.push(i++);
        }
        return range;
    };

    const onCancel = () => {
        setRange(initRange());
        setOpen(false);
    };

    const [range, setRange] = React.useState(initRange());

    React.useEffect(() => {
        setRange(initRange());
    }, [window]);


    const [isOpen, setOpen] = React.useState(false);

    return (
        <>
            <div className={'d-flex align-items-center ms-4'}>
                <button className={'btn btn-link'} onClick={() => setOpen(true)}>Изменить время разгрузки</button>
            </div>
            <Modal isOpen={isOpen} width={800}
                   title={'Изменить временное окно разгрузки'}
                   onCancel={() => setOpen(false)} footer={false}>
                <form>
                    <ArrayOfHoursSimple key={'unload_window'} values={range}
                                        onChange={hours => {
                                            setRange(hours);
                                        }} label={'Временное окно разгрузки'}/>
                </form>
                <div className={'modal-footer'}>
                    <button className={'btn btn-outline-secondary'} onClick={() => onCancel()}>Отмена</button>
                    <button className={'btn btn-outline-success'} onClick={() => {
                        let from = moment.unix(hoursWindow.start).hours(Math.min(...range));
                        let to = moment.unix(hoursWindow.start).hours(Math.max(...range)+1);
                        onChange({start:from.unix(), finish: to.unix()});
                        setOpen(false);
                    }}>Применить
                    </button>
                </div>
            </Modal>

        </>
    );
}

ChangeHoursWindow.propTypes = {
    hoursWindow: PropTypes.object,
    onChange: PropTypes.func
};

export default ChangeHoursWindow;