import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

function StrategyTooltip({label, widgetData, functionDataSources}) {
    let flexValue = null;
    const fIdx = functionDataSources.findIndex(el => el.time_upload === label);
    if (fIdx >= 0) {
        flexValue = Number.parseFloat(functionDataSources[fIdx].value).toFixed(2);
    } else {
        flexValue = null;
    }

    return (
        <div className="custom-tooltip"
             style={{backgroundColor: '#ffffffcf', zIndex: '200', padding: '20px', fontSize: '80%'}}>
            <p><b>{label && moment.unix(label).format('DD/MM/YY HH:mm:ss')}</b></p>
            {widgetData.map((source, i) => {
                if (source.props.data) {
                    const idx = source.props.data.findIndex(el => el.time_upload === label);
                    if (idx >= 0) {
                        return (<div key={'source_tooltip_' + i}>
                            <i style={{borderBottom: '2px solid ' + source.props.parameters.color}}>
                                <small>
                                    {source.props.parameters.dataSourceName}
                                    {source.props.parameters.unit ? (', ' + source.props.parameters.unit) : ''}
                                </small>
                            </i>
                            <b style={{marginLeft: '1rem'}}> {Number.parseFloat(source.props.data[idx].value).toFixed(2)}</b>
                        </div>);
                    }
                }
            })}
            {flexValue &&
                <div key={'function_tooltip'}>
                    <i style={{borderBottom: '2px solid blue'}}>
                        <small>Flexibility</small>
                    </i>
                    <b style={{marginLeft: '1rem'}}>{flexValue}</b>
                </div>
            }

        </div>
    );
}

StrategyTooltip.propTypes = {
    label: PropTypes.number,
    widgetData: PropTypes.object,
    functionDataSources: PropTypes.array
};

export default StrategyTooltip;