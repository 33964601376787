/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {dashboardService} from '../../../services';
import {withSize} from 'react-sizeme';
import {connect} from 'react-redux';
import {dashboardConstants} from '../../../constants';
import Loader from '../../../components/Loader';
import {localeService} from '../../../services/locale.service';
import moment from 'moment';
import SelectDateMultiple from '../../../components/SelectDateMultiple';
import '../../../styles/capacity-profile.scss';
import {capacityCostCalculationService} from '../../../services/capacityCostCalculation.service';
import Modal from '../../../components/Modal';
import DictOfTime from '../../../components/DictOfTime';
import {hoursLabels} from '../../../constants/widgets.constants';
import EmptyData from '../../../components/EmptyData';

function CapacityProfileView({widgetProps, datetimeFilter}) {
    //инициализация массива данных
    const [dataArray, setDataArray] = useState([]);
    //инициализация массива дат
    const [dateArray, setDateArray] = useState([]);
    //инициализация состояния обновления
    const [isUpdating, setUpdating] = useState(false);
    //инициализация списка выбранных дат
    const [selectedDateList, setSelectedDateList] = useState([]);
    //инициализация вспомогательных переменных
    const [sumCapacity, setSumCapacity] = useState([]);
    const [sumElPower, setElPower] = useState([]);
    const [avgMaxCapacity, setAvgMaxCapasity] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [pickHours, setPickHours] = useState([]);
    const [peakHour, setPeakHour] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const arr = Array(24).fill('-');
    //инициализация функции получения данных
    const getDataArray = (result) => {
        const dates = getDateArray(datetimeFilter);
        let sumMaxCapacity = 0;
        let Cdata = [];
        if (result != null && result.length > 0) {
            let dayNumber = Math.floor(result.length / 24);
            let k = 0;
            let sum = 0;
            Cdata = new Array(dates.length);
            for (let i = 0; i < dates.length; i++) {
                if (dayNumber > i) {
                    let arr2 = new Array(hoursLabels.length);
                    for (let j = 0; j < hoursLabels.length; j++) {
                        arr2[j] = result[k].value;
                        sum += result[k].value;
                        k++;
                    }
                    Cdata[i] = arr2;
                    sumMaxCapacity += Math.max.apply(null, Cdata[i]);
                }
                else Cdata[i] = arr;
            }
            setSumCapacity(sum);
            setAvgMaxCapasity((sumMaxCapacity / dayNumber).toFixed(2));
        }
        return Cdata;
    };
    //инициализация функции получения времени
    const getDateArray = (datetimeFilter) => {
        const dateCount = datetimeFilter.finishDate.diff(datetimeFilter.startDate, 'days') + 1;
        let date = moment(datetimeFilter.startDate).subtract(1, 'days');
        let availableDates = Array.from(Array(dateCount), () => {
            date = moment(date).add(1, 'days');
            return date;
        });
        return availableDates;
    };
    //инициализация функции получения пиковых часов
    const getPickHours = (hoursData) => {
        let firstDay = hoursData.slice(0, 24);
        return firstDay.map((el) => el.value);
    };
    //инициализация функции заполнения строки
    const addRow = (selectValues) => {
        let hoursSet = [];
        selectValues.map((el) => {
            let start = el.startsWith('0') ? el[1] : el.substring(0, 2);
            let end = el[9] === '0' ? parseInt(el[10]) : parseInt(el.substring(9, 11));
            for (let i = parseInt(start); i < end + 1; i++) hoursSet.push(i);
        });
        setSelectedRows(hoursSet);
    };
    //инициализация функции установки часа
    const setHours = () => {
        setUpdating(true);
        let additionalID;
        widgetProps.dataSources.map((dataSource) => {
            if (dataSource.parameters.type === 'PEAK_HOURS_DATASOURCE') additionalID = dataSource.id;
        });
        for (let i = 0; i < hoursLabels.length; i++) {
            let isSelect = selectedRows.indexOf(i) > -1 ? true : false;
            if (isSelect) {
                dateArray.map((date) => {
                    const dateSet = moment(date).add(i, 'hours').format('YYYY-MM-DD HH:mm:ss');
                    dashboardService.widgetDataSet(additionalID, dateSet, 1);
                });
            }
            else {
                dateArray.map((date) => {
                    const dateSet = moment(date).add(i, 'hours').format('YYYY-MM-DD HH:mm:ss');
                    dashboardService.widgetDataSet(additionalID, dateSet, 0);
                });
            }
        }
        setUpdating(false);
    };
    //инициализация функции расчета среднего значения
    const findAvgNetPower = () => {
        let sum = [];
        {
            dateArray.map((date, ind) => {
                let netVal = [];
                if (date.get('day') != 6 && date.get('day') != 0 && selectedDateList.findIndex(el => el.isSame(date, 'day')) < 0) {
                    dataArray[ind].map((val, i) => {
                        if (pickHours[i] === 1 && dataArray[ind][i] != '-') netVal.push(val);
                    });
                    if (netVal.length > 0) sum.push(Math.max.apply(null, netVal));
                }
            });
        }
        const avg = sum.length > 0 ? sum.reduce((a, b) => a + b) : 0;
        if (avg > 0) return (avg / sum.length).toFixed(2);
        else return 0;
    };

    const netVolume = () => {
        let avgNet = findAvgNetPower();
        return ((sumCapacity / avgNet) * 12).toFixed(2);
    };

    const getClass = (i, k) => {
        let peaks = [];
        peakHour.map((el) => {
            if (el.value === 1)
                peaks.push(moment.unix(el.time_upload).utc().format('D HH'));
        });
        let str = i < 10 ? (k + 1) + ' ' + '0' + i : (k + 1) + ' ' + i;
        if (peaks.includes(str)) return 'peak-hour';

    };

    const findPeak = (list) => {
        let peaks = [];
        list.map((el) => {
            if (el.value === 1)
                peaks.push(moment.unix(el.time_upload).utc().format('D HH'));
        });
        return peaks;
    };

    const findElPower = (ind) => {
        let i = ind < 9 ? (ind + 1 + ' ') : (ind + 1);
        let row = -1;
        sumElPower.map((val) => {
            if (selectedDateList.findIndex(el => el.isSame(dateArray[ind], 'day')) < 0) {
                if (val.startsWith(i)) row = val.substring(val.length, val.length - 2);
            }
        });
        if (row > -1) {
            row = row.startsWith('0') ? row.substring(1) : row;
            return dataArray[ind][row];
        }
    };

    const findAvgEl = () => {
        let avg = 0;
        for (let i = 0; i < dateArray.length; i++) {
            if (selectedDateList.findIndex(el => el.isSame(dateArray[i], 'day')) < 0) {
                let num = findElPower(i);
                if (typeof(num) == 'number') avg += num;
            }
        }
        avg = (avg / sumElPower.length).toFixed(2);
        return avg;
    };

    const elVolume = () => {
        let avgEl = findAvgEl();
        return ((sumCapacity / avgEl) * 12).toFixed(2);
    };

    /**
     *Обновление данных по изменению @datetimeFilter
     * */
    useEffect((() => {
        setUpdating(true);
        const dates = getDateArray(datetimeFilter);
        setDateArray(dates);

        if(widgetProps.dataSources.length > 0) {

            // Определяем методы доступные для ценовой категории
            if (widgetProps.parameters.method) {
                let methodList = [{
                    name: 'peak_hours',
                    methodName: 'peak_hours',
                    description: 'Часы пиковой нагрузки',
                }];
                let args = [{
                    name: 'start_peaks_date',
                    count: 1,
                    values: [datetimeFilter.startDate.format('YYYY-MM-DD HH:mm:ss')],
                }];
                capacityCostCalculationService.getPeakHour(methodList, args, widgetProps, datetimeFilter).then(
                    response => {
                        if (response && response[0].value && response[0].value.length > 1) {
                            setPeakHour(response[0].value);
                            setElPower(findPeak(response[0].value));
                        }
                    }
                );
            }

            widgetProps.dataSources.map((dataSource) => {
                if (dataSource.parameters.type === 'PEAK_HOURS_DATASOURCE') {
                    dashboardService.getDashboardWidgetDataSourceCapacityProfile(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate)
                        .then(pickHours => {
                            const pHours = getPickHours(pickHours.list);
                            setPickHours(pHours);
                            return pHours;
                        });
                }
                if (dataSource.parameters.type === 'MAIN_DATASOURCE') {
                    dashboardService.getDashboardWidgetDataSourceCapacityProfile(dataSource.id, datetimeFilter.startDate, datetimeFilter.finishDate)
                        .then(result => {
                                const datas = getDataArray(result.list);
                                setDataArray(datas);
                            }
                        ).finally(() => setUpdating(false));
                }
            });

        }else{
            setUpdating(false);
        }
    }), [datetimeFilter]);
    //рендеринг кода
    return <div className={'widget-content'}>
        <Modal onCancel={() => setOpen(false)} isOpen={isOpen}
               onSubmit={() => {
                   setHours();
                   setOpen(false);
               }}
               isClosable={false}
               title={localeService.isRussian() ? 'Укажите часы замера сетевой мощности' : 'Select peak hours'}>
            <form>
                <DictOfTime key={'select-peaks'}
                            label={'Часы замера'}
                            onChange={(newValues) => {
                                addRow(newValues);
                            }}/>
            </form>
        </Modal>
        {!isUpdating && dataArray.length > 0 &&
        <div>
            <div className={'widget-management-console'}>
                <button type="button" className={'btn btn-outline-primary default mr1'} onClick={() => setOpen(true)}>Часы замера
                    сетевой мощности
                </button>
                <SelectDateMultiple datetimeFilter={datetimeFilter}
                                    onSetDateList={(dateList) => {
                                        setSelectedDateList(dateList);
                                    }}
                                    label={localeService.isRussian() ? 'Выбрать выходные дни' : 'Add Target days'}
                />
            </div>
            <div className={'row'}>
                <div className='legend'>
                    <div className={'legend-item'}>
                        <span className={'marker'} style={{backgroundColor: '#6495ED'}}/>
                        <label>Максимальное значение</label>
                    </div>
                    <div className={'legend-item'}>
                        <span className={'marker'} style={{backgroundColor: '#FFD700'}}/>
                        <label>Часы замера эл.мощности</label>
                    </div>
                    <div className={'legend-item'}>
                        <span className={'marker'} style={{backgroundColor: '#ff7f50'}}/>
                        <label>Часы замера сетевой мощности</label>
                    </div>
                    <div className={'legend-item'}>
                        <span className={'marker'} style={{backgroundColor: '#FA8072'}}/>
                        <label>Выходные</label>
                    </div>
                </div>
            </div>
        </div>
        }
        {widgetProps.dataSources.length === 0 && <EmptyData/>}
        {isUpdating && <Loader waitText={localeService.isRussian() ? 'Загрузка данных...' : 'Uploading data...'}/>}
        {!isUpdating && dataArray != null && dataArray.length > 0 &&
        <div>
            <table className='capacity-table' id='capacity-table'>
                <tbody>
                <tr>
                    <td>{localeService.isRussian() ? 'Часы' : 'Hours'}</td>
                    {dateArray.map((date, d) => {
                        let className = '';
                        let color = 'white';
                        if (selectedDateList.findIndex(el => el.isSame(date, 'day')) >= 0) {
                            className = 'weekend';
                            color = '#FA8072';
                        }
                        if (date.get('day') === 6 || date.get('day') === 0) {
                            className = 'weekend';
                            color = '#FA8072';
                        } else className = 'workday';

                        return <td key={d}
                                   className={className}
                                   style={{background: color, borderRadius: '0.5rem'}}>
                            {moment(date).format('DD')}
                        </td>;
                    })}
                </tr>
                {!isUpdating && dataArray != null && dataArray.length > 0 &&
                hoursLabels.map((el, i) => {
                    let color = 'white';
                    if (pickHours[i] == 1) color = '#ff7f50';
                    return (<tr key={'h-' + i}>
                            <td id={'td-h' + i}
                                style={{background: color, border: '1px solid #ccc', borderRadius: '0.5rem'}}>{el}</td>
                            {dateArray.map((date, k) => {
                                let classN = '';
                                if (peakHour.length > 1) classN = getClass(i, k);

                                let max = Math.max.apply(null, dataArray[k]);
                                if (dataArray[k][i] === max) classN = 'is-max';

                                if (k < dataArray.length)
                                    return (<td key={date + '-h'} className={classN}
                                                style={{fontSize: 12 + 'px'}}>{dataArray[k][i]}</td>);
                            })}
                        </tr>
                    );
                })
                }
                <tr className='forecast-row'>
                    <td>{localeService.isRussian() ? 'Вероятность прогноза' : 'Forecast probability'}</td>
                    {dateArray.map((date, k) => {
                        return (<td key={k + 'd'} style={{fontSize: 7 + 'px'}}></td>);
                    })}
                </tr>
                </tbody>
            </table>
            <label className='network'>ЧЧИ сет. М: {netVolume()}</label>
            <label className='electricity'>ЧЧИ эл. М: {elVolume()}</label>
            Всего за месяц: <label className='total'>{sumCapacity.toFixed(2)}</label>
        </div>
        }
    </div>;
}


CapacityProfileView.propTypes = {
    widgetProps: PropTypes.object,
    mode: PropTypes.oneOf([dashboardConstants.EDITION_MODE, dashboardConstants.VIEW_MODE]),
    size: PropTypes.object,
    datetimeFilter: PropTypes.object
};

const mapStateToProps = state => {
    const datetimeFilter = state.dashboardReducer.datetimeFilter;
    return {datetimeFilter};
};

export default withSize()(connect(mapStateToProps, null)(CapacityProfileView));